import React from "react";
// Customizable Area Start
import {
    withStyles, StyleRules, styled
} from "@material-ui/core/styles";
import AdminNavbarController, { Props } from "./AdminNavbarController.web";
import { Avatar, Box, IconButton, TextField } from "@material-ui/core";
import { adminProfile, adminNotification } from "./assets";
import SearchIcon from "@material-ui/icons/Search";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json')
import TranslateIcon from '@material-ui/icons/Translate';

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start

    // Customizable Area End
}

export class AdminNavbar extends AdminNavbarController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start

    // Customizable Area End
    render() {
        // Customizable Area Start
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (
            <AdminNavWrapper>
                <Box style={classes.searchFieldWrapper}>
                    <SearchIcon style={classes.searchIcon} />
                    <SearchTextField
                        id="blankInput"
                        data-test-id="searchInputTestID"
                        fullWidth
                        variant="outlined"
                        placeholder={languageData.Search}
                        inputProps={{ 'aria-label': 'description' }}
                    />
                </Box>
                <Box style={classes.avatarWrapper}>
                    <CustomIconButton style={classes.changeLanguage} onClick={this.handleChangeLanguage}><TranslateIcon style={{color: "#0F172A", fontSize: "22px"}}/></CustomIconButton>
                    <CustomIconButton onClick={this.navigateToNotification}><Avatar id="notfication-test" style={classes.adminNotification} src={adminNotification}></Avatar></CustomIconButton>
                    <CustomIconButton onClick={this.navigateToProfile}><Avatar id="test-profilepic" style={classes.adminProfile} src={adminProfile}></Avatar></CustomIconButton>
                </Box>
            </AdminNavWrapper>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withStyles(styles)(AdminNavbar);

const AdminNavWrapper = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin : "0px 0px 14px 0px",
    boxShadow:"0px 2px 8px 0px #00000014",
    padding : "20px 56px 20px 32px",
    gap: "5px",
    '@media (min-width: 1280px) and (max-width: 1350px)': {
        width: "940px"
    },
    '@media (max-width: 500px)': {
        padding : "20px 15px"
    }
});

const CustomIconButton = styled(IconButton)({
    '@media (max-width: 500px)': {
        padding: "8px"
    }
});

const SearchTextField = styled(TextField)({
    flexGrow: 1,
    maxWidth: "320px",
    '& .MuiOutlinedInput-notchedOutline': {

        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                border: "none !important",
                boxShadow: "none !important"
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const classes = {
    searchFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "10px 8px",
        height: "24px",
        maxWidth: "504px"
    },
    searchIcon: {
        marginLeft: '3.48px',
        color: "#94A3B8"
    },
    avatarWrapper: {
        height: "48px",
        width: "200px",
        display: "flex",
        gap: "5px",
        justifyContent: "space-between",
        alignItems: "center"
    },
    adminNotification: {
        height: "19.5px",
        width: "16px",
        cursor: "pointer"
    },
    adminProfile: {
        height: "48px",
        width: "48px",
        borderradius: "100px",
        border: "3px solid #DBEAFE",
        cursor: "pointer"
    },
    changeLanguage: {
        cursor: "pointer",
    }
}
// Customizable Area End
