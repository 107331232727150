import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { removeStorageData, getStorageData } from "framework/src/Utilities";

const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');

interface AuditLogAttributes {
    subject: string;
    action: string;
    created_at: string;
    user_name: string;
    email: string;
    profile_image: string | null;
};

interface AuditLog {
    id: string;
    type: string;
    attributes: AuditLogAttributes;
};

interface AuditLogResponse {
    data: AuditLog[],
    meta: {
        total_count: number;
        total_pages: number;
        current_page: number;
    }
};
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    anchorEl: HTMLElement | null;
    currentLanguage: 'ar' | 'en',
    headingText: string,
    searchTerm: string;
    startDate: string;
    endDate: string;
    loading: boolean,
    auditLogData: AuditLog[];
    dot: HTMLElement | null;
    totalCount: number;
    currentPage: number;
    pageCount: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class DashboardController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getAuditLogCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            anchorEl: null,
            currentLanguage: 'en',
            headingText: "",
            searchTerm: "",
            startDate: "",
            endDate: "",
            loading: true,
            auditLogData: [],
            dot: null,
            totalCount: 0,
            currentPage: 1,
            pageCount: 1
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if(!responseJson.errors){
                    if(apiRequestCallId === this.getAuditLogCallId) {
                        this.handleAuditLogSuccessResponse(responseJson);
                    }
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const heading = languageTranslationData[language].Profile;
        this.setState({
            currentLanguage: language,
            headingText: heading,
        });
        this.getAuditLogApi(1);
        // Customizable Area End
    }

    getAuditLogApi = async (page: number) => {
        const { searchTerm, startDate, endDate } = this.state;
        const params = `per_page=10&page=${page}&query=${searchTerm}&from=${startDate}&to=${endDate}`;
        const headers = {
            "Content-Type": configJSON.validationApiContentType,
            "token" : await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getAuditLogCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.auditLogEndpoint}?${params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleAuditLogSuccessResponse = (responseJson: AuditLogResponse) => {
        if((responseJson.meta.total_count === 0 || responseJson.meta.total_count < 11) && responseJson.meta.current_page !== 1) {
            this.getAuditLogApi(1);
        } else {
            this.setState({
                auditLogData: responseJson.data,
                totalCount: responseJson.meta.total_count,
                currentPage: responseJson.meta.current_page,
                pageCount: responseJson.meta.total_pages,
                loading: false
            });
        }
    };

    handleDrawerNavigation1 = (routing: string) => {
        if (routing === "logOut") {
            this.logoutHandle1()
        } else {
            this.handleNavigatingRoute1(routing);
        }
    };

    logoutHandle1 = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigatingRoute1("Home");
    };
    handleNavigatingRoute1 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handlePageChange = (page: number) => {
        this.getAuditLogApi(page);
    };

    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        switch (value) {
            case "Created":
                changeBackground = "#FEF3C7";
                changeColor = "#D97706";
                break;
            case "Deleted":
                changeBackground = "#FEE2E2";
                changeColor = "#DC2626";
                break;
            case "Logged In":
                changeBackground = "#DBEAFE";
                changeColor = "#1D4ED8";
                break;
            case "Logged Out":
                changeBackground = "#F1EFFF";
                changeColor = "#6C5CCA";
                break;
            case "Listing Published":
                changeBackground = "#F1F5F9";
                changeColor = "#475569";
                break;
            case "Profile Updated":
                changeBackground = "#F9FAD1";
                changeColor = "#898B28";
                break;
            case "Payment Made":
                changeBackground = "#F8E2FE";
                changeColor = "#873F9B";
                break;
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            searchTerm: event.target.value
        }, ()=> this.getAuditLogApi(this.state.currentPage));
    };

    handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorEl:event.currentTarget});
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        })
    };

    handleFilterDateChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        this.setState({
          [name]: event.target.value
        }as unknown as Pick<S, keyof S>);
    };

    handleFilterReset = () => {
        this.setState({
          startDate: "",
          endDate: ""
        }, ()=> this.getAuditLogApi(this.state.currentPage));
    };

    formatDate(isoString: string) {
        const date = new Date(isoString);
        const day = date.getUTCDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        return `${day} ${month} ${year}`;
    };

    // Customizable Area End
}
