import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/RequestVerificaion.json');
import {getStorageData } from "framework/src/Utilities";

interface CompanyImage {
  file_name: string;
  url: string;
  size: string;
}

interface CompanyDocument {
  file_name: string;
  url: string;
  size: string;
}

interface BusinessProfile {
  name: string;
  website: string;
  industry: string;
  registration: string;
  country: string;
  state: string;
  city: string;
  zip: string;
  services: any[]; 
  bio: string | null;
  account_id: number;
  confirmed: boolean;
  image: CompanyImage | null;
  documents: CompanyDocument[];
}

interface ProviderAccountImages {
  address_proof_image: CompanyImage;
  id_proof_image: CompanyImage;
}

interface ProviderAccount {
  full_name: string | null;
  activated: boolean;
  first_name: string | null;
  last_name: string | null;
  full_phone_number: string;
  phone_number: string;
  country_code: string;
  email: string;
  gender: string | null;
  type: string;
  user_type: string;
  sub_type: string;
  user_name: string | null;
  address: string | null;
  city: string;
  state: string;
  country: string;
  zipcode: string | null;
  business_name: string | null;
  tax_number: string | null;
  created_at: string;
  updated_at: string;
  device_id: string | null;
  unique_auth_id: string;
  kyc_name: string | null;
  kyc_number: string | null;
  kyc_verified: boolean | null;
  date_of_birth: string | null;
  bank_name: string | null;
  agency_name: string;
  service_provider_name: string | null;
  email_otp_verify: boolean;
  commercial_number: string;
  license_number: string;
  signup_status: string;
  last_visit_at: string | null;
  profile_image: string;
  images: ProviderAccountImages;
};

export const nameObject: Record<string, keyof ProviderAccount>  = {
    individual: "full_name",
    investor: "full_name",
    bank: "bank_name",
    agency: "agency_name",
    service_provider: "service_provider_name"
}

interface DeptPortfolio {
  portfolio_size: number;
  account_size: number;
  dept_age: number;
  average_balance: number;
  dept_type: number;
  recovery_status: number;
}

interface Company {
  business_profile: BusinessProfile;
  provider_account: ProviderAccount;
  dept_portfolio: DeptPortfolio;
}

interface Timeline {
    id: number;
    desc: string;
    verification_request_id: number;
    created_at: string;
    updated_at: string;
    business_risk: string;
    supporting_documents: CompanyDocument[]
};

interface RequestVerificationAttributes {
  uuid: string;
  full_name: string;
  status: string;
  business_risk: string;
  registration_number: string;
  verification_type: string;
  premium: string;
  request_date: string;
  details: string;
  Company: Company;
  timelines: Timeline[];
}

interface RequestVerificationData {
  id: string;
  type: string;
  attributes: RequestVerificationAttributes;
}

interface ValidResponse {
  data: RequestVerificationData;
  message: string;
};

  
// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs : Array<"All" | "New" | "In review" |"Draft" |"Archived">,
    selectedTab : "All" | "New" | "In review" |"Draft" |"Archived",
    rowsPerPage : number,
    page : number,
    expanded: boolean,
    accord: boolean,
    companyData: {
        HeadText: string;
        smallText: string;

    }[];
    currentLanguage: "ar" | "en",
    showDetail: boolean,
    breadscrumValues: string[],
    userType: string;
    businessRisk: string;
    data: RequestVerificationData;
    isSubmit: boolean;
    documentArray: (File | null)[];
    statusToChange: "pending" | "accepted" | "rejected";
    submitCheck: boolean;
    businessRiskErr: string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    
    // Customizable Area End
}

export default class RequestManagementController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchProfileCallId: string = "";
    updateBusinessRiskCallId: string = "";
    changeStatusCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.send = this.send.bind(this);


        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabs : ["All","New","In review","Draft","Archived"],
            selectedTab : "All",
            rowsPerPage : 8,
            page : 0,
            expanded: false,
            accord: false,
            companyData: configJSON.companyData,
            currentLanguage: "en",
            showDetail: false,
            breadscrumValues: [],
            userType: "",
            businessRisk: "",
            data: {
            id: "",
              type: "",
              attributes: {
                  uuid: "",
                  full_name: "",
                  status: "",
                  business_risk: "",
                  registration_number: "",
                  verification_type: "",
                  premium: "",
                  request_date: "",
                  details: "",
                  Company: {
                      business_profile: {
                          name: "",
                          website: "",
                          industry: "",
                          registration: "",
                          country: "",
                          state: "",
                          city: "",
                          zip: "",
                          services: [],
                          bio: "",
                          account_id: 972,
                          confirmed: false,
                          image: {
                              file_name: "",
                              url: "",
                              size: ""
                          },
                          documents: [
                              {
                                file_name: "",
                                url: "",
                                size: ""
                              }
                          ]
                      },
                      provider_account: {
                          full_name: "",
                          activated: true,
                          first_name: "",
                          last_name: "",
                          full_phone_number: "",
                          phone_number: "",
                          country_code: "",
                          email: "",
                          gender: "",
                          type: "",
                          user_type: "",
                          sub_type: "",
                          user_name: "",
                          address: "",
                          city: "",
                          state: "",
                          country: "",
                          zipcode: "",
                          business_name: "",
                          tax_number: "",
                          created_at: "",
                          updated_at: "",
                          device_id: "",
                          unique_auth_id: "",
                          kyc_name: "",
                          kyc_number: "",
                          kyc_verified: null,
                          date_of_birth: null,
                          bank_name: null,
                          agency_name: "",
                          service_provider_name: null,
                          email_otp_verify: true,
                          commercial_number: "",
                          license_number: "",
                          signup_status: "",
                          last_visit_at: null,
                          profile_image: "",
                          images: {
                              address_proof_image: {
                                file_name: "",
                                url: "",
                                size: ""
                              },
                              id_proof_image: {
                                file_name: "",
                                url: "",
                                size: ""
                              }
                          }
                      },
                      dept_portfolio: {
                          portfolio_size: 1,
                          account_size: 1,
                          dept_age: 1,
                          average_balance: 1,
                          dept_type: 1,
                          recovery_status: 1
                      }
                  },
                    timelines: []
              }
          },
          isSubmit: false,
          documentArray: [null, null],
          statusToChange: "pending",
          submitCheck: false,
          businessRiskErr: ""
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start

        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if(apiRequestCallId && responseJson) {
                if(responseJson.data || responseJson.message) {
                    this.apiSuccessCallBack(apiRequestCallId, responseJson);
                }
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start
/*  */
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: language,
        });
        this.fetchProfile(await getStorageData("selectedRequest"))
        // Customizable Area End
    }

    apiSuccessCallBack = (apiRequestCallId: string, responseJson: ValidResponse) => {
        if (apiRequestCallId === this.fetchProfileCallId) {
            this.setState({ 
                data: responseJson.data,
                businessRisk: responseJson.data.attributes.business_risk
            });                 
        }
        if (apiRequestCallId === this.updateBusinessRiskCallId) {
            this.apiUpdateStatus(this.state.data.id, this.state.statusToChange)
        }
        if (apiRequestCallId === this.changeStatusCallId) {
            this.setState({
                submitCheck: false,
                statusToChange: "pending"
            });
            this.fetchProfile(this.state.data.id);
        }
    };

    updateBusinessRisk = async (requiestId: string) => {
        const {
            businessRisk,
            documentArray
        } = this.state;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateBusinessRiskCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.varificationRequestShowEndPoint}${requiestId}/upload_supporting_docs`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        const formData = new FormData()
        formData.append("business_risk", businessRisk);
        documentArray
        .forEach(file => {
            if(file !== null) {
                return formData.append("supporting_documents[]", file);
            }
        });
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
 
    apiUpdateStatus =  async (requiestId: string, status: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.changeStatusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.varificationRequestShowEndPoint}${requiestId}/change_status_admin`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        const formData = new FormData()
        formData.append("status", status);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleChange = () => {
        this.setState((prevState) => ({
          expanded: !prevState.expanded,
        }));
      };
     handleAccordian = () => {
        this.setState((prevState) => ({
            accord: !prevState.accord,
        }));
      };

      handleDrawerNavigation3 = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle3()
        } else {
                this.handleNavigatingRoute3(routing);
        
        }
    };
    logoutHandle3 = async() => {
        this.handleNavigatingRoute3("Home");
    };


    handleNavigatingRoute3 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    fetchProfile = async (selectedRequest : string) => {

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchProfileCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.varificationRequestShowEndPoint}${selectedRequest}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token" : await getStorageData("accessToken")
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "pending" || value === "DUE") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "rejected" || value === "PAST DUE") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };

    handleBusinessRiskColor = (value: string) => {
        let changeColor = "#059669";
        if (value === "medium") {
            changeColor = "#D97706";
        }
        if (value === "high") {
            changeColor = "#DC2626";
        }
        return  changeColor
    };

    formatDate = (dateString: string | null) => {
        if (!dateString) return ''; 
        const date = new Date(dateString);
        return date.toLocaleDateString('en-GB', {
            day: '2-digit',
            month: 'short',
            year: 'numeric',
        });
    };

    handleAddDocument = (event: React.ChangeEvent<HTMLInputElement>, index: number | null) => {
        const file: File | null = event.target.files ? event.target.files[0] : null;
        let { documentArray } = this.state;
        let newIndex = index;
        if(index === null) {
            for (let i = 0; i < documentArray.length; i++) {
                if (documentArray[i] === null) {
                  newIndex = i;
                  break;
                }
            }
        }
        if (file) {
            this.changeFile(newIndex, file)
        }
    };

    changeFile = (newIndex:number|null, file: File) => {
        this.setState(prevState => {
            const updatedArray = [...prevState.documentArray];
            if (newIndex !== null && newIndex >= 0 && newIndex < updatedArray.length) {
                updatedArray[newIndex] = file;
            } else {
                updatedArray.push(file);
            }
            return { documentArray: updatedArray, anchorEl : null } as unknown as Pick<S, keyof S>;
        });
    };

    handleBusinessRiskChange = (event: React.ChangeEvent<{ value:unknown}>) => {
        let {value} = event.target;
        if(typeof value==="string"){
            this.setState({
                businessRisk: value
            }, ()=> this.isValid());
        }
    };

    isValid = () => {
        let businessRiskErr = "";
        if (!this.state.businessRisk) {
            businessRiskErr = "Please select business risk"
        }
        this.setState({
            businessRiskErr: businessRiskErr
        })
        return Boolean(!businessRiskErr);
    };

    submitBusinessRisk = (requiestId: string, status: "accepted" | "rejected") => {
        let valid = this.isValid();
        this.setState({
            statusToChange: status,
            submitCheck: true
        }, ()=> {
            if(valid) {
                this.updateBusinessRisk(requiestId);
            }
        });
    };

    handleEmptyString = (value: number | string | null) => {
        let newValue = `${value}`.trimEnd();
        if(value === null || newValue.length < 1) {
            newValue = "---";
        }
        return newValue;
    };

    handleOwnerDetailLabels = () => {
        const {data, currentLanguage} = this.state;
        const userSubType = data.attributes.Company.provider_account.sub_type;
        const { UserProfileDetailed} = languageTranslationData[currentLanguage];
        let detailLabelOne, 
            detailLabelTwo, 
            detailLabelThree, 
            detailLabelFour
        switch(userSubType) {
            case ("bank"):
                detailLabelOne = UserProfileDetailed.CommercialRegistration;
                detailLabelTwo = UserProfileDetailed.BankLicenseNumber;
                detailLabelThree = UserProfileDetailed.CommercialRegistrationDocument;
                detailLabelFour = UserProfileDetailed.BankLicenseDocument;
                break;
            case ("agency"):
                detailLabelOne = UserProfileDetailed.AgencyRegistrationNumber;
                detailLabelTwo = UserProfileDetailed.AgencyLicenseNumber;
                detailLabelThree = UserProfileDetailed.AgencyRegistrationDocument;
                detailLabelFour = UserProfileDetailed.AgencyLicenseDocument;
                break;
            case ("service_provider"):
                detailLabelOne = UserProfileDetailed.RegistrationNumber;
                detailLabelTwo = UserProfileDetailed.ServiceLicenseNumber;
                detailLabelThree = UserProfileDetailed.RegistrationDocument;
                detailLabelFour = UserProfileDetailed.ServiceLicenseDocument;
                break;
            default:
                detailLabelOne = UserProfileDetailed.DateofBirth;
                detailLabelTwo = UserProfileDetailed.NationalIdLabel;
                detailLabelThree = UserProfileDetailed.IdProof;
                detailLabelFour = UserProfileDetailed.AddProof;
        }
        return { detailLabelOne, detailLabelTwo, detailLabelThree, detailLabelFour };
    };

    getDateOrCommercial = (userType: string) => {
        const userData = this.state.data.attributes.Company.provider_account;
        if (userType === "individual" || userType === "investor") {
            return userData.date_of_birth
        } else {
            return userData.commercial_number
        }
    };

    // Customizable Area End
}
