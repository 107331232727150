import React from "react";

import {
    Box,
    Typography,
    styled,
    Button,
    Modal,
    Paper,
    IconButton,
    Divider
  } from "@material-ui/core";
const languageTranslationData = require('../../blocks/languagesupport/src/LanguageData/Notification.json')
import CloseIcon from '@material-ui/icons/Close';

const UploadDocument = require('./UploadDocument.png');

type UploadFileModalPropTypes = {
    open: boolean,
    loading: boolean,
    currentLanguage: string,
    onClose: () => void,
    onUpload: (event: React.ChangeEvent<HTMLInputElement>) => Promise<void>
}

const UploadFileModal = ({ open=false, loading=false, currentLanguage="", onClose=() => "", onUpload }: UploadFileModalPropTypes) => {

    const languageData = languageTranslationData[currentLanguage]

    return (
        <StyledModal
            open={open}
            onClose={onClose}
            closeAfterTransition
            BackdropProps={{ timeout: 500 }}
        >
        <ModalContent>
          <Box sx={webStyle.modalBox}>
            <Box style={webStyle.modalWrapperBox}>
              <Typography style={webStyle.modalTitle}>
                {languageData.UploadingNewDoc}
              </Typography>
              <CloseIcon style={{ cursor: "pointer" }} onClick={onClose} />
            </Box>
            <PaperBox elevation={1}>
              {
                loading ?
                <Typography>File Uploading, Please wait...</Typography>
                :
                <>
                  <IconButton color="primary" component="span" style={{ fontSize: 40 }}>
                    <img src={UploadDocument} style={webStyle.uploadImagestyle} />
                  </IconButton>
                  <Typography style={webStyle.uploadImagelable}>
                    {languageData.uploadImageLable}
                  </Typography>
                  <FileTypeText gutterBottom>
                    {languageData.uploadImageFileFormat}
                  </FileTypeText>
                  <ChoseFileButton variant='text'>
                    {languageData.choseFileButtonText}
                    <input data-test-id="uploadFileInputTestID" name="upload_file" type="file" onChange={onUpload} />
                  </ChoseFileButton>
                </>
              }
            </PaperBox>
            <StyledDivider />
            <ModalButtonWrapper>
              <ModalCancelButton onClick={onClose} variant="contained">
                {languageData.Cancel}
              </ModalCancelButton>
              <ModalConfirmButton variant="contained">
                {languageData.Confirm}
              </ModalConfirmButton>
            </ModalButtonWrapper>
          </Box>
        </ModalContent>
      </StyledModal>
    )
}

export default UploadFileModal;

const StyledModal = styled(Modal)({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
});
  
const ModalContent = styled(Box)({
    position: 'absolute',
    left: "440px",
    boxShadow: "0px 8px 32px 0px #0000000F",
    borderRadius: '20px',
    background: "#FFFFFF",
    width: '560px',
    display: "flex",
    flexDirection: "column",
    gap: "24px",
});
  
const PaperBox = styled(Paper)({
    width: "496px",
    height: "206px",
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: "center",
    border: '1px dashed #CBD5E1'
});
  
const FileTypeText = styled(Typography)({
    fontSize: '12px',
    fontWeight: 400,
    color: '#64748B',
    textAlign: 'center'
});
  
const ChoseFileButton = styled(Button)({
    backgroundColor: '#EFF6FF',
    fontSize: '16px',
    fontWeight: 700,
    color: '#0F172A',
    textTransform: 'none',
    height: '44px',
    marginTop: '8px',
    borderRadius: '8px',
    '& input[type="file"]': {
      position: 'absolute',
      left: 0,
      top: 0,
      width: '100%',
      height: '100%',
      opacity: 0,
      cursor: 'pointer',
    },
});
  
const ModalButtonWrapper = styled(Box)({
    width: "496px",
    display: "flex",
    gap: "16px",
    alignItems: 'center',
    marginBottom: "64px"
  
});

const ModalCancelButton = styled(Button)({
    width: "248px",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    backgroundColor: '#EFF6FF',
    fontSize: '16px',
    fontWeight: 700,
    color: '#0F172A',
    textTransform: 'none'
});

const ModalConfirmButton = styled(Button)({
    backgroundColor: '#1E3A8A',
    width: "248px",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    fontSize: '16px',
    color: '#FFFFFF',
    textTransform: 'none',
    fontWeight: 700,
});
  
const StyledDivider = styled(Divider)({
    background: '#CBD5E1',
});

const webStyle = {
    uploadImagestyle: {
        height: "20.77",
        width: "21.33px"
    },
    uploadImagelable: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#0F172A'
    },
    modalTitle: {
        fontSize: '20px',
        fontWeight: '700',
        color: '#0F172A'
      },
    modalBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '24px',
        alignItems: "center"
    },
    modalWrapperBox: {
        display: "flex",
        justifyContent: "space-between",
        width: "496px",
        marginTop: "64px",
    },
}