import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData,removeStorageData } from "framework/src/Utilities";
import { ChangeEvent, MouseEvent } from "react";
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json');
// types
import { UserType } from "../../../components/src/api"
import moment from "moment";

type SnackbarMessageType = {
    type: "error" | "success",
    message: string,
    open: boolean
}

type VerficationStatus = "verification" | "detailed_information" | "debt_portfolio" | "business_interest" | "finalize" | "completed" | "select"

type FilteredData = {
    fromDate: string,
    toDate: string,
    status: VerficationStatus,
    plan: string
}

type Pagination = {
    total_count: number,
    total_pages: number,
    current_page: number
}


// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs: { name: "All" | "Archive" | "Blocked", key: string }[],
    selectedTab: "All" | "Archive" | "Blocked"
    rowsPerPage: number,
    page: number,
    users: UserType[],
    searchTerm: string,
    anchorEl: HTMLElement | null | SVGSVGElement
    showArchiveModal: boolean,
    selectedUser: string,
    showBlockedModal: boolean,
    loading: boolean,
    isFirstModalOpen: boolean,
    verificationPage: number,
    dot: HTMLElement | null,
    currentLanguage: 'ar' | 'en',
    listingType: string,
    status: string,
    showFilter: boolean,
    headingText: string,
    userType: string,
    showDetail: boolean;
    breadscrumValues: string[];
    currentPage: number;
    snackbar: SnackbarMessageType,
    deleteUserId: number | null | undefined,
    filteredData: FilteredData,
    filteredDataApi: {
        'registration[from]': string,
        'registration[to]': string,
        status: string,
        subscription_id: string
    },
    pagination: Pagination,
    subscriptions: any
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class UserManagementController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchUsersCallId: any;
    chageStatusCallId: any;
    regularStatusCallId: any;
    deleteUserAccountApiCallId: any;
    getSubscriptionsCallId: any;
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabs: [{ name: "All", key: "company_users" }, { name: "Archive", key: "archived_users" }],
            selectedTab: "All",
            rowsPerPage: 10,
            page: 0,
            users: [],
            searchTerm: "",
            anchorEl: null,
            showArchiveModal: false,
            selectedUser: "",
            showBlockedModal: false,
            loading: false,
            isFirstModalOpen: false,
            verificationPage: 1,
            dot: null,
            currentLanguage: 'en',
            listingType: "",
            status: "",
            showFilter: false,
            headingText: "",
            userType: "",
            showDetail: false,
            breadscrumValues: [],
            currentPage: 1,
            snackbar: {
                type: "success",
                message: "",
                open: false
            },
            deleteUserId: null,
            filteredData: {
                fromDate: "mm/dd/yyyy",
                toDate: "mm/dd/yyyy",
                status: "select",
                plan: "select"
            },
            filteredDataApi: {
                'registration[from]': "",
                'registration[to]': "",
                status: "",
                subscription_id: ""
            },
            pagination: {
                total_count: 0,
                total_pages: 1,
                current_page: 1
            },
            subscriptions: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            if (apiRequestCallId === this.fetchUsersCallId) {
                this.handleUserResponse(responseJson)
            }

            if(apiRequestCallId === this.deleteUserAccountApiCallId){
                this.handleDeleteUserResponse(responseJson);
            }

            if(apiRequestCallId === this.getSubscriptionsCallId){
                this.handleGetSubscriptionsResponse(responseJson)
            }
            
            if (apiRequestCallId === this.chageStatusCallId) {
                this.setState({
                    loading: false,
                    selectedTab: "All",
                    selectedUser: "",
                    showArchiveModal: false,
                    showBlockedModal: false
                })
            }
            
            if (apiRequestCallId === this.regularStatusCallId) {
                this.setState({
                    loading: false,
                    selectedUser: "",
                })
            }
        }
        // Customizable Area End
    }

    // Customizable Area Start

    async componentDidMount() {
        // Customizable Area Start
        this.fetchUsers()
        this.fetchSubscriptions()
        const language = await utills.getLanguageOrSetCurrentLanguage();
        const heading = languageTranslationData[language].Signup;
        this.setState({
            currentLanguage: language,
            headingText: heading,
        });
        // Customizable Area End
    }

    handleGetSubscriptionsResponse = (res: any) => {
        if(res.hasOwnProperty("data")){
            this.setState({ subscriptions: res.data })
        } 
    }

    handleSnackbarClose = () => {
        this.setState({ snackbar: { type: "success", message: "", open: false } })
    }

    async handleNavigation(route: string) {
        this.props.navigation.navigate(route, { id: this.state.selectedUser })
    }

    handleSearch = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            searchTerm: e.target.value
        })
        this.debouncedFetchUsers(1, e.target.value)
    }

    convertTimestampToCreatedAt(timestamp: string): { date: string, time: string } {
        const date = new Date(timestamp);
        const formattedDate = date.toLocaleDateString('en-US', { month: 'long', day: '2-digit', year: 'numeric' });
        const formattedTime = date.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit' });

        return {
            date: formattedDate,
            time: `${formattedTime}`
        };
    }

    handleDeleteUserResponse = (response: any) => {
        if(!response.errors){
            const { pagination } = this.state;
            const page = pagination.total_count % this.state.rowsPerPage == 1 ? pagination.current_page - 1 : pagination.current_page
            this.fetchUsers(page > 1 ? page : 1, this.state.searchTerm)
            this.setState({ snackbar: { type: "success", message: "Account deleted successfully", open: true } })
        }
        else{
            this.setState({ snackbar: { type: "error", message: "Something went wrong", open: true } })
        }
    }

    handleUserResponse = (users: any ) => {
        if (users.hasOwnProperty("data")) {
            this.setState({
                users: users.data,
                loading: false,
                pagination: {
                    current_page: users?.meta?.current_page || 1,
                    total_pages: users?.meta?.total_pages || 1,
                    total_count: users?.meta?.total_count || 0
                }
            })
        } else {
            this.setState({
                users: [],
                loading: false,
                pagination: {
                    current_page: 1,
                    total_pages: 1,
                    total_count: 0
                }
            })
        }
    }

    clean(obj: any) {
        for (let propName in obj) {
          if (obj[propName] === null || obj[propName] === undefined || obj[propName] === "") {
            delete obj[propName];
          }
        }
        return obj
    }

    debounce(func: Function, timeout = 500){
        let timer: NodeJS.Timeout;
        return (...args: any) => {
          clearTimeout(timer);
          timer = setTimeout(() => { func.apply(this, args); }, timeout);
        };
    }

    debouncedFetchUsers = this.debounce((page: number, searchText: string) => this.fetchUsers(page, searchText))

    fetchUsers = async (page = 1, searchText="") => {
        this.setState({
            loading: true,
            pagination: { ...this.state.pagination, current_page: page }
        })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchUsersCallId = requestMessage.messageId;

        const payload = {
            query: searchText?.toLowerCase(),
            page: page.toString(),
            per_page: this.state.rowsPerPage.toString(),
            ...this.state.filteredDataApi
        }

        const cleanPayload = this.clean(payload)

        const token = await getStorageData("accessToken")

        const queryParams = new URLSearchParams(cleanPayload).toString()

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.fetchUsersEndPoint}?${queryParams}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": token
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    fetchSubscriptions = async () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getSubscriptionsCallId = requestMessage.messageId;

        const token = await getStorageData("accessToken")

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.getSubscriptions
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        const headers = {
            "token": token
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    deleteUserAccount = async (user_id: number) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.deleteUserAccountApiCallId = requestMessage.messageId;

        const token = await getStorageData("accessToken")

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteAccountEndpoint}${user_id}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.deleteMethod
        );
        const headers = {
            "token": token
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    onFilterChange = (e: any, type: string, category: string) => {
        if(category === "date"){
            const formattedDate = moment(e.target.value, "YYYY-MM-DD").format("YYYY-MM-DD")
            this.setState({ filteredData: { ...this.state.filteredData, [type]: formattedDate  } })
        }
        if(category === "status"){
            this.setState({ filteredData: { ...this.state.filteredData, [type]: e.target.value  } })
        }
    }

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            rowsPerPage: parseInt(event.target.value, 10),
            page: 0
        })
    }

    handleChangePage = (event: unknown, newPage: number) => {
        this.setState({
            page: newPage
        })
    }

    changeStatus = async (status: string) => {
        this.setState({
            loading: true
        })
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.chageStatusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_admin/user_lists/${this.state.selectedUser}/update_status`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        const data = {
            "status": status
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        const headers = {
            "Content-Type": "application/json",
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleUnArchive = async () => {
        this.setState({ anchorEl: null, loading: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.regularStatusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_admin/user_lists/${this.state.selectedUser}/unarchived_user`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        const data = {
            "status": "regular"
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        const headers = {
            "Content-Type": "application/json",
            "token": await getStorageData("accessToken")
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleUnBlock = async () => {
        this.setState({ anchorEl: null, loading: true })

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.regularStatusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `bx_block_admin/user_lists/${this.state.selectedUser}/unblock_user`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.putMethod
        );

        const data = {
            "status": "regular"
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(data)
        );

        const headers = {
            "Content-Type": "application/json",
            "token": await getStorageData("accessToken")
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    handleFirstModalOpen = () => {
        this.setState({ isFirstModalOpen: true });
    };

    handleFirstModalClose = () => {
        this.setState({ isFirstModalOpen: false, deleteUserId: null });
    };
    handleVerificationPagination = (evetn: ChangeEvent<unknown>, page: number) => {
        this.setState({ verificationPage: page });
    };
    handleClick = (event: React.MouseEvent<HTMLElement>) => {
        const deleteId = parseInt(event.currentTarget.dataset.id || "0")
        this.setState({ dot: event.currentTarget, deleteUserId: deleteId, selectedUser: deleteId?.toString() });

    };
    handleUserData = (event: React.MouseEvent<SVGSVGElement>, id: string) => {
        const target = event.currentTarget as SVGSVGElement;
        this.setState({ anchorEl: target, selectedUser: id })
    }

    handleClose = () => {
        this.setState({ dot: null });
    };

    handleReset = (type: string) => {
        let data = { ...this.state.filteredData }
        if(type === "date"){
            data = { ...data, fromDate: "mm/dd/yyyy", toDate: "mm/dd/yyyy" }
            
        }
        if(type === "status"){
            data = { ...data, status: "select" }
        }
        if(type === "plan"){
            data = { ...data, plan: "select" }
        }
        if(type === "all"){
            data = { ...data, fromDate: "mm/dd/yyyy", toDate: "mm/dd/yyyy", status: "select" }
        }
        this.setState({ filteredData: data }, () => this.handleFilterApply())
    }

    handleFilterApply = () => {
        let apiRequestPayload = {...this.state.filteredDataApi}

        apiRequestPayload = { ...apiRequestPayload, "registration[from]": this.state.filteredData.fromDate !== "mm/dd/yyyy" ? moment(this.state.filteredData.fromDate, "YYYY-MM-DD").format("MM-DD-YYYY") : "" }
        apiRequestPayload = { ...apiRequestPayload, "registration[to]": this.state.filteredData.toDate !== "mm/dd/yyyy" ? moment(this.state.filteredData.toDate, "YYYY-MM-DD").format("MM-DD-YYYY") : "" }
        apiRequestPayload = { ...apiRequestPayload, status: this.state.filteredData.status !== "select" ? this.state.filteredData.status : "" }
        apiRequestPayload = { ...apiRequestPayload, subscription_id: this.state.filteredData.plan !== "select" ? this.state.filteredData.plan : "" }

        this.setState({ filteredDataApi: apiRequestPayload, showFilter: false }, () => this.fetchUsers(1, this.state.searchTerm))
    }

    handleSelectReset = (stateName: string) => {
        this.setState({
            [stateName]: ""
        } as unknown as Pick<S, keyof S>);
    };

    handleFilterReset = () => {
        this.setState({
            listingType: "",
            status: ""
        });
    };

    handleToggleFilter = () => {
        this.setState({ showFilter: !this.state.showFilter });
    };
    handleCloseFilter = () => {
        this.setState({ showFilter: false });
    };
    handleDrawerNavigation1 = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle1()
        } else {
            if(routing.includes("breadScrum")) {
                // this.handlebreadScrumClick1(routing);
            } else {
                this.handleNavigatingRoute1(routing);
            }
        }
    };

    logoutHandle1 = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigatingRoute1("Home");
    };
    handleNavigatingRoute1 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };
    handlePageChange = (e: React.ChangeEvent<unknown>, page: number) => {
        this.fetchUsers(page, this.state.searchTerm)
    };
    // Customizable Area End
}
