import React from "react";
import { Box, Button, MenuItem, TextField, styled } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import Edit from "@material-ui/icons/Edit";
const languageTranslationData = require('../../../blocks/languagesupport/src/LanguageData/Signup.json');
export const configJSON = require("../../../blocks/email-account-registration/src/config.js");
import { BasicInformationInputChange } from "./BasicInformation.web";
import { DebtPortfolioInputChange } from "./DebtPortfolio.web";
import { BusinessInterestInputChange } from "./BusinessInterests.web";
import { DetailInformationInputChange } from "./DetailInformation.web";
import moment from "moment";

interface Props {
  basicInformationInputChange: BasicInformationInputChange;
  debtPortfolioInputChange: DebtPortfolioInputChange;
  businessInterest: {
    debtTypes: string;
    portfolioSize: string;
    debtAgeRange: string;
    geographicPreference: string;
  },
  showPassword: boolean,
  businessInterestInputChange: BusinessInterestInputChange;
  debtPortfolio: {
    portfolioSize: string;
    accountCount: string;
    averageBalance: string;
    debtTypes: string;
    debtAge: string;
    recoveryStatus: string;
  };
  detailInformationStates: {
    date: string;
    month: string;
    year: string;
    nationalId: string;
    idProof: File | null;
    addressProof: File | null;
    commercialRegistration: string;
    bankLicenseNumber: string;
    commercialRegistrationDocument: File | null;
    bankLicenseDocument: File | null;
    agencyRegistrationNumber: string;
    agencyLicenseNumber: string;
    agencyRegistrationDocument: File | null;
    agencyLicenseDocument: File | null;
    registrationNumber: string;
    serviceLicenseNumber: string;
    registrationDocument: File | null;
    serviceLicenseDocument: File | null;
  };
  detailInformationInputChange: DetailInformationInputChange;
  currentLanguage: string;
  renderTextInput: (
    id: string,
    label: string,
    value: string,
    onChange: (value: any) => void,
    type: string,
    error: string,
    endAdornment?: boolean,
    disabled?: boolean
  ) => JSX.Element;
  classes: any;
  isEditBasicInfo: boolean;
  isEditDetailInfo: boolean;
  isEditDebtPortfolio: boolean;
  isEditBusinessInterest: boolean;
  handleBasicEditClick: () => void;
  handleDetailEditClick: () => void;
  handleDebtPortfolioEditClick: () => void;
  handleBusinessInterestEditClick: () => void;
  handleFinalReviewCancel: () => void;
  renderDragAndDropInput: any;
  showBusinessInterest: () => boolean;
  showDebtPortfolio: () => boolean;
  showPersonalDetail: () => boolean;
  showBankDetail: () => boolean;
  showAgencyDetail:() => boolean;
  showServiceProviderDetail: () => boolean;
  errors: any;
  updateData: () => void;
  basicInformation: {
    fullName: string;
    bankName: string;
    agencyName: string;
    serviceProviderName: string;
    email: string;
    password: string;
    phone: string;
    city: string;
    state: string;
    country: string;
    zip: string;
  }
}

class FinalReviewWeb extends React.Component<Props> {
    constructor(props: Props) {
      super(props);
    }

    renderPersonalDetail () {
      const { renderTextInput, classes, renderDragAndDropInput, errors,
      detailInformationStates, detailInformationInputChange, isEditDetailInfo } = this.props;
      const updatedStates = {...detailInformationStates};
      const languageData = languageTranslationData[this.props.currentLanguage];
      
      const months = [
        { value: '01', label: '01'}, {value: '02', label: '02'},
        { value: '03', label: '03'},{ value: '04', label: '04'},
        { value: '05', label: '05'},{ value: '06', label: '08'},
        { value: '07', label: '07'},{ value: '08', label: '08'},
        { value: '09', label: '09'},{ value: '10', label: '10'},
        { value: '11', label: '11'},{ value: '12', label: '12'},
      ];
      
      const years = () => {
        const currentYear = moment().year();
        const years = [];
        for (let i = 0; i < 80; i++) {
          years.push({label: (currentYear - i).toString(), value: (currentYear - i).toString()});
        }
        return years;        
      }
  
      const dates = () => {
        const dates = [];
        for (let i = 0; i < 31; i++) {
          const date = i+1 < 10 ? `0${i+1}` : (i+1).toString();
          dates.push({label: date, value: date});
        }
        return dates;        
  
      }
      
      return (
        <PersonalInfoBox mt={-2}>
          <Box sx={classes.form} alignItems="baseline">
            <Box display="flex" flexDirection="column" gridGap="10px">
              <Text size="xx" variant="grey" weight="extraBold" family="inter">Date of Birth</Text>
              <Box display="flex" justifyContent="space-between" gridGap="10px">
                <TextField
                  data-test-id="monthInputTestID"
                  select
                  placeholder="MM"
                  size="small"
                  variant="outlined"
                  value={detailInformationStates.month}
                  disabled={!isEditDetailInfo}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => detailInformationInputChange.monthChange(event.target.value)}
                  InputProps={{
                    style: { borderRadius: 8 }
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: detailInformationStates.month !== '' ? undefined : () => 
                    <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.month}</Text>,
                  }}  
                  style={{ width: 120 }}
                >
                  {months.map((month: {label: string, value: string }) => 
                  <MenuItem key={month.value} value={month.value}>
                    {month.label}
                  </MenuItem>
                )}
                </TextField>
                <TextField
                  data-test-id="dayInputTestID"
                  select
                  placeholder="DD"
                  size="small"
                  value={detailInformationStates.date}
                  variant="outlined"
                  disabled={!isEditDetailInfo}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => detailInformationInputChange.dateChange(event.target.value)}
                  InputProps={{
                    style: { borderRadius: 8 }
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: detailInformationStates.date !== '' ? undefined : () => 
                    <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.date}</Text>,
                  }}
                  style={{ width: 120 }}
                >
                  {dates().map((date: {label: string, value: string }) => 
                    <MenuItem key={date.value} value={date.value}>
                      {date.label}
                    </MenuItem>
                  )}
                </TextField>
                <TextField
                  data-test-id="yearInputTestID"
                  select
                  size="small"
                  placeholder="YYYY"
                  value={detailInformationStates.year}
                  variant="outlined"
                  disabled={!isEditDetailInfo}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => detailInformationInputChange.yearChange(event.target.value)}
                  style={{ width: 120 }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: detailInformationStates.year !== '' ? undefined : () => 
                    <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.year}</Text>,
                  }}
                  InputProps={{
                    style: { borderRadius: 8 }
                }}
                >
                  {years().map((year: {label: string, value: string }) => 
                    <MenuItem key={year.value} value={year.value}>
                      {year.label}
                    </MenuItem>
                  )}
                </TextField>
              </Box>
            </Box>
            <Box>
              {renderTextInput("nationalId", languageData.DetailedInformationData.NationalId+"*", detailInformationStates.nationalId, detailInformationInputChange.nationalIdChange, "text", errors["NationalId"], false, !isEditDetailInfo )}
            </Box>
          </Box>
          <Box  sx={{...classes.formMain, marginTop:"20px !important"}}>
            <Box sx={classes.formMainHalf}>
              {renderDragAndDropInput({ onChange: detailInformationInputChange.idProofChange, label: languageData.DetailedInformationData.IdProof, errorName: "IdProof", selectedDocInfo: updatedStates.idProof,testId:'idproof' })}
            </Box>
            <Box sx={classes.formMainHalf}>
              {renderDragAndDropInput({ onChange: detailInformationInputChange.addressProofChange, label: languageData.DetailedInformationData.AddressProof, errorName: "AddressProof", selectedDocInfo: updatedStates.addressProof ,testId:'addressproof'})}
            </Box>
          </Box>
        </PersonalInfoBox>
      )
    }
  
    renderBankDetail () {
      const { renderTextInput, classes, renderDragAndDropInput, errors,
        detailInformationStates, detailInformationInputChange, isEditDetailInfo } = this.props;
      const languageData = languageTranslationData[this.props.currentLanguage];
      const updatedStates = {...detailInformationStates };

      return (
        <CustomBox mt={3}>
          <Box sx={classes.form}>
            {renderTextInput("commercialRegistration", languageData.DetailedInformationData.CommercialRegistration+"*", detailInformationStates.commercialRegistration, detailInformationInputChange.commercialRegistrationChange, "text", errors["CommercialRegistration"], false, !isEditDetailInfo )}
            {renderTextInput("bankLicenseNumber", languageData.DetailedInformationData.BankLicenseNumber+"*", detailInformationStates.bankLicenseNumber, detailInformationInputChange.bankLicenseNumberChange, "text", errors["BankLicense"], false, !isEditDetailInfo  )}
          </Box>
          <Box sx={{...classes.formMain, marginTop: "20px !important"}}>
            <Box sx={classes.formMainHalf}>
              {renderDragAndDropInput({ onChange: detailInformationInputChange.commercialRegistrationDocumentChange, label: languageData.DetailedInformationData.CommercialRegistrationDocument, errorName: "CommercialRegistrationDocument", selectedDocInfo: updatedStates.commercialRegistrationDocument,testId:'commercialRegistrationDocument' })}
            </Box>
            <Box sx={classes.formMainHalf}>
              {renderDragAndDropInput({ onChange: detailInformationInputChange.bankLicenseDocumentChange, label: languageData.DetailedInformationData.BankLicenseDocument, errorName: "BankLicenseDocument", selectedDocInfo: updatedStates.bankLicenseDocument ,testId:'bankLicenseDocument'})}
            </Box>
          </Box>
        </CustomBox>
      )
    }
  
    renderAgencyDetail () {
      const { renderTextInput, classes, renderDragAndDropInput, 
        detailInformationStates, detailInformationInputChange, errors, isEditDetailInfo } = this.props;
      const languageData = languageTranslationData[this.props.currentLanguage];
      const updatedStates = detailInformationStates;

      return (
        <CustomBox mt={3} mb={2}>
          <Box sx={classes.form}>
            {renderTextInput("agencyRegistrationNumber", languageData.DetailedInformationData.AgencyRegistrationNumber+"*", detailInformationStates.agencyRegistrationNumber, detailInformationInputChange.agencyRegistrationNumberChange, "text", errors["AgencyRegistration"], false, !isEditDetailInfo)}
            {renderTextInput("agencyLicenseNumber", languageData.DetailedInformationData.AgencyLicenseNumber+"*", detailInformationStates.agencyLicenseNumber, detailInformationInputChange.agencyLicenseNumberChange, "text", errors["AgencyLicenseNumber"], false, !isEditDetailInfo)}
          </Box>
          <Box sx={{...classes.formMain, marginTop:"20px !important"}}>
            <Box sx={classes.formMainHalf}>
              {renderDragAndDropInput({ onChange: detailInformationInputChange.agencyRegistrationDocumentChange, label: languageData.DetailedInformationData.AgencyRegistrationDocument, errorName: "AgencyRegistrationDocument", selectedDocInfo: updatedStates.agencyRegistrationDocument,testId:'AgencyRegistrationDocument' })}
            </Box>
            <Box sx={classes.formMainHalf}>
              {renderDragAndDropInput({ onChange: detailInformationInputChange.agencyLicenseDocumentChange, label: languageData.DetailedInformationData.AgencyLicenseDocument, errorName: "AgencyLicenseDocument", selectedDocInfo: updatedStates.agencyLicenseDocument ,testId:'AgencyLicenseDocument'})}
            </Box>
          </Box>
        </CustomBox>
      )
    }
  
    renderServideProviderDetail () {
      const { renderTextInput, classes, renderDragAndDropInput, errors, isEditDetailInfo,
        detailInformationStates, detailInformationInputChange } = this.props;
      const languageData = languageTranslationData[this.props.currentLanguage];
      const updatedStates = detailInformationStates;

      return (
        <CustomBox mt={3}>
          <Box sx={classes.form}>
            {renderTextInput("registrationNumber", languageData.DetailedInformationData.RegistrationNumber+"*", detailInformationStates.registrationNumber, detailInformationInputChange.registrationNumberChange, "text", errors["RegistrationNumber"], false, !isEditDetailInfo)}
            {renderTextInput("serviceLicenseNumber", languageData.DetailedInformationData.ServiceLicenseNumber+"*", detailInformationStates.serviceLicenseNumber, detailInformationInputChange.serviceLicenseNumberChange, "text", errors["ServiceLicenseNumber"], false, !isEditDetailInfo )}
          </Box>
          <Box sx={{...classes.formMain, marginTop: "20px !important"}}>
            <Box sx={classes.formMainHalf}>
              {renderDragAndDropInput({ onChange: detailInformationInputChange.registrationDocumentChange, label: languageData.DetailedInformationData.RegistrationDocument, errorName: "RegistrationDocument", selectedDocInfo: updatedStates.registrationDocument,testId:'registrationDocument' })}
            </Box>
            <Box sx={classes.formMainHalf}>
              {renderDragAndDropInput({ onChange: detailInformationInputChange.serviceLicenseDocumentChange, label: languageData.DetailedInformationData.ServiceLicenseDocument, errorName: "ServiceLicenseDocument", selectedDocInfo: updatedStates.serviceLicenseDocument ,testId:'serviceLicenseDocument'})}
            </Box>
          </Box>
        </CustomBox>
      )
    }

  showUpdateButton = () => this.props.isEditBasicInfo ||
    this.props.isEditDetailInfo || 
    this.props.isEditBusinessInterest || 
    this.props.isEditDebtPortfolio;
  
  hideBasicInfo = () =>  this.props.isEditDetailInfo || this.props.isEditBusinessInterest || 
  this.props.isEditDebtPortfolio;

  hideDetailInfo = () => this.props.isEditBasicInfo || this.props.isEditBusinessInterest || 
  this.props.isEditDebtPortfolio;

  hideDebtPortfolioBusinessInterest = () => this.props.isEditBasicInfo || this.props.isEditDetailInfo;
  
  render() {
    // Customizable Area Start
    const languageData = languageTranslationData[this.props.currentLanguage];
    const { classes, renderTextInput, basicInformation, showPassword,
      basicInformationInputChange, errors, debtPortfolio, debtPortfolioInputChange,
      showPersonalDetail, showBankDetail, showDebtPortfolio, showBusinessInterest,
      showAgencyDetail, showServiceProviderDetail, businessInterest, businessInterestInputChange,
      isEditBasicInfo, isEditDebtPortfolio, isEditBusinessInterest, handleBasicEditClick, handleDetailEditClick, handleDebtPortfolioEditClick,
      handleBusinessInterestEditClick, updateData, handleFinalReviewCancel} = this.props;

    return(
      <MainBox flexDirection="column" display="flex" alignItems="center">
        <Text variant="primary" size="xl" weight="extraBold" family="inter">{languageData.FinalReview}</Text>
        <Box mt={2} mb={3}>
          <Text align="center" size="sm" family="inter" variant="grey" weight="minBold">{languageData.FinalReviewDesc}</Text>
        </Box>
        {!this.hideBasicInfo() && <SectionBox mt={4}>
          <Box display="flex" justifyContent="space-between">
            <Text size="mm" family="inter" variant="lightBlue" weight="extraBold">{languageData.BasicInformation}</Text>
            <Button data-testid="editBasicInfo" style={classes.editBtn} variant="text" onClick={handleBasicEditClick}
            endIcon={<Edit />}
            >{languageData.Edit}</Button>
          </Box>
          <CustomBox sx={classes.formBorderBox}>
            <Box sx={classes.form}>
              {showPersonalDetail() && renderTextInput("fullName", languageData.FullName+"*", basicInformation.fullName, basicInformationInputChange.fullNameInputProps, "text", errors[configJSON.FullName], false, !isEditBasicInfo )}
              {showBankDetail() && renderTextInput("bankName", languageData.BankName+"*", basicInformation.bankName, basicInformationInputChange.bankNameInputProps, "text", errors[configJSON.BankName], false, !isEditBasicInfo )}
              {showAgencyDetail() && renderTextInput("agencyName", languageData.AgencyName+"*", basicInformation.agencyName, basicInformationInputChange.agencyNameInputProps, "text", errors[configJSON.AgencyName], false, !isEditBasicInfo  )}
              {showServiceProviderDetail() && renderTextInput("serviceProviderName", languageData.ServiceProviderName+"*", basicInformation.serviceProviderName, basicInformationInputChange.serviceProviderNameInputProps, "text", errors[configJSON.ServiceProviderName], false, !isEditBasicInfo  )}
              {renderTextInput("email", languageData.Seller.Email+"*", basicInformation.email, basicInformationInputChange.emailInputProps, "email", errors[configJSON.Email], false, true )}
            </Box>

            <Box sx={classes.form}>
              {renderTextInput("phone", languageData.Seller.PhoneNumber+"*", basicInformation.phone, basicInformationInputChange.phoneInputProps, "text", errors[configJSON.PhoneNumber], false, !isEditBasicInfo)}
              {renderTextInput("password", languageData.Password, basicInformation.password, basicInformationInputChange.passwordInputProps, showPassword ? "text" : "password", errors['Password'], true, !isEditBasicInfo )}
            </Box>
            <Box sx={classes.form}>
              {renderTextInput("country", languageData.Country, basicInformation.country, basicInformationInputChange.countryInputProps, "text", errors[configJSON.Country], false, !isEditBasicInfo)}
              {renderTextInput("state", languageData.State, basicInformation.state, basicInformationInputChange.stateInputProps, "text", errors[configJSON.State], false, !isEditBasicInfo)}
            </Box>
            <Box sx={classes.form}>
              {renderTextInput("city", languageData.City, basicInformation.city, basicInformationInputChange.cityInputProps, "text", errors[configJSON.City], false, !isEditBasicInfo)}
              {renderTextInput("zip", languageData.Zip, basicInformation.zip, basicInformationInputChange.zipInputProps, "text", errors[configJSON.Zip], false, !isEditBasicInfo)}
            </Box>
          </CustomBox>
        </SectionBox>}
        {!this.hideDetailInfo() && <SectionBox mt={4}>
          <Box display="flex" justifyContent="space-between">
            <Text size="mm" family="inter" variant="lightBlue" weight="extraBold">{languageData.DetailedInformation}</Text>
            <Button data-testid="editDetailInfo" style={classes.editBtn} variant="text" onClick={handleDetailEditClick}
            endIcon={<Edit />}
            >{languageData.Edit}</Button>
          </Box>
          <DetailBox sx={classes.formBorderBox}>
            { showPersonalDetail() && this.renderPersonalDetail()}
            { showBankDetail() && this.renderBankDetail() }
            { showAgencyDetail() && this.renderAgencyDetail() }
            { showServiceProviderDetail() && this.renderServideProviderDetail() }
          </DetailBox>
        </SectionBox>}
        {!this.hideDebtPortfolioBusinessInterest() && showDebtPortfolio() && <SectionBox mt={4}>
          <Box display="flex" justifyContent="space-between">
            <Text size="mm" family="inter" variant="lightBlue" weight="extraBold">{languageData.DebtPortfolio}</Text>
            <Button data-testid="editDebtPortfolio" style={classes.editBtn} variant="text" onClick={handleDebtPortfolioEditClick}
            endIcon={<Edit />}
            >{languageData.Edit}</Button>
          </Box>
          <CustomBox sx={classes.formBorderBox}>
            <Box sx={classes.form}>
              {renderTextInput("portfolioSize", languageData.DebtPortfolioData.PortfolioSize, debtPortfolio.portfolioSize, debtPortfolioInputChange.portfolioSizeChange, "text", errors['PortfolioSize'], false, !isEditDebtPortfolio)}
              {renderTextInput("accountCount", languageData.DebtPortfolioData.AccountCount, debtPortfolio.accountCount, debtPortfolioInputChange.accountCountChange, "text", errors['AccountCount'],false, !isEditDebtPortfolio )}
            </Box>
            <Box sx={classes.form} alignItems="end">
              {renderTextInput("averageBalance", languageData.DebtPortfolioData.AverageBalance, debtPortfolio.averageBalance, debtPortfolioInputChange.averageBalanceChange, "text", errors['AverageBalance'], false, !isEditDebtPortfolio)}
              <Box mt={2.5} display="flex" flexDirection="column" gridGap="10px">
                <Text size="xx" variant="grey" weight="extraBold" family="inter">{languageData.DebtPortfolioData.TypesDebt}</Text>
                  <TextField
                    data-test-id="typesdebt"
                    id="typesdebt"
                    fullWidth
                    disabled={!isEditDebtPortfolio}
                    select
                    size="small"
                    variant="outlined"
                    value={debtPortfolio.debtTypes}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => debtPortfolioInputChange.debtTypesChange(event.target.value)}
                    SelectProps={{
                      displayEmpty: true,
                      renderValue: debtPortfolio.debtTypes !== '' ? undefined : () => 
                      <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.typesDebt}</Text>,
                    }} 
                    InputProps={{
                      style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                    }}
                  >
                    <MenuItem key="type1" value={1}>Type 1</MenuItem>
                    <MenuItem key="type2" value={2}>Type 2</MenuItem>
                    <MenuItem key="type3" value={3}>Type 3</MenuItem>
                  </TextField>
              </Box>
            </Box>
            <Box sx={classes.form} alignItems="end">
              {renderTextInput("debtAgeRange", languageData.DebtPortfolioData.AgeDebt, debtPortfolio.debtAge, debtPortfolioInputChange.debtAgeChange, "text", errors['DebtAge'], false, !isEditDebtPortfolio)}
              <Box mt={2.5} display="flex" flexDirection="column" gridGap="10px">
                <Text size="xx" variant="grey" weight="extraBold" family="inter">{languageData.DebtPortfolioData.CurrentRecoveryStatus}</Text>
                <TextField
                  data-test-id="recoveryStatus"
                  id="recoveryStatus"
                  fullWidth
                  select
                  value={debtPortfolio.recoveryStatus}
                  disabled={!isEditDebtPortfolio}
                  size="small"
                  variant="outlined"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => debtPortfolioInputChange.recoveryStatusChange(event.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: debtPortfolio.recoveryStatus !== '' ? undefined : () => 
                    <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.currentRecoveryStatus}</Text>,
                  }} 
                  InputProps={{
                    style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                  }}
                >
                  <MenuItem key="recovery1" value={1}>Recovery 1</MenuItem>
                  <MenuItem key="recovery2" value={2}>Recovery 2</MenuItem>
                  <MenuItem key="recovery3" value={3}>Recovery 3</MenuItem>
                </TextField>
              </Box>
            </Box>
          </CustomBox>
        </SectionBox>}
        {!this.hideDebtPortfolioBusinessInterest() && showBusinessInterest() && <SectionBox mt={4}>
          <Box display="flex" justifyContent="space-between">
            <Text size="mm" family="inter" variant="lightBlue" weight="extraBold">{languageData.BusinessInterests}</Text>
            <Button data-testid="editBusinessInterest" style={classes.editBtn} variant="text"
            onClick={handleBusinessInterestEditClick} endIcon={<Edit />}
            >{languageData.Edit}</Button>
          </Box>
          <CustomBox sx={classes.formBorderBox}>
            <Box sx={classes.form} alignItems="end">
              <Box display="flex" flexDirection="column" gridGap="10px">
                <Text size="xx" variant="grey" weight="extraBold" family="inter">{languageData.DebtPortfolioData.TypesDebt}</Text>
                <TextField
                  data-test-id="typesdebt"
                  id="typesdebt"
                  fullWidth
                  select
                  disabled={!isEditBusinessInterest}
                  size="small"
                  variant="outlined"
                  value={businessInterest.debtTypes}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => businessInterestInputChange.debtTypesChange(event.target.value)}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: businessInterest.debtTypes !== '' ? undefined : () => 
                    <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.typesDebt}</Text>,
                  }} 
                  InputProps={{
                    style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                  }}
                >
                  <MenuItem key="type1" value="type1">Type 1</MenuItem>
                  <MenuItem key="type2" value="type2">Type 2</MenuItem>
                  <MenuItem key="type3" value="type2">Type 3</MenuItem>
                </TextField>
              </Box>
              {renderTextInput("portfolioSize", languageData.BusinessInterestsData.PortfolioSize, businessInterest.portfolioSize, businessInterestInputChange.portfolioSizeChange, "text", errors['PortfolioSize'], false, !isEditBusinessInterest)}
            </Box>
            <Box sx={classes.form} alignItems="end">
              {renderTextInput("debtAgeRange", languageData.BusinessInterestsData.PreferredDebtAgeRange, businessInterest.debtAgeRange, businessInterestInputChange.debtAgeRangeChange, "text", errors['DebtAgeRange'], false, !isEditBusinessInterest)}
              <Box display="flex" mt={2} flexDirection="column" gridGap="10px">
                <Text size="xx" variant="grey" weight="extraBold" family="inter">{languageData.BusinessInterestsData.GeographicPreferences}</Text>
                <TextField
                  data-test-id="geographicPreference"
                  id="geographicPreference"
                  fullWidth
                  select
                  size="small"
                  variant="outlined"
                  disabled={!isEditBusinessInterest}
                  value={businessInterest.geographicPreference}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => businessInterestInputChange.geographicPreferenceChange(event?.target.value)}
                  InputProps={{
                    style: { borderRadius: 8, paddingTop: 1.5, paddingBottom: 1.5 }
                  }}
                  SelectProps={{
                    displayEmpty: true,
                    renderValue: businessInterest.geographicPreference !== '' ? undefined : () => 
                    <Text variant="naturalCoolGrey" size="sm" weight="minBold">{languageData.Placeholder.geographicPreferences}</Text>,
                  }} 
                >
                  <MenuItem key="geography1" value="geography1">Geographic 1</MenuItem>
                  <MenuItem key="geography2" value="geography2">Geographic 2</MenuItem>
                  <MenuItem key="geography3" value="geography3">Geographic 3</MenuItem>
                </TextField>
              </Box>
            </Box>
          </CustomBox>
        </SectionBox>}
        {this.showUpdateButton() && <Box mt={4} display="flex" gridGap={8} sx={classes.buttons}>
          <NextButton style={classes.backBtn} data-testid="cancel"
            onClick={() => handleFinalReviewCancel()}>{languageData.Cancel}</NextButton> 
          <NextButton style={classes.formBtn} data-testid="update"
          onClick={() => updateData()}>{languageData.Update}</NextButton>
        </Box>}
      </MainBox>
    )
    // Customizable Area End
  }
}

export default FinalReviewWeb;

const MainBox = styled(Box)({
  "@media (max-width: 925px)": {
    width: "710px"
  },
  "@media (max-width: 767px)": {
    width: "100%"
  }
});

const SectionBox = styled(Box)({
  "@media (max-width: 767px)": {
    width: "100%"
  }
});

const CustomBox = styled(Box)({
  width: "100%",
  "@media (max-width: 925px)": {
    "& .MuiBox-root > div": {
      width: '100% !important',
      "& .MuiFormControl-root.MuiTextField-root": {
        width: "302px !important"
      }
    }
  },
  "@media (max-width: 767px)": {
    "& .MuiBox-root > div": {
      width: '100% !important',
      "& .MuiFormControl-root.MuiTextField-root": {
        width: "100%  !important"
      }
    }
  },
  "@media (max-width: 400px)": {
    padding: "20px"
  }
});

const DetailBox = styled(Box)({
  width: "100%",
  "& .MuiBox-root": {
    marginTop: "0px"
  },
  "@media (max-width: 400px)": {
    padding: "20px 0px",
    "& .MuiBox-root": {
      paddingTop: "0px",
      paddingBottom: "0px",
      marginTop: "0px"
    }
  }
});

const PersonalInfoBox = styled(Box)({
  width: "100%",
  "& .MuiBox-root > div": {
    "@media (max-width: 925px)": {
      width: '100% !important',
      "& .MuiFormControl-root.MuiTextField-root": {
        width: "302px"
      }
    },
    "@media (max-width: 767px)": {
      "& .MuiFormControl-root.MuiTextField-root": {
        width: "100%"
      }
    }
  },
  "@media (max-width: 400px)": {
    padding: "20px"
  }
});

const NextButton = styled(Button)({
  "@media (max-width: 400px)": {
    width: "300px !important"
  }
});