// Customizable Area Start

import React from "react";

import {
  Box,
  Typography,
  Grid,
  styled,
  Breadcrumbs,
  Button,
  Snackbar,
  withStyles
} from "@material-ui/core";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Notification.json')
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import DocumentationFolderController, { Props, FolderFiles } from "./DocumentationFolderController";
import { Circle, FolderDefaultImage, DocUpload } from "./assets";
import { Alert, Pagination } from "@material-ui/lab";
import UploadFileModal from "../../../components/src/UploadFileModal";
import moment from "moment";

export default class DocumentationFolder extends DocumentationFolderController {
  constructor(props: Props) {
    super(props);
  }
  renderFolders = () => {
    const { currentLanguage } = this.state;
    const languageData = languageTranslationData[currentLanguage]
    return (
      this.state.folderMedia.length < 1 ?
      <Typography style={{ marginLeft: "8px" }}>{"Folder is empty"}</Typography>
      :
      this.state.folderMedia.map((document, index) => (
        <Grid item xs={4} key={index}>
          <FolderBox>
            <img style={webStyle.folderImg} src={this.isImage(document.name) ? document.path : FolderDefaultImage} />
            <Box style={webStyle.folderTextBox}>
              <Box sx={webStyle.folderTextWrapper}>
                <Typography style={webStyle.folderTitle}>{document.name}</Typography>
                <Box style={webStyle.folderSizeBox}>
                  <Typography style={webStyle.numberOfFiles}>{moment(document.date).format("DD MMM YYYY")}</Typography>
                  <img src={Circle} />
                  <Typography style={webStyle.numberOfFiles}>{"0 MB"}</Typography>
                </Box>
              </Box>
              <MoreVertIcon style={{ color: "#64748B" }} />
            </Box>
  
          </FolderBox>
        </Grid>
      ))
    )
  }

  render() {
    const { currentLanguage } = this.state;
    const languageData = languageTranslationData[currentLanguage]
    return (
      <UserProfileDrawer
        handleClick={this.drawerNavigation}
        pageName={this.state.userType === "consumer" ? "" : "Documentation"}
        breadScrumArray={this.state.breadscrumValues}
        data-test-id="drawerTestID"
        userType={this.state.userType === "consumer" ? "consumer" : "admin_account"}>
        <MainBox>

          <HeaderBox>
            <Breadcrumbs separator="" style={webStyle.breadcrumBox}>
              <Typography style={webStyle.breadcrumText} onClick={() => this.props.navigation.navigate('Documentation')}>{languageData.Documents}</Typography>
              <ArrowForwardIosIcon style={webStyle.breadcrumIcon} />
              <Typography style={webStyle.breadcrumText}>{this.state.folderName}</Typography>
            </Breadcrumbs>
            <FileButton
              data-test-id="upload-files"
              onClick={this.handleModalOpen}
              variant="contained"
              startIcon={<img src={DocUpload} />}>
              {languageData.UploadNewDoc}
            </FileButton>
          </HeaderBox>
          
          <Title>{this.state.folderName}</Title>
          <Grid container spacing={2}>
            {this.renderFolders()}
          </Grid>

          <Box style={webStyle.paginationContainer}>
            <CustomPagination
                data-test-id="tablePaginationTestID"
                page={this.state.pagination.current_page}
                count={this.state.pagination.total_pages}
                onChange={this.handlePageChange}
                shape="rounded"
                variant="outlined"
                siblingCount={0}
                boundaryCount={1}
            />
          </Box>

          <UploadFileModal 
            data-test-id="uploadFileModalTestID"
            open={this.state.isModalOpen}
            onClose={this.handleModalClose}
            loading={this.state.uploadingLoader}
            currentLanguage={this.state.currentLanguage}
            onUpload={this.handleUpload}
          />

          <Snackbar
              data-test-id="snackbarTestID"
              open={this.state.snackbar.open}
              autoHideDuration={6000}
              onClose={this.handleSnackbarClose}
            >
            <Alert onClose={this.handleSnackbarClose} severity={this.state.snackbar.severity}>
              {this.state.snackbar.message}
            </Alert>
          </Snackbar>

        </MainBox>
      </UserProfileDrawer>
    );
  }
}

const CustomPagination = withStyles({
  ul: {
    '& .MuiPaginationItem-root': {
      color: '#0F172A',
      border: 'none',
    },
    '& .Mui-selected': {
      color: '#1D4ED8',
      fontWeight: 'bold',
      background: 'none',
    },
    '& .MuiPaginationItem-ellipsis': {
      color: '#1F2937',
    },
    '& .MuiPaginationItem-icon': {
      color: '#0F172A',
    },
    '& .MuiPaginationItem-page:not(.Mui-selected)': {
      textDecoration: 'underline',
      textUnderlineOffset: '3px',
    }
  }
})(Pagination);

const FileButton = styled(Button)({
  padding: '10px 16px',
  borderRadius: '8px',
  backgroundColor: '#1E3A8A',
  color: '#FFFFFF',
  fontFamily: 'Inter',
  fontSize: '16px',
  fontWeight: 700,
  lineHeight: '24px',
  textAlign: 'left',
  textTransform: "capitalize",
  cursor: "pointer",
  whiteSpace: "nowrap",
  '&:hover': {
    backgroundColor: '#1E3A8A',
    color: '#FFFFFF',
  },
});

const HeaderBox = styled(Box)({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  alignItems: "center"
});

const MainBox = styled(Box)({
  width: "100%",
  background: "#F8FAFC",
  display: "flex",
  flexDirection: "column",
  gap: "24px",
});
const Title = styled(Typography)({
  color: "#0F172A",
  fontSize: "30px",
  fontWeight: 700,
  letterSpacing: "-0.5%"
});
const FolderBox = styled(Box)({
  width: "338.33px",
  height: "294px",
  borderRadius: "16px",
  border: "1px solid #E2E8F0",
  background: "#FFFFFF",
  overflow: "hidden",
  "@media (max-width: 1280px)": {
    width: "100%", 
  },

});
const webStyle = {
  breadcrumBox: {
    display: 'flex',
    alignItems: 'center',
    height: "30px"
  },
  breadcrumText: {
    color: "#1E293B",
    fontSize: "14px",
    fontWeight: 400,
    cursor: "pointer",
  },
  breadcrumIcon: {
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 400,

  },
  folderTextBox: {
    width: "298.33px",
    height: "54px",
    padding: "20px",
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  folderTextWrapper: {
    width: "257px",
    height: "54px",
    display: "flex",
    flexDirection: "column",
    gap: "8px"
  },
  folderSizeBox: {
    display: "flex",
    gap: "8px",
    alignItems: "center",
  },
  numberOfFiles: {
    color: "#64748B",
    fontSize: "14px",
    fontWeight: 500
  },
  folderTitle: {
    color: "#0F172A",
    fontSize: "16px",
    fontWeight: 500
  },
  folderImg:{
    width : "100%",
    height :"200px"
  },
  paginationContainer: {
    display: "flex",
    flexGrow: 1,
    justifyContent: "center"
  }
};

// Customizable Area End
