import React from "react";
// Customizable Area Start
import {
    withStyles, StyleRules, styled
} from "@material-ui/core/styles";
import { Box, InputLabel, IconButton, FormControl, Typography, Divider, Accordion, AccordionSummary, Chip, Select, MenuItem, Grid, Paper, } from "@material-ui/core";
import { dollarImg, image, image_notes, morebtnuploadImg, serverImg, uploadImg, userProfileImg, vectorImg2, viewCircul } from "./assets";
import RequestManagementController, { Props, nameObject } from "./RequestManagementController.web";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Timeline from '@material-ui/lab/Timeline';
import TimelineItem from '@material-ui/lab/TimelineItem';
import TimelineSeparator from '@material-ui/lab/TimelineSeparator';
import TimelineConnector from '@material-ui/lab/TimelineConnector';
import TimelineContent from '@material-ui/lab/TimelineContent';
import TimelineDot from '@material-ui/lab/TimelineDot';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
const languageTranslationData = require('../../languagesupport/src/LanguageData/RequestVerificaion.json')
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start

    // Customizable Area End
}

export class RequestManagement extends RequestManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    
    renderCardsData = (name: string, size: string) => {
        return (
            <Box sx={classes.documentBox}>
                <CustomCard>
                    <Box style={classes.documentIconContainer}>
                        <img style={classes.idProofImg} src={image_notes} alt = "doc_icon" />
                    </Box>
                    <Box style={classes.documentInfo}>
                        <Typography style={classes.documentTitle1}>
                            {name}
                        </Typography>
                        <Typography style={classes.documentSize}>
                            {size}
                        </Typography>
                    </Box>
                    <IconButton>
                        <img src={image} />
                    </IconButton>
                </CustomCard>
            </Box>
        )
    }

    renderViewDetailBox = (fileName: string, size: string, index: number) => {
        return (
            <Box key={index} sx={classes.documentBoxVr}>
                <CustomCard>
                    <Box style={classes.documentIconContainerSide}>
                        <img src={image_notes} />
                    </Box>
                    <Box style={classes.documentInfoSide}>
                        <Typography style={classes.documentTitleSide}>
                            {fileName}
                        </Typography>
                        <Typography style={classes.documentSizeSide}>
                            {size}
                        </Typography>
                    </Box>
                    <IconButton>
                        <img src={image} />
                    </IconButton>
                </CustomCard>
            </Box>
        )
    };
    
    renderUploadBox = (index: number) => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        return (
            <Grid key={index} item xs={12} md={6}>
                <Typography style={classes.uploadFileSectionGridText}>{languageData.uploadFileSectionGridText}</Typography>
                <PaperBox elevation={1}>
                    <IconButton color="primary" component="span" style={{ fontSize: 40 }}>
                        <img src={uploadImg} style={classes.uploadImagestyle} />
                    </IconButton>
                    <Typography style={classes.uploadImagelable}>
                        {languageData.uploadImageLable}
                    </Typography>
                    <FileType gutterBottom>
                        {languageData.uploadImageFiletype} <br />{languageData.uploadImageFiletypeDoc}
                    </FileType>
                    <ChoseFileButton component={"label"}>
                        {languageData.choseFileButtonText}
                        <input 
                            type="file"
                            onChange={(event)=>this.handleAddDocument(event, index)} 
                            hidden
                        />
                    </ChoseFileButton>
                </PaperBox>
            </Grid>
        )
    };

    renderOwnerinformation = () => {
        const { accord, currentLanguage, data } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        const detailLabels = this.handleOwnerDetailLabels();
        const valueOne = this.getDateOrCommercial(data.attributes.Company.provider_account.sub_type)
        return (
            <CustomAccordionBox data-test-id="accordianTest" expanded={accord} onChange={this.handleAccordian}>
                <AccordionSummaryBox>
                    <Box data-test-id="accordianIconTest" sx={{ display: "flex", height: "28px", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}>
                        <CustomTypography>{languageData.TitleUser}</CustomTypography>
                        <ExpandMoreIcon 
                            style={{
                                transform: this.state.accord ? 'rotate(180deg)' : 'rotate(0deg)',
                                transition: 'transform 0.3s ease',
                            }}
                        />
                    </Box>
                    <Box style={classes.avtarMainBox}>
                        <img src={data.attributes.Company.provider_account.profile_image} style={classes.avtarImage} />
                        <Box>
                            <Typography style={classes.avtarHeading}>{data.attributes.Company.provider_account[nameObject[data.attributes.Company.provider_account.sub_type]]}</Typography>
                            <Typography style={classes.userEmailText}>{data.attributes.Company.provider_account.email}</Typography>
                        </Box>
                    </Box>
                    <Box style={classes.accordianUserStatusbox}>
                        <ActiveButton>{languageData.CollectionButton}</ActiveButton>
                        <Typography style={classes.accordianUserLogin}>{languageData.UserLastLogin} <span style={{ fontWeight: 700 }}>{this.handleEmptyString(this.formatDate(data.attributes.Company.provider_account.last_visit_at))}</span></Typography>
                        <Typography style={classes.accordianUserLogout}>{languageData.UserCurrentLogin}  <span style={{ fontWeight: 700 }}>{this.handleEmptyString(this.formatDate(data.attributes.Company.provider_account.created_at))}</span></Typography>
                    </Box>
                    <Box sx={classes.AccordionDetailsBox2}>
                        <Box sx={classes.AccordionDetailsTextBox}>
                            <Typography style={classes.accordianHeadText}>{this.handleEmptyString(data.attributes.Company.provider_account.full_phone_number)}</Typography>
                            <Typography style={classes.accordianSmallText}>{languageData.accordianSmallText1}</Typography>
                        </Box>
                        <Box sx={classes.AccordionDetailsTextBox}>
                            <Typography style={classes.accordianHeadText}>{this.handleEmptyString(data.attributes.Company.provider_account.country)}</Typography>
                            <Typography style={classes.accordianSmallText}>{languageData.accordianSmallText2}</Typography>
                        </Box>
                        <Box sx={classes.AccordionDetailsTextBox}>
                            <Typography style={classes.accordianHeadText}>{this.handleEmptyString(data.attributes.Company.provider_account.state)}</Typography>
                            <Typography style={classes.accordianSmallText}>{languageData.accordianSmallText3}</Typography>

                        </Box>
                        <Box sx={classes.AccordionDetailsTextBox}>
                            <Typography style={classes.accordianHeadText}>{this.handleEmptyString(data.attributes.Company.provider_account.city)}</Typography>
                            <Typography style={classes.accordianSmallText}>{languageData.accordianSmallText4}</Typography>
                        </Box>
                        <Box sx={classes.AccordionDetailsTextBox}>
                            <Typography style={classes.accordianHeadText}>{this.handleEmptyString(data.attributes.Company.provider_account.zipcode)}</Typography>
                            <Typography style={classes.accordianSmallText}>{languageData.accordianSmallText5}</Typography>
                        </Box>
                    </Box>
                </AccordionSummaryBox>
                <AccordionDetailsMainBox>
                    <Box sx={classes.accordianUserSecondBox}>
                        <Typography style={classes.headTwo}>{languageData.DetailedInfo}</Typography>
                        <Box style={classes.UserBirthBox}>
                            <Box sx={classes.AccordionDetailsTextBox}>
                                <Typography style={classes.accordianHeadText}>{this.handleEmptyString(valueOne)}</Typography>
                                <Typography style={classes.accordianSmallText}>{detailLabels.detailLabelOne}</Typography>
                            </Box>
                            <Box sx={classes.AccordionDetailsTextBox}>
                                <Typography style={classes.accordianHeadText}>{data.attributes.Company.provider_account.license_number}</Typography>
                                <Typography style={classes.accordianSmallText}>{detailLabels.detailLabelTwo}</Typography>
                            </Box>
                        </Box>
                    </Box>
                    { (data.attributes.Company.provider_account.images.id_proof_image || data.attributes.Company.provider_account.images.address_proof_image) &&
                        <Box sx={classes.accordianFourthBox}>
                            <Box sx={classes.documentContainer}>
                                {
                                    [
                                        {label: detailLabels.detailLabelThree, value: data.attributes.Company.provider_account.images.id_proof_image},
                                        {label: detailLabels.detailLabelFour, value: data.attributes.Company.provider_account.images.address_proof_image}
                                    ].map((item, index) => 
                                        <React.Fragment key={index}>
                                            {
                                                item && 
                                                <Box>
                                                    <Typography style={classes.documentBoxMainHe}>{item.label}</Typography>
                                                    {this.renderViewDetailBox(item.value.file_name, item.value.size, index)}
                                                </Box>
                                            }
                                        </React.Fragment>
                                    )
                                }
                            </Box>
                        </Box>
                    }
                    {data.attributes.Company.dept_portfolio && <Box sx={classes.acordianUserProfileLastbox}>
                        <Typography style={classes.headTwo}>{languageData.DBProfileInfo}</Typography>
                        <Box style={classes.acordianUserSubFirst}>
                            <Box style={classes.dolorWrapper}>
                                <img src={dollarImg} style={classes.dolorWrapperImg} />
                                <Box sx={classes.dolorWrapperTextBox}>
                                    <Typography style={classes.dolorWrapperHead}>{this.handleEmptyString(data.attributes.Company.dept_portfolio.portfolio_size)}</Typography>
                                    <Typography style={classes.dolorWrapperDes}>{languageData.DollorSmallText}</Typography>
                                </Box>
                            </Box>
                            <Box style={classes.dolorWrapper}>
                                <img src={userProfileImg} style={classes.dolorWrapperImg} />
                                <Box sx={classes.dolorWrapperTextBox}>
                                    <Typography style={classes.dolorWrapperHead}>{this.handleEmptyString(data.attributes.Company.dept_portfolio.account_size)}</Typography>
                                    <Typography style={classes.dolorWrapperDes}>{languageData.ACountText}</Typography>
                                </Box>
                            </Box>
                            <Box style={classes.dolorWrapper}>
                                <img src={serverImg} style={classes.dolorWrapperImg} />
                                <Box sx={classes.dolorWrapperTextBox}>
                                    <Typography style={classes.dolorWrapperHead}>{this.handleEmptyString(data.attributes.Company.dept_portfolio.average_balance)}</Typography>
                                    <Typography style={classes.dolorWrapperDes}>{languageData.AVBalanceText}</Typography>
                                </Box>
                            </Box>
                        </Box>
                        <Box style={classes.acordianLastWrapperbox}>
                            <Box sx={classes.AccordionDetailsTextBox}>
                                <Typography style={classes.accordianHeadText}>{this.handleEmptyString(data.attributes.Company.dept_portfolio.dept_type)}</Typography>
                                <Typography style={classes.accordianSmallText}>{languageData.CDType}</Typography>
                            </Box>
                            <Box sx={classes.AccordionDetailsTextBox}>
                                <Typography style={classes.accordianHeadText}>{this.handleEmptyString(data.attributes.Company.dept_portfolio.dept_age)}</Typography>
                                <Typography style={classes.accordianSmallText}>{languageData.AgeOfDate}</Typography>
                            </Box>
                        </Box>
                    </Box>}
                </AccordionDetailsMainBox>
            </CustomAccordionBox>  
        );
    };

    renderTimeline = () => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        return (
            <SubBox>
                <Box>
                    <Typography style={classes.varificationHeading}>
                        {languageData.VRTimeLine}
                    </Typography>
                    <Box style={{overflowY: "auto", overflowX: "hidden", height: "668px"}}>
                    <TimelineBox>
                        {this.state.data.attributes.timelines && this.state.data.attributes.timelines.map((item, index) => (
                            <CustomTimelineItem key={index}>
                                <TimelineSeparator>
                                    <TimelineDot style={classes.varificationTimelineDot}>
                                            <Typography style={classes.varificationTimelineDotText}>{index + 1}</Typography>
                                    </TimelineDot>
                                    {this.state.data.attributes.timelines.length !== index+1 &&
                                        <TimelineConnector style={classes.varificationTimelineConnector} />
                                    }
                                </TimelineSeparator>
                                <TimelineContent>
                                    <Typography style={classes.varificationTimelineContent}>
                                        {this.formatDate(item.created_at)}
                                    </Typography>
                                    {
                                        !item.business_risk ?
                                        <Typography style={classes.varificationTimelineContentText}>
                                            {item.desc}
                                        </Typography>:
                                        <>
                                            <Box style={{ display: 'flex', flexDirection: 'column', gap: '12px', marginTop: '6px', width: '250px' }}>
                                                {item.supporting_documents.map((document, index)=> 
                                                    this.renderViewDetailBox(document.file_name, document.size, index)
                                                )}
                                            </Box>
                                            <Box style={{ marginLeft: '8px' }}>
                                                <Typography style={{ display: 'flex', alignItems: 'center', marginTop: '10px' }}>
                                                    <ErrorOutlineIcon style={{ marginRight: 1, color: this.handleBusinessRiskColor(item.business_risk) }} />
                                                    <Typography style={{ color: this.handleBusinessRiskColor(item.business_risk)}}> {languageData[item.business_risk]}</Typography>
                                                </Typography>
                                            </Box>
                                        </>
                                    }
                                </TimelineContent>                                        
                            </CustomTimelineItem>
                        ))}
                    </TimelineBox>
                    </Box>        
                </Box>
            </SubBox>
        );
    };

    // Customizable Area End
    render() {
        // Customizable Area Start

        const { expanded, currentLanguage, data, documentArray, businessRisk } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        return (
            <UserProfileDrawer
                handleClick={this.handleDrawerNavigation3}
                pageName={this.state.userType === "consumer" ? "UserRequests" : "dashboardVerificationRequest"}
                breadScrumArray={this.state.breadscrumValues}
                data-test-id="drawerTestID"
                navigation={this.props.navigation}>

                <Box style={classes.mainHeadBoxRe1}>
                    <Typography
                        data-test-id="bredcrums1ButtonTestId"
                        onClick={() => this.handleNavigatingRoute3('dashboardVerificationRequest')} 
                        style={classes.headBoxText}
                    >
                        {languageData.Title}
                    </Typography>
                    <img style={{ height: "9.31px", width: "5.49px", color: "#64748B" }} src={vectorImg2} alt='icon' />
                    <Typography style={classes.headBoxText2}>{languageData.Bredcrums}</Typography>
                </Box>
                <Typography style={classes.mainHeading}>{languageData.Bredcrums}</Typography>

                <MainBox>
                    <Box style = {{width: "72%"}}>
                        <VrBox>
                            <Box style={classes.topSectionMainBox}>
                                <VrFirstBox>
                                    <Typography style={classes.VrFirstHead}>{data.attributes.registration_number}</Typography>
                                    <Box style={classes.VrFirstWrapper}>
                                        <Typography
                                            data-test-id="statusTextTestId"
                                            style={{
                                                ...classes.VrFirstWrapperText,
                                                ...this.handleStatusColor(data.attributes.status),
                                                textTransform: "uppercase",
                                            }}
                                        >
                                            {data.attributes.status}
                                       </Typography>
                                        <Typography style={classes.VrFirstWrapperDate}>{languageData.CreationDate} <span style={{ fontWeight: 700 }}>{this.formatDate(data.attributes.request_date)}</span></Typography>
                                    </Box>
                                </VrFirstBox>
                                <Box>
                                    {data.attributes.premium === "premium" && (
                                        <Typography style={classes.premiumButton}>
                                            {languageData.Premiumverification}
                                        </Typography>
                                    )}
                                </Box>
                            </Box>
                            <StyledDivider />

                            {data.attributes.Company.business_profile && 
                                <>
                                    <CustomAccordion>
                                        <CustomAccordionBox 
                                            data-test-id="busProAccordionTestId"
                                            expanded={expanded} 
                                            id="accordian_1" 
                                            onChange={this.handleChange}
                                        >
                                            <AccordionSummaryBox>
                                                <Box sx={{ display: "flex", height: "28px", justifyContent: "space-between", alignItems: "center", marginBottom: "24px" }}>
                                                    <CustomTypography>{languageData.TitleSecond}</CustomTypography>
                                                    <ExpandMoreIcon style={{
                                                        transform: this.state.expanded ? 'rotate(180deg)' : 'rotate(0deg)',
                                                        transition: 'transform 0.3s ease',
                                                    }} />
                                                </Box>
                                                <Box style={classes.avtarMainBox}>
                                                    <img src={viewCircul} style={classes.avtarImage} />
                                                    <Box>
                                                        <Typography style={classes.avtarHeading}>{data.attributes.Company.business_profile.name}</Typography>
                                                        <a style={classes.avtarEmail}>{data.attributes.Company.business_profile.website}</a>
                                                    </Box>
                                                </Box>
                                                <Box style={classes.acordianFirstMainBox}>
                                                    <Box sx={classes.acordianFirstMainBoxText1}>
                                                        <Typography style={classes.acordianFirstMainBoxTextHead}>{data.attributes.Company.business_profile.industry}</Typography>
                                                        <Typography style={classes.acordianFirstMainBoxTextDes}>{languageData.FinanceDes}</Typography>
                                                    </Box>
                                                    <Box sx={classes.acordianFirstMainBoxText2}>
                                                        <Typography style={classes.acordianFirstMainBoxTextHead1}>{data.attributes.Company.business_profile.registration}</Typography>
                                                        <Typography style={classes.acordianFirstMainBoxTextDes1}>{languageData.CrnDes}</Typography>
                                                    </Box>
                                                </Box>
                                            </AccordionSummaryBox>
                                            <AccordionDetailsMainBox>
                                                <Box sx={classes.AccordionDetailsBox}>
                                                    <Box sx={classes.AccordionDetailsTextBox}>
                                                        <Typography style={classes.accordianHeadText}>{data.attributes.Company.business_profile.country}</Typography>
                                                        <Typography style={classes.accordianSmallText}>{languageData.accordianSmallText2}</Typography>
                                                    </Box>
                                                    <Box sx={classes.AccordionDetailsTextBox}>
                                                        <Typography style={classes.accordianHeadText}>{data.attributes.Company.business_profile.state}</Typography>
                                                        <Typography style={classes.accordianSmallText}>{languageData.accordianSmallText3}</Typography>
                                                    </Box>
                                                    <Box sx={classes.AccordionDetailsTextBox}>
                                                        <Typography style={classes.accordianHeadText}>{data.attributes.Company.business_profile.city}</Typography>
                                                        <Typography style={classes.accordianSmallText}>{languageData.accordianSmallText4}</Typography>
                                                    </Box>
                                                    <Box sx={classes.AccordionDetailsTextBox}>
                                                        <Typography style={classes.accordianHeadText}>{data.attributes.Company.business_profile.zip}</Typography>
                                                        <Typography style={classes.accordianSmallText}>{languageData.accordianSmallText5}</Typography>
                                                    </Box>
                                                </Box>
                                                <Box sx={classes.accordianSecondBox}>
                                                    <Typography style={classes.headTwo}>{languageData.ServiceOffered}</Typography>
                                                    <ChipBox>
                                                        {
                                                            data.attributes.Company.business_profile.services.map((value, index)=>
                                                                <Chip key={index} label={value} style={classes.chipStyle} />
                                                            )
                                                        }
                                                    </ChipBox>
                                                </Box>
                                                <Box sx={classes.accordianThirdBox}>
                                                    <Typography style={classes.accordianThirdBoxHead}>{languageData.Bio}</Typography>
                                                    <Typography style={classes.accordianThirdBoxDes}>{data.attributes.Company.business_profile.bio}</Typography>
                                                </Box>
                                                <Box sx={classes.accordianFourthBox}>
                                                    <Box sx={classes.documentContainer}>
                                                        {
                                                            data.attributes.Company.business_profile.documents.map((item, index)=>
                                                                <Box key={index}>
                                                                    <Typography style={classes.documentBoxMainHe}>{languageData.Portfolio}</Typography>
                                                                    {this.renderViewDetailBox(item.file_name, item.size, index)}
                                                                </Box>
                                                            )
                                                        }
                                                    </Box>
                                                </Box>
                                            </AccordionDetailsMainBox>
                                        </CustomAccordionBox>
                                    </CustomAccordion> 
                                    <StyledDivider />
                                </>
                            }
                            <CustomAccordion>
                                {this.renderOwnerinformation()}
                            </CustomAccordion>

                        </VrBox>
                    </Box>
                    <Box>
                        {this.renderTimeline()}
                    </Box>
                </MainBox>
                <BottomBox>
                    <Typography style={classes.bottomBoxHeading}>{languageData.bottomBoxHeading} </Typography>
                    <Box sx={classes.bottomSmallwrapper}>
                        <Typography style={classes.bottomSmallText}>{languageData.bottomSmallText}</Typography>
                        <FormControl fullWidth>
                            <CustomInputLabel shrink={false} style={{ lineHeight: "0", display: businessRisk? "none": "block"}}>
                                {languageData.bottomSmallText}
                            </CustomInputLabel>
                            <CustomSelect
                                fullWidth
                                data-test-id="businessRiskTestId"
                                value={businessRisk}
                                style={classes.selectBox}
                                variant="outlined"
                                IconComponent={ExpandMoreIcon}
                                onChange={this.handleBusinessRiskChange}
                                disabled = {data.attributes.status !== "pending"}
                            >
                                <MenuItem value="high">{languageData.varificationMenuItem1}</MenuItem>
                                <MenuItem value="medium">{languageData.varificationMenuItem2}</MenuItem>
                                <MenuItem value="low">{languageData.varificationMenuItem3}</MenuItem>
                            </CustomSelect>
                        </FormControl>
                        {(this.state.businessRiskErr && this.state.submitCheck) && (
                            <Typography style={classes.errorText}>
                                {this.state.businessRiskErr}
                            </Typography>
                        )}
                    </Box>
                    {
                        data.attributes.premium === "premium" && 
                        <Box sx={classes.uploadFileSectionBox}>
                            <Typography style={classes.uploadFileSectionHeading}>
                                {languageData.uploadFileSectionHeading}
                            </Typography>
                            <Grid spacing={4} container>
                                {data.attributes.status === "pending" && 
                                    <>
                                        {documentArray.map((document, index) => 
                                            document !== null? 
                                            this.renderViewDetailBox( document.name, `${((document.size)/1024).toFixed(2)} KB`, index):
                                            this.renderUploadBox(index)
                                        )}
                                    </>
                                }
                            </Grid>
                        </Box>
                    }
                    {
                        data.attributes.status === "pending" && 
                        <>
                            {data.attributes.premium === "premium" && 
                                <Box style={classes.bottomButtonText}>
                                <ChoseFileButton component="label" style={classes.bottomButtonTextWrapper}>
                                <Box style={classes.uploadImaheBox}><img src={morebtnuploadImg} style={classes.bottomButtonImage} /></Box>
                                    <DocumentButtonText>{languageData.documentButtonText}</DocumentButtonText>
                                    <input 
                                        type="file"
                                        data-test-id="docAddMoreTestId"
                                        onChange={(event)=>this.handleAddDocument(event, null)} 
                                        hidden
                                    />
                                </ChoseFileButton>
                                </Box>
                            }
                            <StyledDivider />
                            <ButtonWrraper>
                                <ApproveButton
                                    data-test-id="acceptButtonTestId"
                                    onClick={()=> this.submitBusinessRisk(data.id, "accepted")}
                                >
                                    {languageData.Approve}
                                </ApproveButton>
                                <RejectButton
                                    data-test-id="rejectButtonTestId"
                                    onClick={()=> this.submitBusinessRisk(data.id, "rejected")}
                                >
                                    {languageData.Reject}
                                </RejectButton>
                            </ButtonWrraper>
                        </>
                    }
                </BottomBox>

            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withStyles(styles)(RequestManagement);

const BottomBox = styled(Box) ({
    borderRadius: "18px",
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    gap: '24px',
    boxShadow: "0px 2px 8px 0px #00000014",
    background: "#FFFFFF",
    marginTop: "24px",
    '@media (min-width: 1280px) and (max-width: 1350px)': {
        width: "900px"
    }
});

const ButtonWrraper = styled(Box)({
    height: "44px",
    display: "flex",
    gap: '12px',

});

const CustomInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    "&.Mui-focused": {
      display: "none",
      color: "#94A3B8"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const DocumentButtonText = styled(Typography)({
    fontSize: '16px',
    color: '#0F172A',
    fontWeight: 700,
    textTransform: 'none',
    whiteSpace: "nowrap",
    marginLeft:"8px"

});
const ApproveButton = styled(Box)({
    height: "44px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    backgroundColor: '#1E3A8A',
    fontSize: '14px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'none'

});

const ChoseFileButton = styled(Box)({
    backgroundColor: '#EFF6FF',
    fontSize: '16px',
    fontWeight: 700,
    color: '#0F172A',
    textTransform: 'none',
    height: '44px',
    marginTop: '8px',
    borderRadius: '8px'

});
const PaperBox = styled(Paper)({
    padding: '24px 64px 24px 64px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed grey'

});
const FileType = styled(Typography)({
    fontSize: '12px',
    fontWeight: 400,
    color: '#64748B',
    textAlign: 'center'

});

const RejectButton = styled(Box)({
    width: "100%",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    backgroundColor: '#EFF6FF',
    fontSize: '14px',
    fontWeight: 700,
    color: '#0F172A',
    textTransform: 'none'

});

const CustomAccordionBox = styled(Accordion)({
    boxShadow: 'none',
    position: "relative",
    '&:before': {
        display: 'none',
    },
    '&.Mui-expanded': {
        margin: '0',
        position: "relative"
    },

});
const AccordionSummaryBox = styled(AccordionSummary)({
    display: 'block',
    padding: "0px",
    '& .MuiAccordionSummary-content': {
        display: "block"
    }

});

const MainBox = styled(Box)({
    display: "flex",
    gap: "20px",
    width: "76vw"
});

const VrBox = styled(Box)({
    height: "auto",
    borderRadius: "18px",
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    gap: '24px',
    boxShadow: "0px 2px 8px 0px #00000014",
    background: "#FFFFFF",
    '@media (min-width: 1280px) and (max-width: 1350px)': {
        width: "550px",
        gap: '16px'
    }
});
const SubBox = styled(Box)({
    width: "290px",
    height: "688px",
    borderRadius: "18px",
    padding: "28px",
    display: "flex",
    flexDirection: "column",
    gap: '24px',
    boxShadow: "0px 2px 8px 0px #00000014",
    background: "#FFFFFF",

});
const VrFirstBox = styled(Box)({
    height: "66px",
    display: "flex",
    flexDirection: "column",
    gap: "16px",

});
const StyledDivider = styled(Divider)({
    background: '#CBD5E1',
});
const CustomAccordion = styled(Box)({
    gap: '24px',

});
const AccordionDetailsMainBox = styled(Box)({
    height: 'auto',
    display: "flex",
    flexDirection: "column",
    gap: "24px",

});

const CustomSelect = styled(Select)({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    "& .MuiOutlinedInput-notchedOutline": {
      borderRadius: "8px",
      border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
      borderColor: "#CBD5E1"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
      border: "1px solid #CBD5E1"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    }
});

const CustomTypography = styled(Typography)({
    fontSize: '18px',
    fontWeight: 600,
    lineHeight: '26px',
    textAlign: 'left',
    color: "#0F172A"
});

const ChipBox = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
}));
const CustomCard = styled(Box)(() => ({
    alignItems: "center",
    padding: "8px 4px 8px 8px",
    display: "flex",
    gap: "16px"
}));

const ActiveButton = styled(Typography)(() => ({
    height: "22px"
    , width: "143px"
    , background: "#D1FAE5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
    , textTransform: "uppercase",
    color: "#059669",
    fontSize: "12px",
    fontWeight: 700,
    borderRadius: "40px"
}));

const TimelineBox = styled(Timeline)({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    padding: "6px 6px"
});

const CustomTimelineItem = styled(TimelineItem)({
    "&.MuiTimelineItem-missingOppositeContent::before": {
      padding: "0 !important"
    }
});

const classes = {
    mainHeading: {
        fontSize: '30px', 
        fontWeight: 700, 
        color: '#0F172A', 
        marginBottom: '8px'
    },
    errorText: {
        fontSize: "12px",
        fontWeight: 400,
        color: "red",
        margin: "5px 0 0 0"
    },
    mainHeadBoxRe1: {
        width: "264px",
        height: "30px",
        gap: "10px",
        alignItems: "center",
        display: "flex",
        marginBottom: '15px',
        marginTop : "-25px"
    },
    headBoxText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#475569",
        whiteSpace: 'nowrap',
        cursor: "pointer"
    },
    headBoxText2: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#0F172A",
        whiteSpace: 'nowrap',
        cursor: "pointer"
    },
    reportLine: {
        position: 'relative' as 'relative',
        margin: '20px 0',
        padding: '0px 0px',
        '&::before': {
            content: '""',
            position: 'absolute' as 'absolute',
            left: '28px',
            top: 0,
            bottom: 0,
            width: '1px',
            backgroundColor: '#E2E8F0',
        },
    },
    VrFirstHead: {
        fontSize: "20px",
        fontWeight: 600,
        color: '#1E293B',
    },
    VrFirstWrapper: {
        display: "flex",
        gap: "12px",
        alignItems: "center"
    },
    VrFirstWrapperText: {
        fontSize: "12px",
        fontWeight: 700,
        height: "22px",
        width: "68px",
        display: "flex",
        justifyContent: 'center',
        alignItems: "center",
        borderRadius: "40px",
    },
    VrFirstWrapperDate: {
        fontSize: "14px",
        fontWeight: 500,
        color: "#64748B"
    },
    AccordionDetailsBox: {
        height: "52px",
        borderTop: "1px solid #E2E8F0",
        padding: "24px 0 0 0",
        gap: "72px",
        display: "flex"
    },
    AccordionDetailsBox2: {
        height: "52px",
        borderTop: "1px solid #E2E8F0",
        padding: "24px 0 0 0",
        justifyContent: "space-between",
        display: "flex"
    },
    AccordionDetailsTextBox: {
        display: "flex",
        flexDirection: "column",
        gap: "8px",
        height: "52px"
    },
    accordianHeadText: {
        fontSize: "14px",
        fontWeight: 600,
        color: "#0F172A"
    },
    accordianSmallText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#334155"
    },
    accordianSecondBox: {
        height: "112px",
        borderTop: "1px solid #E2E8F0",
        padding: "24px 0 0 0",
        gap: "16px",
        display: "flex",
        flexDirection: "column",
    },
    accordianSecondBoxHead: {
        fontSize: "16px",
        color: "#0F172A",
        fontWeight: 600,
    },
    accordianSecondBoxWrapper: {
        height: "72px",
        width: "654px",

    },
    chipStyle: {
        padding: '6px 12px',
        backgroundColor: '#E2E8F0',
        fontSize: '12px',
        fontWeight: 500
    },
    headTwo: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#0F172A'
    },
    accordianThirdBox: {
        height: "154px",
        borderTop: "1px solid #E2E8F0",
        padding: "24px 0 0 0",
        gap: "16px",
        display: "flex",
        flexDirection: "column",
    },
    accordianThirdBoxHead: {
        fintSize: "18px",
        fontWeight: 600,
        color: "#0F172A"
    },

    accordianThirdBoxDes: {
        fintSize: "14px",
        fontWeight: 400,
        color: "#0F172A"
    },
    accordianFourthBox: {
        borderTop: "1px solid #E2E8F0",
        padding: "24px 0 0 0",
        gap: "16px",
        display: "flex",
        flexDirection: "column",
    },
    documentContainer: {
        display: 'grid',
        gap: '24px',
        gridTemplateColumns: '1fr',
        '@media (min-width: 1280px) and (max-width: 1380px)': {
          gridTemplateColumns: '1fr',
        },
        '@media (min-width: 1380px)': {
          gridTemplateColumns: '1fr 1fr', 
        },
    },
    documentBox: {
        display: "flex",
        width: "315px",
        flexDirection: "column",
        gap: "4px",
        boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: "8px",
        paddingRight: '8px'
    },
    documentBoxVr: {
        flexDirection: "column",
        display: "flex",
        padding:"8px 4px 8px 8px",
        boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
        gap: "4px",
        borderRadius: "8px",
    },

    documentIconContainer: {
        borderRadius: "8px",
        backgroundColor: "#DBEAFE",
        gap: "10px",
        display: "flex",
        padding: "16px",
    },
    documentIconContainerSide: {
        backgroundColor: "#DBEAFE",
        borderRadius: "8px",
        padding: "16px"
    },
    documentInfo: {
        flex: "1 0 auto",
        marginLeft: "4px"
    },
    documentInfoSide: {
    },
    documentTitle1: {
        color: "#0F172A",
        fontWeight: 400,
        fontSize: "16px",
        width:"183px",
    },
    documentTitleSide: {
        fontSize: "16px",
        color: "#0F172A",
        fontWeight: 400
    },
    documentSize: {
        fontWeight: 400,
        color: "#64748B",
        fontSize: "16px",
    },
    documentSizeSide: {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "16px",
    },
    documentBoxMainHe: {
        color: "#334155",
        fontWeight: 700,
        fontSize: "14px",
        marginBottom: "4px"
    },
    avtarMainBox: {
        display: "flex",
        alignItems: "center",
        height: "64px",
        gap: "12px",
        marginBottom: "24px"
    },
    avtarImage: {
        height: "64px",
        width: "64px",
        border: "2px solid #DBEAFE",
        borderRadius: "100px"
    },
    idProofImg: {
        height: "24px",
        width: "24px"
    },
    avtarHeading: {
        color: "#0F172A",
        fontWeight: 600,
        fontSize: "16px",
    },
    avtarEmail: {
        color: "#334155",
        fontWeight: 400,
        fontSize: "14px",
        textDecoration: "underline"
    },
    acordianFirstMainBox: {
        borderTop: "1px solid #E2E8F0",
        padding: "24px 0 0 0",
        gap: "72px",
        display: "flex",
        height: "52px",
        width: "100%",

    },
    userEmailText: {
        fontWeight: 400,
        color: "#334155",
        fontSize: "14px",
    },
    acordianFirstMainBoxText1: {
        gap: "8px",
        display: "flex",
        flexDirection: "column",
    },
    acordianFirstMainBoxText2: {
        gap: "8px",
        flexDirection: "column",
        display: "flex",
    },
    acordianFirstMainBoxTextHead: {
        color: "#0F172A",
        fontWeight: 600,
        fontSize: "14px",
    },
    acordianFirstMainBoxTextDes: {
        color: "#334155",
        fontWeight: 400,
        fontSize: "14px",
    },
    acordianFirstMainBoxTextHead1: {
        fontWeight: 600,
        fontSize: "14px",
        color: "#0F172A",
    },
    acordianFirstMainBoxTextDes1: {
        color: "#334155",
        fontSize: "14px",
        fontWeight: 400,
    },
    accordianUserStatusbox: {
        height: "22px",
        display: "flex",
        gap: "24px",
        alignItems: "center",
        marginBottom: "24px"
    },

    accordianUserLogin: {
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500,
    },
    accordianUserLogout: {
        color: "#64748B",
        fontWeight: 500,
        fontSize: "14px",
    },
    accordianUserSecondBox: {
        height: "142px",
        display: "flex",
        gap: "24px",
        flexDirection: "column",
        paddingTop: "24px",
        borderTop: "1px solid #E2E8F0",
    },
    UserBirthBox: {
        height: "52px",
        display: "flex",
        gap: "72px",
    },
    acordianUserProfileLastbox: {
        height: "172px",
        display: "flex",
        gap: "24px",
        flexDirection: "column",
        paddingTop: "24px",
        borderTop: "1px solid #E2E8F0",
    },
    acordianUserSubFirst: {
        height: "48px",
        display: "flex",
        gap: "72px",
    },
    dolorWrapper: {
        height: "48px",
        display: "flex",
        gap: "8px",
    },
    dolorWrapperImg: {
        height: "16.67px",
        width: "16.67px"
    },
    dolorWrapperTextBox: {
        height: "48px",
        display: "flex",
        gap: "4px",
        flexDirection: "column",
    },
    dolorWrapperHead: {
        color: "#0F172A",
        fontWeight: 600,
        fontSize: "14px",
    },
    dolorWrapperDes: {
        color: "#334155",
        fontWeight: 400,
        fontSize: "14px",
    },
    acordianLastWrapperbox: {
        height: "52px",
        display: "flex",
        gap: "72px",
    },
    varificationHeading: {
        fontSize: '18px',
        fontWeight: 600,
        color: '#1E293B'
    },
    varificationTimelineDot: {
        width: '24px',
        height: '24px',
        backgroundColor: '#EFF6FF',
        padding: '4px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
    },
    varificationTimelineDotText: {
        color: '#1D4ED8',
        fontSize: '14px',
        fontWeight: '500'
    },
    varificationTimelineConnector: {
        marginBottom: '-40px',
        marginTop: '-10px',
        width: '1px'
    },
    varificationTimelineContent: {
        fontSize: '12px',
        fontWeight: 400,
        color: '#475569'
    },
    varificationTimelineContentText: {
        fontSize: '16px',
        fontWeight: 500,
        color: '#1E293B',
        width: '250px'
    },
    bottomBoxHeading: {
        fontSize: "20px",
        color: "#1E293B",
        fontweight: 600
    },
    bottomSmallwrapper: {
        height: "70px",
        display: "flex",
        gap: "4px",
        flexDirection: "column",

    },
    bottomSmallText: {
        color: "#334155",
        fontSize: "14px",
        fontweight: 700
    },
    selectBox: {
        fontSize: '16px',
        fontWeight: 400,
        border: "1px solid #CBD5E1",
        height: "44px",
        background: "transparent",
        borderRadius: "8px"
    },
    uploadFileSectionBox: {
        display: 'flex',
        flexDirection: 'column',
        gap: '16px'
    },
    uploadFileSectionHeading: {
        fontSize: '18px',
        fontWeight: 600,
        color: '#0F172A'
    },
    uploadFileSectionGridText: {
        fontSize: '14px',
        fontWeight: 700,
        color: '#334155',
        marginBottom: "4px"
    },
    uploadImagestyle: {
        height: "20.77",
        width: "21.33px"
    },
    uploadImagelable: {
        fontSize: '16px',
        fontWeight: 700,
        color: '#0F172A'
    },
    bottomButtonText: {
        display: 'flex',
        justifyContent: 'center',
        marginTop: '12px',
    },
    bottomButtonTextWrapper: {
        backgroundColor: '#EFF6FF',
        borderRadius: '8px',
        display: "flex",
        alignItems: "center",
        gap: "8px",
        padding: "10px 16px",

    },
    uploadImaheBox: {
        height:"24px",
        width:"24px",
        marginTop:"4px"
    },
    bottomButtonImage: {
        height: "15.57px",
        width: "16px"
    },
    topSectionMainBox: {
        height: "66px",
        display: "flex",
        justifyContent: "space-between",
    },
    premiumButton: {
        padding: "8px 20px",
        background: "#DBEAFE",
        borderRadius: "100px",
        fontSize: "14px",
        fontWeight: 700,
        color: "#1D4ED8"
    }
}
// Customizable Area End
/*  */