import React from "react";
// Customizable Area Start
import {
  Box,
  Typography,
  Grid,
  Avatar,
  Button,
  styled,
  Tabs,
  Tab,
} from "@material-ui/core";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import { BlogDotImage } from "./assets";
import ImageTextCard from "../../../components/src/design-system/Card/ImageTextCard";
import { configJSON, TabPanelProps, Blog } from "./CatalogueController";
import moment from "moment";
// Customizable Area End

import CatalogueController, { Props } from "./CatalogueController";

const theme = createTheme({
  palette: {
    primary: {
      main: "#0000ff",
      contrastText: "#fff",
    },
  },
});

export default class Catalogue extends CatalogueController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  renderBlogCards() {
    return (
      <>
        {
          this.state.blogs.length < 1 ? <Typography style={{ width: "100%", textAlign: "center" }}>No blog found</Typography>
          :
          this.state.blogs.map((blog: Blog, index: number) => (
          <BlogCardGrid
            data-testID="blogTestID"
            item
            key={index}
            onClick={() => this.handleNavigation("ContentManagement", { blog_id: blog.id })}
          >
            <Box key={index} sx={webStyle.cardMainBox}>
              <CardImage src={blog.attributes.image?.url || ""} />
              <Box sx={webStyle.cardImgText}>{blog.attributes.category_name || ""}</Box>
              <Box sx={webStyle.smallCard}>
                <Box style={webStyle.dateWrapperBox}>
                  <Typography
                    data-testID="UserDataTest"
                    style={webStyle.smallCardDate}
                  >
                    {moment(blog.attributes.created_at).format("DD MMM, YYYY")}
                  </Typography>
                  <Avatar style={webStyle.blogDotImage} src={BlogDotImage} />
                  <Typography style={webStyle.smallCardDate}>
                   {blog.attributes.read_minutes || ""}
                  </Typography>
                </Box>
                <Typography style={webStyle.smallCardHeading}>
                  {blog.attributes.title}
                </Typography>
                <Box style={webStyle.smallCardAvt}>
                  <Avatar
                    style={webStyle.avtImg}
                    src={blog.attributes.author_image?.url}
                  />
                  <Box>
                    <Typography style={webStyle.smallCardName}>
                      {blog.attributes.author?.name}
                    </Typography>
                    <Typography style={webStyle.smallCardSubHead}>
                      {blog.attributes.author?.bio}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </BlogCardGrid>
        ))}
      </>
    );
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    // Customizable Area End
    return (
      // Customizable Area Start
      //Merge Engine DefaultContainer
      <ThemeProvider theme={theme}>
        <MainBox>
          <ImageTextCard
            image={this.state.bannerData.attributes.image}
            heading={
              <HeadingPropBox>
                {`${this.state.bannerData.attributes.banner_title} ${this.state.bannerData.attributes.sub_text1}`}&nbsp;
                <span style={webStyle.headingHeiglightText}>
                  {this.state.bannerData.attributes.sub_text2}
                </span>
              </HeadingPropBox>
            }
            description={this.state.bannerData.attributes.banner_description}
            darkTheme={false}
            alignLeft={false}
          />
          <NavigationTabBox>
            <BlogTabs
              value={this.state.tabValue}
              data-testID={"tabTestID"}
              onChange={this.handleTabValueChange}
            >
              <BlogStyledTab
                label={configJSON.blogListAll}
                selected={this.state.tabValue === 0}
              />
              {this.state.data.map((category, index) => (
                <BlogStyledTab
                  key={category.id}
                  label={category.attributes.name}
                  selected={this.state.tabValue === index}
                />
              ))}
            </BlogTabs>
          </NavigationTabBox>
          <BlogContainerBox>
          <Grid container spacing={4} style={webStyle.blogCardWrapper}>
            {this.renderBlogCards()}
          </Grid>
          {this.state.meta.current_page < this.state.meta.total_pages && (
            <Box style={webStyle.moreButtonBox}>
              <MoreButton
                data-testID="showmoreTestID"
                onClick={this.handleShowMore}
              >
                {configJSON.moreButtonText}
              </MoreButton>
            </Box>
          )}
          </BlogContainerBox>
        </MainBox>
      </ThemeProvider>
      //Merge Engine End DefaultContainer
      // Customizable Area End
    );
  }
}

// Customizable Area Start
const CardImage = styled(Avatar)({
  height: "280px",
  width: "100%",
  objectFit: "cover",
  borderRadius: "20px",
  "@media (max-width: 1280px)": {
    width: "100%",
    borderRadius: "16px",
  },
});

const BlogCardGrid = styled(Grid)({
  display: "flex", 
  padding: "0 !important", 
  justifyContent: "center", 
  marginBottom: "33px",
  maxWidth: "32%",
  flexBasis: "32%",
  "@media (max-width: 959px)": {
    maxWidth: "48%",
    flexBasis: "48%"
  },
  "@media (max-width: 600px)": {
    maxWidth: "100%",
    flexBasis: "100%"
  }
});

const HeadingPropBox = styled(Box)({
  maxWidth: "460px",
  whiteSpace: "normal",
  "@media (max-width: 959px)": {
    maxWidth: "100%"
  }
});

const MainBox = styled(Box)({
  padding: "80px 20px",
  "& #imageTextInnDiv": {
    maxWidth: "75vw",
    padding: "20px 0 !important",
    "@media (max-width: 1400px)": {
      maxWidth: "1200px",
    },
    "@media (max-width: 500px)": {
      padding: "0px !important"
    },
    "& #gridRightToLeft": {
    "@media (max-width: 991px)": {
      flexDirection: "row-reverse"
    }
    }
  }
});

const MoreButton = styled(Button)({
  padding: "10px 16px 10px 16px",
  backgroundColor: "#1E3A8A",
  fontSize: 14,
  fontWeight: 700,
  color: "#FFFFFF",
  textTransform: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#1E3A8A",
    boxShadow: "none",
  },
});
const NavigationTabBox = styled(Box)({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  marginTop: "200px",
  marginBottom: "45px",
  maxWidth: "80vw",
  marginLeft: "auto",
  marginRight: "auto",
  "@media (max-width: 1400px)": {
    maxWidth: "1280px",
  },
  "@media (max-width: 959px)": {
    marginTop: "100px"
  }
});

const BlogContainerBox = styled(Box)({
  margin: "auto",
  maxWidth: "80vw",
  "@media (max-width: 1400px)": {
    maxWidth: "1280px"
  }
});

const BlogTabs = styled(Tabs)({
  '& .MuiTabs-flexContainer': {
    flexWrap: "wrap"
  },
  "& .MuiTabs-indicator": {
    display: "none",
  },
});
const BlogStyledTab = styled(Tab)(
  ({ selected }: { theme?: any; selected?: boolean }) => ({
    borderRadius: "48px",
    background: selected ? "#1D4ED8" : "transparent",
    fontWeight: 500,
    color: selected ? "#FFFFFF" : "#0F172A",
    fontSize: "16px",
    textTransform: "capitalize",
    border: "none",
    cursor: "pointer",
    padding: 0,
    minHeight: 0,
    minWidth: 0,
    "&.MuiTab-root": {
      padding: "10px 24px",
    },
  })
);
const webStyle = {
  productContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    width: "100%",
    backgroundColor: "white",
    marginTop: "20px",
  },
  productBox: {
    height: 250,
    width: "49%",
    marginRight: "5px",
    flexDirection: "column",
  },
  ImgContainer: {
    height: 150,
  },
  dateWrapperBox: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
  },
  productImg: {
    width: "100%",
    height: "100%",
  },
  detailContent: {
    display: "flex",
    flexDirection: "column",
  },
  mainBoxHeadingText: {
    display: "inline-block",
    fontWeight: "bold",
    borderRadius: "16px",
    padding: "6px",
    marginLeft: "-10px",
    fontSize: "30px",
  },
  headingHeiglightText: {
    display: "inline-block",
    backgroundColor: "#DBEAFE",
    fontWeight: "bold",
    color: "#1D4ED8",
    borderRadius: "16px",
    padding: "4px 6px",
    fontSize: "30px",
    lineHeight: 1.1
  } as React.CSSProperties,
  cardMainBox: {
    position: "relative",
    width: "100%",
    borderRadius: "26px",
    display: "flex",
    flexDirection: "column",
    gap: "12px",
    marginBottom: "0px",
    "@media (max-width: 1280px)": {
      gap: "10px",
      marginBottom: "0px"
    },
  },
  blogCardWrapper: {
    gap: "5px",
    justifyContent: "space-between",
    width: "100%",
    margin: "0"
  },
  cardImgText: {
    position: "absolute",
    top: 8,
    left: 8,
    backgroundColor: "#DBEAFE",
    padding: "6px 12px 6px 12px",
    color: "#1D4ED8",
    fontSize: "14px",
    fontWeight: 400,
    borderRadius: "26px",
  },
  smallCard: {
    display: "flex",
    flexDirection: "column",
    gap: "6px",
    marginTop: "12px",
  },
  smallCardDate: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
  },
  smallCardHeading: {
    fontSize: "18px",
    fontWeight: 600,
    color: "#101828",
  },
  smallCardAvt: {
    display: "flex",
    alignItems: "center",
    gap: "6px",
  },
  avtImg: {
    height: "48px",
    width: "48px",
    borderRadius: "50px",
  },
  smallCardName: {
    fontSize: "14px",
    fontWeight: 600,
    color: "#334155",
  },
  smallCardSubHead: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#64748B",
  },
  moreButtonBox: {
    display: "flex",
    justifyContent: "center",
    marginTop: "64px",
  },
  blogDotImage: {
    height: "4px",
    width: "4px",
    opacity: "60%",
  },
};
// Customizable Area End
