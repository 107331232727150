export const imgPasswordVisible = require("../assets/ic_password_visible.png");
export const imgPasswordInVisible = require("../assets/ic_password_invisible.png");
export const slider1 = require("../assets/slider-1.jpg");
export const slider2 = require("../assets/slider-2.jpg");
export const slider3 = require("../assets/slider-3.jpg");
export const dummyProduct = require("../assets/dummy-image.jpg");
export const ServiceInternalHeroImage = require("../assets/ServiceInternalHeroImage.svg");
export const SellerLogo = require("../assets/SellerLogo.svg");
export const Duration = require("../assets/Duration.svg");
export const Financial = require("../assets/Financial.svg");
export const Language = require("../assets/Language.svg");
export const Service = require("../assets/Service.svg");
export const Target = require("../assets/Target.svg");
export const ServiceCards = require("../assets/ServiceCard.svg");
export const ServiceCardSecond = require("../assets/ServiceCardSecond.svg");
export const ServiceCardThird = require("../assets/ServiceCardThird.svg");
export const ServiceCardFourth = require("../assets/ServiceCardFourth.svg");
export const ServiceCardFifth = require("../assets/ServiceCardFifth.svg");
export const ServiceCardSixth = require("../assets/ServiceCardSixth.svg");
export const ServiceCardSeventh = require("../assets/ServiceCardSeventh.svg");
export const ServiceCardEighth = require("../assets/ServiceCardEighth.svg");
export const ServiceCardNinth = require("../assets/ServiceCardNinth.svg");
export const applicationLogo = require("../assets/mainImg.png");
export const ServiceInternal = require("../assets/serviceInternalLogin.svg");
export const ResetCaptchaImg = require("../assets/ResetCaptchaImg.svg");
export const CaptchaImg = require("../assets/image_captcha.svg");
export const Verified = require("../assets/image_verified.png")
export const Info = require("../assets/info.svg")
export const image_banner = require("../assets/image_banner.png")
export const viewcircle = require("../assets/viewcircle_.png")
export const image = require("../assets/image_.png")
export const image_notes = require("../assets/image_notes.png")
export const dollar_24px = require("../assets/dollar_24px.png")
export const group = require("../assets/group_.png")
export const Path = require("../assets/Path.png")
export const viewcircle_s = require("../assets/viewcircle_user.png")
export const userDummyImage = require("../assets/profile.svg")