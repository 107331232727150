import { IBlock } from "framework/src/IBlock";
import { Message } from "framework/src/Message";
import { BlockComponent } from "framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "framework/src/Messages/MessageEnum";
import { runEngine } from "framework/src/RunEngine";

// Customizable Area Start
import { userProfile } from "./assets";
import { getStorageData, setStorageData } from "framework/src/Utilities";
const utills  = require("../../utilities/src/utills");
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
  navigation: any;
  id: string;
  // Customizable Area Start
  drawerContent?: boolean;
  selectedTab?: number
  handleChange: (e: any, n: number) => void;
  smallScreen: boolean
  // Customizable Area End
}

interface S {
  // Customizable Area Start
  token: any;
  showMenu: boolean;
  anchorEl: HTMLElement | null;
  profile: string | null;
  href: string;
  currentLanguage: 'en' | 'ar';
  isAdmin: boolean;
  userType: string;
  // Customizable Area End
}

interface SS {
  id: any;
  // Customizable Area Start
  // Customizable Area End
}

export default class NavigationMenuController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  apiGetDataCallId: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    this.subScribedMessages = [
      getName(MessageEnum.AccoutLoginSuccess),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      token: "",
      showMenu: false,
      anchorEl: null,
      profile: null,
      href: "",
      currentLanguage: configJSON.EN,
      isAdmin: false,
      userType: ""
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

    // Customizable Area Start
    // Customizable Area End
  }

  async receive(from: string, message: Message) {
    runEngine.debugLog("Message Recived", message);
    // Customizable Area Start

    // Customizable Area End
  }

  // Customizable Area Start

  userProfileProps = {
    source: userProfile,
  };

  async componentDidMount() {
    super.componentDidMount();
    const profilePic = await getStorageData('profilePic');
    const userType = await getStorageData('userType');
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({
      userType: userType,
      profile: profilePic,
      href: window.location.href,
      currentLanguage: language,
      isAdmin: location.pathname.includes("SuperAdmin") 
    })
    window.addEventListener("storage", this.handleProfileSet)
  }

  async componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<S>, snapshot?: SS | undefined) {
    const profilePic = await getStorageData('profilePic');
    const userType = await getStorageData('userType');
    if (profilePic !== prevState.profile) {
      this.setState({
        userType: userType,
        profile: profilePic,
        href: window.location.href
      })
    }
  }

  handleProfileSet = () => {
    this.componentDidMount()
  }

  handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
    this.setState({
      anchorEl: event.currentTarget
    })
  };

  handleChangeLanguage = async () => {
    const currentLanguage = await getStorageData('currentLanguage');
    if (currentLanguage) {
      const language = currentLanguage === 'en' ? 'ar' : 'en'
      setStorageData('currentLanguage', language);
      window.location.reload()
    }
  }

  handleNavigationLinkClick = (location: string, index: number) => {
    this.props.handleChange(location, index);
    this.setState({ showMenu: false, anchorEl: null })
  }

  hideMenu = () => {
    this.setState({ anchorEl: null })
  }

  toggleMenu = () => {
    this.setState({ showMenu: !this.state.showMenu })
  }
  // Customizable Area End
}
