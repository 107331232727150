import React from "react";
// Customizable Area Start
import UserProfileController, { Props, TabPanelProps } from "./UserProfileController.web";
import {
    withStyles, StyleRules, styled
} from "@material-ui/core/styles";
import { Box, IconButton, Typography, AccordionSummary, Tab, Tabs, Chip } from '@material-ui/core'
import Dashboard from "./Dashboard.web"
import { BreadcrumArrow, dollarImg, image, image_notes, serverImg, userProfileImg, dummyUserImage } from "./assets";
import { configJSON } from "./AdminDashboardController.web";
const configJson = require("./config.js");
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json')
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import moment from "moment";
import { defaultValue } from "../../../components/src/utilities";

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    // Customizable Area End
}

export class UserProfile extends UserProfileController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderCardsData = (url="", name="", fileSize="") => {
        return (

            <Box sx={userclasses.documentBox}>
                <CustomCard>
                    {
                        url ?
                        <img style={{ height: "56px", width: "56px", borderRadius: "8px" }} src={url} />
                        :
                        <Box style={userclasses.documentIconContainer}>
                            <img src={image_notes} />
                        </Box>
                    }
                    <Box style={userclasses.documentInfo}>
                        <Typography style={userclasses.documentTitle1}>
                            {defaultValue(name, "---")}
                        </Typography>
                        <Typography style={userclasses.documentSize}>
                            {fileSize}
                        </Typography>
                    </Box>
                    <IconButton>
                        <img src={image} />
                    </IconButton>
                </CustomCard>
            </Box>
        )
    }
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { currentLanguage} = this.state;
        const languageData = languageTranslationData[currentLanguage];
        const { tabValue } = this.state;
        const TabPanel: React.FC<TabPanelProps> = ({ children, value, index }) => (
            <div
                role="tabpanel"
                hidden={value !== index}
                id={`tabpanel-${index}`}
                aria-labelledby={`tab-${index}`}
                data-test-id="tabTestID"
            >
                {value === index && (
                    <Box>
                        {children}
                    </Box>
                )}
            </div>
        );
        return (
            <UserProfileDrawer 
            handleClick={this.handleDrawerNavigation}
            pageName={this.state.userType==="consumer"? "UserProfile" : "dashboardUserManagement"} 
            breadScrumArray={this.state.breadscrumValues}
            data-test-id = "drawerTestID"
            userType = {this.state.userType==="consumer"? "consumer" : "admin_account"}
            navigation={this.props.navigation}
        >
                <Box style={userclasses.mainHeadBoxRe} data-test-id="testing_user">
                    <Typography style={userclasses.headBoxText}>{languageData.UserManagement}</Typography>
                    <img style={{ height: "9.31px", width: "5.49px", color: "#64748B" }} src={BreadcrumArrow} alt='icon' />
                    <Typography style={userclasses.headBoxText2}>{languageData.userProfileDetails}</Typography>
                </Box>
                <Box style={{ display: "flex", flexDirection: "column", gap: "28px"}}>
                    <Typography data-test-id='title' style={userclasses.userProfileDetails}>{languageData.userProfileDetails}</Typography>
                    <NavigationBox data-test-id = "tabTestId">
                        <StyledTabs  value={tabValue} onChange={this.handleTabValueChange} aria-label="user profile tabs">
                            <StyledTab label={languageData.AccountDetails} selected={tabValue === 0} />
                            <StyledTab label={languageData.BuisnessProfile} selected={tabValue === 1} />
                        </StyledTabs>
                    </NavigationBox>
                    <TabPanel value={tabValue} index={0}>
                        
                        <AccountDetails>
                            <CustomAccordionBox >
                                <AccordionSummaryBox>
                                    <Box style={userclasses.avtarMainBoxUser}>
                                        <img src={defaultValue(this.state.response.profile_image, dummyUserImage)} style={userclasses.avtarImageUser} />
                                        <Box>
                                            <Typography style={userclasses.avtarHeadingUser}>{defaultValue(this.state.response.full_name)}</Typography>
                                            <Typography style={{fontWeight: 400,  color: "#334155",textDecoration: "none",fontSize: "14px",}}>{defaultValue(this.state.response.email)}</Typography>
                                        </Box>
                                    </Box>
                                    <Box style={userclasses.accordianUserStatusboxUser}>
                                        <ActiveButton>{configJSON.accountDetailsActive}</ActiveButton>
                                        <Typography style={userclasses.accordianUserLoginUser}>{configJSON.accountDetailsLogin} <span style={{ fontWeight: 700 }}>{moment(this.state.response.last_visit_at).format("DD MMM YYYY")}</span></Typography>
                                        <Typography style={userclasses.accordianUserLogoutUser}>{configJSON.accountDetailsCreate}  <span style={{ fontWeight: 700 }}>{moment(this.state.response.created_at).format("DD MMM YYYY")}</span></Typography>

                                    </Box>
                                    <Box sx={userclasses.AccordionDetailsBox2User}>
                                        <Box sx={userclasses.AccordionDetailsTextBoxUser}>
                                            <Typography style={userclasses.accordianHeadTextUser}>{defaultValue(this.state.response.full_phone_number, "---")}</Typography>
                                            <Typography style={userclasses.accordianSmallTextUser}>{configJSON.accordianSmallText5}</Typography>

                                        </Box>
                                        <Box sx={userclasses.AccordionDetailsTextBoxUser}>
                                            <Typography style={userclasses.accordianHeadTextUser}>{defaultValue(this.state.response.country, "---")}</Typography>
                                            <Typography style={userclasses.accordianSmallTextUser}>{configJSON.accordianSmallText1}</Typography>

                                        </Box>
                                        <Box sx={userclasses.AccordionDetailsTextBoxUser}>
                                            <Typography style={userclasses.accordianHeadTextUser}>{defaultValue(this.state.response.state, "---")}</Typography>
                                            <Typography style={userclasses.accordianSmallTextUser}>{configJSON.accordianSmallText2}</Typography>

                                        </Box>
                                        <Box sx={userclasses.AccordionDetailsTextBoxUser}>
                                            <Typography style={userclasses.accordianHeadTextUser}>{defaultValue(this.state.response.city, "---")}</Typography>
                                            <Typography style={userclasses.accordianSmallTextUser}>{configJSON.accordianSmallText3}</Typography>

                                        </Box>
                                        <Box sx={userclasses.AccordionDetailsTextBoxUser}>
                                            <Typography style={userclasses.accordianHeadTextUser}>{defaultValue(this.state.response.zipcode, "---")}</Typography>
                                            <Typography style={userclasses.accordianSmallTextUser}>{configJSON.accordianSmallText4}</Typography>

                                        </Box>
                                    </Box>
                                </AccordionSummaryBox>
                                <AccordionDetailsMainBox>
                                    <Box sx={userclasses.accordianUserSecondBoxUser}>
                                        <Typography style={userclasses.headTwo}>{languageData.DetailedInfo}</Typography>
                                        <Box style={userclasses.UserBirthBoxUser}>
                                            <Box sx={userclasses.AccordionDetailsTextBoxUser}>
                                                <Typography style={userclasses.accordianHeadTextUser}>{this.state.response.date_of_birth ? moment(this.state.response.date_of_birth).format("MMMM DD, YYYY")  : "---"}</Typography>
                                                <Typography style={userclasses.accordianSmallTextUser}>{configJSON.accordianUserBirthTitle}</Typography>

                                            </Box>
                                            <Box sx={userclasses.AccordionDetailsTextBoxUser}>
                                                <Typography style={userclasses.accordianHeadTextUser}>{defaultValue(this.state.response.state, "---")}</Typography>
                                                <Typography style={userclasses.accordianSmallTextUser}>{configJSON.accordianSmallText2}</Typography>

                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={userclasses.accordianFourthBox}>
                                        <Box style={userclasses.documentContainer}>
                                            <Box>
                                                <Typography style={userclasses.documentBoxMainHe}>{configJSON.documentUserFilelabel1}</Typography>
                                                {this.state.response.images && this.renderCardsData(this.state.response.images.id_proof_image?.url, this.state.response.images.id_proof_image?.file_name, this.state.response.images.id_proof_image?.size)}
                                            </Box>
                                            <Box>
                                                <Typography style={userclasses.documentBoxMainHe}>{configJSON.documentUserFilelabel2}</Typography>
                                                {this.state.response.images && this.renderCardsData(this.state.response.images.address_proof_image?.url, this.state.response.images.address_proof_image?.file_name, this.state.response.images.address_proof_image?.size)}
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box sx={userclasses.acordianUserProfileLastboxUser}>
                                        <Typography style={userclasses.headTwo}>{languageData.DebtProtfolio}</Typography>
                                        <Box style={userclasses.acordianUserSubFirstUser}>
                                            <Box style={userclasses.dolorWrapperUser}>
                                                <img src={dollarImg} style={userclasses.dolorWrapperImgUser} />
                                                <Box sx={userclasses.dolorWrapperTextBoxUser}>
                                                    <Typography style={userclasses.dolorWrapperHeadUser}>{defaultValue(this.state.response.dept_portfolio?.portfolio_size, "---")}</Typography>
                                                    <Typography style={userclasses.dolorWrapperDesUser}>{configJSON.dolorWrapperDes}</Typography>
                                                </Box>
                                            </Box>
                                            <Box style={userclasses.dolorWrapperUser}>
                                                <img src={userProfileImg} style={userclasses.dolorWrapperImgUser} />
                                                <Box sx={userclasses.dolorWrapperTextBoxUser}>
                                                    <Typography style={userclasses.dolorWrapperHeadUser}>{defaultValue(this.state.response.dept_portfolio?.account_size, "---")}</Typography>
                                                    <Typography style={userclasses.dolorWrapperDesUser}>{configJSON.UserWrapperDes}</Typography>
                                                </Box>
                                            </Box>
                                            <Box style={userclasses.dolorWrapperUser}>
                                                <img src={serverImg} style={userclasses.dolorWrapperImgUser} />
                                                <Box sx={userclasses.dolorWrapperTextBoxUser}>
                                                    <Typography style={userclasses.dolorWrapperHeadUser}>{defaultValue(this.state.response.dept_portfolio?.average_balance, "---")}</Typography>
                                                    <Typography style={userclasses.dolorWrapperDesUser}>{configJSON.ServerrWrapperDes}</Typography>
                                                </Box>
                                            </Box>

                                        </Box>
                                        <Box style={userclasses.acordianLastWrapperboxUser}>
                                            <Box sx={userclasses.AccordionDetailsTextBoxUser}>
                                                <Typography style={userclasses.accordianHeadTextUser}>{defaultValue(this.state.response.dept_portfolio?.dept_type, "---")}</Typography>
                                                <Typography style={userclasses.accordianSmallTextUser}>{configJSON.accordianUserCardType}</Typography>

                                            </Box>
                                            <Box sx={userclasses.AccordionDetailsTextBoxUser}>
                                                <Typography style={userclasses.accordianHeadTextUser}>{defaultValue(this.state.response.dept_portfolio?.dept_age, "---")}</Typography>
                                                <Typography style={userclasses.accordianSmallTextUser}>{configJSON.accordianUserCardValidLabel}</Typography>

                                            </Box>
                                        </Box>
                                    </Box>


                                </AccordionDetailsMainBox>
                            </CustomAccordionBox>
                        </AccountDetails>
                    </TabPanel>
                    <TabPanel value={tabValue} index={1}>
                        <BusinessProfile>

                            <CustomAccordion>
                                <CustomAccordionBox>
                                    <AccordionSummaryBox>
                                        <Box style={userclasses.avtarMainBoxUser}>
                                            <img src={defaultValue(this.state.businessResponse?.account_image, dummyUserImage)} style={userclasses.avtarImageUser} />
                                            <Box>
                                                <Typography style={userclasses.avtarHeadingUser}>{defaultValue(this.state.businessResponse?.name, "")}</Typography>
                                                <a href={defaultValue(this.state.businessResponse?.website )} style={userclasses.avtarWebUser}>{defaultValue(this.state.businessResponse?.website, "")}</a>
                                            </Box>
                                        </Box>


                                    </AccordionSummaryBox>
                                    <AccordionDetailsMainBox>
                                        <Box sx={userclasses.AccordionDetailsBoxUser}>
                                            {this.state.businessProfileData.map((value, index) => (
                                                <Box key={index} sx={userclasses.AccordionDetailsTextBoxUser}>
                                                    <Typography style={userclasses.accordianHeadTextUser}>{value.TopText}</Typography>
                                                    <Typography style={userclasses.accordianSmallTextUser}>{value.BottomText}</Typography>
                                                </Box>
                                            ))}
                                        </Box>
                                        <Box sx={userclasses.accordianSecondBoxUser}>

                                            <Typography style={userclasses.headTwo}>{languageData.ServicesOffered}</Typography>
                                            <ChipBox>
                                                {
                                                    this.state.businessResponse?.services?.map((item: string, index: number) => {
                                                        return  <Chip key={index} label={item} style={userclasses.chipStyle} />
                                                    })
                                                }
                                            </ChipBox>

                                        </Box>

                                        <Box sx={userclasses.accordianThirdBox}>
                                            <Typography style={userclasses.accordianThirdBoxHead}>{languageData.Bio}</Typography>
                                            <Typography style={userclasses.accordianThirdBoxDes}>{defaultValue(this.state.businessResponse?.bio, "---")}</Typography>
                                        </Box>

                                        <Box sx={userclasses.accordianFourthBox}>
                                            <Box style={userclasses.documentContainer}>
                                                {
                                                    this.state.businessResponse?.documents?.map((item: any, index: number) => {
                                                        return (
                                                            <Box key={index}>
                                                                <Typography style={userclasses.documentBoxMainHe}>{configJSON.documentBoxPortfolit}</Typography>
                                                                <Box sx={userclasses.documentBox}>
                                                                    <CustomCard>
                                                                        <Box style={userclasses.documentIconContainer}>
                                                                            <img src={image_notes} />
                                                                        </Box>
                                                                        <Box style={userclasses.documentInfo}>
                                                                            <Typography style={userclasses.documentTitle1}>
                                                                                {defaultValue(item?.file_name, "---")}
                                                                            </Typography>
                                                                            <Typography style={userclasses.documentSize}>
                                                                                {defaultValue(`${item?.size?.split(".")[0]}KB`, "---")}
                                                                            </Typography>
                                                                        </Box>
                                                                        <IconButton>
                                                                            <img src={image} />
                                                                        </IconButton>
                                                                    </CustomCard>
                                                                </Box>

                                                            </Box>
                                                        )
                                                    })
                                                }
                                            </Box>
                                        </Box>


                                    </AccordionDetailsMainBox>
                                </CustomAccordionBox>
                            </CustomAccordion>
                        </BusinessProfile>
                    </TabPanel>
                </Box>
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start
export default withStyles(styles)(UserProfile);

const StyledTabs = styled(Tabs)({
    '& .MuiTabs-indicator': {
        display: 'none',
    },
});

const StyledTab = styled(Tab)(({ selected }: { theme?: any; selected?: boolean }) => ({
    padding: '8px 16px',
    borderRadius: '100px',
    background: selected ? '#EFF6FF' : 'transparent',
    border: selected ? '1px solid #BFDBFE' : 'none',
    color: selected ? '#1D4ED8' : '#94A3B8',
    fontWeight: 700,
    fontSize: '16px',
    cursor: "pointer",
    textTransform: "capitalize",
    '&:hover': {
        background: '#F0F9FF',
    },
}));
const NavigationBox = styled(Box)({
    height: "40px",
    display: "flex",
    alignItems: "center",
    gap: "12px",
});
const AccountDetails = styled(Box)(({ theme }) => ({
    position: "relative",
    boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
    borderRadius: "24px",
    padding: "40px",
    backgroundColor : "white"

}));
const CustomAccordionBox = styled(Box)(({ theme }) => ({
    position: "relative",
    width: "100%",

}));
const CustomAccordion = styled(Box)({
    width: '100%',
    gap: '24px',

});
const AccordionSummaryBox = styled(AccordionSummary)(({ theme }) => ({
    display: 'block',
    padding: "0px",
    '& .MuiAccordionSummary-content': {
        display: "block"
    }

}));

const ActiveButton = styled(Typography)(() => ({
    height: "22px"
    , width: "143px"
    , background: "#D1FAE5",
    display: "flex",
    alignItems: "center",
    justifyContent: "center"
    , textTransform: "uppercase",
    color: "#059669",
    fontSize: "12px",
    fontWeight: 700,
    borderRadius: "40px"
}));
const AccordionDetailsMainBox = styled(Box)({

    height: 'auto',
    display: "flex",
    flexDirection: "column",
    gap: "24px"

});
const CustomCard = styled(Box)(() => ({
    alignItems: "center",
    padding: "8px 4px 8px 8px",
    display: "flex",
    gap: "16px",
}));
const BusinessProfile = styled(Box)(() => ({
    boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
    borderRadius: "24px",
    padding: "40px",
    backgroundColor : 'white'
}));
const ChipBox = styled(Box)(() => ({
    display: 'flex',
    flexWrap: 'wrap',
    gap: '12px',
}));

const userclasses = {
    mainHeadBoxRe: {
        height: "30px",
        width: "264px",
        display: "flex",
        gap: "10px",
        alignItems: "center",
        marginBottom: '15px',
        marginTop : "-25px"
    },
    headBoxText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#475569",
        whiteSpace: 'nowrap',

    },
    headBoxText2: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#0F172A",
        whiteSpace: 'nowrap'
    },
    userProfileDetails: {
        color: "#0F172A",
        fontSize: "30px",
        fontWeight: 700,
        letterSpacing: "-0.5%"
    },
    avtarMainBoxUser: {
        alignItems: "center",
        display: "flex",
        gap: "12px",
        marginBottom: "24px",
        height: "64px",
    },
    avtarImageUser: {
        width: "64px",
        height: "64px",
        border: "2px solid #DBEAFE",
        borderRadius: "100px"
    },
    avtarHeadingUser: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 600,
    },
    avtarWebUser: {
        fontWeight: 400,
        color: "#334155",
        textDecoration: "underline",
        fontSize: "14px"
    },
    accordianUserStatusboxUser: {
        gap: "24px",
        height: "22px",
        display: "flex",
        alignItems: "center",
        marginBottom: "24px"
    },

    accordianUserLoginUser: {
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500,
    },
    accordianUserLogoutUser: {
        color: "#64748B",
        fontWeight: 500,
        fontSize: "14px",
    },
    accordianUserSecondBoxUser: {
        height: "142px",
        gap: "24px",
        display: "flex",
        paddingTop: "24px",
        flexDirection: "column",
        borderTop: "1px solid #E2E8F0",
    },
    UserBirthBoxUser: {
        height: "52px",
        gap: "72px",
        display: "flex",
    },
    acordianUserProfileLastboxUser: {
        height: "172px",
        display: "flex",
        flexDirection: "column",
        paddingTop: "24px",
        gap: "24px",
        borderTop: "1px solid #E2E8F0",
    },
    acordianUserSubFirstUser: {
        height: "48px",
        gap: "72px",
        display: "flex",
    },
    dolorWrapperUser: {
        height: "48px",
        gap: "8px",
        display: "flex",
    },
    dolorWrapperImgUser: {
        height: "16.67px",
        width: "16.67px"
    },
    dolorWrapperTextBoxUser: {
        height: "48px",
        gap: "4px",
        display: "flex",
        flexDirection: "column",
    },
    dolorWrapperHeadUser: {
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 600,
    },
    dolorWrapperDesUser: {
        color: "#334155",
        fontSize: "14px",
        fontWeight: 400,
    },
    acordianLastWrapperboxUser: {
        height: "52px",
        gap: "72px",
        display: "flex",
    },
    AccordionDetailsBoxUser: {
        height: "52px",
        borderTop: "1px solid #E2E8F0",
        gap: "72px",
        padding: "24px 0 0 0",
        display: "flex"
    },
    AccordionDetailsBox2User: {
        borderTop: "1px solid #E2E8F0",
        height: "52px",
        justifyContent: "space-between",
        padding: "24px 0 0 0",
        display: "flex"
    },
    AccordionDetailsTextBoxUser: {
        gap: "8px",
        display: "flex",
        flexDirection: "column",
        height: "52px"
    },
    accordianHeadTextUser: {
        fontWeight: 600,
        fontSize: "14px",
        color: "#0F172A"
    },
    accordianSmallTextUser: {
        fontWeight: 400,
        fontSize: "14px",
        color: "#334155"
    },
    accordianSecondBoxUser: {
        height: "112px",
        borderTop: "1px solid #E2E8F0",
        gap: "16px",
        padding: "24px 0 0 0",
        display: "flex",
        flexDirection: "column",
    },
    accordianSecondBoxHeadUser: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 600,
    },
    accordianSecondBoxWrapperUser: {
        width: "654px",
        height: "72px",

    },
    headTwo: {
        fontSize: '16px',
        fontWeight: 600,
        color: '#0F172A'
    },
    accordianFourthBox: {
        height: "122px",
        borderTop: "1px solid #E2E8F0",
        padding: "24px 0 0 0",
        gap: "16px",
        display: "flex",
        flexDirection: "column",
    },
    documentContainer: {
        display: "flex",
        gap: "24px",
    },
    documentBoxMainHe: {
        color: "#334155",
        fontWeight: 700,
        fontSize: "14px",
        marginBottom: "4px"
    },
    documentBox: {
        display: "flex",
        width: "315px",
        flexDirection: "column",
        gap: "4px",
        boxShadow: " rgba(0, 0, 0, 0.24) 0px 3px 8px",
        borderRadius: "8px",
        paddingRight: '8px'
    },
    documentIconContainer: {
        borderRadius: "8px",
        backgroundColor: "#DBEAFE",
        gap: "10px",
        display: "flex",
        padding: "16px",
    },
    documentInfo: {
        flex: "1 0 auto",
        marginLeft: "4px"
    },
    documentTitle1: {
        color: "#0F172A",
        fontWeight: 400,
        fontSize: "16px",
    },

    documentSize: {
        fontWeight: 400,
        color: "#64748B",
        fontSize: "16px",
    },
    chipStyle: {
        padding: '6px 12px',
        backgroundColor: '#E2E8F0',
        fontSize: '12px',
        fontWeight: 500
    },
    accordianThirdBox: {
        height: "154px",
        borderTop: "1px solid #E2E8F0",
        padding: "24px 0 0 0",
        gap: "16px",
        display: "flex",
        flexDirection: "column",
    },
    accordianThirdBoxHead: {
        fintSize: "18px",
        fontWeight: 600,
        color: "#0F172A"
    },

    accordianThirdBoxDes: {
        fintSize: "14px",
        fontWeight: 400,
        color: "#0F172A"
    },

}
// Customizable Area End
