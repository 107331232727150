import React from "react";
// Customizable Area Start
import Link from '@material-ui/core/Link';
import UserProfileWebController, { Props } from "./UserProfileController.web";
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import { Box, Typography, Button, IconButton,Modal,styled,TextField,Divider, FormControl, Select, Grid, MenuItem, InputLabel } from "@material-ui/core";
import Paper from '@material-ui/core/Paper';
import { groupReImg, infoReImg, morebtnuploadImg, uploadImg, vectorImg } from "./assets";
import {  MoreVert } from "@material-ui/icons";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json')
import CloseIcon from '@material-ui/icons/Close';
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import NotesIcon from "@material-ui/icons/Notes";
import MoreVertIcon from '@material-ui/icons/MoreVert';


const StyledModal = styled(Modal) ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
})
const ModalContent = styled(Box)({
    position: 'absolute',
    borderRadius: '20px',
    boxShadow: "0px 8px 32px 0px #0000000F",
    background: "#FFFFFF",
    width: '887px',
    maxHeight: '90vh', 
    overflowX: 'auto',
    overflowY: 'auto'
})
const ModalHeading = styled(Typography)({
    color:"#0F172A",
    fontWeight:700,
    fontSize:"24px",
    letterSpacing:"-0.5%"
})
const ModalHeadingWrapper = styled(Box)({
    padding:"0px 40px",
    height:"80px",
    display:"flex",
    justifyContent:"space-between",
    alignItems:"center",
    borderBottom:"1px solid #E2E8F0"
})
const InputWrapper = styled(Box)({
    padding:"0px 40px",
    width:"792px",
    display:"flex",
    flexDirection:"column",
    gap:"24px",
})
const CustomTextField = withStyles({
    root: {
        width: '380px',
        height: '44px',
        gap: '8px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',
        color: "#0F172A",
        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);
const InputFieldWrapper = styled(Box)({
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',
    width: "380px",

})
const InputLabeltext = styled(Typography)({
    fontSize: '14px', 
    fontWeight: 700, 
    color: '#334155', 
    lineHeight: "22px"
})
const VisuallyHiddenInput = styled('input')({
    clip: 'rect(0 0 0 0)',
    clipPath: 'inset(50%)',
    height: 1,
    overflow: 'hidden',
    position: 'relative',
    bottom: 0,
    left: 0,
    whiteSpace: 'nowrap',
    width: 1
});
const FileButtonBox = styled(Box)({
    display: "flex",
    alignItems: "center",
    backgroundColor: "#EFF6FF",
    justifyContent: "center",
    cursor: "pointer",
    height: "44px",
    color: "#0F172A",
    borderRadius: "8px",
    textTransform: "none",
    fontSize: "16px",
    fontWeight: 700
});

const CustomInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    "&.Mui-focused": {
      display: "none",
      color: "#94A3B8"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});

const FilterSelectField = styled(Select)({
    width: '380px',
    fontSize: "14px",
    height: '44px',
    fontWeight: 400,
    color: "#0F172A",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent",
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent",
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    "& .MuiSelect-outlined": {
        background: "transparent !important"
    }
});
const FirstWrapperBox = styled(Box)({
    display:"flex",
    gap:"32px"
})
const TextFieldWrapper = styled(Box)({
    width: "100%",
    display: 'flex',
    flexDirection: 'column',
    gap: '4px',

})
const CustomDescriptionField = withStyles({
    root: {
        borderRadius: '8px',
        width: '100%',
        color: "#0F172A",
        gap: '8px',
        border: '1px solid #CBD5E1',
        '& .MuiInputBase-root': {
            height: "100px"
        },
        '& .MuiOutlinedInput-root': {
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
})(TextField);
const PaperBox = styled(Paper)({
    padding: '24px 64px 24px 64px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    border: '1px dashed grey',
    marginTop:"4px"

});
const UploadDocText = styled(Typography)({
   color:"#0F172A",
   fontSize:"16px",
   fontWeight:700,

});
const FileType = styled(Typography)({
    fontSize: '12px',
    fontWeight: 400,
    color: '#64748B',
    textAlign: 'center'

});
const CustomGrid = styled(Grid)({
    "& .MuiTypography-root": {
      color: "#334155",
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: "8px"
    },
    "& .MuiTextField-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          border: "1px solid #CBD5E1"
        }
      },
      "& .MuiInputBase-input": {
        height: "30px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        "&.Mui-disabled": {
          WebkitTextFillColor: "#0F172A"
        }
      },
      "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
      }
    }
});
const PortfolioBodyBox = styled(Box)({
    display: "flex",
    flexDirection: "column",
    alignItems: "center"
});

const EmptyUploadIcon = styled("img")({
    filter: "invert(62%) sepia(23%) saturate(452%) hue-rotate(165deg) brightness(97%) contrast(50%)",
    width: "25px",
    height: "25px"
});
const StyledDivider = styled(Divider)({
    background: '#CBD5E1',
});
const ButtonWrraper = styled(Box)({
    height: "104px",
    display: "flex",
    gap: '12px',
    alignItems:"center",
    position:'sticky',
    bottom:"0px",
    background:"#FFFFFF",
    padding:"0 24px",
    boxShadow: "0px -3px 8px #00000017",
    marginTop:"24px",
});
const ApproveButton = styled(Box)({
    height: "56px",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    backgroundColor: '#1E3A8A',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'none'

});
const RejectButton = styled(Box)({
    width: "100%",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    backgroundColor: '#EFF6FF',
    fontSize: '16px',
    fontWeight: 700,
    color: '#0F172A',
    textTransform: 'none'

});
const MainHeading = styled(Typography)({
    fontSize: "30px", 
    color: "#0F172A", 
    fontWeight: 700, 
    letterSpacing: "-0.5%", 
    marginTop: "25px" 

});
const BusinessHeader = styled(Box)({
    height: "62px", 
    display: "flex", 
    flexDirection: "column", 
    gap: "8px", 
    marginTop: "25px",
    marginBottom: "25px"
});
const BusinessHeaderWrapper = styled(Box)({
    display: "flex", 
    gap: "12px" ,
    alignItems:"center",

});
const IdText = styled(Typography)({
    fontSize: "12px",
    fontWeight: 400,
    color: "#1E293B"

});
const Status = styled(Typography)({
    fontSize: "12px", 
    fontWeight: 700, 
    textTransform: "uppercase", 
    borderRadius: "40px", 
    padding:"2px 6px 2px 8px", 
    display: "flex", 
    alignItems: "center", 
    justifyContent: "center" 

});
const ApexHead = styled(Typography)({
    fontSize: "24px", 
    fontWeight: 600, 
    letterSpacing: "-0.5%", 
    color: "#1E293B"

});
const TimelineBox = styled(Box)({
    padding: '40px', 
    display: 'flex', 
    flexDirection: 'column', 
    gap: '18px', 
    height: 'auto', 
    width: '793px', 
    borderRadius: '24px', 
    boxShadow: 'rgba(0, 0, 0, 0.15) 0px 5px 8px'

});
const TimelineHeading = styled(Typography)({
    fontSize: '20px', 
    fontWeight: 600, 
    color: '#1E293B', 
    marginLeft: "16px"

});
const TimelineWrapper = styled(Box)({
    display: 'flex', 
    alignItems: 'center', 
    marginBottom: '40px', 
    position: 'relative', 
    paddingLeft: '40px'

});
const TimelineCount = styled(Box)({
    padding: '8px', 
    backgroundColor: '#EFF6FF', 
    color: '#1D4ED8', 
    borderRadius: '50%', 
    width: '24px', 
    height: '24px', 
    display: 'flex', 
    alignItems: 'center', 
    justifyContent: 'center', 
    position: 'absolute', 
    left: '8px', 
    top: '0px', 
    fontSize: '18px', 
    fontWeight: 600,

});
const TimelineTextBox = styled(Box)({
    marginLeft: '20px', 
    display: 'flex', 
    flexDirection: 'column', 
    gap: '8px'

});
const TimelineDate = styled(Typography)({
    fontSize: '12px', 
    color: '#475569',
    fontWeight: 400, 

});
const TimelineText = styled(Typography)({
    color: '#1E293B',
    fontSize: '16px', 
    fontWeight: 500, 

});
const FileBox = styled(Box)({
    display: 'flex', 
    gap: '16px' 
});
const FileBoxWrapper = styled(Box)({
    display: 'flex', 
    alignItems: 'center', 
    gap: '16px', 
    marginBottom: '10px', 
    padding: '8px 4px 8px 8px', 
    borderRadius: '4px', 
    backgroundColor: '#f5f5f5'
});
const FileTextWrapper = styled(Box)({
    display: 'flex', 
    flexDirection: 'column'
});
const FileName = styled(Typography)({
    fontSize: '16px', 
    fontWeight: 400, 
    color: '#0F172A'
});
const FileSize = styled(Typography)({
    fontSize: '14px', 
    fontWeight: 400, 
    color: '#64748B'
});
const RiskBox = styled(Typography)({
    display: 'flex', 
    alignItems: 'center', 
    color: '#059669', 
    fontSize: '16px', 
    fontWeight: 500,
    gap:"8px"
});
const DealButtonBox = styled(Box)({
    marginLeft: '20px', 
    textAlign: 'center'
});
const CreateDealButton = styled(Button)({
    background: '#1E3A8A', 
    fontSize: '14px', 
    fontWeight: 700, 
    textTransform: 'none', 
    color: "#FFFFFF",
    '&:hover': {
        background: '#1E3A8A',   
      },
});
const InputFieldWrapperBox = styled(Box)({
    display: "flex", 
    gap: "24px", 
    flexDirection: "column", 
    marginTop: "24px"
});
const webStyle = {
    createButtonInBox: {
        display:'flex',
        gap:"10px"
    },
    fileInputWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "8px",
        gap: "14px",
    },
    noteIconBox: {
        backgroundColor: "#DBEAFE",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "56px",
        minWidth: "56px",
        height: "56px",
        cursor: "pointer"
    },
    fileSizeStyle: {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "0"
    },
    fileNameStyle: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "0",
        width:"100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    fileMoreIcon: {
        color: "#475569",
        cursor: "pointer"
    },
    errorText: {
        fontSize: "12px",
        fontWeight: 400,
        color: "red",
        margin: "5px 0 0 0"
    },
    portFolioBodyText:{
        color: "#64748B",
        fontSize: "12px",
        fontWeight: 400,
        margin: "0px"
    },
    upPortText: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 700,
        margin: "0"
    },
    porfolioBox: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        border: "1px dashed #CBD5E1",
        borderRadius: "8px",
        padding: "25px",
        gap: "12px"
    } as React.CSSProperties,
    basicInfoBox: {
        padding: "40px 17px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "24px",
        marginBottom: "42px",
        background: "white"
    },
}

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    labelText: {
        fontSize: "12px",
        color: "#fff",
        margin: "0px",
        fontWeight: 700,
        display: "flex"
    },
    container: {
        justifyContent: "center",
        gap: '20px',
        alignItems: "center",
        display: 'flex',
        flexDirection: "column",
    },
    mainBoxConatiner: {
        padding: "50px 100px",
        width: "100%",
        borderRadius: "20px",
        backgroundColor: "#1E293B",
        marginBottom: "32px"
    },
    lableTextBox: {
        fontFamily: "poppins",
        fontWeight: 600,
        fontSize: "24px",
        marginBottom: "25px",
        color: "#fff",
    },
    gridMainContainer: {
        alignItems: "center",
        gap: '20px',
        justifyContent: "start",
        display: 'flex',
    },
    formControllerBox: {
        margin: "12px 0px",
        width: '100%'
    },
    reqireText: {
        color: "red"
    },
    inputTextFiled: {
        border: '1px solid var(--neutrals-cool-gray-300, #CBD5E1)',
        background: 'var(--basic-white, #FFF)',
        width: "100%",
        borderRadius: "8px",
        height: "44px",
        fontFamily: "Inter"
    },
    documnetIcon: {
        backgroundColor: "#C2D5FF",
        marginLeft: "16px",
        height: "56px",
        width: "56px",
        justifyContent: "center",
        borderRadius: "8px",
        alignItems: "center",
        display: "flex",
    },
    documentMainBox: {
        padding: "16px 0",
        backgroundColor: "#fff",
        marginTop: "5px",
        borderRadius: "8px",
        gap: "16px",
        display: "flex",
        alignItems: "center",
        width: "100%"
    },
    documnetName: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#0F172A",
        fontFamily: "inter",
    },
    documnetSizeText: {
        color: "#64748B",
        fontFamily: "inter",
        fontSize: "16px",
        fontWeight: 400,
    },
    optionBox: {
        marginRight: "16px",
        marginLeft: "auto"
    },
    activeTab: {
        color: "#0F172A",
        fontWeight: 700
    },
    mainHeadBoxRe: {
        height: "30px",
        width: "264px",
        display: "flex",
        gap: "10px",
        alignItems: "center"
    },
    headBoxText: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#475569",
    },
    headBoxText2: {
        fontSize: "14px",
        fontWeight: 400,
        color: "#475569",
    },
    reportLine : {
        position: 'relative' as 'relative',
        margin: '20px 0',
        padding: '0px 0px',
        '&::before': {
          content: '""',
          position: 'absolute' as 'absolute',
          left: '28px',
          top: 0,
          bottom: 0,
          width: '1px',
          backgroundColor: '#E2E8F0',
        },
      },
      requestBredcrum: {
        height: "9.31px", 
        width: "5.49px", 
        color: "#64748B"
      },
      timelineImage: {
        marginRight: '10px',
        height:"56px",
        width:"56px"
      },
      riskImage: {
        height:"20px",
        width:"20px"
      },
      uploadBoxImage: {
        height: "15.57px", 
        width: "16px"
      }
  
    

  
    // Customizable Area End
}


export class RequetWeb extends UserProfileWebController {

    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area 

    renderEmptyPortfolio = (index:number, error: string, arrayName:string) =>{
        const { currentLanguage, checkSave } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (
            <CustomGrid key={index} item xs={12} sm={12} md={6}>
                <InputLabeltext>{languageData.kycUserProfile.Document}</InputLabeltext>
                <Box style={webStyle.porfolioBox}>
                    <EmptyUploadIcon 
                        src={uploadImg} 
                        style={{ fontSize: "22px", color: "#94A3B8" }}
                        alt= "file_upload_icon"
                    />
                    <Typography style={webStyle.upPortText}>
                    {languageData.Uploaddocument}
                    </Typography>
                    <PortfolioBodyBox>
                        <Typography style={webStyle.portFolioBodyText}>{languageData.DocType1}</Typography>
                        <Typography style={webStyle.portFolioBodyText}>{languageData.DocType2}</Typography>
                    </PortfolioBodyBox>
                    <FileButtonBox 
                        style={{width:"137px"}}
                        component={"label"}
                    >
                      {languageData.ChooseFile}
                        <VisuallyHiddenInput 
                            data-test-id={`${arrayName+index}TestID`}
                            onChange={(event)=>this.handleAddPortfolio(event, index)}
                            type="file"  
                        />
                    </FileButtonBox>
                </Box>
                {(error && checkSave) && (
                    <Typography style={webStyle.errorText}>
                        {error}
                    </Typography>
                )}
            </CustomGrid>
        )
    };

    renderPortfolioFile = (file:File | null, index:number, docType: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
            <CustomGrid key={index} item xs={12} sm={12} md={6}>
                <InputLabeltext>
                    {languageData.kycUserProfile.Document}
                </InputLabeltext>
                <Box style={webStyle.fileInputWrapper}>
                    <Box style={webStyle.noteIconBox}>
                        <NotesIcon style={{color: "#2563EB"}}/>
                    </Box>
                    {file !== null && <Box style={{flexGrow:1, width:"50%"}}>
                        <Typography style={webStyle.fileNameStyle}>{file.name}</Typography>
                        <Typography style={webStyle.fileSizeStyle}>{((file.size)/1024).toFixed(2)} KB</Typography>
                    </Box>}
                    <IconButton 
                        data-test-id={`fileMoreTestID${docType+index}`}
                        aria-label="more1"
                        aria-haspopup="true"
                        id = {`${docType+index}`}
                    >
                        <MoreVertIcon style={webStyle.fileMoreIcon} />
                    </IconButton>
                </Box>
                
            </CustomGrid>
        );
    };
    renderFileUploadBox = (array:(File|null)[], error: string, docType: string) => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        return (
            <Grid container spacing={4}>
                {array.map((item, index) =>
                    (item===null)
                        ? this.renderEmptyPortfolio(index, error, docType)
                        : this.renderPortfolioFile(item, index, docType)
                )}
                <CustomGrid
                    style={{ display: "flex", justifyContent: "center" }}
                    item
                    xs={12}
                    sm={12}
                    md={12}
                >
                    <FileButtonBox 
                        style={{width:"224px"}} 
                        component={"label"}
                    >
                        <Box style={{display: "flex", gap: "10px"}}>
                            <img 
                                src={uploadImg}
                                style={{width:"24px"}}
                                alt="file_upload_icon"
                            /> 
                          {languageData.documentButtonText}
                        </Box>
                        <VisuallyHiddenInput 
                            type="file" 
                            data-test-id={`addMore${docType}`} 
                            onChange={(event)=>this.handleAddPortfolio(event, null)}
                        />
                    </FileButtonBox>
                </CustomGrid>
            </Grid>
        );
    };
    renderUploadBox = () => {
        const languageData = languageTranslationData[this.state.currentLanguage]
        return (
            <Grid item xs={12} md={6}>
            <InputLabeltext>{languageData.Document}</InputLabeltext>
            <PaperBox elevation={1}>
                <IconButton color="primary" component="span" style={{ fontSize: 40 }}>
                    <img src={uploadImg} style={{ height: "20.77px", width: "21.33px" }} />
                </IconButton>
                <UploadDocText>
                    {languageData.Uploaddocument}
                </UploadDocText>
                <FileType gutterBottom>
                    {languageData.DocType1} <br />{languageData.DocType2}
                </FileType>
            </PaperBox>
        </Grid>
        
        )
    }

    renderFileBox = () => {
        const { classes } = this.props;
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage]
        return (
            <FileBoxWrapper>
            <img className={classes.timelineImage} src={groupReImg} alt="group"/>
            <FileTextWrapper>
                <FileName>{languageData.FileName}</FileName>
                <FileSize>{languageData.Size}</FileSize>
            </FileTextWrapper>
            <Box style={{ marginLeft: 'auto' }}>
                <IconButton size="small">
                    <MoreVert />
                </IconButton>
            </Box>
        </FileBoxWrapper>
        )
    }
    
    // Customizable Area End

    render() {

        // Customizable Area Start
        const { classes } = this.props;
        const { 
            currentLanguage, 
            checkSave, 
            nameError, 
            categoryIdError,
            subCategoryIdError,
            descriptionError,
            quantityError,
            termsError,
            priceError,
            documentErr
         } = this.state;
        const languageData = languageTranslationData[currentLanguage];

        const breadcrumbs = [
            <Link underline="hover" key="1" color="inherit">
                Profile
            </Link>,
            <Link underline="hover" key="1" color="inherit" href="/UserProfileWeb">
                Requests
            </Link>,
            <Link
                underline="hover"
                key="2"
                className={classes.activeTab}
            >
                {"Requests 1"}
            </Link>,
        ];


        return (
            <UserProfileDrawer
                data-test-id="drawerComponentTestID"
                handleClick={this.handleDrawerClick}
                pageName="UserRequests">
                <Box>
                    <Box className={classes.mainHeadBoxRe}>
                        <Typography onClick={() => this.props.navigation.navigate('UserRequests')} className={classes.headBoxText}>{languageData.VerificationRequest}</Typography>
                        <img className={classes.requestBredcrum} src={vectorImg} alt='icon'/>
                        <Typography className={classes.headBoxText2}>{languageData.DetailsPage}</Typography>
                    </Box>
                    <MainHeading>{languageData.DetailVerificationRequest}</MainHeading>
                    <BusinessHeader>
                        <BusinessHeaderWrapper>
                            <IdText>#{this.state.data.id}</IdText>
                            <Status
                            style={{
                                
                                  ...this.handleStatusColor(this.state.data.attributes.status)
                            }}>

                            {this.state.data.attributes.status}
                            </Status>
                        </BusinessHeaderWrapper>
                        <ApexHead>{this.state.data.attributes.full_name}</ApexHead>
                    </BusinessHeader>

                    <TimelineBox>
                        <TimelineHeading>{languageData.VerificationTimeline}</TimelineHeading>
                        <Box className={classes.reportLine}>
                        {this.state.data.attributes.timelines && this.state.data.attributes.timelines.map((item, index) => (
                            <TimelineWrapper key={index}>
                                <TimelineCount>{index + 1}</TimelineCount>
                                <TimelineTextBox>
                                    <TimelineDate>{this.formatTimelineDate(item.created_at)}</TimelineDate>
                                    <TimelineText>{item.desc}</TimelineText>
                                </TimelineTextBox>
                            </TimelineWrapper>
                        ))}

                            <TimelineWrapper>
                                <TimelineCount>{this.state.data.attributes.timelines.length + 1}</TimelineCount>
                                <TimelineTextBox>
                                    <TimelineDate>26 Feb 2022</TimelineDate>
                                    <FileBox>
                                        {this.renderFileBox()}
                                        {this.renderFileBox()}
                                    </FileBox>
                                    <RiskBox>
                                       <img className={classes.riskImage} src={infoReImg} alt="info"/>  
                                       {languageData.LowRiskBusiness}
                                    </RiskBox>
                                </TimelineTextBox>
                            </TimelineWrapper>

                            <TimelineWrapper>
                                <TimelineCount>{this.state.data.attributes.timelines.length + 2}</TimelineCount>
                                <DealButtonBox>
                                    <CreateDealButton 
                                        data-test-id = "createBusinessDealTestId" 
                                        onClick={this.handleModalOpen} 
                                        variant="contained"
                                    >
                                        {languageData.CreateBusinessDeal}
                                    </CreateDealButton>
                                </DealButtonBox>
                            </TimelineWrapper>
                        </Box>
                    </TimelineBox>
                    <StyledModal
                        data-test-id = "businessDealModalTestId"
                        open={this.state.isModalOpen}
                        onClose={this.handleModalClose}
                        closeAfterTransition
                        BackdropProps={{ timeout: 500 }}
                    >
                        <ModalContent>
                            <ModalHeadingWrapper>
                                <ModalHeading>{languageData.BusinessDeal}</ModalHeading>
                                <CloseIcon onClick={this.handleModalClose}/>
                            </ModalHeadingWrapper>
                            <InputWrapper>
                                <FirstWrapperBox style= {{marginTop: "25px"}}>
                                    <InputFieldWrapper>
                                            <InputLabeltext>{languageData.ClientName}</InputLabeltext>
                                            <CustomTextField
                                                data-test-id = "clientNameInputTestId"
                                                variant="outlined"
                                                placeholder={languageData.ClientName}
                                                value={this.state.business_deal.name}
                                                onChange={(event) => this.handleInputPostData('name', event.target.value)} 
                                            />
                                            {(nameError&& checkSave) && (
                                                <Typography 
                                                    data-test-id= "clientNameErrorTestId"
                                                    style={webStyle.errorText}
                                                >
                                                    {nameError}
                                                </Typography>
                                            )}
                                    </InputFieldWrapper>
                                    <InputFieldWrapper>
                                            <InputLabeltext>{languageData.Category}</InputLabeltext>
                                            <FormControl fullWidth>
                                            <CustomInputLabel shrink={false} style={{ display: !this.state.business_deal.category_id ? "block" : "none", lineHeight: "0.1" }}>
                                                {languageData.Category}
                                            </CustomInputLabel>
                                                <FilterSelectField
                                                    fullWidth
                                                    data-test-id="categorySelectTestID"
                                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                                    variant="outlined"
                                                    name="category"
                                                    value={this.state.business_deal.category_id}
                                                    onChange={(event) => this.handleInputPostData('category_id', event.target.value as string)}
                                                >
                                                    {
                                                        this.state.categories.map((value, index) => (
                                                            (value.attributes.sub_categories && value.attributes.sub_categories.length !== 0) && (
                                                            <MenuItem key={index} value={value.id}>
                                                                {value.attributes.name}
                                                            </MenuItem>
                                                            )
                                                        ))
                                                    }
                                                </FilterSelectField>
                                            </FormControl>
                                            {(categoryIdError&& checkSave) && (
                                                <Typography style={webStyle.errorText}>
                                                    {categoryIdError}
                                                </Typography>
                                            )}
                                    </InputFieldWrapper>
                                </FirstWrapperBox>
                                <FirstWrapperBox>
                                    <InputFieldWrapper>
                                            <InputLabeltext>{languageData.SubCategory}</InputLabeltext>
                                            <FormControl fullWidth>
                                            <CustomInputLabel shrink={false} style={{ display: !this.state.business_deal.sub_category_id ? "block" : "none", lineHeight: "0.1" }}>
                                                {languageData.SubCategory}
                                            </CustomInputLabel>
                                                <FilterSelectField
                                                    fullWidth
                                                    data-test-id="listTypeTestID"
                                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                                    variant="outlined"
                                                    name="subCategory"
                                                    disabled = {!this.state.business_deal.category_id || this.state.subCategories.length === 0}
                                                    value={this.state.business_deal.sub_category_id}
                                                    onChange={(event) => this.handleInputPostData('sub_category_id', event.target.value as string)}>
                                                    {
                                                        this.state.subCategories.map((value, index) =>
                                                        <MenuItem key={index} value={value.id}>
                                                            {value.name}
                                                        </MenuItem>)
                                                    }
                                                </FilterSelectField>
                                            </FormControl>
                                            {(subCategoryIdError&& checkSave) && (
                                                <Typography style={webStyle.errorText}>
                                                    {subCategoryIdError}
                                                </Typography>
                                            )}
                                    </InputFieldWrapper>
                                    <InputFieldWrapper>
                                            <InputLabeltext>{languageData.Quantity}</InputLabeltext>
                                            <CustomTextField
                                                data-test-id="quantityTestID"
                                                variant="outlined"
                                                placeholder={languageData.Quantity}
                                                value={this.state.business_deal.quantity}
                                                onChange={(event) => this.handleInputPostData('quantity', event.target.value)} 
                                            />
                                            {(quantityError&& checkSave) && (
                                                <Typography style={webStyle.errorText}>
                                                    {quantityError}
                                                </Typography>
                                            )}
                                    </InputFieldWrapper>
                                </FirstWrapperBox>
                                <TextFieldWrapper>
                                    <InputLabeltext>{languageData.Description}</InputLabeltext>
                                    <CustomDescriptionField
                                        data-test-id = "descriptionTestId"
                                        variant="outlined"
                                        multiline
                                        placeholder={languageData.Description} 
                                        value={this.state.business_deal.description}
                                        minRows={4}
                                        maxRows={4}
                                        onChange={(event) => this.handleInputPostData('description', event.target.value)}
                                    />
                                    {(descriptionError&& checkSave) && (
                                        <Typography style={webStyle.errorText}>
                                            {descriptionError}
                                        </Typography>
                                    )}
                                </TextFieldWrapper>
                                <FirstWrapperBox>
                                    <Box>
                                        <InputFieldWrapper>
                                            <InputLabeltext>{languageData.PaymentTerms}</InputLabeltext>
                                            <FormControl fullWidth>
                                            <CustomInputLabel shrink={false} style={{ display: !this.state.business_deal.terms ? "block" : "none", lineHeight: "0.1" }}>
                                                30% Down payment
                                            </CustomInputLabel>
                                                <FilterSelectField
                                                    fullWidth
                                                    data-test-id="termsSelectTestID"
                                                    IconComponent={KeyboardArrowDownRoundedIcon}
                                                    variant="outlined"
                                                    name="terms"
                                                    value={this.state.business_deal.terms}
                                                    onChange={(event) => this.handleInputPostData('terms', `${event.target.value}`)}
                                                >   
                                                    <MenuItem value={"30% Down payment"}>30% Down payment</MenuItem>
                                                    <MenuItem value={"50% Down payment"}>50% Down payment</MenuItem>
                                                </FilterSelectField>
                                            </FormControl>
                                            {(termsError&& checkSave) && (
                                                <Typography style={webStyle.errorText}>
                                                    {termsError}
                                                </Typography>
                                            )}
                                        </InputFieldWrapper>
                                    </Box>
                                    <Box>
                                        <InputFieldWrapper>
                                            <InputLabeltext>{languageData.TotalPrice}</InputLabeltext>
                                            <CustomTextField
                                                data-test-id="totalPriceTestID"
                                                variant="outlined"
                                                placeholder={languageData.TotalPrice}
                                                value={this.state.business_deal.price}
                                                onChange={(event) => this.handleInputPostData('price', event.target.value)} 
                                            />
                                            {(priceError&& checkSave) && (
                                                <Typography style={webStyle.errorText}>
                                                    {priceError}
                                                </Typography>
                                            )}
                                        </InputFieldWrapper>

                                    </Box>
                                </FirstWrapperBox>
                                <Grid spacing={4} container>
                                <Box style={webStyle.basicInfoBox}>
                                    {this.renderFileUploadBox(this.state.supportDocArray, documentErr ,"supportDocArray")}
                                </Box>
                                </Grid>
                                <StyledDivider />
                                
                            </InputWrapper>
                            <ButtonWrraper>
                                    <RejectButton 
                                        data-test-id = "modalCancelButtonTestId"
                                        onClick={this.handleModalClose}
                                    >
                                        {languageData.Cancel}
                                    </RejectButton>
                                    <ApproveButton
                                        data-test-id = "modalSubmitButtonTestId"
                                        onClick={() => this.handleFormSubmit()}
                                    >
                                        {languageData.SendBuyerConfirmation}
                                    </ApproveButton>
                                </ButtonWrraper>
                        </ModalContent>
                    </StyledModal>
                </Box>
            </UserProfileDrawer>
        )
    }
}

export default withStyles(styles)(RequetWeb);