import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';
import { Grid, Container, Box, Button, withStyles, WithStyles } from '@material-ui/core';
import { makeStyles } from "@material-ui/core/styles";

import { newLogo } from '../../assets';
import Text from '../Text';
import DividerWeb from '../Button/Divider.web';
import { withRouter } from "react-router-dom";
import { History } from 'history';

export const configJSON = require("../../config");
const utills = require('../../../../blocks/utilities/src/utills')
const languageTranslation = require('../../../../blocks/languagesupport/src/LanguageData/footer.json')

const useStyles = makeStyles((theme) => ({
  footer: {
    background: '#F8FAFC',
    padding: '40px 40px 0px 40px',
    '@media (max-width: 960px)': {
      padding: '40px 10px 0px 10px',
    }
  },
  logointro: {
    width: '76%',
    '@media (max-width: 1200px)': {
      width: '100%',
    }
  },
  noTransform: {
    textTransform: "none" as const,
    padding: 0,
    minWidth: 0,
    cursor: 'pointer',
    "&:hover": {
      background: "none",
    },
    "&:active": {
      background: "none",
    }
  },

  footerlinkTitle: {
    color: "#475569",
    fontSize: "30px",
    fontclassName: "normal",
    fontWeight: 700,
    lineHeight: "40px",
    paddingBottom: '24px',
    minHeight: '40px'
  },

  footerlink: {
    display: "flex",
    flexDirection: 'column',
    alignItems: "last baseline",
    color: "#334155",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    '@media (max-width: 960px)': {
      alignItems: 'center',
    }
  },

  links: {
    display: "flex",
    gap: "34px",
    justifyContent: "flex-end",
    '@media (max-width: 960px)': {
      flexDirection: 'column'
    }
  },

  copyRight: {
    padding: "20px 20px 60px",
    width: "100%",
    display: "flex",
    alignItems: "center", 
    justifyContent: "center",
    maxWidth: "1200px",
    margin: "0 auto",
  },

  gridContainer: {
    maxWidth: "1200px",
    width:"100%",
    margin: "0 auto",
    '@media (max-width: 1200px)': {
      textAlign: 'center',
    }
  },
  divider: {
    margin: "10px 40px"
  },
  marginSeller: {
    marginTop: "0px",
  },
  footerLogo: {
    display: 'block',
    marginBottom: '20px',
    height: "61px"
  },
  footerImageBlock: {
    '@media (max-width: 1200px)': {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center'
    }
  }
}));

const Footer = ({ history }: { history: History }) => {
  const [currentLanguage, setCurrentLanguage] = useState(configJSON.EN)
  const classes = useStyles();
  const languageData = languageTranslation[currentLanguage];

  useEffect(() => {
    handleCurrentLanguage();
  });

  const handleCurrentLanguage = async() => {
    const language = await utills.getLanguageOrSetCurrentLanguage();
    setCurrentLanguage(language);
  }

  const navigateTo = async (newValue: string) => {
    history.push(newValue);
  };

  return (
    <>
      <Container maxWidth={false} className={classes.footer}>
        <Grid container spacing={5} className={classes.gridContainer}>
        <Grid item xs={12} sm={12} md={12}>
          <Box style={ {width : "100%", boxSizing : "border-box", display : "flex", gap : "20px", alignItems:"flex-end", justifyContent:"space-between", padding:"40px 0px 0px 0px"}}>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={3} md={3} className={classes.footerImageBlock}>
                <img src={newLogo} className={classes.footerLogo} alt='logo-image' />
                </Grid>
                <Grid item xs={12} sm={9} md={9} className={classes.footerlink}>
                  <Grid item xs={12} sm={12} md={12}>
                    <Text align='center' size="xm" weight="lightBold">{languageData.QuickLinks}</Text>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} className={classes.links}>
                    <Button variant='text' className={classes.noTransform} onClick={() => { navigateTo(configJSON.CreditoreWeb)}}>
                      <Text family='inter' variant='grey' weight='bold' size='sm'>{languageData.Creditors}</Text>
                    </Button>
                    <Button variant='text' className={classes.noTransform} onClick={() => navigateTo(configJSON.DebtBuyer)}>
                      <Text family='inter' variant='grey' weight='bold' size='sm'>{languageData.DebtBuyer}</Text>
                    </Button>
                    <Button variant='text' className={classes.noTransform} onClick={() => navigateTo(configJSON.Services)}>
                      <Text family='inter' variant='grey' weight='bold' size='sm'>{languageData.Services}</Text>
                    </Button>
                    <Button variant="text" className={classes.noTransform} onClick={() => navigateTo(configJSON.Blog)}>
                      <Text family='inter' variant='grey' weight='bold' size='sm'>{languageData.Blog}</Text>
                    </Button>
                    <Button variant='text' className={classes.noTransform} onClick={() => navigateTo(configJSON.Contact)}>
                      <Text family='inter' variant='grey' weight='bold' size='sm'>{languageData.Contact}</Text>
                    </Button>
                    <Button variant='text' className={classes.noTransform} onClick={() => navigateTo(configJSON.Terms)}>
                      <Text family='inter' variant='grey' weight='bold' size='sm'>{languageData.Terms}</Text>
                    </Button>
                    <Button variant='text' className={classes.noTransform} onClick={() => navigateTo(configJSON.Privacy)}>
                      <Text family='inter' variant='grey' weight='bold' size='sm'>{languageData.Policy}</Text>
                    </Button>
                  </Grid>
                </Grid>
            </Grid>
            </Box>
          </Grid>
        </Grid>
        <Box className={classes.divider}>
          <DividerWeb />
        </Box>
        <Box className={classes.copyRight}>
          <Text variant='neutrals' size='sm' weight='minBold' lineHeight='24px' family='inter'>
            {languageData.copyRight}
          </Text>
        </Box>
      </Container>
    </>

  );
};

export default withRouter(Footer);
