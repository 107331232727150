import React from "react";
// Customizable Area Start
import {
    withStyles, StyleRules
} from "@material-ui/core/styles";
import DashboardController, { Props } from "./DashboardController.web";
import { Box, IconButton, Typography,  Button, TextField, styled, TableContainer, Paper, Avatar, Menu } from "@material-ui/core";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json')
import SearchIcon from "@material-ui/icons/Search";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import MoreVertIcon from "@material-ui/icons/MoreVert";
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import Skeleton from '@material-ui/lab/Skeleton';
import { DocUpload } from "./assets";
import TablePagination from "../../../components/src/design-system/Pagination.web";

// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start
    // Customizable Area End
}

export class Dashboard extends DashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderMenu = () => {
        const { currentLanguage, anchorEl, startDate, endDate, currentPage } = this.state
        const languageData = languageTranslationData[currentLanguage];
        const direction = currentLanguage==="en"? "ltr": "rtl"
        return (
            <Menu
                open={Boolean(anchorEl)}
                id="basic-menu"
                data-test-id="publishMenuTestID"
                anchorEl={anchorEl}
                onClose={this.handleMenuClose}
                elevation={0}
                getContentAnchorEl={null}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: currentLanguage==="en"? 'left': "right"
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: currentLanguage==="en"? 'left': "right"
                }}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                PaperProps={{
                    style: {
                        overflow: 'visible',
                        boxShadow: '0px 0px 8px 4px #00000008',
                        borderRadius: "8px",
                        border: "1px solid #E2E8F0",
                        marginTop: "10px"
                    }
                }}
                anchorReference="anchorPosition"
                anchorPosition={
                    anchorEl
                    ? {
                        top: anchorEl.getBoundingClientRect().bottom + 3,
                        left: anchorEl.getBoundingClientRect().left,
                    }
                    : undefined
                }
                dir={direction}
            >
                <Box style={classes.filterCloseBox}>
                    <Box style={classes.filterHeadingBox}>
                        <FilterListRoundedIcon style={{ width: "20px" }} />
                        {languageData.Filter}
                    </Box>
                    <IconButton data-test-id="filterCloseTestID" onClick={this.handleMenuClose}>
                        <CloseRoundedIcon style={{ width: "20px", color: "#0F172A" }} />
                    </IconButton>
                </Box>
                <Box style={classes.filterMiddleBox}>
                    <Box>
                        <Box style={{ display: "flex", gap: "20px" }}>
                            <Box>
                                <Typography style={classes.filterLabel}>{languageData.From}</Typography>
                                <FilterDateField
                                    data-test-id = "startDateInputTestId"
                                    type="date"
                                    variant="outlined"
                                    value={startDate}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>)=>this.handleFilterDateChange(event, "startDate")}
                                    style={{color: startDate? "red": "green"}}
                                />
                            </Box>
                            <Box>
                                <Typography style={classes.filterLabel}>{languageData.To}</Typography>
                                <FilterDateField
                                    data-test-id = "endDateInputTestId"
                                    type="date"
                                    variant="outlined"
                                    value={endDate}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement>)=>this.handleFilterDateChange(event, "endDate")}
                                    style={{color: endDate? "red": "green"}}
                                />
                            </Box>
                        </Box>
                    </Box>
                </Box>
                <Box style={classes.filterBottomBox}>
                    <ResetButton
                        data-test-id="filterResetButtonTestID"
                        onClick={this.handleFilterReset}
                    >
                        {languageData.Reset}
                    </ResetButton>
                    <DevFileButton
                        data-test-id="applyNowTestID"
                        style={{ fontSize: "14px", height: "44px" }}
                        onClick={()=> this.getAuditLogApi(currentPage)}
                    >
                        {languageData.ApplyNow}
                    </DevFileButton>
                </Box>
            </Menu>
        )
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (

            <UserProfileDrawer
                handleClick={this.handleDrawerNavigation1}
                pageName={"dashboardAuditlog"}
                data-test-id="drawerTestID"
                navigation={this.props.navigation}
            >
                <Box data-test-id="cta-Tables">
                    <Typography style={classes.devTypographyHeader}>{languageData.AuditLogs}</Typography>
                    <Box style={classes.devTableWrapperBox}>
                        <DevTableUpBox>
                            <Box style={classes.devSearchFieldWrapper}>
                                <SearchIcon style={classes.devSearchIcon} />
                                <DevSearchTextField
                                    id="blankInput"
                                    data-test-id="searchInputTestID"
                                    fullWidth
                                    variant="outlined"
                                    value={this.state.searchTerm}
                                    placeholder={languageData.Search}
                                    inputProps={{ 'aria-label': 'description' }}
                                    onChange={this.handleSearchChange}
                                />
                            </Box>
                            <Box style={{ display: "flex", gap: "20px", position: "relative" }}>
                                <DevFilterButton
                                    style={{ height: "44px" }}
                                    data-test-id="filterButtonTestID"
                                    onClick={this.handleMenu}
                                >
                                    <Box style={classes.devCreateButton}>
                                        <FilterListRoundedIcon />
                                        {languageData.Filter}
                                    </Box>
                                </DevFilterButton>
                                <DevFileButton
                                    data-test-id="upload-files"
                                    variant="contained"
                                    startIcon={<img src={DocUpload} />}>
                                    {languageData.ExportLog}
                                </DevFileButton>
                            </Box>
                        </DevTableUpBox>

                        <TableContainer style={{ boxShadow: "none", width: "100%", overflow: "auto" }} component={Paper}>
                            {this.state.loading === false && this.state.auditLogData.length === 0?
                                <Box style = {classes.noResultMatchBox}>
                                    <Typography style = {classes.noResultMatchText}>
                                        {languageData.NoResultMatch}
                                    </Typography>
                                </Box>:
                            <Table style={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <DevCustomHeadingRow>
                                        <TableCellText>{languageData.User}</TableCellText>
                                        <TableCellText>{languageData.Email}</TableCellText>
                                        <TableCellText style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}>{languageData.Action}</TableCellText>
                                        <TableCellText>{languageData.Date}</TableCellText>
                                        <TableCellText colSpan={2}>{languageData.Subject}</TableCellText>
                                    </DevCustomHeadingRow>
                                </TableHead>
                                <TableBody>
                                    {this.state.loading?
                                        <>
                                            {Array(6).fill(null).map((_value, index) =>
                                                <DevCustomBodyRow key={index}>
                                                    <TableCellText>
                                                        <Box style={classes.devUserProfileCell}>
                                                            <Skeleton animation="wave" variant="circle" width={40} height={40} />
                                                            <Skeleton animation="wave" variant="rect" width={"100%"} height={20} />
                                                        </Box>
                                                    </TableCellText>
                                                    {Array(4).fill(null).map((_value, index) =>
                                                        <TableCellText key={index}>
                                                            <Skeleton animation="wave" variant="rect" width={"100%"} height={20} />
                                                        </TableCellText>
                                                    )}
                                                    <TableCellText style={{textAlign: currentLanguage === "en"? "right": "left", width: "40px"}}>
                                                        <IconButton>
                                                            <MoreVertIcon style={classes.devmoreOpStyle} />
                                                        </IconButton>
                                                    </TableCellText>
                                                </DevCustomBodyRow>
                                            )}
                                        </>:
                                        <>
                                            {this.state.auditLogData.map((item, index) => (
                                                <DevCustomBodyRow key={index}>
                                                    <TableCellText>
                                                        <Box style={classes.devUserProfileCell}>
                                                            <DevUserAvatar src={`${item.attributes.profile_image}`} alt="user" />
                                                            {item.attributes.user_name}
                                                        </Box>
                                                    </TableCellText>
                                                    <TableCellText>{item.attributes.email}</TableCellText>
                                                    <TableCellText>
                                                        <span
                                                            style={{
                                                                ...classes.devstatusStyle,
                                                                ...this.handleStatusColor(item.attributes.action),
                                                            }}
                                                        >
                                                            {item.attributes.action}
                                                        </span>
                                                    </TableCellText>
                                                    <TableCellText>{this.formatDate(item.attributes.created_at)}</TableCellText>
                                                    <TableCellText>{item.attributes.subject}</TableCellText>
                                                    <TableCellText>
                                                        <IconButton data-test-id="publishedMoreButtonTestID">
                                                            <MoreVertIcon style={classes.devmoreOpStyle} />
                                                        </IconButton>
                                                    </TableCellText>
                                                </DevCustomBodyRow>
                                            ))}
                                        </>
                                    }
                                </TableBody>

                            </Table>}
                        </TableContainer>
                        <Box style={classes.devmainResultBox}>
                            <Box style={classes.devpaginationBox}>
                                <TablePagination
                                    data-test-id="paginationTestID"
                                    page={this.state.currentPage}
                                    count={this.state.pageCount}
                                    handlePageChange={this.handlePageChange}
                                />
                            </Box>
                            <DevCustomResultBox>
                                <Typography style={{ color: "#64748B", fontSize: "12px" }}>
                                    {`${(this.state.currentPage - 1) * 10 + 1} - ${(this.state.currentPage - 1) * 10+ this.state.auditLogData.length} of ${this.state.totalCount} results`}
                                </Typography>
                            </DevCustomResultBox>
                        </Box>
                    </Box>
                </Box>
                {this.renderMenu()}
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withStyles(styles)(Dashboard);

const TableCellText = styled(TableCell)({
    whiteSpace: "nowrap"
});

const FilterDateField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1",
            top: "0px"
        }
    },
    "& .MuiInputBase-input": {
        height: "10px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        top: "0px",
        "&.Mui-disabled": {
                WebkitTextFillColor: "#0F172A"
            }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const ResetButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A",
    height: "44px",
    backgroundColor: "#EFF6FF",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#EFF6FF"
    }
});

const DevTableUpBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
    gap: "20px",
    flexWrap: "wrap"
});
const DevSearchTextField = styled(TextField)({
    maxWidth: "320px",
    flexGrow: 1,
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                boxShadow: "none !important",
                border: "none !important",
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        color: "#94A3B8",
        fontSize: "16px",
        opacity: 1
    }
});
const DevFilterButton = styled(Button)({
    borderRadius: "8px",
    padding: "10px 16px",
    display: "flex",
    border: "1px solid #CBD5E1",
    gap: "8px",
    textTransform: "capitalize",
    alignItems: "center",

});
const DevCustomHeadingRow = styled(TableRow)({
    background: "#E2E8F0",
    "& .MuiTableCell-root": {
        fontWeight: 600,
        color: "#64748B",
        fontSize: "14px",
        whiteSpace: "nowrap",
        padding: "20px 16px 8px",
    }
});
const DevCustomBodyRow = styled(TableRow)({
    height: "54px",
    "& .MuiTableCell-root": {
        fontSize: "12px"
    }
});
const DevUserAvatar = styled(Avatar)({
    borderRadius: "100px",
    height: "40px",
    width: "40px",
    border: "1px solid #D9D9D9",
    objectFit: "cover"

});
const DevCustomResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        display: "flex",
        width: "100%",
        justifyContent: "flex-end"
    }
});
const DevFileButton = styled(Button)({
    borderRadius: '8px',
    padding: '10px 16px',
    backgroundColor: '#1E3A8A',
    color: '#FFFFFF',
    cursor: "pointer",
    fontFamily: 'Inter',
    fontSize: '16px',
    lineHeight: '24px',
    fontWeight: 700,
    textAlign: 'left',
    textTransform: "capitalize",
    whiteSpace: "nowrap",
    '&:hover': {
        color: '#FFFFFF',
        backgroundColor: '#1E3A8A',
    },
});
const classes = {
    devTypographyHeader: {
        fontSize: "30px",
        fontWeight: 700,
        letterSpacing: "0.5%",
        lineHeight: "44px",
        color: "#0F172A",

    },
    noResultMatchBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "325px"
    },
    noResultMatchText: {
        fontSize: "30px",
        color: "#94A3B8"
    },
    devverificationBox: {
        alignItems: 'center',
        height: '80vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center'

    },
    devverificationInnerBox: {
        alignItems: 'center',
        display: 'flex',
        gap: '20px',
        height: '328px',
        flexDirection: 'column',
        width: '287px'

    },
    devgroupImg: {
        width: '150px',
        height: "166px",

    },
    devTableWrapperBox: {
        width: "100%",
        background: "#FFFFFF",
        borderRadius: "12px",
        padding: "16px",
        boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px",
        margin: "20px 0 80px 0",
    },
    devSearchFieldWrapper: {
        border: "1px solid #CBD5E1",
        display: "flex",
        flexGrow: 1,
        alignItems: "center",
        borderRadius: "8px",
        height: "24px",
        padding: "10px 8px",
        maxWidth: "304px"
    },
    devSearchIcon: {
        color: "#94A3B8",
        marginLeft: '3.48px',

    },
    devCreateButton: {
        gap: "10px",
        display: 'flex',
    },
    devUserProfileCell: {
        alignItems: "center",
        display: "flex",
        gap: "8px"
    },
    devstatusStyle: {
        fontSize: "12px",
        borderRadius: "25px",
        padding: "9px 10px 5px",
        backgroundColor: "#D1FAE5",
        fontWeight: 700,
        color: '#059669'
    },
    devmoreOpStyle: {
        cursor: "pointer",
        color: "#64748B",
    },
    devviewButtonText: {
        fontSize: "14px",
        color: "#0F172A",
        fontWeight: 400,
    },
    devdeleteButtonText: {
        fontSize: "14px",
        color: "#DC2626",
        fontWeight: 400,
        alignItems: "center",
        display: "flex",
        gap: "8px"
    },
    devmainResultBox: {
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        paddingRight: "20px",
        paddingTop: "16px",
    } as React.CSSProperties,

    devpaginationBox: {
        justifyContent: "center",
        display: "flex",
        flexGrow: 1,
    },
    filterHeadingBox: {
        fontSize: "14px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        color: "#0F172A",
        gap: "8px"
    },
    filterLabel: {
        fontWeight: 700,
        fontSize: "14px",
        color: "#334155"
    },
    filterBottomBox: {
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        padding: "20px"
    },
    filterMiddleBox: {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        padding: "10px 20px 20px",
        borderBottom: "1px solid #CBD5E1"
    } as React.CSSProperties,
    filterCloseBox: {
        padding: "5px 20px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #CBD5E1",
        alignItems: "center"
    },
    filterWrapper: {
        width: "350px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        background: "white"
    } as React.CSSProperties
}

// Customizable Area End
