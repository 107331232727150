import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData, setStorageData } from "framework/src/Utilities";
import { ChangeEvent } from "react";
const utills = require('../../utilities/src/utills');

export interface ValidResponseType {
    data: VerifcationRequiestItem[];
    message: string;
    meta: MetaData;
};

interface VerifcationRequiestItem {
    id: string;
    type: string;
    attributes: {
        uuid: string;
        full_name: string;
        status: string;
        registration_number: string;
        verification_type: string;
        request_date: string;
        details: string;
        Company: string | null;
        Debtor: string | null;
        premium: string | null;
        supporting_documents: (string | null)[];
    };
};

interface MetaData {
    total_count: number;
    total_pages: number;
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
    counter: {
        approved: number;
        pending: number;
        rejected: number;
    };
};
// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    userSubType: string | null;
    open: boolean;
    anchorEl: HTMLElement | null;
    verificationSearch: string;
    invoiceSearch: string;
    verificationReqAlbl: boolean,
    verificatoinListData: VerifcationRequiestItem[];
    invoiceData: {
        mainID: string;
        invoiceID: string;
        payment: string;
        dueDate: string;
        status: string;
    }[];
    currentLanguage: 'ar' | 'en';
    verificationPage: number;
    verificationTotalPage: number;
    verificationTotalCount: number;
    invoicePage: number;
    invoiceTotalPage: number;
    invoiceTotalCount: number;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserDashboardController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    getVerficationReqAlbleCheckCallId: string = "";
    getVerificationRequiestCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            userSubType: "individual",
            open: false,
            anchorEl: null,
            verificationSearch: "",
            invoiceSearch:"",
            verificationReqAlbl: true,
            verificatoinListData: [],
            invoiceData: configJSON.invoiceData,
            currentLanguage: 'en',
            verificationPage: 1,
            verificationTotalPage: 1,
            verificationTotalCount: 1,
            invoicePage: 1,
            invoiceTotalPage: 8,
            invoiceTotalCount: 48,
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        this.handleDrawerClick = this.handleDrawerClick.bind(this);
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if(!responseJson.errors){
                    this.apiSuccessCallBacks(apiRequestCallId, responseJson);
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        const token = await getStorageData("accessToken");
        const userType = await getStorageData("userType");
        const userSubType = await getStorageData("userSubType");
        if (!token || !userType) {
            this.handleNavigation("Home");
        }
        this.getVerificationRequiestCheckAvailable();
        this.getVerificationRequiestList(1);
        const selectedLanguage = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: selectedLanguage,
            userSubType: userSubType
        });
    };

    apiSuccessCallBacks = (apiRequestCallId: string, responseJson: ValidResponseType) => {
        if (apiRequestCallId === this.getVerficationReqAlbleCheckCallId) {
            this.getVerficationReqAlbleCheckSuccessResponse(responseJson);
        }
        if (apiRequestCallId === this.getVerificationRequiestCallId) {
            this.getVerificationRequiestSuccessResponse(responseJson);
        }
    };

    getVerificationRequiestCheckAvailable = async() => {
        const params = `?per_page=2&page=1`
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getVerficationReqAlbleCheckCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listRequestVerificationEndPoint}${params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getVerificationRequiestList = async(page:number) => {
        const params = `?per_page=6&page=${page}&search=${this.state.verificationSearch}`
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getVerificationRequiestCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listRequestVerificationEndPoint}${params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getVerficationReqAlbleCheckSuccessResponse = (responseJson: ValidResponseType) => {
        if(responseJson.data) {
            this.setState({
                verificationReqAlbl: true
            });
        } else if (responseJson.message) {
            this.setState({
                verificationReqAlbl: false
            });
        }
    };

    getVerificationRequiestSuccessResponse = (responseJson: ValidResponseType) => {
        if(responseJson.data) {
            this.setState({
                verificatoinListData: responseJson.data,
                verificationPage: responseJson.meta.current_page,
                verificationTotalPage: responseJson.meta.total_pages,
                verificationTotalCount: responseJson.meta.total_count
            });
        } else if (responseJson.message) {
            this.setState({
                verificatoinListData: [],
                verificationPage: 0,
                verificationTotalPage: 0,
                verificationTotalCount: 0
            });
        }
        
    };

    handleNavigation(route: string) {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleVerificationSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ verificationSearch: event.target.value },
            () => this.getVerificationRequiestList(this.state.verificationPage)
        );
    };

    handleInvoiceSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({ invoiceSearch: event.target.value });
    };

    handleStatusColor = (status: string) => {
        let value = status.toUpperCase();
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "PENDING" || value === "DUE") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "REJECTED" || value === "PAST DUE") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };

    onLogout = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("userSubType");
        await removeStorageData("userId");
        await removeStorageData("otpToken");
        await removeStorageData("selectedRequest");
        await removeStorageData("profilePic");
        await removeStorageData("signupStatus");
        this.handleNavigation("Home");
    };

    handleVerificationPagination = (_event: ChangeEvent<unknown>, page: number) => {
       this.getVerificationRequiestList(page)
    };

    handleInvoicePagination = (_event: ChangeEvent<unknown>, page: number) => {
        this.setState({ invoicePage: page });
    };

    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.onLogout();
        } else{
            this.handleNavigation(route);
        }
    };

    formatDate(isoString: string) {
        const date = new Date(isoString);
        const day = date.getUTCDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        return `${day} ${month} ${year}`;
    };

    handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl:event.currentTarget
        });
    };

    handleMenuClose = () => {
        this.setState({
            anchorEl: null
        });
    };

    handleViewVerificationRequiestDetail = async() => {
        const anchorEl = this.state.anchorEl;
        if (anchorEl) {
            await setStorageData("selectedRequest", anchorEl.id);
            this.handleNavigation("UserRequest");
        }
    };

    // Customizable Area End
}
