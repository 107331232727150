import React from "react";

// Customizable Area Start
import {
    Box,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TextField,
    styled,
    Button,
    Menu,
    IconButton
} from "@material-ui/core";
// Customizable Area End

import UserDashboardController, {
    Props,
    configJSON
} from "./UserDashboardController";

// Customizable Area Start
import SearchIcon from "@material-ui/icons/Search";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import Pagination from "@material-ui/lab/Pagination";
import { groupImg } from "./assets";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
const languageTranslationData = require('../../languagesupport/src/LanguageData/UserDashboard.json');
// Customizable Area End

export default class UserDashboard extends UserDashboardController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }

    // Customizable Area Start

    renderVerificationRequiestTable = () => {
        const { 
            currentLanguage, 
            verificationPage, 
            verificationTotalPage, 
            verificationTotalCount, 
            verificatoinListData,
            verificationReqAlbl
        } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (
            <>
                <TableUpperBox>
                    <Typography style={webStyle.tableHeading}>
                        {languageData.verificationRequest}
                    </Typography>
                    { verificationReqAlbl &&
                        <Box style={webStyle.searchButtonWrapper}>
                            <Box style={webStyle.textFieldWrapper}>
                                <SearchIcon style={webStyle.searchIconStyle} />
                                <CustomTextField
                                    data-test-id="searchInputTestID"
                                    fullWidth
                                    variant="outlined"
                                    value={this.state.verificationSearch}
                                    onChange={this.handleVerificationSearchChange}
                                    placeholder={languageData.search}
                                />
                            </Box>
                            <RequestButton 
                                data-test-id = "createNewVerificationReqButtonTestId"
                                variant="contained"
                                onClick = {()=> this.handleNavigation("UserRequests")}
                            >
                                <Typography>{languageData.requestNewVerification}</Typography>
                            </RequestButton>
                        </Box>
                    }
                </TableUpperBox>
                <TableContainer style={{ boxShadow: "none", width: "100%", overflow: "auto" }} component={Paper}>
                {(verificatoinListData.length === 0) ? 
                    <>
                        { verificationReqAlbl ? 
                            <Box style = {webStyle.noResultMatchBox}>
                                <Typography style = {webStyle.noResultMatchText}>
                                    {languageData.NoResultMatch}
                                </Typography>
                            </Box> :
                            <DefaultBoxWrapper>
                                <DefaultBox>
                                    <DefaultImage src={groupImg}></DefaultImage>
                                    <DefaultBoxText>{languageData.defaultBoxText}</DefaultBoxText>
                                    <RequestButton 
                                        data-test-id = "noCreateNewVerificationReqButtonTestId"
                                        variant="contained"
                                        onClick = {()=> this.handleNavigation("UserRequests")}
                                    >
                                        <Typography>{languageData.requestNewVerification}</Typography>
                                    </RequestButton>
                                </DefaultBox>
                            </DefaultBoxWrapper>
                        }
                    </>:
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <CustomHeadingRow>
                                <TableCell>{languageData.verificationTableHeadings.requestID}</TableCell>
                                <TableCell>{languageData.verificationTableHeadings.name}</TableCell>
                                <TableCell>{languageData.verificationTableHeadings.registrationNumber}</TableCell>
                                <TableCell>{languageData.verificationTableHeadings.date}</TableCell>
                                <TableCell colSpan={2} style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}>{languageData.verificationTableHeadings.status}</TableCell>
                            </CustomHeadingRow>
                        </TableHead>
                        <TableBody>
                            {verificatoinListData.map((item, index) => (
                                <CustomBodyRow key={index}>
                                    <TableCell>#{item.id}</TableCell>
                                    <TableCell>{item.attributes.full_name}</TableCell>
                                    <TableCell>{item.attributes.registration_number}</TableCell>
                                    <TableCell>{this.formatDate(item.attributes.request_date)}</TableCell>
                                    <TableCell>
                                        <span
                                            style={{
                                                ...webStyle.statusStyle,
                                                ...this.handleStatusColor(item.attributes.status),
                                            }}
                                        >
                                            {item.attributes.status}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <IconButton 
                                            id={item.id} 
                                            data-test-id = {`verificationMore${index}TestId`}
                                            onClick={this.handleMenuOpen}
                                        >
                                            <MoreVertIcon style={webStyle.moreOpStyle} />
                                        </IconButton>
                                    </TableCell>
                                </CustomBodyRow>
                            ))}
                        </TableBody>
                    </Table>
                }
                </TableContainer>
                {
                    (verificatoinListData.length !== 0) &&
                    <Box
                        style={webStyle.paginationResultBox}
                    >
                        <Box
                            style={webStyle.paginationBox}
                        >
                            <CustomPagination
                                data-test-id="verificationPaginationTestID"
                                page={verificationPage}
                                count={verificationTotalPage}
                                siblingCount={1}
                                boundaryCount={1}
                                onChange={this.handleVerificationPagination}
                            />
                        </Box>
                        <CustomVerificationResultBox>
                            <Typography style={webStyle.resultsText}>
                                {
                                    `${((verificationPage-1)*6)+1} - 
                                    ${((verificationPage-1)*6)+verificatoinListData.length} of 
                                    ${verificationTotalCount} results`
                                }                                                       
                            </Typography>
                        </CustomVerificationResultBox>
                    </Box>
                }
            </>
        );
    };

    renderInvoiceTable = () => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (
            <>
                <TableUpperBox>
                    <Typography style={webStyle.tableHeading}>{languageData.allInvoices}</Typography>
                    <Box style={webStyle.searchButtonWrapper}>
                        <Box style={webStyle.textFieldWrapper}>
                            <SearchIcon style={webStyle.searchIconStyle} />
                            <CustomTextField
                                data-test-id="invoiceSearchTestID"
                                fullWidth
                                variant="outlined"
                                value={this.state.invoiceSearch}
                                onChange={this.handleInvoiceSearchChange}
                                placeholder={languageData.search}
                            />
                        </Box>
                        <RequestButton variant="contained">
                            <Typography>{languageData.generateInvoice}</Typography>
                        </RequestButton>
                    </Box>
                </TableUpperBox>
                <TableContainer style={{ boxShadow: "none", width: "100%", overflow: "auto" }} component={Paper}>
                    <Table style={{ minWidth: 650 }} aria-label="simple table">
                        <TableHead>
                            <CustomHeadingRow>
                                <TableCell>{languageData.invoiceTableHeading.mainId}</TableCell>
                                <TableCell>{languageData.invoiceTableHeading.invoiceId}</TableCell>
                                <TableCell>{languageData.invoiceTableHeading.payment}</TableCell>
                                <TableCell>{languageData.invoiceTableHeading.dueDate}</TableCell>
                                <TableCell colSpan={2} style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}>{languageData.invoiceTableHeading.status}</TableCell>
                            </CustomHeadingRow>
                        </TableHead>
                        <TableBody>
                            {this.state.invoiceData.map((value, index) => (
                                <CustomBodyRow key={index}>
                                    <TableCell>{value.mainID}</TableCell>
                                    <TableCell>{value.invoiceID}</TableCell>
                                    <TableCell>{value.payment}</TableCell>
                                    <TableCell>{value.dueDate}</TableCell>
                                    <TableCell>
                                        <span
                                            style={{
                                                ...webStyle.statusStyle,
                                                ...this.handleStatusColor(value.status),
                                            }}
                                        >
                                            {value.status}
                                        </span>
                                    </TableCell>
                                    <TableCell>
                                        <MoreVertIcon style={webStyle.moreOpStyle} />
                                    </TableCell>
                                </CustomBodyRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
                <Box
                    style={{ display: "flex", alignItems: "center", flexWrap:"wrap", paddingTop: "16px" }}
                >
                    <Box
                        style={{ display: "flex", flexGrow: 1, justifyContent: "center" }}
                    >
                        <CustomPagination
                            data-test-id="invoicePaginationTestID"
                            page={this.state.invoicePage}
                            count={8}
                            siblingCount={1}
                            boundaryCount={1}
                            onChange={this.handleInvoicePagination}
                        />
                    </Box>
                    <CustomResultBox>
                        <Typography style={webStyle.resultsText}>
                            {languageData.results}
                        </Typography>
                    </CustomResultBox>
                </Box>
            </>
        );
    };

    renderMenu = () => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (
            <Menu 
                id={"simple-popper"}
                data-test-id="menuTestID"
                open={Boolean(this.state.anchorEl)} 
                anchorEl={this.state.anchorEl}
                elevation={0}
                getContentAnchorEl={null}
                onClose={this.handleMenuClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center'
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center'
                }}
                PaperProps={{
                    style: {
                        boxShadow: '0px 0px 8px 4px #00000008',
                        minWidth: "160px",
                        borderRadius: "8px",
                        border:"1px solid #E2E8F0"
                    }
                }}
            >
                <MenuBox>
                   <Typography
                        data-test-id="viewVeificationReqDetailButtonTestID"
                        onClick = {this.handleViewVerificationRequiestDetail}
                    >
                        {languageData.ViewDetails}
                    </Typography>
                </MenuBox>
            </Menu>
        );
    };

    // Customizable Area End

    render() {
        // Customizable Area Start
        const { currentLanguage, userSubType } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        // Customizable Area End
        return (
            // Customizable Area Start
                <UserProfileDrawer 
                    data-test-id="drawerComponentTestID" 
                    handleClick={this.handleDrawerClick}
                    pageName="UserDashboard"
                >
                    <Box style={webStyle.mainBoxWrapper}>                   
                        <Box>
                            <Typography style={webStyle.dashboardStyle}>{languageData.dashboard}</Typography>
                        </Box>
                        {
                            (userSubType === "individual" || userSubType === "investor" )?
                            null:
                            <Box style={webStyle.verificatioinTableWrapper}>
                                {this.renderVerificationRequiestTable()}
                            </Box>
                        }
                        <Box style={webStyle.verificatioinTableWrapper}>
                            {this.renderInvoiceTable()}
                        </Box>
                    </Box>
                    {this.renderMenu()}
                </UserProfileDrawer>
            // Customizable Area End
        );
    }
}

// Customizable Area Start

const CustomResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        display: "flex",
        justifyContent: "flex-end",
        width:"100%"
    }
});

const MenuBox = styled(Box)({
    padding: "0 8px",
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    "& .MuiTypography-root": {
        padding: "4px 15px",
        cursor: "pointer",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: 400,
        borderRadius: "8px",
        "&:hover":{
            background: "#EFF6FF"
        }
    }
});

const DefaultBoxWrapper = styled(Box)({
    width:"100%",
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
});

const DefaultBox = styled(Box)({
   width:"287px",
   height:"328px",
   position:"relative",
   display:"flex",
   flexDirection:"column",
   gap:"20px",
   justifyContent:"center",
   alignItems:"center",
});

const DefaultImage = styled("img")({
    width:"150px",
    height:"166px",
});

const DefaultBoxText = styled(Typography)({
    color:"#000000",
    fontSize:"18px",
    fontWeight:500,
    lineHeight:"26px",
    textAlign:"center",
});

const CustomVerificationResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        width:"100%",
        display: "flex",
        justifyContent: "flex-end"
    }
});

const CustomPagination = styled(Pagination)({
    "& .MuiPaginationItem-root": {
        fontSize: "1rem",
        minWidth: "32px",
        height: "32px",
        color: "#0F172A",
        textDecoration: "underline",
        backgroundColor: "transparent",
        "@media(max-width: 421px)": {
            margin: "0px"
        },
        "&:hover": {
            backgroundColor: "#f0f0f0"
        }
    },
    "& .Mui-selected": {
        color: "blue !important",
        fontWeight: 700,
        backgroundColor: "transparent !important",
        textDecoration: "none",
        "&:hover": {
            backgroundColor: "#f0f0f0 !important"
        }
    },
    "& .MuiPaginationItem-ellipsis": {
        fontSize: "1.2rem",
        textDecoration: "none"
    }
});

const CustomBodyRow = styled(TableRow)({
    "& .MuiTableCell-root": {
        fontSize: "12px"
    }
});

const CustomHeadingRow = styled(TableRow)({
    background: "#F8FAFC",
    "& .MuiTableCell-root": {
        color: "#64748B",
        fontWeight: 600,
        fontSize: "14px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap"
    }
});

const CustomTextField = styled(TextField)({
    flexGrow: 1,
    maxWidth: "320px",
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                border: "none !important",
                boxShadow: "none !important"
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const TableUpperBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "16px",
    gap: "20px",
    flexWrap: "wrap"
});

const RequestButton = styled(Button)({
    backgroundColor: "#1E3A8A",
    width: "auto",
    height: "44px",
    borderRadius: "8px",
    cursor: "pointer",
    "& .MuiTypography-root": {
        whiteSpace: "nowrap",
        width: "auto",
        textTransform: "capitalize",
        fontSize: "14px",
        fontWeight: 700,
        color: "white"
    },
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});

const webStyle = {
    mainBoxWrapper:{
        display:"flex",
        flexDirection:"column",
        gap:"30px"
    } as React.CSSProperties,
    noResultMatchBox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "325px"
    },
    noResultMatchText: {
        fontSize: "30px",
        color: "#94A3B8"
    },
    paginationResultBox:{
        display: "flex", 
        alignItems: "center", 
        paddingTop: "16px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    paginationBox: {
        display: "flex", 
        justifyContent: "center", 
        flexGrow: 1
    },
    moreOpStyle: {
        color: "#64748B",
        cursor: "pointer"
    },
    statusStyle: {
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#FEF3C7"
    },
    resultsText: {
        color: "#64748B",
        fontSize: "12px"
    },
    textFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "10px 8px",
        height: "23px"
    },
    searchIconStyle: {
        color: "#94A3B8"
    },
    dashboardStyle: {
        fontSize: "30px",
        fontWeight: 700
    },
    verificatioinTableWrapper: {
        padding: "16px",
        borderRadius: "12px",
        boxShadow: "0px 0px 8px 4px #00000008",
        marginBottom:"24px"
    },
    tableHeading: {
        fontWeight: 600,
        fontSize: "18px",
        whiteSpace: "nowrap"
    } as React.CSSProperties,
    searchButtonWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        flexGrow: 1,
        maxWidth: "583px",
        flexWrap: "wrap"
    } as React.CSSProperties
};

// Customizable Area End
