import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
const utills = require('../../utilities/src/utills');
// Customizable Area Start
import {removeStorageData, getStorageData, setStorageData} from "framework/src/Utilities";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Listing.json');
export const configJSON = require("./config");

export interface ValidResponseType {
    data: UserMainDataResponse | UserListing[] | UserListing | UserMainDataResponse[];
    message: string;
    meta: Meta
};
  
export interface ErrorPayloadType {
    key: string;
    token: string;
};
  
export interface InvalidResponseType {
    errors: Array<ErrorPayloadType>;
};

const reqVeriData = [
    ["#669677", "Medical Debt Package", "2,500", "Credit Card", "1", "1", "Active"],
    ["#669459", "Auto Loan Portfolio", "2,500", "Credit Card", "2", "2", "Active"],
    ["#869287", "Personal Loan Debt", "4,000", "Credit Card", "3", "0", "Active"],
    ["#664979", "Zenith Credit Management", "6,000", "Credit Card", "4", "4", "Active"],
    ["#679287", "Retail Credit Accounts", "4,000", "Credit Card", "2", "3", "Active"],
    ["#664559", "Healthcare Debt Portfolio", "2,500", "Credit Card", "1", "10", "Active"]    
];

interface UserListing {
    id: string;
    type: string;
    attributes: UserListingAttributes;
};

interface UserListingAttributes {
    id: number;
    portfolio_size: string;
    listing_title: string;
    avg_balance_per_account: string;
    account_count: string;
    total_number_of_debts: number;
    total_amount_of_debts: number;
    total_judgements: number;
    total_months_as_costomer: number;
    pas_payment_made: boolean;
    month_average_paytime: number;
    last_collectors_contact: string;
    last_creditors_contact: string;
    collections_used_in_past: number;
    age_of_dept: string;
    type_of_dept: string;
    current_recovery_status: string;
    status: string;
    sub_status: string;
    confirmed: boolean;
    account_id: number;
    created_at: string;
    supporting_documents: (IDocumentInfo | null)[][];
    other_documents: (IDocumentInfo | null)[][];
    offer_count: number;
    account: UserDataResponse;
    account_image: string;
};

interface IDocumentInfo {
    file_name: string | null
    url: string | null;
    size: string | null;
};
  
interface Meta {
    total_count: number;
    total_pages: number;
    current_page: number;
    message: string;
    counter: {
        completed: number;
        pending: number;
        active: number;
        rejected: number;
    };
};

interface UserMainDataResponse {
    id: string;
    type: string;
    attributes: UserDataResponse;
};

interface UserDataResponse {
    full_name: string;
    bank_name: string;
    agency_name: string;
    service_provider_name: string;
    full_phone_number: string;
    country_code: string | null;
    email: string;
    phone_number: string;
    gender: string | null;
    type: string  | null;
    user_type: string  | null;
    sub_type: string;
    user_name: string  | null;
    address: string | null;
    city: string;
    state: string;
    country: string ;
    zipcode: string ;
    profile_image: string | null;
    admin: boolean
};

export let nameObject: Record<string, keyof UserDataResponse>  = {
    individual: "full_name",
    investor: "full_name",
    bank: "bank_name",
    agency: "agency_name",
    service_provider: "service_provider_name"
};

// Customizable Area End

export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    currentLanguage:"ar"|"en",
    pageHeadingText: string;
    isListingAvailable: boolean;
    showPublished: boolean;
    data: typeof reqVeriData;
    listingArray: UserListing[];
    publishAnchorEl: HTMLElement | null;
    showFilter: boolean;
    showDetailPreview: boolean;
    supportDoc: (File|null)[];
    otherDoc: (File|null)[];
    breadscrumarray: string[];
    name: string;
    email: string;
    phoneNumber: string;
    country: string;
    state: string;
    city: string;
    zip: string;
    listingType: string;
    status: string;
    userType: string;
    showModal: boolean;
    modCreatSelect: string;
    selectModUser: string;
    showModUserList: boolean;
    startDate: string;
    endDate: string;
    countAcitve: number;
    countCompleted: number;
    countRejected: number;
    countPending: number;
    page: number;
    totalPage: number;
    totalCount: number;
    listDetailData: UserListingAttributes;
    search: string;
    userSearch: string;
    checkListDataAvailabilty: "both" | "onlyPublished" | "onlyDraft" | "none";
    profilePicture: string;
    usersList: UserMainDataResponse[]
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class AllListingController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getProfileCallId: string= "";
    getListingCallId: string= "";
    getListingAvailabilityCallId: string= "";
    getListDetailListCallId: string= "";
    publishListCallId: string= "";
    deactivateCallId: string= "";
    deleteListCallId: string= "";
    getUsersListCallId: string= "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            currentLanguage:"en",
            pageHeadingText: "",
            isListingAvailable: true,
            showPublished: true,
            data:reqVeriData,
            listingArray: [],
            publishAnchorEl: null,
            showFilter: false,
            showDetailPreview: false,
            supportDoc: Array(2).fill(null),
            otherDoc: Array(2).fill(null),
            name: "",
            email: "",
            phoneNumber: "",
            country: "",
            state: "",
            city: "",
            zip: "",
            breadscrumarray: [],
            listingType: "",
            status: "",
            userType: "",
            showModal: false,
            modCreatSelect: "own",
            selectModUser: "",
            showModUserList: false,
            startDate: "",
            endDate: "",
            countAcitve: 0,
            countCompleted: 0,
            countRejected: 0,
            countPending: 0,
            page: 1,
            totalPage: 8,
            totalCount: 48,
            listDetailData: {
                id: 0,
                portfolio_size: "",
                listing_title: "",
                avg_balance_per_account: "",
                account_count: "",
                total_number_of_debts: 0,
                total_amount_of_debts: 0,
                total_judgements: 0,
                total_months_as_costomer: 0,
                pas_payment_made: false,
                month_average_paytime: 0,
                last_collectors_contact: "",
                last_creditors_contact: "",
                collections_used_in_past: 0,
                age_of_dept: "",
                type_of_dept: "",
                current_recovery_status: "",
                status: "",
                sub_status: "",
                confirmed: false,
                account_id: 0,
                created_at: "",
                supporting_documents: [],
                other_documents: [],
                offer_count: 0,
                account: {
                    full_name: "",
                    bank_name: "",
                    agency_name: "",
                    service_provider_name: "",
                    full_phone_number: "",
                    country_code: "",
                    email: "",
                    phone_number: "",
                    gender: "",
                    type: "",
                    user_type: "",
                    sub_type: "",
                    user_name: "",
                    address: "",
                    city: "",
                    state: "",
                    country: "",
                    zipcode: "",
                    profile_image: "",
                    admin: false
                },
                account_image: ""
            },
            search: "",
            userSearch: "",
            checkListDataAvailabilty: "both",
            profilePicture: "",
            usersList: []
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (this.isValidResponseAddress(responseJson)) {
                this.apiSuccessCallBacks(apiRequestCallId, responseJson);
            } else if (this.isInValidResponseAddress(responseJson)) {
                this.apiFailureCallBacks(apiRequestCallId, responseJson);
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const initialFile = new File([''], 'example.txt', { type: 'text/plain' });
        const language = await utills.getLanguageOrSetCurrentLanguage();
        let token = await getStorageData("accessToken");
        if (token) {
            this.handleUserType()
        } else {
            this.handleNavigationRouting("Home");
        }
        this.setState({ 
            currentLanguage: language,
            supportDoc: [initialFile, initialFile],
            otherDoc: [initialFile, initialFile]
        });
        this.getProfile();
        // Customizable Area End
    }

    // Customizable Area Start

    handleUserType = async () => {
        let user = await getStorageData("userType");
        const language = await utills.getLanguageOrSetCurrentLanguage();
        if ( !user ){
            this.setState({
                userType: "admin_account",
                pageHeadingText: languageTranslationData[language].Listings
            }, () => this.getListingPublishedDraftData());
            this.getUserListApi();
        } else {
            this.setState({
                userType: "consumer",
                pageHeadingText: languageTranslationData[language].MyListings
            }, () =>  {
                this.checkListingAvailability();
            });
            
        }
    };

    isValidResponseAddress = (responseJson: ValidResponseType) => {
        return (responseJson && responseJson.data) || responseJson.message;
    };
    
    isInValidResponseAddress = (responseJson: InvalidResponseType) => {
        return responseJson && responseJson.errors;
    };
    
    apiSuccessCallBacks = (
        apiRequestCallId: string,
        responseJson: ValidResponseType
        ) => {
        if (apiRequestCallId === this.getProfileCallId) {
            this.getProfileApiSuccessResponse(responseJson.data as UserMainDataResponse);
        }
        if (apiRequestCallId === this.getListingAvailabilityCallId) {
            this.handleListDataAvailabilityCheck(responseJson)
        }
        if (apiRequestCallId === this.getListingCallId) {
            this.handleGetListSuccesResponse(responseJson);
        }
        if (apiRequestCallId === this.getListDetailListCallId) {
            const listDetailData = responseJson.data as UserListing
            this.handleDetailPreviewShow(listDetailData.attributes);
        }
        if (apiRequestCallId === this.deactivateCallId) {
            this.setState({
                publishAnchorEl: null
            });
            this.checkListingAvailability();
            this.getListingPublishedDraftData();
        }
        if (apiRequestCallId === this.publishListCallId) {
            this.setState({
                publishAnchorEl: null
            })
            this.checkListingAvailability();
            this.getListingPublishedDraftData();
        }
        if (apiRequestCallId === this.deleteListCallId) {
            this.setState({
                publishAnchorEl: null
            });
            if(this.state.userType === "consumer") {
                this.checkListingAvailability();
            }
            this.getListingPublishedDraftData();
        }
        if (apiRequestCallId === this.getUsersListCallId) {
            this.setState({
                usersList: responseJson.data as UserMainDataResponse[]
            });
        }
    };

    apiFailureCallBacks= (
        apiRequestCallId: string,
        responseJson: InvalidResponseType
      ) => {
        if (apiRequestCallId === this.getProfileCallId) {
            this.getProfileApiFailureResponse();
        }
    };

    getUserListApi = async () => {
        const params = `status=completed&search=${this.state.userSearch}`
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getUsersListCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.deleteAccountEndPoint}?${params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getListingPublishedDraftData = async () => {
        const {showPublished, page, search, startDate,endDate, listingType, status, userType} = this.state;
        let listingFor = "";
        let statusType = "published";
        if(userType === "admin_account") {
            if(showPublished) {
                listingFor = "other"
            } else {
                statusType = ""
            }
        } else {
            if(!showPublished) {
                statusType = "draft"
            }
        }
        const params = `?status=${statusType}&per_page=10&page=${page}&listing_for=${listingFor}&search=${search}&from=${startDate}&to=${endDate}&listing_type=${listingType}&sub_status=${status}`
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getListingCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createListApiEndPoint}${params}` 
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    checkListingAvailability = async () => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getListingAvailabilityCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.createListApiEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getListDetail = async (listId: string) => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getListDetailListCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createListApiEndPoint}/${listId}` 
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    putPublishListApi = async (listId: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.publishListCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createListApiEndPoint}/${listId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodPut
        );
        const formData = new FormData()
        formData.append("[data][status]", "published");
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    deactivePublishedList =  async (listID: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deactivateCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createListApiEndPoint}/${listID}/update_sub_status`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );
        const formData = new FormData()
        formData.append("[sub_status]", "deactive");
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    deleteListApi = async (listID: string) => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteListCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.createListApiEndPoint}/${listID}` 
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodDelete
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleGetListSuccesResponse = (responseJson: ValidResponseType) => {
        if (this.state.page > responseJson.meta.total_pages) {
            this.setState({
                page: responseJson.meta.total_pages
            }, ()=> this.getListingPublishedDraftData());            
        } else {
            this.setState({
                listingArray: responseJson.data as UserListing[],
                countAcitve: responseJson.meta.counter.active,
                countCompleted: responseJson.meta.counter.completed,
                countRejected: responseJson.meta.counter.rejected,
                countPending: responseJson.meta.counter.pending,
                totalCount: responseJson.meta.total_count,
                totalPage: responseJson.meta.total_pages,
                page: responseJson.meta.current_page
            });
        }
    };

    handleListDataAvailabilityCheck = (responseJson: ValidResponseType) => {
        const listDataArray = responseJson.data as UserListing[];
        let publishedCount = 0;
        let draftCount = 0;
        listDataArray.forEach(item => {
            if (item.attributes.status === 'published') {
                publishedCount++;
            } else if (item.attributes.status === 'draft') {
                draftCount++;
            }
        });
        if (publishedCount === 0 && draftCount === 0) {
            this.setState({
                checkListDataAvailabilty: "none"
            })
        } else if (publishedCount > 0 && draftCount === 0) {
            this.setState({
                checkListDataAvailabilty: "onlyPublished",
                showPublished: true
            }, () => this.getListingPublishedDraftData());
        } else if (publishedCount === 0 && draftCount > 0) {
            this.setState({
                checkListDataAvailabilty: "onlyDraft",
                showPublished: false
            }, () => this.getListingPublishedDraftData());
        } else if (publishedCount > 0 && draftCount > 0) {
            this.setState({
                checkListDataAvailabilty: "both"
            }, () => this.getListingPublishedDraftData());
        }
    };

    getProfile = async () => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getProfileCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.fetchProfileEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    getProfileApiSuccessResponse = (resp: UserMainDataResponse) => {
        this.setState({
            profilePicture: resp.attributes.profile_image? resp.attributes.profile_image: "profile_image",
            name : `${resp.attributes[nameObject[resp.attributes.sub_type]]}`,
            email: `${resp.attributes.email}`,
            phoneNumber: `${resp.attributes.full_phone_number}`,
            country: `${resp.attributes.country}`,
            state: `${resp.attributes.state}`,
            city: `${resp.attributes.city}`,
            zip: `${resp.attributes.zipcode}`
        });
    };

    getProfileApiFailureResponse = () => {
        this.handleLogOut();
    };

    handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            search: event.target.value
        }, () => this.getListingPublishedDraftData());
    };

    handleUserSearch = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({
            userSearch: event.target.value
        }, () => this.getUserListApi());
    };

    handleFilterDateChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        this.setState({
          [name]: event.target.value
        }as unknown as Pick<S, keyof S>);
    };

    handleFilterDateReset = () => {
        this.setState({
          startDate: "",
          endDate: ""
        });
    };

    handleSelect = (event: React.ChangeEvent<{ value:unknown}>, name: string) => {
        let {value} = event.target;
        if(typeof value==="string"){
            this.setState({
                [name]: value
            } as unknown as Pick<S, keyof S>);
        } 
    };

    handleSelectReset = (stateName: string) => {
        this.setState({
            [stateName]: ""
        } as unknown as Pick<S, keyof S>);
    };

    handleFilterReset = () => {
        this.setState({
            listingType: "",
            status: "",
            startDate: "",
            endDate: ""
        }, () => this.getListingPublishedDraftData());
    };

    handleNavigationRouting = async (selectRoute: string) => {
        if (selectRoute !== "UserCreateListing" && selectRoute !== "dashboardCreateListing") {
            await removeStorageData("updateListId");
            await removeStorageData("behalfUserId");
        }
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleDrawer = (route:string)=>{
        if(route==="logOut"){
            this.handleLogOut()
        } else {
            if(route.includes("breadScrum")) {
                this.handleBreadScrumClick(route);
            } else {
                this.handleNavigationRouting(route);
            }
        }
    };

    handleLogOut = async() => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        await removeStorageData("updateListId");
        await removeStorageData("behalfUserId");
        await removeStorageData("signupStatus");
        await removeStorageData("selectedRequest");
        this.handleNavigationRouting("Home");
    };

    handleBreadScrumClick = (clickOn: string) => {
        const languageData = languageTranslationData[this.state.currentLanguage]; 
        if(clickOn==="breadScrum0"){
            this.setState({
                showDetailPreview: false, 
                breadscrumarray:[],
                publishAnchorEl: null,
                pageHeadingText: languageData.MyListings
            });
        }
    };

    handlePublishedShow = (show: boolean) => {
        this.setState({
            showPublished: show,
            showFilter: false,
            page: 1,
            startDate: "",
            endDate: "",
            listingType: "",
            status: "",
            search: ""
        }, ()=> this.getListingPublishedDraftData());
    };

    handlePublishMenu = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({publishAnchorEl:event.currentTarget});
    };

    handleMenuClose = () => {
        this.setState({publishAnchorEl:null});
    };

    handleToggleFilter = () => {
        this.setState({showFilter: !this.state.showFilter});
    };

    handleCloseFilter = () => {
        this.setState({showFilter: false});
    };

    handleDetailPreviewShow = (responseDataAttributes: UserListingAttributes) => {
        const {currentLanguage} = this.state;
        const languageData = languageTranslationData[currentLanguage];        
        this.setState({
            listDetailData: responseDataAttributes,
            pageHeadingText: languageData.ListingDetails,
            showDetailPreview: true,
            breadscrumarray: [languageData.MyListings, languageData.ListingDetails],
            publishAnchorEl: null
        });
    };

    handleStatusCell = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "completed") {
            changeBackground = "#DBEAFE";
            changeColor = "#1D4ED8";
        }
        if (value === "pending") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "rejected") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor
        };
    };

    handleCloseModal = () => {
        this.setState({
            showModal: false,
            showModUserList: false
        });
    };

    handleModalCreate = (value: string) => {
        this.setState({modCreatSelect: value});
    };

    handleModalList = (value: string) => {
        this.setState({selectModUser: value});
    };

    handleNewListingButton = async () => {
        if(this.state.userType==="consumer") {
            await removeStorageData("updateListId");
            await removeStorageData("behalfUserId");
            this.handleNavigationRouting("UserCreateListing");
        } else {
            this.setState({showModal: true});
        }
    };

    handleModalOkButton = async() => {
        if(!this.state.showModUserList){
            if (this.state.modCreatSelect==="own") {
                await removeStorageData("updateListId");
                await removeStorageData("behalfUserId");
                this.handleNavigationRouting("dashboardCreateListing");
            } else {
                this.setState({showModUserList: true});
            }
        } else {
            if(this.state.selectModUser) {
                await removeStorageData("updateListId");
                await setStorageData("behalfUserId", this.state.selectModUser)
                this.handleNavigationRouting("dashboardCreateListing");
            }
        }
    };

    paginationColSpan = () => {
        const {userType, showPublished} = this.state;
        let colSpan = 7;
        if(showPublished){
            if(userType === "consumer") {
                colSpan = 6;
            }
        } else {
            if(userType === "consumer") {
                colSpan = 4;
            } else {
                colSpan = 6;
            }
        }
        return colSpan;
    };

    handleApplyFilter = () => {
        this.getListingPublishedDraftData();
    };

    handlePaginationChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        this.setState({
            page: page
        }, ()=> this.getListingPublishedDraftData());
    };

    handleEditClick = async() => {
        await setStorageData("updateListId", this.state.listDetailData.id);
        this.handleNavigationRouting("UserCreateListing");
    };    

    // Customizable Area End
}
