import React from "react";
import { Box, Button, TextField, styled } from "@material-ui/core";
import Text from "../../../components/src/design-system/Text";
import { CSSProperties } from "@material-ui/core/styles/withStyles";
const languageTranslationData = require('../../../blocks/languagesupport/src/LanguageData/Signup.json');

interface Props {
  currentLanguage: string;
  verificationCode: Array<string>;
  error: string;
  email: string;
  handleVerificationCode: (event: React.ChangeEvent<HTMLInputElement>, verificationIndex: number ) => void;
  handleResendOtp: () => void;
}

const CustomTextField = styled(TextField)({
  width: "48px",
  "@media (max-width: 370px)":{
    width: "42px"
  }
});

const webStyle = {
    mainBox: {
      paddingTop: 80,
      marginBottom: 80,    
    "@media(min-width: 1920px), @media(min-height: 1080px)": {
        paddingTop: 175,
        marginBottom: 175
    }
  }
}

class VerificationWeb extends React.Component<Props> {
  
  constructor(props: Props) {
    super(props);
  }

  render () {
    const length = 6;
    const { verificationCode, error, email } = this.props;
    const languageData = languageTranslationData[this.props.currentLanguage];
    return (
      <Box sx={webStyle.mainBox as CSSProperties} flexDirection="column" display="flex" alignItems="center">
        <Text size="xl" weight="extraBold" lineHeight="48px">{languageData.Verification}</Text>
        <Text size="sm" weight="minBold" lineHeight="24px">{languageData.VerificationCheck1 +" "+ languageData.VerificationCheck2}</Text>
        <Text size="sm" weight="minBold" lineHeight="24px">{email}</Text>
        <Box mt={2} mb={4}>
          <Button 
            variant="text"
            data-test-id = "resendEmailButtonTestId"
            style={{ textTransform: 'none' }}
            onClick={this.props.handleResendOtp}
          >
            <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">
              {languageData.ResendEmail}</Text>
          </Button>
        </Box>
        <Box display="flex" flexDirection="column" gridGap="2px">
          <Box display="flex" gridGap="8px">
            {Array.from({ length }).map((_, otpIndex) =>
              <CustomTextField
                variant="outlined"
                id={`digit-${otpIndex}`}
                value={verificationCode[otpIndex]} 
                InputProps={{
                  style: { borderRadius: 8, border: '1px solid #CBD5E1' }
                }}
                inputProps={{ maxLength: 1 }}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => this.props.handleVerificationCode(event, otpIndex)}
                key={otpIndex} />
              )}
          </Box>
          <Box>
            {error && <Text variant="error" family="inter" weight="minBold" size="xs">{error}</Text>}
          </Box>
        </Box>
        <Box mt={3} display="flex" gridGap="4px" alignItems="center">
          <Text variant="grey" size="sm" weight="minBold" family="inter">
            {languageData.DidntGetMessage}
          </Text>
          <Button 
            variant="text" 
            style={{ textTransform: 'none' }}
            onClick={this.props.handleResendOtp}
          >
            <Text family="inter" align="right" variant="primaryBlue" weight="extraBold" size="sm">
              {languageData.Resend}</Text>
          </Button>
        </Box>
      </Box>
    )
  }
}

export default VerificationWeb;