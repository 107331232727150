
Object.defineProperty(exports, "__esModule", {
  value: true,
});

// Customizable Area Start
exports.loginAPiMethod = "POST";
exports.validationApiContentType = "application/json";
exports.validationApiMethodType = "GET";
exports.exampleAPiEndPoint = "EXAMPLE_URL";
exports.exampleAPiMethod = "POST";
exports.exampleApiContentType = "application/json";
exports.textInputPlaceHolder = "Enter Text";
exports.labelTitleText = "Product Title";
exports.labelBodyText = "ProductDescription Body";
exports.btnExampleTitle = "CLICK ME";
exports.productAPiEndPoint = "bx_block_productdescription/productdescriptions";
exports.productAPiMethod = "GET";
exports.productApiContentType = "application/json";
exports.heading = "Service Name";
exports.subHeading = "Seller Name";
exports.description = "The platform provides many services to various government agencies, the private sector, and individuals, which enhances the partnership between them to achieve the goals of development projects in the Kingdom. To enable the digital transformation of these services, increase and efficiency, and facilitate the conduct of services transparency.";
exports.sideBarHeadingFirst = "Target Group";
exports.sideBarHeadingSecond = "Duration of service";
exports.sideBarHeadingThird = "Service delivery channels";
exports.sideBarHeadingFourth = "The language used for the service";
exports.sideBarHeadingFifth = "Financial compensation";
exports.sideBarHeadingFirstDescription = "Private sector, government agencies, Ministry of Finance (Revenue Agency)";
exports.sideBarHeadingSecondDescription = "It depends on the procedures of the government agency and the Ministry of Finance";
exports.sideBarHeadingThirdDescription = "Our Platform";
exports.sideBarHeadingsideBarHeadingFourthDescription = "English, Arabic";
exports.sideBarHeadingsideBarHeadingFifthDescription = "Free";
exports.sideBarButtonHeadingFirst = "Start the service";
exports.sideBarButtonHeadingSecond = "Contact Us";
exports.tabHeadingFirst = "Required Documents";
exports.tabHeadingSecond = "The Conditions";
exports.tabHeadingThird = "Steps";
exports.tabContain = "Several attachments depend on the type of case";
exports.BreadcrumbsHeadingFirst = "Services";
exports.BreadcrumbsHeadingSecond = "Service Name";
exports.Rating = "4.0(120)";
exports.ServiceHeading = "Our Services";
exports.ServiceDesc = "The platform provides many services to various government agencies, the private sector, and individuals, which enhances the partnership between them to achieve the goals of development projects in the Kingdom. To enable the digital transformation of these services, increase and efficiency, and facilitate the conduct of services transparency.";
exports.ServiceBtn = "Request Verification";
exports.ServiceSideBar = "6500 Services";
exports.ServiceCategoryHeading = "Select Category";
exports.ServiceSearchHeading = "Search";
exports.ServiceSideFirst = "All Companies";
exports.ServiceSideSecond = "Most Used";
exports.ServiceSideThird = "Contracts";
exports.ServiceSideFourth = "Payments";
exports.ServiceSideFifth = "Market";
exports.ServiceSideSixth = "Voilations";
exports.ServiceCardDesc = "Lorem Ipsum is simply dummy text the printing and typesetting industry. Lorem Ipsum has been the standard dummy.";
exports.ServiceCardName = "Company Name";
exports.ServiceCardBtn = "View More";
exports.ServiceCardTagFirst = "Business";
exports.ServiceCardTagSecond = "Government";
exports.Heading = "Application Submitted Successfully";
exports.SubHeading = "Your application is under review our team will revert you back.";
exports.ButtonText = "Check Progress";
exports.contentType = "application/json";
exports.getMethod = "GET";
exports.getServiceEndPoint = "bx_block_landingpage/services";
exports.getServiceListEndPoint = "account_block/provider_accounts";
exports.headerTab1= "Companies";
exports.headerTab2= "Offers";
exports.headerTab3= "All";
exports.serviceInternalFormHeading = "Login to your account";
exports.serviceInternalFormLable1 = "ID Number or Email";
exports.serviceInternalFormLable2 = "Password";
exports.serviceInternalBtnLabel = "Login";
exports.serviceInternalfooterText1 = "Don’t have an account? ";
exports.serviceInternalfooterText2 = "Register";
exports.serviceContactHeading = "Get connected with us";
exports.serviceContactButton = "Submit";
exports.verificationrequest = "Verification Request";
exports.verificationStep1Description = "Enter the name of the company to request verification";
exports.verificationStep2Description = "Enter your details to request verification";
exports.verifyCompanyEndPoint = "account_block/accounts/verify_company";
exports.verificationRequestEndPoint = "account_block/accounts/verify_provider";
exports.EN = "en";
exports.AR = "ar";
exports.Invalid = "Invalid";
exports.InvalidInArabic = "غير صالح";
exports.FieldRequired = "Field is required.";
exports.FieldRequiredInArabic = "الحقل مطلوب.";
exports.verificationRequestEndPoint = "account_block/accounts/verify_provider";
exports.fetchOfferEndPoint = "/bx_block_profile/user_listings";
exports.marketListingHead = "Your Premier Marketplace for Debt Listings and Collection Services";
exports.marketListingHead1="Your"
exports.marketListingHead2="Premier Marketplace"
exports.marketListingHead3="for"
exports.marketListingHead4="Debt Listings and Collection "
exports.marketListingHead5="Services"
exports.marketListingDescription = "Seamlessly Buy, Sell, and Manage Debts with Trusted Partners on Our Comprehensive Platform";
exports.companyName = "Medical Debt Package";
exports.activeCollection = "Active Collection";
exports.postTime = "Posted 54 mintues ago";
exports.companyValue = "$2500";
exports.companyPortfolio = "Portfolio Size";
exports.companyUser = "1";
exports.companyAccount = "Account Count";
exports.averageBalance = "$500";
exports.averageAcoountBalance = "Average Balance Per Account";
exports.navHome = "Home"
exports.navMarketPlace = "MarketPlace"
exports.navListing = "Listing"
exports.headerBox = "Listing"
exports.MarketPlaceBannerID = "bx_block_content_management/banner/get_banner?slug=MarketPlace";
exports.ListingsEndPoints = "bx_block_profile/user_listings/list/";
exports.ListingsShowEndPoints = "bx_block_profile/user_listings/";
exports.BusinessEndPoints = "account_block/business_profiles";
exports.serviceType = "Service Type";
exports.makeOfferEndPoint = "bx_block_profile/user_offers";
exports.offerDescription= "Optionally, add an appeal to the owner of the debt, explaining why you are the best firm to handle this deb"
// Customizable Area End
