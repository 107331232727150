// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
import { getStorageData } from "framework/src/Utilities";
export const configJSON = require("./config");
const utills = require('../../utilities/src/utills')
import React, { ReactNode } from 'react';
export interface TabPanelProps {
    children: ReactNode;
    value: number;
    index: number;
  }

export type ContentAttributes = {
    id: number | string;
    title: string;
    description: string;
    image: Image | null;
    feature_article: string | null;
    feature_video: string | null;
    tag_list: string[];
    status: string;
    publish_date: string | null;
    view_count: number;
    created_at: string;
    updated_at: string;
  };


  
  export type Content = {
    id: number | string;
    type: string;
    attributes: ContentAttributes;
  };
  
  export type ContentsData = {
    data: Content[];
  };
  
  export type BlogAttributes = {
    id: number;
    title: string;
    description: string;
    read_minutes : string;
    author_id: number;
    category_id: number;
    created_at: string;
    updated_at: string;
    image: Image | null;
    author: Author;
    contents: ContentsData | null;
    author_image : Image | null,
    category_name : string
    
  };
  
  export type Blog = {
    id: number | string;
    type: string;
    attributes: BlogAttributes;
  };
  
  export type BlogsData = {
    data: Blog[];
  };
  
  export type CategoryAttributes = {
    id: number;
    name: string;
    dark_icon: string | null;
    dark_icon_active: string | null;
    dark_icon_inactive: string | null;
    light_icon: string | null;
    description: string;
    light_icon_active: string | null;
    light_icon_inactive: string | null;
    rank: number | null;
    created_at: string;
    updated_at: string;
    selected_sub_categories: string | null;
    blogs: BlogsData;
    category_name : BlogAttributes
  };
  
  export type CategoryState = {
    id: number | string;
    type: string;
    attributes: CategoryAttributes;
  };
  
  export type Image = {
    file_name: string;
    url: string;
    size: string;

  };
  
  export type Author = {
    id: number | string;
    name: string;
    bio: string;
    created_at: string;
    updated_at: string;
  };

  export type BlogImage = {
    file_name: string;
    url: string;
    size: string;
  };
  
  export type Author1 = {
    id: number;
    name: string;
    bio: string;
    created_at: string;
    updated_at: string;
  };

// Customizable Area End

export interface ContentProps {
    id: string;
    classes: any;
    navigation: any;
    // Customizable Area Start
    // Customizable Area End
}

export interface AboutPageData {
    id: string;
    type: string;
    attributes: {
        id: number;
        sequence: number;
        title: string;
        title_in_arabic:string;
        description: string;
        description_in_arabic:string;
        image: string;
    };
}

interface Pagination {
  total_count: number,
  total_pages: number,
  current_page: number,
  next_page: number | null,
  prev_page: number | null
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    loading: boolean;
    aboutUsData: AboutPageData[];
    currentLanguage:'en'|'ar',
    data : Blog,
    value: number,
    blogsShow: Blog[],
    itemsToShow: number,
    blogStart : number,
    selectedBlog: string,
    progress: number,
    meta: Pagination,
    category_id: string | null
    // Customizable Area End
}

export default class IndustrialPageWebController extends BlockComponent<
    ContentProps,
    S,
    SS
> {

    // Customizable Area Start
    getAboutPageDataApiCall: string = ""
    getMoreArtcle: string = ""
    // Customizable Area End

    constructor(props: ContentProps) {

        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            // Customizable Area Start
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.NavigationPayLoadMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            loading: false,
            aboutUsData: [],
            currentLanguage:configJSON.EN,
            data : {
                "id": 0,
                "type": "",
                "attributes": {
                    "id": 0,
                    "title": "",
                    "description": "",
                    "read_minutes": "",
                    "author_id": 0,
                    "category_id": 0,
                    "created_at": "",
                    "updated_at": "",
                    "image":null,
                    "author": {
                        "id": 0,
                        "name": "",
                        "bio": "",
                        "created_at": "",
                        "updated_at": ""
                    },
                    "author_image": null,
                    "category_name": "",
                    "contents": {
                        "data": [
                            {
                                "id": "",
                                "type": "",
                                "attributes": {
                                    "id": 0,
                                    "title": "",
                                    "description": "",
                                    "image": null,
                                    "feature_article": null,
                                    "feature_video": null,
                                    "tag_list": [],
                                    "status": "",
                                    "publish_date": null,
                                    "view_count": 0,
                                    "created_at": "",
                                    "updated_at": ""
                                }
                            },
                        ]
                    }
                }
            },
            value: 0,
            blogsShow : [],
            itemsToShow: 3,
            blogStart : 0,
            selectedBlog: "",
            progress : 0,
            meta: {
              total_count: 0,
              total_pages: 0,
              current_page: 1,
              next_page: null,
              prev_page: null
            },
            category_id: null
            // Customizable Area End
        };


        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start

        // Customizable Area End
    }

    async receive(from: string, message: Message) {

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            runEngine.debugLog("API Message Recived", message);

            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );

            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );

            let errorReponse = message.getData(
                getName(MessageEnum.RestAPIResponceErrorMessage)
            );

            if(responseJson.hasOwnProperty("errors") || responseJson.hasOwnProperty("error")){
              console.log("Manoj error", responseJson)
              return;
            }
            
            if (apiRequestCallId && responseJson) {
                if (apiRequestCallId === this.getAboutPageDataApiCall) {
                    this.setState({ data: responseJson.data, loading:false, category_id: responseJson.data.attributes.category_id });
                    this.getNewBlogs(responseJson.data.attributes.category_id)
                }
                if (apiRequestCallId === this.getMoreArtcle) {
                  this.setState({blogsShow : responseJson.data, meta: responseJson.meta})
                }
            }
            
        }
        // Customizable Area End
    }

    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({ currentLanguage: language })

        const blogID = this.props.navigation.getParam("blog_id")
        this.getAboutUsPageData(blogID)
        // Customizable Area End
    }

    // Customizable Area Start
    getAboutUsPageData = (id : string) => {
        this.setState({loading:true})
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getAboutPageDataApiCall = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.getBlog}/${id}`   
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    getNewBlogs = (category_id: string, page=1) => {
        this.setState({loading:true})
        const header = {
            "Content-Type": configJSON.validationApiContentType,
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.getMoreArtcle = requestMessage.messageId;

        const PER_PAGE = 3
        const ENDPOINT = `${configJSON.getBlog}?per_page=${PER_PAGE}&page=${page}&category_id=${category_id}`

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            ENDPOINT
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.getMethod
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleTabValueChange = (event:React.ChangeEvent<{}>, newValue: number) => {
        this.setState({ value: newValue });
      };

    handleShowPrev = () => {
      if (this.state.meta.prev_page) {
        this.getNewBlogs(this.state.category_id || "", this.state.meta.prev_page)
      }
    };

    handleShowNext = () => {
      if(this.state.meta.next_page){
        this.getNewBlogs(this.state.category_id || "", this.state.meta.next_page)
      }
    };

      formatDate(dateString: string): string {
        if (!dateString) {
          return "Invalid Date";
        }
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
          return "Invalid Date";
        }
        return new Intl.DateTimeFormat("en-GB", {
          day: "2-digit",
          month: "short",
          year: "numeric",
        }).format(date);
      }
      scrollToSection = (id: string): void => {
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'start' });
        }
      };
      handleNewBlog = (id : number) => {
        window.open(`/ContentManagement/${id}`, "_self")
      }
      handleCatogryData = () => {
       return  this.state.blogsShow.slice(this.state.blogStart, this.state.itemsToShow)
      }

    // Customizable Area End
}
