
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
export const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
// Customizable Area Start
import { getStorageData } from "framework/src/Utilities";
interface ProductItem {
  id: string;
  type: string;
  attributes: {
    title: string;
    image: string;
    description: string;
    sub_debut_buyers: {
       title: string; 
       description: string; 
       arabic_title: string; 
       arabic_description: string };
    arabic_title: string;
    arabic_description: string;
    step_text: string,
    sub_buyer_text: string,
  };
}
export type BannerAttributes = {
  id: number;
  type: string;
  attributes: {
    id: number;
    banner_title: string;
    banner_title_arabic: string;
    banner_description: string;
    banner_description_arabic: string;
    created_at: string;
    updated_at: string;
    image: string;
    sub_text1:string;
    sub_text2:string;
  };
};
export type FAQIndexData = {
  id: number,
  type: string,
  attributes: {
    question: string,
    answer: string,
    faq_type: string
  }
}
// Customizable Area End

export interface Props {
  navigation: object;
  idContent: string | number | object;
}
interface S {
  // Customizable Area Start
  title: string;
  image: string;
  description: string;
  userDataList: ProductItem[]
  currentLanguage: "ar" | "en",
  loading: boolean;
  bannerData: BannerAttributes;
  dataFAQ: FAQIndexData[];
  // Customizable Area End
}

interface SS {
  // Customizable Area Start
  idManagement: number | string;
  // Customizable Area End
}

export default class DebtBuyerController extends BlockComponent<
  Props,
  S,
  SS
> {
  // Customizable Area Start
  userDataListCall: string | Message = ''
  addDataCall: string | Message = ''
  getDebtBannerCallID: string ="";
  getDebtBuyerFAQCall: string = "";
  // Customizable Area End

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    // Customizable Area Start
    // Customizable Area End
    this.subScribedMessages = [
      getName(MessageEnum.RestAPIResponceMessage),
      // Customizable Area Start
      // Customizable Area End
    ];

    this.state = {
      // Customizable Area Start
      title: "",
      image: "",
      description: "",
      userDataList: [],
      currentLanguage: "en",
      loading: false,
      bannerData: {
        id: 0,
        type: "",
        attributes: {
          id: 0,
          banner_title: "",
          banner_title_arabic: "",
          banner_description: "",
          banner_description_arabic: "",
          created_at: "",
          updated_at: "",
          image: "",
          sub_text1:"",
          sub_text2:"",
        },
      },
      dataFAQ: [],
      // Customizable Area End
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);
    // Customizable Area Start
    // Customizable Area End
  }
  async receive(from: string, message: Message) {
    // Customizable Area Start
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.userDataListCall !== null &&
      this.userDataListCall ===
      message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)

      );

      let errorResponse = message.getData(
        getName(MessageEnum.RestAPIResponceErrorMessage)
      );
      if (responseJson) {
        this.setState({ userDataList: responseJson.data, loading: false });
      }
      this.parseApiCatchErrorResponse(errorResponse);
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDebtBannerCallID != null &&
      this.getDebtBannerCallID ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ bannerData: responseJson.data[0] });
      }
    }
    if (
      getName(MessageEnum.RestAPIResponceMessage) === message.id &&
      this.getDebtBuyerFAQCall != null &&
      this.getDebtBuyerFAQCall ===
        message.getData(getName(MessageEnum.RestAPIResponceDataMessage))
    ) {
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      if (responseJson && !responseJson.errors && responseJson.data) {
        this.setState({ dataFAQ: responseJson.data });
      }
    }

    // Customizable Area End
  }
  // Customizable Area Start
  async componentDidMount() {
    const language = await utills.getLanguageOrSetCurrentLanguage();
    this.setState({ currentLanguage: language })
    this.getUserDataListApi()
    this.DebtBuyerGetBanner();
    this.DebtBuyerGetFAQ();
  }
  getUserDataListApi = async () => {
    this.setState({ loading: true })
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.userDataListCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.getDebutbuyerdata
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );

    runEngine.sendMessage(requestMessage.id, requestMessage);
    return true;
  };

  DebtBuyerGetBanner = () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDebtBannerCallID = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.debtBuyerBannerID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  DebtBuyerGetFAQ = async () => {
    const header = {
      "Content-Type": configJSON.validationApiContentType,
      token: await getStorageData("accessToken")
    };
    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    this.getDebtBuyerFAQCall = requestMessage.messageId;
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      configJSON.debtBuyerFAQID
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      configJSON.getMethod
    );
    runEngine.sendMessage(requestMessage.id, requestMessage);
  };

  // Customizable Area End
}
