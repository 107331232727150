import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData ,removeStorageData,setStorageData} from "framework/src/Utilities";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Request.json')
import { ChangeEvent, MouseEvent } from "react";
const reqVeriData = [
    ["#669677", "Blue Horizon Investments", "APPROVED", "GHI128789", "09 Jul 2018"],
    ["#669459", "Apex Financial Solutions", "APPROVED", "ABC823456", "20 Jan 2018"],
    ["#869287", "Legacy Financial Group", "PENDING", "MNO458723", "17 Sep 2013"],
    ["#664979", "Zenith Credit Management", "REJECTED", "JKL787654", "29 Aug 2023"],
    ["#679287", "Crestwood Capital Partners", "PENDING", "XYZ709012", "12 May 2023"],
    ["#664559", "Silverline Ventures LLC", "APPROVED", "BEF456789", "24 Jun 2012"]
];
  
  export interface VerificationState {
    id: number;
    type: string;
    attributes: {
          uuid: string;
          full_name: string;
          status: string;
          registration_number: string;
          verification_type: string;
          request_date: string;
          details: string;
          Company: string | null;
          Debtor: string | null;
      }
  };

interface Meta {
    total_count: number;
    total_pages: number;
    current_page: number;
    next_page: number | null;
    prev_page: number | null;
    counter: {
        approved: number;
        pending: number;
        rejected: number;
    };
};
// Customizable Area End
type AllOrNewOrArchived = "All" | "New" | "Archived";
type MyRequestsOrVerificationRequest = "My Requests" | "Verification Request";
interface RequestVerificationAttributes {
    first_name: string;
    last_name: string;
    full_phone_number: string;
    email: string;
    status: string;
    legal_business_name: string;
    uuid: string | null;
    request_date: string | null;
    Company: string | null;
    Debtor: string | null;
}

interface RequestVerificationData {
    id: string;
    type: string;
    attributes: RequestVerificationAttributes;
}

interface ApiResponse {
    data: RequestVerificationData[];
}


export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs: Array<AllOrNewOrArchived>,
    selectedTab: AllOrNewOrArchived,
    MyRequestTab: Array<MyRequestsOrVerificationRequest>,
    MyRequestTabSelectedTab: MyRequestsOrVerificationRequest,
    agreeModal: boolean,
    cancelModal: boolean,
    verificationRequestList: RequestVerificationData[],
    verificationRequestListAvailable: boolean;
    currentLanguage: 'ar' | 'en',
    isFirstModalOpen: boolean,
    isSecondModalOpen: boolean,
    isThirdModalOpen: boolean,
    data: typeof reqVeriData;
    search: string;
    verificationPage: number;
    isFilterDrawerOpen: boolean;
    fromDate: string;
    toDate: string;
    status: string;
    anchorEl: HTMLElement | null | SVGSVGElement
    dot: HTMLElement | null,
    token: string,
    entityName: string;
    registrationNumber: string;
    entityType: string;
    additionalDetails: string;
    isSubmit : boolean
    requesrUserdata: VerificationState[];
    selectedRequest:number;
    totalPages: number;
    currentPage: number;
    itemsPerPage:number;
    totalCount:number;
    statusCounts: {
        approved: number,
        pending: number,
        rejected: number,
    };
    errors:{
        detailsErr: string,
        registration_numberErr: string,
        full_nameErr: string,
        verification_typeErr: string,
    },
    registrationNumberError:string;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class RequestsWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    verificationRequestListApiCallId: string = "";
    tablePostApiCallId : string = "";
    filterRequestListApiCallId: string = "";
    deleteVerificationRequiestCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            verificationRequestList: [],
            verificationRequestListAvailable: false,
            tabs: this.tabsList('en'),
            selectedTab: "All",
            MyRequestTab: this.myRequestTabList('en'),
            MyRequestTabSelectedTab: "My Requests",
            agreeModal: false,
            cancelModal: false,
            currentLanguage: 'en',
            isFirstModalOpen: false,
            isSecondModalOpen: false,
            isThirdModalOpen: false,
            data: reqVeriData,
            search: "",
            verificationPage: 1,
            isFilterDrawerOpen: false,
            fromDate: '',
            toDate: '',
            status: "",
            anchorEl: null,
            dot: null,
            token:"",
            entityName: '',
            registrationNumber: '',
            entityType: "", 
            additionalDetails: '',
            isSubmit : false,
            requesrUserdata: [],
            selectedRequest: 0,
            totalPages: 1,
            currentPage: 1,
            itemsPerPage:10,
            totalCount:0,
            statusCounts: {
                approved: 0,
                pending: 0,
                rejected: 0,
            },
            errors:{
                detailsErr: "",
                registration_numberErr: "",
                full_nameErr: "",
                verification_typeErr: "",
            },
            registrationNumberError:"",
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (responseJson && (responseJson.data || responseJson.message)) {
                this.apiSuccessCallBack(apiRequestCallId, responseJson)
            } else {
                if (responseJson && responseJson.error) {
                    if (apiRequestCallId === this.tablePostApiCallId) {
                        this.setState({
                            registrationNumberError: "Registration number does not match."
                        });
                    }
                }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        this.getRequestVerificationListAvailable();
        this.handleFilterForVerification(1);
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: language,
            tabs: this.tabsList(language),
            MyRequestTab: this.myRequestTabList(language),
            selectedTab: languageTranslationData[language].All,
            MyRequestTabSelectedTab: languageTranslationData[language].MyRequests
        })
        // Customizable Area End
    }

    // Customizable Area Start

    apiSuccessCallBack = (apiRequestCallId: string, responseJson: ApiResponse) => {
        if (apiRequestCallId === this.verificationRequestListApiCallId) {
            this.handleVerificationReqAvailSuccessResp("availableCheck", responseJson as {data: (RequestVerificationData | VerificationState)[], meta: Meta});
        }
        if (apiRequestCallId === this.filterRequestListApiCallId) {
            this.handleVerificationReqAvailSuccessResp("getList", responseJson as {data: (RequestVerificationData | VerificationState)[], meta: Meta});
        }
        if (apiRequestCallId === this.deleteVerificationRequiestCallId) {
            this.setState({ dot: null, selectedRequest: 0 });
            this.getRequestVerificationListAvailable();
            this.handleFilterForVerification(this.state.currentPage);
        }
        if (apiRequestCallId === this.tablePostApiCallId) {
            this.setState({
                additionalDetails: "",
                registrationNumber:  this.state.registrationNumber,
                entityName: "",
                entityType: "",
                registrationNumberError: "", 
                isThirdModalOpen: true  ,
                isSecondModalOpen: false  
            });
            this.getRequestVerificationListAvailable();
            this.handleFilterForVerification(this.state.currentPage);
        }
    };

    deleteVerificationRequiest = async(verificationReqId: string) => {
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteVerificationRequiestCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listRequestVerificationEndPoint}/${verificationReqId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodDelete
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handleVerificationReqAvailSuccessResp = (whoCall: string, responseJson: {data: (RequestVerificationData | VerificationState)[], meta: Meta}) => {
        if (whoCall === "availableCheck") {
            if(!responseJson.data || responseJson.data.length === 0) {
                this.setState({
                    verificationRequestListAvailable: false
                });
            } else {
                this.setState({
                    verificationRequestListAvailable: true
                });
            }
        } else {
            const { data, meta } = responseJson;
            if (data) {
            if (meta.total_pages < this.state.currentPage) {
                this.setState({
                    currentPage: meta.total_pages,
                }, () => this.handleFilterForVerification(meta.total_pages));
            } else {
                this.setState({
                verificationRequestList: data as RequestVerificationData[], 
                requesrUserdata : responseJson.data as VerificationState[],
                totalPages: meta.total_pages,
                totalCount: meta.total_count,
                currentPage: meta.current_page,
                    statusCounts: {
                        approved: meta.counter.approved,
                        pending: meta.counter.pending,
                        rejected: meta.counter.rejected
                    }
                });
            }
            } else {
                this.setState({
                    verificationRequestList: [], 
                    requesrUserdata : [],
                    totalPages: 1,
                    totalCount: 0,
                    currentPage: 1,
                    statusCounts: {
                        approved: 0,
                        pending: 0,
                        rejected: 0
                    }
                });
            }
        }
       
    };

    tabsList = (language: string) => [
        languageTranslationData[language].All,
        languageTranslationData[language].New,
        languageTranslationData[language].Archived
    ]

    myRequestTabList = (language: string) => [
        languageTranslationData[language].MyRequests,
        languageTranslationData[language].VerificationRequest
    ]

    handleAgreeButtonClick = () => {
        this.setState({ agreeModal: true });
    };

    handleModalConfirm = () => {
        this.setState({ agreeModal: false });
    };
    handleCancelButtonClick = () => {
        this.setState({ cancelModal: true });
    };

    handleModalBlockConfirm = () => {
        this.setState({ cancelModal: false });
    };

    handleChangeTab = (tab: MyRequestsOrVerificationRequest) => {
        this.setState({ MyRequestTabSelectedTab: tab })
    }

    handleChangeFilterTab = (tab: AllOrNewOrArchived) => {
        this.setState({ selectedTab: tab })
    }

    getRequestVerificationListAvailable = async () => {
        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.verificationRequestListApiCallId = requestMessage.messageId;
        const endpoint =`${configJSON.listRequestVerificationEndPoint}?page=1&per_page=1`;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            endpoint
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    handlePageChange = (event: React.ChangeEvent<unknown>, page: number) => {
        this.handleFilterForVerification(page);
    };

    handleFirstModalOpen = () => {
        this.setState({ isFirstModalOpen: true });
    };

    handleFirstModalClose = () => {
        this.setState({ isFirstModalOpen: false });
    };

    handleSecondModalOpen = () => {
        this.setState({ isFirstModalOpen: false, isSecondModalOpen: true });
    };

    handleSecondModalClose = () => {
        this.setState({ isSecondModalOpen: false });
    };

    handleThirdModalOpen = () => {
        this.setState({ isSecondModalOpen: false, isThirdModalOpen: true });
    };
    handleThirdModalClose = () => {
        this.setState({ isThirdModalOpen: false });
    };

    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "pending" || value === "due") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "rejected" || value === "past due") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };
    handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleMenuClose = () => {
        this.setState({ anchorEl: null });
    };

    handleClick = (event: React.MouseEvent<HTMLElement>, verificationReqId: number) => {
        this.setState({ dot: event.currentTarget, selectedRequest: verificationReqId});
    };

    handleClose = () => {
        this.setState({ dot: null });
    };

    async  handleNavigation(route: string) {
        if(route === "UserRequest") {
            await setStorageData("selectedRequest", this.state.selectedRequest);
        }
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    onLogout = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigation("Home");
    };
    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.onLogout();
        } else{
            this.handleNavigation(route);
        }
    };
    
    handleNavigateForUserList = (userListId: number) => {
        const message = new Message(getName(MessageEnum.NavigationMessage));
        message.addData(getName(MessageEnum.NavigationTargetMessage), "UserRequest");
        message.addData(getName(MessageEnum.NavigationTargetMessage), "ProductDescription");
        message.addData(getName(MessageEnum.NavigationTargetMessage), "Customisableusersubscriptions");
        message.addData(getName(MessageEnum.NavigationPropsMessage), this.props);
        message.addData(getName(MessageEnum.NavigationScreenNameMessage), userListId)
        this.send(message);
      } 

    handlePostapi = async (event:React.ChangeEvent<HTMLFormElement>) => {
        event.preventDefault()
        const jsonData = {
            data: {
                attributes: {
                    details: this.state.additionalDetails,
                    registration_number: this.state.registrationNumber,
                    full_name: this.state.entityName,
                    verification_type: "Not specified",
                    status: "pending",
                    provider_id: "1117"
                }
            }
        };

        const header = {
            "Content-Type": configJSON.validationApiContentType,
            token: await getStorageData("accessToken")

        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.tablePostApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.RequestEndPoint
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            JSON.stringify(jsonData)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handleInputChange = (field:string, value: string) => {
        this.setState((prevState) => ({
          ...prevState,
          [field]: value,
        }));
      };
      
    formatDate = (dateString: string) => {
        const date = new Date(dateString);
        return new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: 'short',
          year: '2-digit',
        }).format(date);
      };

    handleFilterForVerification = async (page: number) => {
        const {search, fromDate, toDate, status} = this.state;
        const params = `per_page=10&page=${page}&search=${search}&query[status]=${status}&query[from]=${fromDate}&query[to]=${toDate}`
        const header = {
            "Content-Type": configJSON.loginApiContentType,
            token: await getStorageData("accessToken")
        };
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.filterRequestListApiCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.listRequestVerificationEndPoint}?${params}`
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    }
    
    handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        this.setState({
            search: event.target.value
        }, () => this.handleFilterForVerification(this.state.currentPage))
    };

    handleFilterDateChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        this.setState({
          [name]: event.target.value
        }as unknown as Pick<S, keyof S>);
    };

    handleSelect = (event: React.ChangeEvent<{ value:unknown}>, name: string) => {
        let {value} = event.target;
        if(typeof value==="string"){
            this.setState({
                [name]: value
            } as unknown as Pick<S, keyof S>);
        } 
    };

    handleFilterDateReset = () => {
        this.setState({
            fromDate: "",
            toDate: ""
        }, () => this.handleFilterForVerification(this.state.currentPage));
    };

    handleFilterSelectReset = () => {
        this.setState({
            status: ""
        }, () => this.handleFilterForVerification(this.state.currentPage))
    };

    handleFilterResetAll = () => {
        this.setState({
            fromDate: "",
            toDate: "",
            status: ""
        }, () => this.handleFilterForVerification(this.state.currentPage));
    };
    
    // Customizable Area End
}
