import React from "react";
// Customizable Area Start
import {
  Box,
  styled,
  Grid,
  Container,
  Typography,
  Card,
  CardContent,
  ListItem,
  List,
  ListItemIcon,
  ListItemText,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Chip
} from "@material-ui/core";
import Text from "../../../../packages/components/src/design-system/Text";
import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import CustomisableusersubscriptionsController, { Props, Subscription } from "./CustomisableusersubscriptionsController";
import Paragraph from "../../../../packages/components/src/design-system/Paragraph";
import AddIcon from '@material-ui/icons/Add';
import { bgImageRight } from "../../../../packages/blocks/landingpage/src/assets";
const languageTranslation = require('../../languagesupport/src/LanguageData/Subscription.json')
// Customizable Area End

// Customizable Area Start

const MainBox = styled(Box)({
  background: `url(${bgImageRight}) no-repeat top right`,
  backgroundSize: "15% 50%"
});

const Section = styled(Container)({
  padding: '90px 90px',
  "@media (max-width: 1160px)": {
    padding: "40px 40px"
  },
  "@media (max-width: 1070px)": {
    padding: "40px 20px"
  },
  "@media (max-width: 600px)": {
    padding: "40px 20px",
  },
  '& .MuiInputBase-input-root': {
    padding: 12
  },
  '& .MuiInputBase-input:focus' : {
    outline: 'solid'
  }
})

const StyledLisItemText = styled(ListItemText)({
  padding: 0,
  '& span': {
    fontSize: 14,
    fontWeight: 500,
    lineHeight: "22px"
  }
});

const SubscriptionGrid = styled(Grid)({
  "@media (max-width: 1040px)": {
    flexDirection: "column",
    gap: "20px"
  },
  "@media (max-width: 430px)": {
    "& .MuiCardContent-root": {
      padding: "16px 20px 20px !important"
    }
  }
});

const CustomChip = styled(Chip)({
  width: "fit-content !important",
  "& .MuiChip-label": {
    textOverflow: "clip"
  }
});

const CustomAccordion = styled(Accordion)({
  borderRadius: 12,
  marginBottom: 24,
  padding: "24px",
  "@media (max-width: 500px)": {
    padding: "8px"
  }
});

// Customizable Area End

export default class Customisableusersubscriptions extends CustomisableusersubscriptionsController {
  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }
  
  // Customizable Area Start
  // Customizable Area End

  render () {
    // Customizable Area Start
    const { currentLanguage, subscriptions } = this.state;
    const subscriptionTranslation = languageTranslation[currentLanguage];

    return (
      <MainBox>
        <Section>
          <Grid style={webStyle.heading} container xs={12} sm={12} spacing={2}>
            <Grid item xs={12} sm={12}>
              <Text variant="coolBlue" size="xm" weight="bold" lineHeight="26px" align="center">{subscriptionTranslation.Heading}</Text>
              <Text size="1xl" weight="lightBold" lineHeight="44px" align="center">{subscriptionTranslation.Title}</Text>
              <Paragraph color="#64748B" fontWeight={400} lineHeight="26px" fontSize="18px" align="center">{subscriptionTranslation.Description}</Paragraph>
            </Grid>
          </Grid>
          <SubscriptionGrid container xs={12} sm={12} spacing={1} alignItems="center" justifyContent="center">
            { subscriptions.map((subscription: Subscription, subIdx: number) => {
              return (
                <Grid item xs={12} sm={6} style={{ maxWidth: 515 }}>
                  <Card style={{ ...webStyle.plans, ...(subIdx === 0 && webStyle.activePlan) 
                  }}>
                    <CardContent style={webStyle.content}>
                        {subIdx === 0 && <CustomChip style={webStyle.chip} label={subscriptionTranslation.MostPopular} />}
                        <Box display="flex" alignItems="center">
                          <Text size="1xl" variant="lightBlue" weight="extraBold">{subscription.price}  </Text>/<Text size="xm" variant="lightBlue" weight="bold">{subscription.cycle}</Text>
                        </Box>
                        <Box mt={2}>
                          <Text size="1xl" variant="lightBlue" weight="lightBold" lineHeight="44px">{subscription.name}</Text>
                        </Box>
                        <Box component="ul" style={webStyle.description}>
                          <Box marginBottom="10px" marginLeft="-15px">
                            <Text size="xx" lineHeight="22px" weight="minBold">{subscriptionTranslation.IdealFor}</Text>
                          </Box>
                          { subscription.description.map((description: string) => 
                              <Typography style={webStyle.descriptionItem} component="li">{description}</Typography>
                            )
                          }
                        </Box>
                        <List>
                          { subscription.services.map((service: string) => 
                              <ListItem style={webStyle.services}>
                                <ListItemIcon style={webStyle.servicesIcon}>
                                  <CheckCircleRoundedIcon htmlColor="#34D399" />
                                </ListItemIcon>
                                <StyledLisItemText>
                                  {service}
                                </StyledLisItemText>
                              </ListItem>
                            )}
                          </List> 
                          <Button style={subIdx === 0 ? webStyle.choosePlanBtn1 : webStyle.choosePlanBtn2 } variant="contained" fullWidth>{subscriptionTranslation.ChoosePlan}</Button>
                    </CardContent>
                  </Card>
                </Grid>
              )
            })}
          </SubscriptionGrid>
          <Grid style={webStyle.faqHeading} container xs={12} sm={12} spacing={2}>
            <Grid item xs={12} sm={12}>
              <Text variant="coolBlue" size="xm" weight="bold" lineHeight="26px" align="center">{subscriptionTranslation.Support}</Text>
              <Text size="1xl" weight="lightBold" lineHeight="44px" align="center">{subscriptionTranslation.Faq}</Text>
              <Paragraph color="#64748B" fontWeight={400} lineHeight="26px" fontSize="18px" align="center">{subscriptionTranslation.FaqDesc}</Paragraph>
            </Grid>
          </Grid>

          <Grid container xs={12} sm={12} spacing={2}>
            <Grid item xs={12} sm={12}>
              {subscriptionTranslation.Faqs.map((faq: {title: string, description: string}) => 
                <CustomAccordion>
                  <AccordionSummary
                    expandIcon={<AddIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    <Text size="mm" weight="lightBold" lineHeight="26px">{faq.title}</Text>
                  </AccordionSummary>
                  <AccordionDetails>
                    <Paragraph color="#161616" fontWeight={400} lineHeight="24px" fontSize="16px">{faq.description}</Paragraph>
                  </AccordionDetails>
                </CustomAccordion>)}
            </Grid>
          </Grid>
        </Section>
      </MainBox>
    )
    // Customizable Area End
  }
}

// Customizable Area Start
const webStyle = {
  chip: {
    display: "flex",
    width: "35%",
    marginLeft: 'auto',
    color: '#1D4ED8',
    backgroundColor: "#DBEAFE"
  },
  heading: {
    marginBottom: 32
  },
  plans: {
    borderRadius: 28,
    border: '1px solid #BFDBFE',
    maxWidth: 476
  },
  content: {
    paddingLeft: 42,
    paddingRight: 42,
    paddingBottom: 42
  },
  activePlan: {
    backgroundColor: "#EFF6FF"
  },
  choosePlanBtn1: {
    textTransform: "none" as const,
    borderRadius: 8,
    marginTop: 10,
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: '#1E3A8A',
    color: '#FFFFFF',
    padding: 10,
    boxShadow: 'none'
  },
  choosePlanBtn2: {
    textTransform: "none" as const,
    borderRadius: 8,
    marginTop: 10,
    fontSize: 14,
    fontWeight: 700,
    backgroundColor: '#EFF6FF',
    color: '#0F172A',
    padding: 10,
    boxShadow: 'none'
  },
  description: {
    listStyleType: 'disc',
    paddingLeft: 15,
    },
  descriptionItem: {
    fontSize: 14 as const,
    fontWeight: 400
  },
  services: {
    paddingBottom: 2,
    paddingLeft: 0
  },
  servicesIcon: {
    minWidth: 32 as const
  },
  faqHeading: {
    marginTop: 48,
    marginBottom: 32
  }
}
// Customizable Area End