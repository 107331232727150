import React from "react";
// Customizable Area Start
import ListingSummaryController, { Props } from "./ListingSummaryController.web";
import { Box, Select, MenuItem, Grid,Typography, Chip, Button, TextField, Divider, IconButton, Card, Breadcrumbs } from "@material-ui/core"
import { image, imgProfile } from "./assets";
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import { styled } from "@material-ui/core/styles";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
const languageTranslation = require('../../languagesupport/src/LanguageData/Listing.json')
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import DocView from "../../../components/src/DocView";
// Customizable Area End


// Customizable Area Start
const CustomBreadcrumbs = styled(Breadcrumbs)({
  maxWidth: "1200px",
  margin: '2rem auto 0px auto',
});

const CustomBox = styled(Box)({
  margin: '12px auto 0px auto',
  maxWidth: "1200px",
  paddingBottom: "20px"
});

const InfoBox = styled(Box) ({
  padding: '40px',
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 15px',
  marginTop: '20px',
  marginBottom: '100px',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const ProfileSection = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  gap: '12px',
});

const ServicesBox = styled(Box)({
  paddingTop: '40px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
});

const ChipBox = styled(Box)({
  display: 'flex',
  flexWrap: 'wrap',
  gap: '12px',
});

const OfferBox = styled(Box)({
  marginTop: '20px',
  padding: '24px',
  borderRadius: '24px',
  display: 'flex',
  flexDirection: 'column',
  gap: '24px',
  boxShadow: 'rgba(0, 0, 0, 0.15) 0px 3px 15px',
});

const StyledTextField = styled(TextField)({
  borderRadius: '8px',
  border: '1px solid #CBD5E1',
  marginBottom: '8px',
  '& .MuiInputBase-root': {
    fontSize: '16px',
    fontWeight: 600,
    color: '#94A3B8',
    wordSpacing: '6px',
  },
});
const MakeOfferButton = styled(Button)({
  fontSize: '16px',
  fontWeight: 700,
  backgroundColor: '#1E3A8A',
  color: 'white',
  padding: '10px 16px',
  borderRadius: '8px',
  textTransform: 'none',
});

const BoxOne = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  paddingTop: '40px',
  gap: '10px',
  flexWrap: 'wrap'
});

const customStyle = {
  apexHeading: {
    fontSize: '24px',
    fontWeight: 700,
    color: '#0F172A'
  },
  apexLink: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#334155',
    textDecoration: 'underline',
    textDecorationColor: '#334155'
  },
  boxOne: {
    display: 'flex',
    justifyContent: 'space-between',
    paddingTop: '40px',
    flexWrap: 'wrap',
    gap: '10px'
  },
  headOne: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#0F172A'
  },
  descriptionOne: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#334155'
  },
  headTwo: {
    fontWeight: 600,
    fontSize: '20px',
    color: '#0F172A'
  },
  chipStyle: {
    padding: '8px 16px',
    fontSize: '14px',
    backgroundColor: '#E2E8F0',
    fontWeight: 500
  },
  aboutCompany: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#0F172A'
  },
  boxTwo: {
    display: 'flex',
    gap: '24px',
    paddingTop: '40px',
  },
  aboutCompanyTwo: {
    fontWeight: 400,
    fontSize: '16px',
    color: '#0F172A'
  },
  portHEad: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#334155',
    marginLeft: '8px',
    marginBottom: '2px'
  },
  cardOne: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px 4px',
    gap: '16px',
  },
  cardTwo: {
    alignItems: 'center',
    display: 'flex',
    gap: '16px',
    padding: '8px 4px'
  },
  subBoxTwo: {
    backgroundColor: '#DBEAFE',
    display: 'flex',
    gap: '10px',
    padding: '16px',
    borderRadius: '8px'
  },
  docSize: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#64748B'
  },
  subBox3: {
    padding: '16px',
    display: 'flex',
    gap: '10px',
    borderRadius: '8px',
    backgroundColor: '#DBEAFE'
  },
  docSizeOne: {
    fontWeight: 400,
    color: '#64748B',
    fontSize: '16px'
  },
  makeOffer: {
    fontSize: '20px',
    fontWeight: 600,
    color: '#334155'
  },
  offerType: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#334155'
  },
  optionMenu: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#94A3B8'
  },
  amountValue: {
    fontSize: '14px',
    fontWeight: 700,
    color: '#334155'
  },
  dollorSign: {
    marginRight: '8px',
    fontSize: '16px',
    fontWeight: 700,
    color: '#64748B'
  },
  byuersProfileImage: {
    height:"100px",
    width:"100px",
    border:"2px solid #DBEAFE",
    borderRadius:"100px",
  },
  fileImage: {
    height:"24px",
    width:"24px",
    borderRadius:"8px"
  },
  cardWrapperBox : {
    display: 'flex', 
    flexDirection: 'column', 
    gap: '4px'
  }

}
// Customizable Area End

export class ListingSummary extends ListingSummaryController {

  constructor(props: Props) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area

  // Customizable Area End

  render() {
    // Customizable Area Start
    const { currentLanguage } = this.state;
    const languageData = languageTranslation[currentLanguage];
    return (
      <Box >
        <CustomBreadcrumbs data-test-id="breadCrumsTest" separator={<NavigateNextIcon fontSize="small" />}>
          <Typography data-test-id="homePage" color="inherit" onClick={() => this.handleNavigateForUserList('LandingPage')}>{languageData.Home}</Typography>
          <Typography data-test-id="servicePage" color="inherit" onClick={() => this.handleNavigateForUserList('Service')}>{languageData.MarketPlace}</Typography>
          <Typography color="textPrimary">{languageData.BuyersProfile}</Typography>
        </CustomBreadcrumbs>
        
        <CustomBox>
        <Grid container spacing={4}>
          <Grid item md={9} style={{ margin: "0px auto" }}>
          <InfoBox>
            <ProfileSection>
              <img style={customStyle.byuersProfileImage} src={this.state.businessProfile.attributes.image?.url || imgProfile} alt="" />
              <Box>
                <Typography style={customStyle.apexHeading}>{this.state.businessProfile.attributes.name}</Typography>
                <Typography data-test-id="testWebId" style={customStyle.apexLink}>
                  {this.state.businessProfile.attributes.website}
                </Typography>
              </Box>
            </ProfileSection>
            <Divider />
            <BoxOne>
              <Box>
                <Typography style={customStyle.headOne}>{this.state.businessProfile.attributes.industry}</Typography>
                <Typography style={customStyle.descriptionOne}>{languageData.IndustrySector}</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>{this.state.businessProfile.attributes.registration}</Typography>
                <Typography style={customStyle.descriptionOne}>{languageData.RegistrationNumber}</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>{this.state.businessProfile.attributes.country}</Typography>
                <Typography style={customStyle.descriptionOne}>{languageData.Country}</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>{this.state.businessProfile.attributes.state}</Typography>
                <Typography style={customStyle.descriptionOne}>{languageData.State}</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>{this.state.businessProfile.attributes.city}</Typography>
                <Typography style={customStyle.descriptionOne}>{languageData.City}</Typography>
              </Box>
              <Box>
                <Typography style={customStyle.headOne}>{this.state.businessProfile.attributes.zip}</Typography>
                <Typography style={customStyle.descriptionOne}>{languageData.Zip}</Typography>
              </Box>
            </BoxOne>
            <Divider />
            <ServicesBox>
              <Typography style={customStyle.headTwo}>{languageData.ServicesOffered}</Typography>
              <Grid container spacing={2}>
                {this.state.businessProfile.attributes.services.map((serviceData, index) => (
                  <Grid item key={index}>
                    <Chip label={serviceData} style={customStyle.chipStyle} />
                  </Grid>
                ))}
              </Grid>
            </ServicesBox>
            <Divider />
            <ServicesBox>
              <Typography style={customStyle.headTwo}>{languageData.Bio}</Typography>
              <Typography style={customStyle.aboutCompany}>
                {this.state.businessProfile.attributes.bio}
              </Typography>
            </ServicesBox>
            <Divider />
            <Grid container spacing={4}>
              {this.state.businessProfile.attributes.documents.map((card, cIndex: number) => (
                <Grid key={cIndex} item xs={"auto"} sm={6} md={6} lg={6}>
                  <DocView imageUrl={card.url} name={card.file_name} size={card.size} />
                </Grid>
              ))}
            </Grid>
          </InfoBox>
          </Grid>
          <Grid item md={3}  style={{ margin: "0px auto" }}>
          <OfferBox>
            <Snackbar
              data-test-id="closemenumodalID"
              open={this.state.openSnackbar}
              autoHideDuration={6000}
              onClose={this.handleSnackbarClose}
            >
              <Alert onClose={this.handleSnackbarClose} severity="success">
                {this.state.successMessage}
              </Alert>
            </Snackbar>
            <Typography variant="h6" style={customStyle.makeOffer}>{languageData.MakeOffer}</Typography>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography style={customStyle.offerType}>{languageData.OfferType}</Typography>
              <Select
                data-test-id="offer-type"
                defaultValue={"1"}
                variant="outlined"
                fullWidth style={customStyle.optionMenu}
                displayEmpty
                IconComponent={ExpandMoreIcon}
                value={this.state.offerType}
                name="offerType"
                onChange={this.handleSelectChange}>
                <MenuItem value="1" disabled>{languageData.SelectOfferType}</MenuItem>
                <MenuItem value="2">Offer type 1</MenuItem>
                <MenuItem value="3">Offer type 2</MenuItem>
              </Select>
            </Box>
            <Box style={{ display: 'flex', flexDirection: 'column', gap: '4px' }}>
              <Typography style={customStyle.amountValue}>{languageData.EnterAmount}</Typography>
              <TextField
                data-test-id="amountId"
                variant="outlined"
                fullWidth
                InputProps={{
                  startAdornment: (
                    <Box component="span" style={customStyle.dollorSign}>$</Box>
                  ),
                }}
                placeholder="0.00"
                style={customStyle.makeOffer}
                value={this.state.amount}
                name="amount"
                onChange={(event) => this.handleInputChange('amount', event.target.value)} />
            </Box>
            <StyledTextField
            data-test-id="pitchId"
              fullWidth
              multiline
              placeholder="Describe the details of your debt"
              minRows={12}
              variant="outlined"
              value={this.state.pitch}
              name="pitch"
              onChange={(event) => this.handleInputChange('pitch', event.target.value)}/>
            <MakeOfferButton
            data-test-id="make-offer"
              variant="contained"
              fullWidth
              onClick={this.handleMakeOffer}>
              {languageData.MakeOffer}
            </MakeOfferButton>
          </OfferBox>
          </Grid>

        </Grid>
        </CustomBox>
      </Box>
    )
    // Customizable Area End
  }
}
export default (ListingSummary)