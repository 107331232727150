import React from "react";
// Customizable Area Start
import {
    withStyles, StyleRules, styled
} from "@material-ui/core/styles";
import UserManagementController, { Props } from "./UserManagementController.web";
import { Box, IconButton, Menu, Modal, Typography, Select, Button, MenuItem, TextField, TableContainer, Paper, InputLabel, Avatar, FormControl } from "@material-ui/core";
import { UserDeleteProfile } from "./assets";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { Skeleton } from "@material-ui/lab";
import Pagination from "@material-ui/lab/Pagination";
import FilterListRoundedIcon from '@material-ui/icons/FilterListRounded';
import CloseRoundedIcon from '@material-ui/icons/CloseRounded';
import MoreVertIcon from "@material-ui/icons/MoreVert";
const languageTranslationData = require('../../languagesupport/src/LanguageData/Signup.json')
import KeyboardArrowDownRoundedIcon from "@material-ui/icons/KeyboardArrowDownRounded";
import SearchIcon from "@material-ui/icons/Search";
import UserProfileDrawer from "../../../components/src/design-system/UserProfileDrawer/UserProfileDrawer.web";
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
const configJSON = require("./config");
// Customizable Area End

const styles: StyleRules = {
    // Customizable Area Start

    // Customizable Area End
}

export class UserManagement extends UserManagementController {
    constructor(props: Props) {
        super(props);
        // Customizable Area Start
        // Customizable Area End
    }
    // Customizable Area Start
    renderFilter = () => {
        const { currentLanguage } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        return (
            <Box data-test-id="renderFilterUiId" style={classes.filterWrapper}>
                <Box style={classes.filterCloseBox}>
                    <Box style={classes.filterHeadingBox}>
                        <FilterListRoundedIcon style={{ width: "20px" }} />
                        {languageData.Filter}
                    </Box>
                    <IconButton data-test-id="filterCloseTestID" onClick={this.handleCloseFilter}>
                        <CloseRoundedIcon style={{ width: "20px", color: "#0F172A" }} />
                    </IconButton>
                </Box>
                <Box style={classes.filterMiddleBox}>
                    <Box>
                        <FilterResetWrapper>
                            <Button data-test-id="date-reset-button" onClick={() => this.handleReset("date")}>{languageData.Reset}</Button>
                        </FilterResetWrapper>
                        <Box>
                            <Typography style={{ ...classes.filterLabel, marginBottom: "4px" }}>{languageData.DateRegistered}</Typography>
                            <Box style={{ display: "flex", gap: "20px" }}>

                                <Box>
                                    <Typography style={classes.filterDateLabel}>{languageData.From} :</Typography>
                                    <FilterDateField
                                        type="date"
                                        variant="outlined"
                                        // label={this.state.filteredData.fromDate}
                                        // inputProps={{ value: this.state.filteredData.fromDate }}
                                        InputLabelProps={{ shrink: true }}
                                        value={this.state.filteredData.fromDate}
                                        onChange={e => this.onFilterChange(e, "fromDate", "date")}
                                    />
                                </Box>
                                <Box>
                                    <Typography style={classes.filterDateLabel}>{languageData.To} :</Typography>
                                    <FilterDateField
                                        type="date"
                                        variant="outlined"
                                        value={this.state.filteredData.toDate}
                                        onChange={e => this.onFilterChange(e, "toDate", "date")}
                                    />
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                    <Box>
                        <FilterResetWrapper>
                            <Button
                                data-test-id="listingResetTestID"
                                onClick={() => this.handleReset("status")}
                            >
                                {languageData.Reset}
                            </Button>
                        </FilterResetWrapper>
                        <Typography style={{ ...classes.filterLabel, marginBottom: "4px" }}>{languageData.VerificationStatus}</Typography>
                        <FormControl fullWidth>

                            <FilterSelect
                                fullWidth
                                data-test-id="listTypeTestID"
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                variant="outlined"
                                name="UserManagement"
                                defaultValue={"select"}
                                style={{ textTransform: "capitalize" }}
                                value={this.state.filteredData.status}
                                onChange={e => this.onFilterChange(e, "status", "status")}
                            >
                                {
                                    configJSON.verificationStatus.map((item:string, index: number) => {
                                        return <MenuItem disabled={index == 0} style={{ textTransform: "capitalize" }} key={index} value={item}>{item}</MenuItem>
                                    })
                                }
                            </FilterSelect>
                        </FormControl>
                    </Box>
                    <Box>
                        <FilterResetWrapper>
                            <Button
                                data-test-id="statusResetTestID"
                                onClick={() => this.handleReset("plan")}
                            >
                                {languageData.Reset}
                            </Button>
                        </FilterResetWrapper>
                        <Typography style={{ ...classes.filterLabel, marginBottom: "4px" }}>{languageData.SubscriptionPlan}</Typography>
                        <FormControl fullWidth>
                            <FilterSelect
                                fullWidth
                                data-test-id="statusTestID"
                                IconComponent={KeyboardArrowDownRoundedIcon}
                                variant="outlined"
                                defaultValue={"select"}
                                value={this.state.filteredData.plan}
                                name="status"
                                onChange={e => this.onFilterChange(e, "plan", "status")}
                            >
                                    <MenuItem disabled value={"select"}>{"Select"}</MenuItem>
                                    {
                                        this.state.subscriptions?.map((item: any, index: number) => {
                                            return <MenuItem key={index} value={item.id}>{item?.attributes?.name}</MenuItem>
                                        })
                                    }
                            </FilterSelect>
                        </FormControl>
                    </Box>
                </Box>
                <Box style={classes.filterBottomBox}>
                    <ResetButton
                        data-test-id="filterResetButtonTestID"
                        onClick={() => this.handleReset("all")}
                    >
                        {languageData.Reset}
                    </ResetButton>
                    <CreateButton
                        data-test-id="applyNowTestID"
                        style={{ fontSize: "14px", height: "44px" }}
                        onClick={this.handleFilterApply}
                    >
                        {languageData.ApplyNow}
                    </CreateButton>
                </Box>
            </Box>
        );
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        const { currentLanguage, dot, pagination } = this.state;
        const languageData = languageTranslationData[currentLanguage];
        const results = (pagination.current_page - 1) * this.state.rowsPerPage;
        console.log("pagination", pagination)

        return (
            <UserProfileDrawer
                handleClick={this.handleDrawerNavigation1}
                pageName={this.state.userType === "consumer" ? "UserProfile" : "dashboardUserManagement"}
                data-test-id="drawerTestID"
                userType={this.state.userType === "consumer" ? "consumer" : "admin_account"}
                navigation={this.props.navigation}
            >
                <Box style={classes.RequestSuperBox}>
                    <Typography style={classes.typographyHeader}>{languageData.UserManagement}</Typography>
                    <Box style={{ background: "#FFFFFF", width: "100%", borderRadius: "12px", boxShadow: "rgba(17, 17, 26, 0.1) 0px 4px 16px, rgba(17, 17, 26, 0.05) 0px 8px 32px", padding: "16px", margin: "20px 0 80px 0", }}>

                        <TableUpBox>
                            <Box style={classes.searchFieldWrapper}>
                                <SearchIcon style={classes.searchIcon} />
                                <SearchTextField
                                    id="blankInput"
                                    data-test-id="searchInputTestID"
                                    fullWidth
                                    variant="outlined"
                                    value={this.state.searchTerm}
                                    onChange={(e) => this.handleSearch(e)}
                                    placeholder={languageData.Search}
                                    inputProps={{ 'aria-label': 'description' }}
                                />
                            </Box>
                            <Box style={{ width: "348px", position: "relative", display: "flex", justifyContent: "end" }}>
                                <FilterButton
                                    style={{ height: "44px" }}
                                    data-test-id="filterButtonTestID"
                                    onClick={this.handleToggleFilter}
                                >
                                    <Box style={classes.createButton}>
                                        <FilterListRoundedIcon />
                                        {languageData.Filter}
                                    </Box>
                                </FilterButton>
                                {this.state.showFilter && this.renderFilter()}
                            </Box>
                        </TableUpBox>

                        <TableContainer style={{ boxShadow: "none", width: "100%", overflow: "auto" }} component={Paper}>
                            <Table style={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <CustomHeadingRow>
                                        <TableCell>{languageData.User}</TableCell>
                                        <TableCell>{languageData.Email}</TableCell>
                                        <TableCell>{languageData.CreationDate}</TableCell>
                                        <TableCell>{languageData.LastLogin}</TableCell>
                                        <TableCell colSpan={2} style={{ textAlign: currentLanguage === "ar" ? "revert" : "left" }}>{languageData.VerificationStatus}</TableCell>
                                        <TableCell></TableCell>
                                    </CustomHeadingRow>
                                </TableHead>
                                {
                                    this.state.loading ?
                                        <TableBody>
                                            {
                                                Array.from(Array(this.state.rowsPerPage)).map((item, index) => {
                                                    return (
                                                        <TableRow key={index} style={{ verticalAlign: "text-top" }}>
                                                            <TabelBodyCell><Skeleton /></TabelBodyCell>
                                                            <TabelBodyCell><Skeleton /></TabelBodyCell>
                                                            <TabelBodyCell><Skeleton /></TabelBodyCell>
                                                            <TabelBodyCell><Skeleton /></TabelBodyCell>
                                                            <TabelBodyCell><Skeleton /></TabelBodyCell>
                                                            <TabelBodyCell><Skeleton /></TabelBodyCell>
                                                        </TableRow>
                                                    )
                                                })
                                            }
                                            
                                        </TableBody>
                                        :
                                        <TableBody>
                                            {this.state.users.slice(0, 10).map((user) => (
                                                <CustomBodyRow key={user.id}>
                                                    <TableCell>
                                                        <Box style={classes.UserProfileCell}>
                                                            <UserAvatar src="" alt="user" />
                                                            {user.attributes.full_name || ""}
                                                        </Box>
                                                    </TableCell>
                                                    <TableCell>{user.attributes.email || ""}</TableCell>
                                                    <TableCell>{this.convertTimestampToCreatedAt(user.attributes.created_at).date}</TableCell>
                                                    <TableCell>3 Feb 2024</TableCell>
                                                    <TableCell>
                                                        <span
                                                            style={{
                                                                ...classes.statusStyle,
                                                                textTransform: "capitalize"
                                                            }}
                                                        >
                                                            {user.attributes.signup_status}
                                                        </span>
                                                    </TableCell>
                                                    <TableCell style={{ position: "relative" }}>

                                                        <IconButton onClick={this.handleClick} data-id={user.id} data-test-id="publishedMoreButtonTestID">
                                                            <MoreVertIcon id="moreIcon" style={classes.moreOpStyle} />
                                                        </IconButton>
                                                        <Menu
                                                            id="menu-view"
                                                            anchorEl={dot}
                                                            open={Boolean(dot)}
                                                            onClose={this.handleClose}
                                                            style={{ position: "absolute", top: "10px" }}>
                                                            <MenuItem id="menu-view" onClick={() => this.handleNavigation('dashboardUserProfile')} style={{ color: "#0F172A", fontSize: "14px", fontWeight: 400 }}>{languageData.View}</MenuItem>
                                                            <MenuItem id="menu-archive" data-user_id={user.id} onClick={(e) => {
                                                                this.handleFirstModalOpen();
                                                                this.handleClose();
                                                            }}
                                                                style={{ color: "#DC2626", fontSize: "14px", fontWeight: 400, display: "flex", alignItems: "center", gap: "8px" }}><img style={{ height: "18px", width: "18px" }} src={UserDeleteProfile} />{languageData.DeleteProfile}</MenuItem>
                                                        </Menu>
                                                    </TableCell>
                                                </CustomBodyRow>
                                            ))}
                                        </TableBody>
                                }
                            </Table>
                        </TableContainer>
                        <Box style={classes.mainResultBox}>
                            <Box style={classes.paginationBox}>
                                <CustomPagination
                                    data-test-id="tablePaginationTestID"
                                    page={pagination.current_page}
                                    count={pagination.total_pages}
                                    onChange={this.handlePageChange}
                                    shape="rounded"
                                    variant="outlined"
                                    siblingCount={0}
                                    boundaryCount={1}
                                />
                            </Box>
                            <CustomResultBox>
                                <Typography style={{ color: "#64748B", fontSize: "12px" }}>
                                    {`${results + 1} - ${results + this.state.rowsPerPage > pagination.total_count ? pagination.total_count : results + this.state.rowsPerPage} of ${pagination.total_count} results`}
                                </Typography>
                            </CustomResultBox>
                        </Box>
                    </Box>
                    <StyledModal
                        open={this.state.isFirstModalOpen}
                        onClose={this.handleFirstModalClose}
                        closeAfterTransition
                        BackdropProps={{ timeout: 500 }}>
                        <ModalContent>
                            <Box style={classes.umModalBox}>
                                <CloseButton onClick={this.handleFirstModalClose}>
                                    <CloseRoundedIcon onClick={this.handleFirstModalClose} />
                                </CloseButton>
                                <DeleteProfile>
                                    {languageData.DeleteProfile}
                                </DeleteProfile>
                                <ProfileDes>
                                    {languageData.DeleteMessage}
                                </ProfileDes>
                            </Box>
                            <ButtonWrapperBox>
                                <CancelButton id="modalCancle" onClick={this.handleFirstModalClose} variant="contained">
                                    {languageData.Cancel}
                                </CancelButton>
                                <DeleteButton id="modalConfirm" onClick={() => {
                                    this.deleteUserAccount(this.state.deleteUserId || 0)
                                    this.handleFirstModalClose();
                                }} variant="contained">
                                    {languageData.DeleteProfile}
                                </DeleteButton>
                            </ButtonWrapperBox>
                        </ModalContent>
                    </StyledModal>
                    <Snackbar
                        data-test-id="closemenumodalID"
                        open={this.state.snackbar.open}
                        autoHideDuration={3000}
                        onClose={this.handleSnackbarClose}
                        >
                        <Alert onClose={this.handleSnackbarClose} severity={this.state.snackbar.type}>
                            {this.state.snackbar.message}
                        </Alert>
                    </Snackbar>
                </Box>
            </UserProfileDrawer>
        );
        // Customizable Area End
    }
}

// Customizable Area Start

export default withStyles(styles)(UserManagement);

const CustomPagination = withStyles({
    ul: {
      '& .MuiPaginationItem-root': {
        color: '#0F172A',
        border: 'none',
      },
      '& .Mui-selected': {
        color: '#1D4ED8',
        fontWeight: 'bold',
        background: 'none',
      },
      '& .MuiPaginationItem-ellipsis': {
        color: '#1F2937',
      },
      '& .MuiPaginationItem-icon': {
        color: '#0F172A',
      },
      '& .MuiPaginationItem-page:not(.Mui-selected)': {
        textDecoration: 'underline',
        textUnderlineOffset: '3px',
      }
    }
  })(Pagination);

const DeleteButton = styled(Button)({
    width: "248px",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    backgroundColor: '#1E3A8A',
    fontSize: '16px',
    fontWeight: 700,
    color: '#FFFFFF',
    textTransform: 'none'
})
const CancelButton = styled(Button)({
    width: "248px",
    height: "56px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: '8px',
    backgroundColor: '#EFF6FF',
    fontWeight: 700,
    fontSize: '16px',
    color: '#0F172A',
    textTransform: 'none'
})
const DeleteProfile = styled(Typography)({
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: "28px",
    color: '#0F172A',
    position: "relative",
    top: "32px",
    left: "32px"
})
const ProfileDes = styled(Typography)({
    width: "462px",
    fontSize: '14px',
    fontWeight: 400,
    color: '#475569',
    lineHeight: "22px",
    position: "relative",
    top: "50px",
    left: "32px"
})
const ButtonWrapperBox = styled(Box)({
    display: 'flex',
    gap: '16px',
    width: '560px',
    height: "104px",
    padding: '24px'
})
const TabelBodyCell = styled(TableCell)({
    padding: "26px 32px",
    fontSize: "14px",
    fontWeight: 600,
    color: "#252733"
})
const FilterSelect = styled(Select)(({ theme }) =>({
    color: "#0F172A",
    fontSize: "14px",
    fontWeight: 400,
    height: "44px",
    background: "transparent",
    "& .MuiOutlinedInput-notchedOutline": {
        borderRadius: "8px",
        border: "1px solid #CBD5E1"
    },
    "&:hover .MuiOutlinedInput-notchedOutline": {
        borderColor: "#CBD5E1",
        background: "transparent"
    },
    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
        border: "1px solid #CBD5E1",
        background: "transparent"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A",
        borderColor: "#CBD5E1",
        "& fieldset": {
            borderColor: "#CBD5E1 !important"
        }
    },
    '& .MuiSelect-select': {
        color: '#94A3B8', 
      },
      '&.Mui-focused .MuiSelect-select': {
        color: 'inherit',
      },
}));


const StyledModal = styled(Modal)(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}))

const ModalContent = styled(Box)({
    position: 'absolute',
    borderRadius: '20px',
    boxShadow: "0px 8px 32px 0px #0000000F",
    background: "#FFFFFF",
    height: '234px',
    width: '560px',
})
const CloseButton = styled(IconButton)(({ theme }) => ({
    position: 'absolute',
    top: theme.spacing(1),
    right: theme.spacing(1),
}));

const FilterButton = styled(Button)({
    padding: "10px 16px",
    borderRadius: "8px",
    border: "1px solid #CBD5E1",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    textTransform: "capitalize",

});

const CustomHeadingRow = styled(TableRow)({
    background: "#E2E8F0",
    "& .MuiTableCell-root": {
        color: "#64748B",
        fontWeight: 600,
        fontSize: "14px",
        padding: "20px 16px 8px",
        whiteSpace: "nowrap"
    }
});
const CustomBodyRow = styled(TableRow)({
    height: "54px",
    "& .MuiTableCell-root": {
        fontSize: "12px"
    }
});

const SearchTextField = styled(TextField)({
    flexGrow: 1,
    maxWidth: "320px",
    '& .MuiOutlinedInput-notchedOutline': {
        border: 'none'
    },
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            border: "none"
        },
        "&:focus-within": {
            "& fieldset": {
                border: "none !important",
                boxShadow: "none !important"
            }
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});
const CustomResultBox = styled(Box)({
    "@media(max-width: 540px)": {
        width: "100%",
        display: "flex",
        justifyContent: "flex-end"
    }
});
const TableUpBox = styled(Box)({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "26px",
    gap: "20px",
    flexWrap: "wrap"
});
const UserAvatar = styled(Avatar)({
    height: "40px",
    width: "40px",
    border: "1px solid #D9D9D9",
    borderRadius: "100px",
    objectFit: "cover"

});
const ResetButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "#0F172A",
    height: "44px",
    backgroundColor: "#EFF6FF",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#EFF6FF"
    }
});

const FilterResetWrapper = styled(Box)({
    display: "flex",
    justifyContent: "flex-end",
    "& .MuiButton-root": {
        textTransform: "capitalize",
        color: "#1E3A8A",
        fontSize: "14px",
        fontWeight: 700
    }
});

const FilterDateField = styled(TextField)({
    "& .MuiOutlinedInput-root": {
        "& fieldset": {
            borderRadius: "8px",
            border: "1px solid #CBD5E1",
            top: "0px"
        }
    },
    "& .MuiInputBase-input": {
        height: "10px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        top: "0px",
        "&.Mui-disabled": {
            WebkitTextFillColor: "#0F172A"
        }
    },
    "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
    }
});

const FilterInputLabel = styled(InputLabel)({
    fontSize: "16px",
    color: "#94A3B8",
    opacity: 1,
    paddingLeft: "14px",
    top: "-5px",
    "&.Mui-focused": {
        display: "none"
    },
    "&.Mui-disabled": {
        WebkitTextFillColor: "#0F172A"
    }
});
const CreateButton = styled(Button)({
    fontSize: "16px",
    fontWeight: 700,
    color: "white",
    height: "56px",
    backgroundColor: "#1E3A8A",
    borderRadius: "8px",
    textTransform: "capitalize",
    padding: "10px 16px",
    width: "fit-content",
    "&:hover": {
        backgroundColor: "#1E3A8A"
    }
});
const classes = {

    RequestSuperBox: {
        padding: "0px 24px 0px 0px"
    },
    typographyHeader: {
        fontWeight: "700", fontSize: "30px", lineHeight: "44px", letterSpacing: "0.5%", color: "#0F172A",

    },
    verificationBox: {
        height: '80vh', width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'

    },
    verificationInnerBox: {
        display: 'flex', gap: '20px', flexDirection: 'column', alignItems: 'center', height: '328px', width: '287px'

    },
    groupImg: {
        height: "166px", width: '150px'

    },
    verificationText: {
        fontSize: '18px', fontWeight: '500', textAlign: 'center', color: '#000000', lineHeight: "26px"

    },
    requestButton: {
        padding: "10px 16px", borderRadius: '8px', backgroundColor: '#1E3A8A', fontSize: '14px', fontWeight: '700', color: '#FFFFFF', textTransform: 'none'
    },
    tableHeading: {
        fontWeight: 600,
        fontSize: "18px",
        whiteSpace: "nowrap"
    },
    searchButtonWrapper: {
        display: "flex",
        alignItems: "center",
        gap: "20px",
        flexGrow: 1,
        width: "320px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    searchIconStyle: {
        color: "#94A3B8"
    },
    textFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        height: "44px",
        width: "320px"
    },
    moreOpStyle: {
        color: "#64748B",
        cursor: "pointer"
    },
    statusStyle: {
        borderRadius: "25px",
        padding: "9px 10px 5px",
        fontSize: "12px",
        fontWeight: 700,
        backgroundColor: "#D1FAE5",
        color: '#059669'
    },
    filterBtn: {
        height: "10px",
        width: "15px"
    },
    mainResultBox: {
        display: "flex",
        flexWrap: "wrap",
        alignItems: "center",
        paddingTop: "16px"
    } as React.CSSProperties,

    paginationBox: {
        display: "flex",
        flexGrow: 1,
        justifyContent: "center"
    },
    buttonMainBox: {
        display: "flex",
        gap: "16px",
        width: "325px",
        alignItems: "center",
    },

    drawerPaper: {
        padding: '16px',
        width: '320px',
    },
    filterBoxHeader: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        marginBottom: '16px',
    },
    formControl: {
        marginBottom: '16px',
    },
    actionButtons: {
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '16px',
    },
    inputBox: {
        display: 'flex',
        gap: '16px',
        width: '334px',
        height: '70px',
    },
    label: {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontWeight: 700,
        lineHeight: '22px',
        textAlign: 'left',
    },
    inputField: {
        width: '159px',
        height: '44px',
        gap: '8px',
        border: "1px solid #CBD5E1",
        borderRadius: "8px"
    },
    inputRoot: {
        width: '320px',
        height: '44px',
        borderRadius: '8px',
        border: '1px solid #CBD5E1',

        '& .MuiInputBase-root': {
            height: '100%',
        },
        '& .MuiOutlinedInput-root': {
            paddingRight: '8px',
            '& fieldset': {
                borderColor: 'transparent',
            },
            '&:hover fieldset': {
                borderColor: 'transparent',
            },
            '&.Mui-focused fieldset': {
                borderColor: 'transparent',
            },
        },
    },
    searchIcon: {
        width: '17.05px',
        height: '17.06px',
        marginTop: '3.47px',
        marginLeft: '3.48px',
        color: "#94A3B8"

    },
    placeholder: {
        fontFamily: 'Inter',
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        textAlign: 'left',
        color: '#94A3B8',
    },
    vMainBox: {
        width: '590px',
        height: '154px',
        display: 'flex',
        gap: '20px',
        marginTop: '48px',
    },

    vImg: {
        height: '24px',
        width: '24px',
    },

    vApprovedText: {
        color: '#94A3B8',
        lineHeight: '26px',
        fontSize: '18px',
        fontWeight: '500',
    },
    vApprovedNumber: {
        color: '#0F172A',
        lineHeight: '32px',
        fontSize: '24px',
        fontWeight: '600',
        letterSpacing: '-0.5%',
    },
    UserProfileCell: {
        display: "flex",
        alignItems: "center",
        gap: "8px"
    },
    filterHeadingBox: {
        fontSize: "14px",
        fontWeight: 600,
        display: "flex",
        alignItems: "center",
        color: "#0F172A",
        gap: "8px"
    },
    filterLabel: {
        fontWeight: 700,
        fontSize: "14px",
        color: "#334155"
    },
    filterBottomBox: {
        display: "flex",
        justifyContent: "space-between",
        gap: "20px",
        padding: "20px"
    },
    filterMiddleBox: {
        display: "flex",
        gap: "10px",
        flexDirection: "column",
        padding: "10px 20px 20px",
        borderBottom: "1px solid #CBD5E1"
    } as React.CSSProperties,
    filterCloseBox: {
        padding: "5px 20px",
        display: "flex",
        justifyContent: "space-between",
        borderBottom: "1px solid #CBD5E1",
        alignItems: "center"
    },
    filterWrapper: {
        width: "350px",
        display: "flex",
        flexDirection: "column",
        border: "1px solid #CBD5E1",
        borderRadius: "12px",
        position: "absolute",
        top: "56px",
        background: "white",
        zIndex: 10,
        right: "0px",
    } as React.CSSProperties,

    createButton: {
        display: 'flex',
        gap: "10px"
    },
    searchFieldWrapper: {
        display: "flex",
        flexGrow: 1,
        border: "1px solid #CBD5E1",
        borderRadius: "8px",
        alignItems: "center",
        padding: "10px 8px",
        height: "23px",
        maxWidth: "400px"
    },
    filterDateLabel: {
        color: "#334155",
        fontWeight: 600,
        fontSize: "12px",
    },
    umModalBox: {
        borderBottom: "1px solid #E2E8F0",
        height: "130px",
        width: '560px'
    },

}
// Customizable Area End
