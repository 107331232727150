import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import React from "react";
import { getStorageData, removeStorageData } from "framework/src/Utilities";
import { bellIcon, editProfile, listIcon, sheetIcon } from "./assets";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
const configJSON = require("./config");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json')
type FileArray = (File | DocResponse | null)[];

interface BusinessDeal {
    name: string;
    description: string;
    price: string;
    category_id: string;
    sub_category_id: string;
    quantity: string;
    terms: string;
    verification_request_id: string;
  }
  interface DocResponse {
    file_name: string;
    url: string;
    size: string;
};

  type Company = {
    id: number;
    user_name: string | null;
    address: string | null;
    city: string;
    state: string;
    country: string;
    zipcode: string | null;
    business_name: string | null;
    tax_number: string | null;
    kyc_name: string | null;
    kyc_number: string | null;
    kyc_verified: string | null;
  };
  
  type Timeline = {
    id: number;
    desc: string;
    verification_request_id: number;
    created_at: string;
    updated_at: string;
  };
  
  type RequestVerificationDetail = {
    id: number;
    type: string;
    attributes: {
      uuid: string;
      full_name: string;
      status: string;
      registration_number: string;
      verification_type: string;
      request_date: string;
      details: string;
      Company: Company;
      timelines: Timeline[];
    };
  };

interface Category {
    id: string;
    type: string;
    attributes: {
        id: number;
        name: string;
        sub_categories: SubCategory[];
    };
};
    
interface SubCategory {
    id: number;
    name: string;
};

interface ValidResponse {
    data: Category[] | RequestVerificationDetail | BusinessDeal;
};
// Customizable Area End


export interface Props {
    navigation: any;
    id: string;
    classes: ClassNameMap<string>;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    menu: {
        icon: string,
        title: string,
        route: string,
        tabs: {
            title: string,
            route: string
        }[]
    }[];
    providerMenu: {
        icon: string,
        title: string,
        route: string,
        tabs: {
            title: string,
            route: string
        }[]
    }[];
    type: string,
    currentLanguage: 'en' | 'ar'
    cancelModal: boolean,
    isModalOpen: boolean,
    isSubmit : boolean,
    business_deal: BusinessDeal;
    data: RequestVerificationDetail;
    portfolioArray:FileArray;
    supportDocArray: (File | null)[];
    nameError: string;
    descriptionError: string;
    priceError: string;
    categoryIdError: string;
    subCategoryIdError: string;
    quantityError: string;
    termsError: string;
    documentErr: string;
    checkSave: boolean;
    otherDocArray: (File | null)[];
    categories: Category[];
    subCategories: SubCategory[];
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class UserProfileWebController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    fetchRequestCallId:string = "";
    fetchRequestPostCallId:string = "";
    getCategoriesCallId:string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        // Customizable Area Start
        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            type: "consumer",
            currentLanguage: 'en',
            menu: this.Menu('en'),
            providerMenu: this.ProvideMenu('en'),
            cancelModal: false,
            isModalOpen: false,
            isSubmit : false,
            business_deal: {
                name: '',
                description: '',
                price: "",
                category_id: "",
                sub_category_id: "",
                quantity: "",
                terms: '',
                verification_request_id: "",
            },
              data: {
                "id": 0,
                "type": "",
                "attributes": {
                    "uuid": "",
                    "full_name": "",
                    "status": "",
                    "registration_number": "",
                    "verification_type": "",
                    "request_date": "",
                    "details": "",
                    "Company": {
                        "id": 0,
                        "user_name": null,
                        "address": null,
                        "city": "",
                        "state": "",
                        "country": "",
                        "zipcode": null,
                        "business_name": null,
                        "tax_number": null,
                        "kyc_name": null,
                        "kyc_number": null,
                        "kyc_verified": null
                    },
                    "timelines": [
                        {
                            "id": 0,
                            "desc": "",
                            "verification_request_id": 0,
                            "created_at": "",
                            "updated_at": ""
                        }
                    ],
                }
            },
            portfolioArray:Array(2).fill(null),
            supportDocArray: Array(2).fill(null),
            nameError: "",
            descriptionError: '',
            priceError: "",
            categoryIdError: "",
            subCategoryIdError: "",
            quantityError: "",
            termsError: '',
            documentErr: "",
            checkSave: false,
            otherDocArray: Array(2).fill(null),
            categories: [],
            subCategories: [],
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
              getName(MessageEnum.RestAPIResponceDataMessage)
            );
      
            let responseJson = message.getData(
              getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
    
            if (apiRequestCallId && responseJson) {
              if (!responseJson.errors) {
                this.apiSuccessCallBack(apiRequestCallId, responseJson);
              }
            }
        }
        // Customizable Area End
    }
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.fetchRequest(await getStorageData("selectedUser"))
        this.fetchRequest(await getStorageData("selectedRequest"));
        this.getCategories();
        this.setState({
            menu: this.Menu(language),
            providerMenu: this.ProvideMenu(language),
            currentLanguage: language,
            type: await getStorageData("userType")
        });
        // Customizable Area End
    }
    // Customizable Area Start
    
    apiSuccessCallBack = (apiRequestCallId: string, responseJson: ValidResponse) => {
        if ( apiRequestCallId === this.fetchRequestCallId) {
            this.setState({
                data : responseJson.data as RequestVerificationDetail,
                business_deal : responseJson.data as BusinessDeal
            })
        }
        if ( apiRequestCallId === this.fetchRequestPostCallId) {
            this.setState({
                checkSave: false,
                isModalOpen: false,
                business_deal: {
                    name: '',
                    description: '',
                    price: "",
                    category_id: "",
                    sub_category_id: "",
                    quantity: "",
                    terms: '',
                    verification_request_id: "",
                },
                supportDocArray: Array(2).fill(null)
            });
        }
        if ( apiRequestCallId === this.getCategoriesCallId) {
            this.setState({
                categories: responseJson.data as Category[]
            });
        }
    };

    getCategories = () => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getCategoriesCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.categoryEndPoint
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    navigationTrack = [
        {
            title: "Profile",
            link: "UserProfile"
        },
        {
            title: "Notification",
            link: "UserNotification"
        },
        {
            title: "Requests",
            link: "UserRequests"
        },
        {
            title: "My Listing",
            link: "UserMyListing"
        },
        {
            title: "Create Listing",
            link: "UserCreateListing"
        },
        {
            title: "My Offers",
            link: "UserMyOffers"
        }
    ];

    Menu = (language: string) => [
        {
            icon: editProfile,
            tabs: [],
            title: languageTranslationData[language].Profile,
            route: "UserProfile"
        },
        {
            icon: bellIcon,
            tabs: [],
            title: languageTranslationData[language].Notification,
            route: "UserNotification"
        },
        {
            icon: sheetIcon,
            tabs: [],
            title: languageTranslationData[language].Requests,
            route: "UserRequests"
        }
    ]

    ProvideMenu = (language: string) => [
        {
            icon: listIcon,
            title: languageTranslationData[language].MyListing,
            route: "UserMyListing",
            tabs: [
                {
                    title: languageTranslationData[language].CreateListing,
                    route: "UserCreateListing"
                },
                {
                    title: languageTranslationData[language].MyOffers,
                    route: "UserMyOffers"
                }
            ]
        }
    ];

    handleNavigation(route: string) {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), route);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }

    handleLogout = async () => {
        await removeStorageData("accessToken")
        await removeStorageData("profilePic")
        await removeStorageData("userType")
        this.handleNavigation("Home")
    }

    onLogout = async () => {
        await removeStorageData("accessToken");
        await removeStorageData("userType");
        await removeStorageData("profilePic");
        this.handleNavigation("Home");
    };
    handleDrawerClick = (route:string)=>{
        if(route==="logOut"){
            this.onLogout();
        } else{
            this.handleNavigation(route);
        }
    }
    handleModalClose = () => {
        this.setState({ isModalOpen: false });
    };
    handleModalOpen = () => {
        this.setState({ isModalOpen: true });
    };

    fetchRequest = async (id : number) => {

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchRequestCallId = requestMessage.messageId;

        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.RequestEndPoint}${id}`
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );

        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token" : await getStorageData("accessToken")
        }

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );

        runEngine.sendMessage(requestMessage.id, requestMessage);
    }

    handlePostBusinessDealData = async (verificaitionId: string) => {
        const { name, description, price, quantity, terms, category_id, sub_category_id } = this.state.business_deal;
        const formData = new FormData();
        formData.append("business_deal[name]", name);
        formData.append("business_deal[description]", description);
        formData.append("business_deal[price]", price);
        formData.append("business_deal[category_id]", category_id);
        formData.append("business_deal[sub_category_id]", sub_category_id);
        formData.append("business_deal[quantity]", quantity);
        formData.append("business_deal[terms]", terms);
        formData.append("business_deal[verification_request_id]", verificaitionId);
        this.state.supportDocArray
        .forEach(file => {
            if(file) {
                return formData.append("business_deal[documents][]", file);
            }
        });
        const header = {
            token: await getStorageData("accessToken")
        };

        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );

        this.fetchRequestPostCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BusinessDealEndPoint

        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(header)
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );

        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
        this.setState({ isSubmit: true });
    }
  
    formatTimelineDate = (dateString: string) => {
        const date = new Date(dateString);

        if (isNaN(date.getTime())) {
          return 'Invalid date';
        }
        return new Intl.DateTimeFormat('en-GB', {
          day: '2-digit',
          month: 'short',
          year: '2-digit',
        }).format(date);
      };

    handleInputPostData = (field:string, value: string) => {
        let subCatogories = this.state.subCategories;
        if(field ==='quantity' || field ==='price') {
            value = value.replace(configJSON.replaceAlphabetRegex, "");
        }
        if(field === 'category_id') {
            const category = this.state.categories.find(cat => cat.attributes.id === Number(value));
            if (category) {
                subCatogories = category.attributes.sub_categories;
                this.setState((prevState) => ({
                    business_deal: {
                      ...prevState.business_deal,
                      category_id: value,
                      sub_category_id: "",
                    },
                    subCategories: subCatogories
                }), () => this.handleBusinessDealErrorCheck());
            }
        } else {
            this.setState((prevState) => ({
                business_deal: {
                    ...prevState.business_deal,
                    [field]: value
                }
            }), () => this.handleBusinessDealErrorCheck());
        }
    };

    handleAddPortfolio = (event: React.ChangeEvent<HTMLInputElement>, index: number | null) => {
        const file: File | null = event.target.files ? event.target.files[0] : null;
        let fileArray = this.state.supportDocArray;
        let newIndex = index;
        if(index === null) {
            for (let i = 0; i < fileArray.length; i++) {
                if (fileArray[i] === null) {
                  newIndex = i;
                  break;
                }
              }
        }
        if (file) {
        this.setState(prevState => {
            const updatedArray = [...prevState.supportDocArray];
            if (newIndex !== null && newIndex >= 0 && newIndex < updatedArray.length) {
                updatedArray[newIndex] = file;
            } else {
                updatedArray.push(file);
            }
            return { supportDocArray: updatedArray };
        }, () => this.handleBusinessDealErrorCheck());
        }
    };
    
    handleBusinessDealErrorCheck = () => {
        const { name, description, price, quantity, terms, category_id, sub_category_id } = this.state.business_deal;
        const documentArr = this.state.supportDocArray;
        let nameError= "",
            descriptionError= '',
            priceError= "",
            categoryIdError= "",
            subCategoryIdError= "",
            quantityError= "",
            termsError= '',
            documentErr= ""
        if(!name) {
            nameError = "Please provide client name"
        }
        if(!description) {
            descriptionError = "Please provide descirption"
        }
        if(!price) {
            priceError = "Please provide total price"
        }
        if(!quantity) {
            quantityError = "Please provide quantity"
        }
        if(!category_id) {
            categoryIdError = "Please provide category"
        }
        if(!sub_category_id) {
            subCategoryIdError = "Please provide sub-category"
        }
        if(!terms) {
            termsError = "Please provide payment terms"
        }
        if(documentArr.length < 2 || !documentArr[0] || !documentArr[1]) {
            documentErr = "Please provide documents"
        }
        this.setState({
            nameError: nameError,
            descriptionError: descriptionError,
            priceError: priceError,
            categoryIdError: categoryIdError,
            subCategoryIdError: subCategoryIdError,
            quantityError: quantityError,
            termsError: termsError,
            documentErr: documentErr
        });

        return {
            nameError,
            descriptionError,
            priceError,
            categoryIdError,
            subCategoryIdError,
            quantityError,
            termsError,
            documentErr
        };
    };

    handleFormSubmit = () => {
        this.setState({checkSave: true});
        let getErrors = this.handleBusinessDealErrorCheck();
        let checkErrors = Object.values(getErrors).every((value) =>
           value === undefined || value.length === 0
        );
        if(checkErrors){
            this.handlePostBusinessDealData(`${this.state.data.id}`);
        }
    };

      handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "pending" || value === "due") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "rejected" || value === "past due") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };     
    // Customizable Area End
}
