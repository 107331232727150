import React, { Component } from "react";

import {
    Box,
    styled,
    IconButton,
    Typography
} from "@material-ui/core";

import NotesIcon from "@material-ui/icons/Notes";
import MoreVertIcon from '@material-ui/icons/MoreVert';

const utills = require('../../blocks/utilities/src/utills');

interface Props {
    handleMoreClick: (route: string) => void;
    fileName: string;
    fileSize: string
}

interface S {
    currentLanguage: "en" | "ar";
}

interface SS {
    id: any;
}

export default class FileView extends Component<Props, S, SS> {
    constructor(props: Props) {
        super(props);
        this.state = {
            currentLanguage: "en"
        };
    }

    static defaultProps = {
        handleMoreClick: ()=> {}
    };

    async componentDidMount() {
        const selectedLanguage = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: selectedLanguage
        });
    };

    render() {
        const { currentLanguage } = this.state;
        const { fileName, fileSize, handleMoreClick } = this.props;
        const dir = currentLanguage === "ar"? "rtl": "ltr";
        return (
            <Box dir={dir} style={webStyle.fileInputWrapper}>
                <Box style={webStyle.noteIconBox}>
                    <NotesIcon style={{color: "#2563EB"}}/>
                </Box>
                    <FileMidBox>
                        <Typography style={webStyle.fileNameStyle}>{fileName}</Typography>
                        <Typography style={webStyle.fileSizeStyle}>{fileSize}</Typography>
                    </FileMidBox>
                <IconButton 
                    aria-label="more1"
                    aria-haspopup="true"
                    onClick={() => handleMoreClick}
                >
                    <MoreVertIcon style={webStyle.fileMoreIcon} />
                </IconButton>
            </Box> 
        );
    }
}

const FileMidBox = styled(Box)({
    flexGrow:1,
    width: "80px !important",
});

const webStyle = {
    fileMoreIcon: {
        color: "#475569"
    },
    fileSizeStyle: {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "0",
        width:"100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    },
    fileNameStyle: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "0",
        width:"100%",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap"
    } as React.CSSProperties,
    fileInputWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "8px",
        gap: "14px"
    },
    noteIconBox: {
        backgroundColor: "#DBEAFE",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "56px",
        minWidth: "56px",
        height: "56px"
    }
}