import React from "react"
import { Card, Box, IconButton, Typography } from "@material-ui/core"

const dotImage = require("./image_.png")
const defaultDocImage = require("./default_doc_image.png")

const DocView = ({ imageUrl="", name="", size="" }) => {
    return (
        <Card style={styles.container}>
            <Box style={styles.imageContainer}>
                <img style={styles.image} src={imageUrl || defaultDocImage} alt="" />
            </Box>
            <Box style={styles.infoBox}>
                <Typography style={styles.name}>
                {name}
                </Typography>
                <Typography style={styles.size}>
                {size}
                </Typography>
            </Box>
            <IconButton style={styles.dotButton}>
                <img src={dotImage} alt="Icon" />
            </IconButton>
        </Card>
    )
}

export default DocView

const styles = {
    container: {
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: "8px"
    },
    imageContainer: {
        height: "56px",
        width: "56px",
        borderRadius: "8px",
        overflow: "hidden"
    },
    infoBox: {
        marginLeft: "8px"
    },
    image: {
        height: "100%",
        width: "100%"
    },
    name: {
        maxWidth: "200px",
        width: "100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    size: {
        fontWeight: 400,
        fontSize: "16px",
        color: "#64748B"
    },
    dotButton: {
        marginLeft: "auto",
        display: "flex",
        height: "50px",
        width: "50px",
        alignItems: "center",
        justifyContent: "center"
    }
}