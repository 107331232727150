// Customizable Area Start
import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
  getName,
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

import { Alert, Platform } from "react-native";
import DocumentPicker from 'react-native-document-picker';
import { imgPasswordInVisible, imgPasswordVisible } from "./assets";
import { getStorageData } from "../../../framework/src/Utilities";
import { Color } from "@material-ui/lab";
import { ChangeEvent } from "react";
import { debounce } from "../../../components/src/utilities";

const navigation = require("react-navigation");
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/Profile.json');
const docData = {
  en: [
    ["Business_Registration_Certificate", "DOCX", "28 Feb 2024",],
    ["Annual_Financial_Report_2023", "JPG", "29 Feb 2024"],
    ["Vendor_Agreement_Contract", "JPG", "24 Feb 2024",],
    ["Sarah Employee_Handbook_2024", "DOCX", "24 Feb 2024",],
    ["Vendor_Agreement_Contract", "PDF", "22 Feb 2024",],
    ["Sales_Report_Q1_2023", "DOCX", "21 Feb 2024",],
    ["Tax_Compliance_Certificate_2022", "PNG", "12 Feb 2024",],
    ["Product_Specifications", "PDF", "6 Feb 2024",],
    ["Client_Project_Proposal_ABC_Corp", "PDF", "3 Feb 2024",],

  ],
  ar: [
    ["شهادة - تسجيل - الأعمال", "DOCX", "٢٨ فبراير ٢٠٢٤",],
    ["التقرير - المالي - السنوي - ٢٠٢٣", "JPG", "٢٩ فبراير ٢٠٢٤",],
    ["عقد - اتفاق - البائعين", "JPG", "٢٤ فبراير ٢٠٢٤",],
    ["كتيب - موظف - سارة -  ٢٠٢٤", "DOCX", "٢٤ فبراير ٢٠٢٤",],
    ["عقد - اتفاق - البائعين", "PDF", "٢٢ فبراير ٢٠٢٤",],
    ["تقرير - المبيعات - الربع  -الأول - ٢٠٢٣", "DOCX", "٢١ فبراير ٢٠٢٤",],
    ["شهادة الامتثال الضريبي ٢٠٢٢", "PNG", "١٢ فبراير ٢٠٢٤",],
    ["مواصفات - المنتج", "PDF", "٦ فبراير ٢٠٢٤",],
    ["اقتراح - مشروع - العميل - ABC - Corp", "PDF", "٣ فبراير ٢٠٢٤",],
  ]
};

const SNACKBAR_INITIAL_STATE: Snackbar = {
  open: false,
  message: "",
  severity: "success",
  autoHideDuration: 2000
}

export interface GalleryMedias {
  url?: string,
  blob_id?: number,
  filename?: string,
  gallery_id?: string | number,
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface FolderItemsData {
  id?: number,
  type?: string,
  attributes: { folder_name: string }
}

export interface DocumentType {
  id: string;
  name:      string;
  extension: string;
  path:      string;
  date:      Date;
}

export interface DataItems {
  id: string,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder: {
      data: Array<FolderItemsData>
    }
  }
}

interface FileMenu {
  id?: number | string,
  file?: string,
  blob_id?: number,
  fileAndFolderName?: string,
}

export interface AllDocumentsResponse {
  data: Array<DocumentType>;
  meta: PaginationType
}

export interface AllDocumentData {
  gallery_id?: string,
  filename?: string,
  blob_id?: string
  id?: number,
  attributes: {
    folder_name: string,
    gallery?: {
      id?: number
    }
  }
}

export interface MapData {
  id: number
  url: string,
  blob_id: number,
  filename: string,
  gallery_id: number,
  attributes: {
    gallery_medias: Array<GalleryMedias>;
    folder_name: string,
    gallery: {
      id: number
    }
  }
}

interface ApiCallData {
  contentType?: string,
  method: string,
  endPoint: string,
  body?: Object,
  type?: string
  token?: string
}

interface SuccessResponse {
  message: string;
}

interface ErrorResponse {
  errors: string;
}

interface TokenExpire {
  errors: Array<TokenAlert>
}

interface TokenAlert {
  token: string
}

type Snackbar = {
  open: boolean,
  message: string,
  severity: Color | undefined,
  autoHideDuration?: number
}

// For Mobile
export interface Galleryobjectuniqueid {
  gallery_type: string;
  account_id: number;
}
export interface Innerobjectoffolder {
  folder_name: string;
  folder_type: string;
  gallery: Galleryobjectuniqueid;
  folder_medias: Array<GalleryMedia>
}
export interface Foldermedia {
  type: string;
  attributes: Innerobjectoffolder
}
export interface FolderInterface {
  data: Array<Foldermedia>
}
export interface GalleryMedia {
  url: string;
  blob_id: number;
  filename: string;
}
export interface Innerobject {
  gallery_type: string;
  gallery_medias: Array<GalleryMedia>
  folder: FolderInterface;
}
export interface Versionitems {
  data: Array<ObjectMedia>;
}
export interface ObjectMedia {
  type: string;
  attributes: Innerobject;
}

export interface Itemmedia {
  concat(data: Itemmedia[]): Itemmedia[];
  url: string,
  blob_id: number,
  filename: string
}

export interface DocumentlistItemapi {
  data: Array<GetAllDocumentlistapi>;
}

export interface GetAllDocumentlistapi {
  type: string;
  attributes: Objectattributes;
}

export interface Objectattributes {
  folder: ObjectOffolder;
  gallery_medias: Itemmedia;
}

export interface ObjectOffolder {
  data: Itemmedia[];
}

interface GetAllDocumentResponseJson {
  data: Array<Versionitems>;
  message: string
}

interface Errorobject {
  errors: string;
}

interface Tokenexpire {
  errors: Array<Tokenalert>
}
export interface Tokenalert {
  token: string
}

export interface Itemmedia {
  url: string;
  blob_id: number;
  filename: string;
  id: string,
  type: string,
  attributes: Folderobject
}
export interface Folderobject {
  folder_name: string;
  folder_type: string;
  gallery: Uniqeidnumberobject;
  folder_medias: Array<Fileinfolder>;
  cover_photo: null;
}
export interface Fileinfolder {
  url: string;
  blob_id: number;
  filename: string;
}

export interface Uniqeidnumberobject {
  gallery_type: string,
  account_id: number
}

export interface FolderType {
  id:                       number;
  name:                     string;
  created_at:               string;
  updated_at:               string;
  folder_attachments_count: number | null;
  total_size:               string | null;
  total_size_in_mb:         number | null;
}

export interface PaginationType {
  per_page: number;
  total_pages: number;
  current_page: number;
  total_count: number;
}

export const configJSON = require("./config");

export interface Props {
  navigation: typeof navigation;
  id: string;
}

interface S {
  txtInputValue: string;
  txtSavedValue: string;
  enableField: boolean;
  loader: boolean;
  fileMenu: FileMenu | null;
  addFolderModal: boolean;
  renameModal: FileMenu | boolean | null;
  deleteModal: null | FileMenu;
  uploadedFiles: [];
  folderName: string;
  renameValue: string;
  menuOption: string;
  folderMenuOptions: boolean;
  allDocuments: Array<DocumentType>,
  recentDocs: Array<DocumentType>,
  folderId: number;
  galleryId: number | string;
  blobId: number;
  breadscrumValues: string[],
  userType: string;
  currentLanguage: 'ar' | 'en',
  headingText: string,
  loading: boolean,
  docData: typeof docData;
  dot: HTMLElement | null,
  currentPage: number;
  isModalOpen: boolean,
  isNewFolderModalOpen: boolean,
  snackbar: Snackbar,
  isAddNewFolderError: string | null,
  folders: FolderType[],
  pagination: PaginationType,
  file: File | null,
  uploadingLoader: boolean,
  searchText: string, 
  deleteUserId: string | undefined,
  navigatePath: string | undefined,
  // For Mobile

  modalDots: boolean;
  folderModal: boolean;
  folderNameNative: string;
  cancel: boolean;
  create: boolean;
  rename: boolean;
  delete: boolean;
  modalRename: boolean;
  fileRenameText: string;
  deleteModalnative: boolean;
  fileDeleteText: string;
  documentList: Array<Itemmedia>;
  blobIdNumber: number;
  floderId: string;
  documentId: string;
  isFolder: boolean;
  isDelete: boolean;
  isloading: boolean;
  yesNoModal: boolean;
  yesNoModalFile: boolean;
  responseFailureData: boolean;
}

interface SS {
  id: string;
}

export default class DocumentationController extends BlockComponent<
  Props,
  S,
  SS
> {
  addFolderApiCallId: string = "";
  renameFileApiCallId: string = "";
  deleteFileApiCallId: string = "";
  allDocumentsApiCallId: string = "";
  renameFolderApiCallId: string = "";
  deleteFolderApiCallId: string = "";
  uploadFilesAndFoldersApiCallId: string = "";
  getAllFoldersApiCallId: string = "";

  // For Mobile

  getAllDocumentsApiCallId: string = "";
  createDocumentApiCallId: string = "";
  getDeleteFileApiCallId: string = "";
  getPatchRenameFileApiCallId: string = "";
  getAddFolderApiCallId: string = "";
  getDeleteFolderApiCallId: string = "";
  getRenameFolderApiCallId: string = "";
  willFocusScreen!: string

  constructor(props: Props) {
    super(props);
    this.receive = this.receive.bind(this);

    this.subScribedMessages = [
      getName(MessageEnum.SessionSaveMessage),
      getName(MessageEnum.RestAPIResponceMessage),
    ];

    this.state = {
      txtInputValue: "",
      txtSavedValue: "A",
      enableField: false,
      deleteModal: null,
      allDocuments: [],
      recentDocs: [],
      addFolderModal: false,
      uploadedFiles: [],
      loader: false,
      folderName: "",
      renameModal: null,
      galleryId: 0,
      renameValue: "",
      menuOption: "",
      folderMenuOptions: false,
      folderId: 0,
      fileMenu: null,
      blobId: 0,
      breadscrumValues: [],
      userType: "",
      headingText: "",
      currentLanguage: 'en',
      loading: false,
      docData: docData,
      dot: null,
      currentPage: 1,
      isModalOpen: false,
      isNewFolderModalOpen: false,
      snackbar: SNACKBAR_INITIAL_STATE,
      isAddNewFolderError: null,
      folders: [],
      pagination: {
        per_page: 10,
        current_page: 1,
        total_pages: 1,
        total_count: 0
      },
      file: null,
      uploadingLoader: false,
      searchText: "",
      deleteUserId: undefined,
      navigatePath: undefined,
      // For Mobile

      modalDots: false,
      folderModal: false,
      cancel: false,
      create: false,
      rename: false,
      delete: false,
      folderNameNative: '',
      modalRename: false,
      documentList: [],
      documentId: '512',
      blobIdNumber: 0,
      fileRenameText: '',
      deleteModalnative: false,
      fileDeleteText: '',
      floderId: '',
      isFolder: false,
      isDelete: false,
      isloading: false,
      yesNoModal: false,
      yesNoModalFile: false,
      responseFailureData: false,
    };
    runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

  }

  async receive(from: string, message: Message) {
    if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
      const apiRequestCallId = message.getData(
        getName(MessageEnum.RestAPIResponceDataMessage)
      );
      let responseJson = message.getData(
        getName(MessageEnum.RestAPIResponceSuccessMessage)
      );
      // For Mobile
      if (responseJson.status === 500) {
        this.showAlert("Error", "Internal Server Error");
        return;
      }

      if (responseJson.errors || responseJson.error) {
        this.getAllErrorFunctionCallBack(apiRequestCallId, responseJson);
      } else {
        this.getAllSuccessFunctionCallBack(apiRequestCallId, responseJson);
      }

      // For Web
      if (responseJson.error || responseJson.errors) {
        this.failureCallBack(apiRequestCallId, responseJson);
      } else {
        this.successCallBack(apiRequestCallId, responseJson);
      }
    }
  }



  async componentDidMount(): Promise<void> {
    if (Platform.OS !== 'web') {
      this.willFocusScreen = this.props.navigation.addListener(
        'willFocus',
        () => {
          this.getAllDocumentList()
        }
      );
    }
    else {
      this.getAllDocuments();
    }
    this.getAllFolders()
    const language = await utills.getLanguageOrSetCurrentLanguage();
    const heading = languageTranslationData[language].Signup;
    this.setState({
      currentLanguage: language,
      headingText: heading,
    });
  }

  handleSnackbarClose = () => {
    this.setState({ snackbar: SNACKBAR_INITIAL_STATE })
  }

  handleSnackbarOpen = (message: string, severity: Color | undefined) => {
    this.setState({ snackbar: {...this.state.snackbar, open: true, message, severity} })
  }

  handleMenuModal = (value: FileMenu | null) => { this.setState({ fileMenu: value }) }

  handleAddFolderModal = (value: boolean) => { this.setState({ addFolderModal: value }) }

  handleRenameModal = (value: FileMenu | null) => {
    this.setState({ renameModal: value, renameValue: value?.fileAndFolderName ?? "" })
  }

  handleDeleteModal = (value: FileMenu | null) => {
    this.setState({ deleteModal: value })
    this.handleMenuModal(value);
  }

  handleFolderName = (value: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ folderName: value.target.value, isAddNewFolderError: null })
  }

  handleRename = (value: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ renameValue: value.target?.value })
  }

  handleFolderNavigate = (gallery_id?: number | string, folderId?: number) => {
    if (gallery_id && folderId) {
      this.setState({ folderId: folderId, galleryId: gallery_id })
      this.props.navigation.navigate("DocumentationFolder", { galleryId: gallery_id, folderId: folderId })
    }
  }

  apiCall = (data: ApiCallData) => {
    const { contentType, method, endPoint, body, type, token } = data;
    const header = {
      "Content-Type": contentType,
      token
    };

    const requestMessage = new Message(
      getName(MessageEnum.RestAPIRequestMessage)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestHeaderMessage),
      JSON.stringify(header)
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIRequestMethodMessage),
      method
    );
    requestMessage.addData(
      getName(MessageEnum.RestAPIResponceEndPointMessage),
      endPoint
    );
    body && type != 'formData' ?
      requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        JSON.stringify(body)
      )
      : requestMessage.addData(
        getName(MessageEnum.RestAPIRequestBodyMessage),
        body
      );
    runEngine.sendMessage(requestMessage.id, requestMessage);
    return requestMessage.messageId;
  };

  handleUpload = async (event: React.ChangeEvent<HTMLInputElement>) => {

    this.setState({ uploadingLoader: true });
    const token = await getStorageData("accessToken")
    let files = Array.from(event.target.files!)
    let formData = new FormData();
    for (const file of files) {
      formData.append("upload_file", file)
    }
    this.uploadFilesAndFoldersApiCallId = this.apiCall({
      method: 'POST',
      endPoint: configJSON.uploadFileEndpoint,
      body: formData,
      type: "formData",
      token
    })
  }

  getAllDocuments = async (page=1) => {
    this.setState({ loading: true })
    const token = await getStorageData("accessToken")
    const payload = {
      per_page: this.state.pagination.per_page?.toString(),
      page: page?.toString(),
      search: this.state.searchText?.trim()?.toLowerCase()
    }
    const queryParams = new URLSearchParams(payload)
    this.allDocumentsApiCallId = this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `${configJSON.getAllDocumentsEndpoint}?${queryParams}`,
      token
    })
  }

  getAllFolders = async () => {
    const token = await getStorageData("accessToken")
    this.setState({ loader: true })
    this.getAllFoldersApiCallId = this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `${configJSON.getAllFoldersEndpoint}?per_page=200`,
      token
    })
  }


  renameFileAndFolder = async (value: FileMenu | null) => {
    if (value) {
      this.setState({ loader: true })
      if (value.file === "file") {
        let bodyData = {
          data: {
            attributes: {
              blob_id: value.blob_id,
              filename: this.state.renameValue
            }
          }
        }
        this.renameFileApiCallId = await this.apiCall({
          contentType: "application/json",
          method: 'PATCH',
          endPoint: `bx_block_documentation/gallery_documents/${value.id}/update_file_name`,
          body: bodyData,
        })
      }
      else {
        let bodyData = {
          data: {
            attributes: {
              gallery_id: value.id,
              folder_name: this.state.renameValue
            }
          }
        }
        this.renameFolderApiCallId = await this.apiCall({
          contentType: "application/json",
          method: 'PATCH',
          endPoint: `bx_block_documentation/folder_documents/${value.blob_id}/update_folder_name`,
          body: bodyData,
        })
      }
      this.setState({ renameModal: false, fileMenu: null })
      this.getAllDocuments()
    }
  }

  addFolder = async () => {
    if (!this.state.folderName.trim()) {
      this.setState({ isAddNewFolderError: 'Please Enter Folder Name' });
      return
    }
    const token = await getStorageData("accessToken")
    let bodyData = {
      folder: {
        name: this.state.folderName
      } 
    } 
    this.setState({ loader: true });
    this.addFolderApiCallId = this.apiCall({
      contentType: "application/json",
      method: 'POST',
      endPoint: configJSON.createFolderEndpoint,
      body: bodyData,
      token: token
    })
  }

  deleteFolderAndFiles = async () => {
    
    this.setState({ loader: true })
    const file_id = this.state.deleteUserId
    const token = await getStorageData("accessToken")

    this.deleteFolderApiCallId = this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint: `${configJSON.deleteFileEndpoint}?file_id=${file_id}`,
      token
    })
    this.handleClose()
  }

  folderNavigate = (element: GalleryMedias) => {
    if (element.attributes?.folder_name) {
      this.handleFolderNavigate(element.attributes.gallery?.id, element.id)
    }
  }

  //  For Mobile
  getAllSuccessFunctionCallBack = (apiRequestCallId: string, responseJson: GetAllDocumentResponseJson & DocumentlistItemapi) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderSuccesCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderSuccesCallBack(responseJson);
    }
  }

  getAllErrorFunctionCallBack = (apiRequestCallId: string, responseJson: Tokenexpire & string & Errorobject) => {
    if (apiRequestCallId === this.getAllDocumentsApiCallId) {
      this.getAllDocListFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.createDocumentApiCallId) {
      this.createDocumentFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFileApiCallId) {
      this.deleteFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getPatchRenameFileApiCallId) {
      this.renameNewFileFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getAddFolderApiCallId) {
      this.addNewFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getDeleteFolderApiCallId) {
      this.deleteFolderFailureCallBack(responseJson);
    }
    else if (apiRequestCallId === this.getRenameFolderApiCallId) {
      this.renameFolderFailureCallBack(responseJson);
    }
  }

  //  For Web

  successCallBack = (apiRequestCallId: string, responseJson: string & AllDocumentsResponse & SuccessResponse) => {
    if(apiRequestCallId === this.getAllFoldersApiCallId){
      this.getAllFoldersSuccessCallback(responseJson)
    } else if (apiRequestCallId === this.allDocumentsApiCallId) {
      this.getAllDocumentsSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.uploadFilesAndFoldersApiCallId) {
      this.uploadFilesSuccesCallback(responseJson);
    } else if (apiRequestCallId === this.addFolderApiCallId) {
      this.addFolderSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFileSuccessCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderSuccessCallback(responseJson);
    }
  }

  failureCallBack = (apiRequestCallId: string, responseJson: string & TokenExpire & ErrorResponse) => {
    if(apiRequestCallId === this.getAllFoldersApiCallId){
      this.getAllFoldersFailureCallback(responseJson)
    } else if (apiRequestCallId === this.allDocumentsApiCallId) {
      this.getAllDocumentsFailureCallback(responseJson);
    } else if (apiRequestCallId === this.uploadFilesAndFoldersApiCallId) {
      this.uploadFilesFailureCallback(responseJson);
    } else if (apiRequestCallId === this.addFolderApiCallId) {
      this.addFolderFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFileApiCallId) {
      this.renameFileFailureCallback(responseJson);
    } else if (apiRequestCallId === this.renameFolderApiCallId) {
      this.renameFolderFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFileApiCallId) {
      this.deleteFileFailureCallback(responseJson);
    } else if (apiRequestCallId === this.deleteFolderApiCallId) {
      this.deleteFolderFailureCallback(responseJson);
    }
  }

  // API Success and Failure Callbacks for Web

  getAllFoldersSuccessCallback = (response: any) => {
    this.setState({ folders: response.data })
  }

  getAllFoldersFailureCallback = (response: any) => {
    
  }

  getAllDocumentsSuccesCallback = (response: AllDocumentsResponse) => {
    if(this.state.pagination.current_page == 1 && this.state.searchText == ""){
      this.setState({ recentDocs: response.data.slice(0, 3) })
    }
    this.setState({ loading: false, allDocuments: response.data, pagination: { ...this.state.pagination, ...response.meta } })
  }

  getAllDocumentsFailureCallback = (response: any) => {
    this.setState({ loading: false })
    if(response.errors?.length){
      this.handleSnackbarOpen(response.errors[0].error, "error")
    }
  }

  uploadFilesSuccesCallback = (response: string) => {
    this.setState({ uploadingLoader: false, isModalOpen: false })
    this.handleSnackbarOpen("File uploaded successfully", "success")
    this.getAllDocuments();
  }

  uploadFilesFailureCallback = (response: any) => {
    this.setState({ uploadingLoader: false })
    if(response?.errors?.length){
      this.handleSnackbarOpen(response.errors[0]?.error, "error")
    }
    if(response?.error){
      this.handleSnackbarOpen(response.error, "error")
    }
    this.getAllDocuments();
  }

  addFolderSuccessCallback = (response: string) => {
    console.log("Manoj success")
    this.setState({ loader: false, isNewFolderModalOpen: false, folderName: "" }, () => {
      this.handleSnackbarOpen("Folder added successfully", "success")
      this.getAllFolders()
    })
  }

  addFolderFailureCallback = (response: any) => {
    this.setState({ loader: false, isAddNewFolderError: response?.errors[0]?.error ? response?.errors[0]?.error : null })
  }

  renameFileSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
  }

  renameFileFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  renameFolderSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
  }

  renameFolderFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }

  deleteFileSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.message)
    this.getAllDocuments();
  }

  deleteFileFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.showAlert('Alert', response.errors)
    this.getAllDocuments();
  }
  deleteFolderSuccessCallback = (response: SuccessResponse) => {
    this.setState({ loader: false }, () => {
      this.handleSnackbarOpen("File deleted successfully", "success")
    })
    const { pagination } = this.state;
    const page = pagination.total_count % pagination.per_page == 1 ? pagination.current_page - 1 : pagination.current_page
    this.getAllDocuments(page)
  }

  deleteFolderFailureCallback = (response: ErrorResponse) => {
    this.setState({ loader: false })
    this.handleSnackbarOpen("Something went wrong! Please try later", "error")
  }

  // API Success and Failure Callbacks for Mobile

  getAllDocListSuccesCallBack = (responseJson: DocumentlistItemapi) => {
    this.setState({ isloading: false }
      , () => {
        this.setState({ documentList: responseJson.data[0].attributes.gallery_medias.concat(responseJson.data[0].attributes.folder.data) });
      }
    )
  };

  getAllDocListFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== errorResponseJson", responseJson);
  };

  createDocumentSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false })
    if (responseJson.data) {
      this.getAllDocumentList()
    }
  };

  createDocumentFailureCallBack = (responseJson: Errorobject) => {
    this.setState({ isloading: false })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };

  deleteFileSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModalFile: false, deleteModalnative: false, blobIdNumber: 0, fileDeleteText: '', isloading: false })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  deleteFileFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false })
    Alert.alert("@@@ ==== deleteFileFailureCallBack", responseJson);
  };

  renameNewFileSuccesCallBack = async (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, blobIdNumber: 0, fileRenameText: '' })
    if (responseJson.message) {
      this.showAlert('Alert', responseJson.message)
    }
    this.getAllDocumentList()
  };

  renameNewFileFailureCallBack = (responseJson: Errorobject) => {
    this.setState({ isloading: false, modalRename: false, })
    if (responseJson.errors) {
      this.showAlert('Alert', responseJson.errors)
    }
  };

  addNewFolderSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ folderModal: false }, () => {
      this.getAllDocumentList()
    })
    this.setState({ isloading: false })
  };

  addNewFolderFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false })
  };

  deleteFolderSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ yesNoModal: false, isloading: false, deleteModalnative: false, floderId: '', fileDeleteText: '' })
    this.showAlert('Alert', responseJson.message)
    this.getAllDocumentList()
  };

  deleteFolderFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false })
  };

  renameFolderSuccesCallBack = (responseJson: GetAllDocumentResponseJson) => {
    this.setState({ isloading: false, modalRename: false, floderId: '', folderNameNative: '', })
    this.showAlert('Alert', responseJson?.message)
    this.getAllDocumentList()
  };

  renameFolderFailureCallBack = (responseJson: string) => {
    this.setState({ isloading: false, modalRename: false, })
  };

  createFolderOpen = () => { this.setState({ addFolderModal: false, }, () => this.addFolder()) }

  getAllDocumentList = async () => {
    this.setState({ isloading: true })
    this.getAllDocumentsApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'GET',
      endPoint: `bx_block_documentation/gallery_documents/account_gallery?gallery_type=documents`,
      body: "",
      type: ""
    });
  }

  createDocumentApiCell = async (urlresponse: string, fileName: string) => {
    this.setState({ isloading: true })
    let formData = new FormData();
    formData.append("gallery_medias[]",
      JSON.parse(JSON.stringify({
        uri: urlresponse,
        type: "sample/pdf",
        name: fileName
      }))
    )

    this.createDocumentApiCallId = await this.apiCall({
      contentType: "multipart/form-data",
      method: 'PATCH',
      endPoint: `/bx_block_documentation/gallery_documents/${this.state.documentId}`,
      body: formData,
      type: 'formData'
    });
  }

  documentDrawerNavigation = (routing: string) => {
    if (routing === "logOut") {
      this.logoutDoc()
    } else {
      this.documentNavigatingRoute(routing);
    }
  };
  logoutDoc = async () => {
    this.documentNavigatingRoute("Home");
  };

  documentNavigatingRoute = (selectedRoute: string) => {
    const message: Message = new Message(
      getName(MessageEnum.NavigationMessage)
    );
    message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

    message.addData(
      getName(MessageEnum.NavigationPropsMessage),
      this.props
    );
    this.send(message);
  };
  handleClick = (event: React.MouseEvent<HTMLElement>) => {
    const dataset = event.currentTarget.dataset
    this.setState({ dot: event.currentTarget, deleteUserId: dataset.id, navigatePath: dataset.path });

  };
  handleClose = () => {
    this.setState({ dot: null, deleteUserId: undefined, navigatePath: undefined });
  };
  handlePageChange = (e: ChangeEvent<unknown>, page: number) => {
    this.setState({ pagination: { ...this.state.pagination, current_page: page } });
    this.getAllDocuments(page)
  };

  handleModalOpen = () => {
    this.setState({ isModalOpen: true });
};
  handleModalClose = () => {
    this.setState({ isModalOpen: false });
};

  handleNewFolderModalOpen = () => {
    this.setState({ isNewFolderModalOpen: true });
  };

  handleNewFolderModalClose = () => {
    this.setState({ isNewFolderModalOpen: false });
  };

  DeleteFileApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber
        }
      }
    }
    this.getDeleteFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint: `/bx_block_documentation/gallery_documents/${this.state.documentId}/destroy_media_file`,
      body: apidata,
      type: ""
    });
  }

  RenameFileApiCell = async () => {
    if (this.state.fileRenameText.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    const apidata = {
      data: {
        attributes: {
          blob_id: this.state.blobIdNumber,
          filename: this.state.fileRenameText
        }
      }
    }
    this.getPatchRenameFileApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint: `bx_block_documentation/gallery_documents/${this.state.documentId}/update_file_name`,
      body: apidata,
      type: ""
    });
  }

  addFolderApiCell = async () => {
    if (this.state.folderNameNative.trim().length == 0) {
      this.showAlert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.folderNameNative
        }
      }
    }

    this.getAddFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'POST',
      endPoint: `bx_block_documentation/folder_documents`,
      body: apidata,
      type: ""
    });
  }

  RenameFolderApiCell = async () => {
    if (this.state.fileRenameText.trim().length == 0) {
      Alert.alert('Alert', 'Please Enter File Name');
      return
    }
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
          folder_name: this.state.fileRenameText,
        }
      }
    }
    this.getRenameFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'PATCH',
      endPoint: `bx_block_documentation/folder_documents/${this.state.floderId}/update_folder_name`,
      body: apidata,
      type: ""
    });
  }

  DeleteFolderApiCell = async () => {
    this.setState({ isloading: true })
    let apidata = {
      data: {
        attributes: {
          gallery_id: this.state.documentId,
        }
      }
    }
    this.getDeleteFolderApiCallId = await this.apiCall({
      contentType: "application/json",
      method: 'DELETE',
      endPoint: `bx_block_documentation/folder_documents/${this.state.floderId}`,
      body: apidata,
      type: ""
    });
  }

  threeDotsTouchable = (item: Itemmedia) => item?.type !== 'folder' ? this.onRequestClose(item?.blob_id, item?.filename) : this.onFolderRenameandDelete(item.id, item.attributes.folder_name)

  onRequestClose = (Item_id: number, fileName: string) => this.setState({ blobIdNumber: Item_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: false, isDelete: false }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  onFolderRenameandDelete = (Items_id: string, fileName: string) => this.setState({ floderId: Items_id, fileRenameText: fileName, fileDeleteText: fileName, isFolder: true, isDelete: true }, () => { this.setState({ modalDots: true, cancel: false, delete: false, rename: false }) })

  folderClickButton = () => this.setState({ folderModal: true, create: false, cancel: false, folderNameNative: '' })

  modalDotsTrue = () => this.setState({ modalDots: false })

  modalOverMOdal = () => this.setState({ modalDots: false, modalRename: true, })

  modalDeleteOverMOdal = () => this.setState({ modalDots: false, deleteModalnative: true, })

  navigateScreen = (item: Itemmedia) => { this.props.navigation.navigate('DocumentationFolder', { item: item, documentId: this.state.documentId }) }

  changeTextFolderModal = (text: string) => { this.setState({ folderNameNative: text }) }

  RenameChangeFileModal = (text: string) => { this.setState({ fileRenameText: text }) }

  changeDeleteFileModal = (text: string) => { this.setState({ fileDeleteText: text }) }

  cancelFolderModal = () => this.setState({ folderModal: false, cancel: true, create: false })

  cancelDeleteFile = () => this.setState({ deleteModalnative: false, cancel: true, delete: false })

  cancelFolderRename = () => this.setState({ modalRename: false, cancel: true, rename: false })

  createFolderModal = () => { this.setState({ create: true, cancel: false, }, () => this.addFolderApiCell()) }

  responseRenameFile = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFileApiCell()) }

  responseRenameFolder = () => { this.setState({ rename: true, cancel: false, }, () => this.RenameFolderApiCell()) }

  responseDeleteFile = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModalFile: true }) }

  responseDeleteFolder = () => { this.setState({ delete: true, cancel: false, deleteModalnative: false, yesNoModal: true }) }

  responseDeleteFolderYesNo = () => { this.DeleteFolderApiCell() }

  responseDeleteFolderYesNoFile = () => { this.DeleteFileApiCell() }

  uplaodCsvFile = async () => {
    try {
      const isResponse = await DocumentPicker.pick({
        type: [DocumentPicker.types.pdf]
      })
      if (isResponse) {
        this.createDocumentApiCell(isResponse[0].uri, isResponse[0].name)
      }
    }
    catch (error) {
    }
  };

  yesNoModalFalse = () => this.setState({ yesNoModal: false, yesNoModalFile: false })

  debouncedSearch = debounce(() => this.getAllDocuments(), this)

  handleSearchChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    this.setState({ searchText: e.target.value }, () => {
      this.debouncedSearch()
    })
  }

  handleFolderNavigation = (id: number, name: string) => {
    this.props.navigation.navigate(`DocumentationFolder`, { id, name })
  }

  handleViewFile = (event: React.MouseEvent<HTMLElement>) => {
    window.open(this.state.navigatePath, "_blank")
    this.handleClose()
  }

}



// Customizable Area End
