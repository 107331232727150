
export const defaultValue = (value: any = null, defaultValue: any = "") => {
    return value ? value : defaultValue
}

export const debounce = (func: Function, ctx: any, timeout = 500) => {
    let timer: NodeJS.Timeout;
    return (...args: any) => {
      clearTimeout(timer);
      timer = setTimeout(() => { func.apply(ctx, args); }, timeout);
    };
}