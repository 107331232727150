//@ts-nocheck
import React, { Fragment } from "react";
// Customizable Area Start
import { Box, Typography, Avatar, styled,LinearProgress } from "@material-ui/core";
import { withStyles, StyleRules } from "@material-ui/core/styles";
import { rightImg, leftImg, alarmImg, vectorImg } from "./assets";
import ContentManagementControllerWeb, {
  AboutPageData,
  Props as ContentProps
} from "./ContentManagementController.web";
import Text from "../../../components/src/design-system/Text";
import './ContentManagement.css'
import Loader from "../../../../packages/components/src/Loader.web";
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
// Customizable Area End

import ContentManagementController, {
  Props,
  configJSON,
} from "./ContentManagementController";
// Customizable Area Start

// Customizable Area End

// Customizable Area Start

export class ContentManagement extends ContentManagementControllerWeb {
  constructor(props: ContentProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start
  CustomTabPanel = (props: TabPanelProps) => {
    const { children, value, index, ...other } = props;

    return (
      <Box
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel-${index}`}
        aria-labelledby={`vertical-tab-${index}`}
        {...other}
      >
        {value === index && <Box>{children}</Box>}
      </Box>
    );
  }

  StyledTabs = styled(Tabs)({
    '& .MuiTab-wrapper': {
      display:'block',
      textAlign:'start',
    },
  });

  // Customizable Area End

  render() {

    // Customizable Area Start
    const { aboutUsData, currentLanguage, data } = this.state;
    const aboutUsPageData = aboutUsData;
    const isEnglish = currentLanguage === configJSON.EN;
    const { contents } = this.state.data.attributes
    // Customizable Area End
    return (
      // Customizable Area Start
      <MainBox data-test-id="description" id="ScrollToTop">
        <Box style={styles.breadcrumbContainer}>
          <Typography style={styles.breadcrumbText}>
            {configJSON.breadcrumHome}
          </Typography>
          <Avatar style={styles.breadcrumbImg} src={vectorImg} />
          <Typography style={styles.breadcrumbText} onClick={() => this.props.navigation.navigate('Catalogue')}>
            {configJSON.breadcrumBlog}
          </Typography>
          <Avatar style={styles.breadcrumbImg} src={vectorImg} />
          <Typography style={styles.breadcrumbTextH}>
            {configJSON.breadcrumArticle}
          </Typography>
        </Box>
        <BlogTopBox>
          <Box style={styles.blogHeroSubBox}>
            <Box style={styles.blogHeroTextBox}>
              <Typography style={styles.blogTextHiglight}>
                {data.attributes.category_name}
              </Typography>
              <Typography data-testID="dateTestId" style={styles.blogTextDate}>{this.formatDate(data.attributes.created_at)}</Typography>
            </Box>
            <Box>
              <Typography style={styles.blogHeroHead}>{data.attributes.title}</Typography>
            </Box>
            <Box style={styles.blogAvtarBox}>
              <Avatar style={styles.blogAvtar} src={data.attributes.author_image?.url} />
              <Box>
                <Typography style={styles.blogAvtarName}>
                  {data.attributes.author.name}
                </Typography>
                <Typography style={styles.blogAvtarDetail}>
                  {data.attributes.author.bio}
                </Typography>
              </Box>
            </Box>
          </Box>
          
          <Box style={styles.firstBannerImageBox}>
            {data.attributes.image?.url &&
              <img  style={styles.firstBannerImage} src={data.attributes.image?.url} />
            }
          </Box>
        </BlogTopBox>

        <Box style={styles.blogMainBox}>
          <Box style={styles.blogMainBoxWrapper}>
            <Box style={styles.blogSideBar}>
              <Typography style={styles.blogSideBarHead}>{configJSON.blogSideBarHead}</Typography>
              <Box style={styles.blogSideBarHeadLine}></Box>
            </Box>
            <Box style={styles.blogSideBarData}>
              <this.StyledTabs
                orientation="vertical"
                variant="standard"
                TabIndicatorProps={{ style: { left: 0, backgroundColor: '#1D4ED8'} }}
                value={this.state.value}
                onChange={this.handleTabValueChange}
                data-testID={"tabTestID"} 
                style={{...styles.tabs}}
                arial-label="Vertical tab example">
                {contents?.data.map((head,index) => {
                  return <Tab  onClick={() => this.scrollToSection(head.attributes.id)} key={index}
                   style={styles.sideList}
                    label={<Typography style={styles.sideListText}>{head.attributes.title}</Typography>} selected={this.state.value === index}/>
              })}
              </this.StyledTabs>
            </Box>
            <Box style={styles.blogTimeBox}>
                <StyledLinearProgress variant="determinate" value={this.state.progress} />
              <Box style={styles.blogAlarmBox}>
                <img style={styles.blogAlarmIcon} src={alarmImg} />
                <Typography style={styles.blogAlarmText}>{data.attributes.read_minutes}</Typography>
              </Box>
            </Box>
          </Box>
          <Box style={styles.blogTextSectionBox}>
            {
              contents?.data.map((head, index) => {
                return <Box key={index}>
                  <Typography id={head.attributes.id} style={styles.blogTextHead}>{head.attributes.title}</Typography>
                  <Typography style={styles.blogTextDes}>{head.attributes.description}</Typography>
                  {
                    head.attributes.image?.url &&
                    <img style={styles.blogdetailsImage} src={head.attributes.image?.url} />
                  }
                </Box>
              })
            }
          </Box>
        </Box>

        <Box style={styles.contentHero}>
          <Box style={styles.blogTestimonial}>
            <Typography style={styles.blogTestimonialHeading}>{configJSON.blogTestimonialHeading}</Typography>
            <Box style={styles.blogTestimonialBox}>
              {
                this.state.meta.prev_page ?
                <Box data-testId="showPrevTest" onClick={() => this.handleShowPrev()} style={styles.blogTestimonialImgBox}>
                  <img src={leftImg} />
                </Box>
                :
                <></>
              }
              {
                this.state.meta.next_page ?
                <Box data-testId="showNextTest" onClick={() => this.handleShowNext()} style={styles.blogTestimonialImgBox2}>
                  <img  src={rightImg} />
                </Box>
                :
                <></>
              }
            </Box>
          </Box>

          <Box  style={styles.blogTestMainBox}>
            { this.state.blogsShow.map((element, index) => {
                return <Box data-testID="blogTestID" onClick={() => this.handleNewBlog(element.id)}   key={index} style={styles.blogTestSubBox}>
                  <CardImage style={styles.blogTestImg} src={element.attributes.image?.url}></CardImage>
                  <Box style={styles.blogTestImgText}>
                    {element.attributes.category_name}
                  </Box>
                  <Box style={styles.blogTestTextBox}>
                    <Box style={{display : 'flex',gap : '12px'}}>
                    <Typography style={styles.blogTestTextDate}>
                      {this.formatDate(element.attributes.created_at)}
                    </Typography>
                    <Typography style={styles.blogTestTextDate}>
                      {element.attributes.read_minutes}
                    </Typography>
                    </Box>
                    <Typography style={styles.blogTestHeading}>
                      {element.attributes.title}
                    </Typography>
                    <Box style={styles.blogAvatarDeatilBox}>
                      <Avatar style={styles.blogAvatarImg} src={element.attributes.author_image?.url} />
                      <Box>
                        <Typography style={styles.blogAvatarName}>
                          {element.attributes.author?.name || ""}
                        </Typography>
                        <Typography style={styles.blogAvatarPosition}>
                          {element.attributes.author?.bio || ""}
                        </Typography>
                      </Box>
                    </Box>
                  </Box>
                </Box>
              })
            }
          </Box>
        </Box>
      </MainBox>
      // Customizable Area End

    );
  }
}

// Customizable Area End

// Customizable Area Start

const MainBox = styled(Box)({
  padding: '80px 120px',
  "@media (max-width: 1280px)": {
    padding: '80px 60px'
  },
  "@media (max-width: 960px)": {
    padding: '80px 20px'
  },
  '&.MuiTab-wrapper': {
    alignItems: 'start !important'
  }
});

const BlogTopBox = styled(Box)({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: "flex-start",
  "@media (max-width: 960px)": {
    flexDirection: "column",
    alignItems: "center",
    gap: "30px"
  },
  "& > div": {
    maxWidth: "49%",
  "@media (max-width: 960px)": {
    width: "100%",
    maxWidth: "100%"
  }
    
  },
});

const CardImage = styled(Avatar)({
  height: "280px",
  width: "387px",
  objectFit: "cover",
  borderRadius: "20px",
  "@media (max-width: 1280px)": {
    height: "250px",
    width: "330px",
    borderRadius: "16px",
  },
});
const StyledLinearProgress = styled(LinearProgress)({
  height: 10,
  borderRadius: 5,
  backgroundColor: '#e0e0e0', 
  '& .MuiLinearProgress-bar': {
    backgroundColor: 'red', 
  },
});
const styles: StyleRules = {

  breadcrumbContainer: {
    width: '197px',
    height: '30px',
    position: 'absolute' as 'absolute',
    top: '112px',
    display: 'flex',
    alignItems: 'center',
    gap: '12px'
  },
  breadcrumbText: {
    fontSize: '14px',
    fontWeight: 400,
    lineHeight: '22px',
    textAlign: 'left' as 'left',
    color: '#475569',
  },
  breadcrumbTextH: {
    fontSize: '14px',
    color: '#0F172A',
    fontWeight: 400,
    textAlign: 'left' as 'left',
    lineHeight: '22px',
  },
  contentHero: {
    display: 'flex',
    flexDirection: 'column',
    gap: '40px',
    marginTop: "96px"
  },
    tabs: {
    color: "#101828",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: 'left',
    display : 'flex',
    flexDirection : 'column',
    justifyContent: 'left',
    alignItems: 'left',
    padding: '8px 0px',
    textTransform: 'none',
    '&.Mui-selected': {
      color: '#1D4ED8',
      borderLeft: '3px solid #1D4ED8',
      fontWeight: 'bold',
      textAlign:"left"
    },
    '&.MuiTab-wrapper': {
      display : 'flex',
      flexDirection : 'column',
      justifyContent: 'flex-start',
      alignItems: 'self-start',
    }
  },
  sideList: {
    alignSelf:'start',
    color: "#101828",
    fontSize: "14px",
    fontWeight: 400,
    textAlign: 'left',
    display : 'flex',
    flexDirection : 'column',
    justifyContent: 'stretch',
    alignItems: 'start',
    padding: '4px 0px 4px 14px',
    textTransform: 'none',
    '&.Mui-selected': {
      borderLeft: '3px solid #1D4ED8',
      fontWeight: 'bold',
      textAlign:"left"
    },
    '&.MuiTab-wrapper': {
      display : 'flex',
      flexDirection : 'column',
      justifyContent: 'flex-start',
      alignItems: 'self-start',
      alignSelf:'start',

    }
  },
  sideListText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#475569"
  },
  breadcrumbImg: {
    width: "5.49px",
    height: "9.31px"
  },
  blogHeroSubBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px'
  },
  blogHeroTextBox: {
    display: 'flex',
    gap: '20px',
    alignItems: 'center'
  },
  blogTextHiglight: {
    background: '#DBEAFE',
    padding: '6px 12px',
    color: '#1D4ED8',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '26px'

  },
  blogTextDate: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#64748B'
  },
  blogHeroHead: {
    fontSize: '36px',
    fontWeight: 600,
    color: '#0F172A',
    lineHeight: "52px",
  },
  firstBannerImageBox: {
    height: '240px',
    borderRadius: "20px",
    overflow:"hidden",
  },
  firstBannerImage: {
    height: '100%',
    width: '100%',
    objectFit: "cover",
  },
  blogAvtarBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px'

  },
  blogAvtar: {
    height: '48px',
    width: '48px',
    borderRadius: '50px'
  },
  blogAvtarName: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#334155'

  },
  blogAvtarDetail: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#64748B'

  },
  blogMainBox: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: "96px",
    position:"relative",
    gap:"64px",

  },
  blogMainBoxWrapper: {
    height: '464px',
    width: '246px',
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
    position:"sticky",
    top:"50px",
    left:0,
  },
  blogSideBar: {
    width: "232px",
    height: '24px',
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginLeft:"14px"

  },
  blogSideBarHead: {
    fontSize: "16px",
    fontWeight: 600,
    color: "#101828"
  },
  blogSideBarHeadLine: {
    height: "2px",
    width: "18px",
    background: "#0F172A"

  },
  blogSideBarData: {
    display: "flex",
    flexDirection: "column",
    gap: "8px",
    color: "#101828",
    fontSize: "14px",
    fontWeight: 400,
  },

  blogTimeBox: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
  },
  blogAlarmBox: {
    width: "246px",
    height: "22px",
    display: "flex",
    gap: "8px",
    justifyContent: "center"
  },
  blogAlarmIcon: {
    height: "16.48",
    width: "15.89px"
  },
  blogAlarmText: {
    fontSize: "14px",
    fontWeight: 400,
    color: "#0F172A"
  },
  blogTextSectionBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '44px',
    scrollMarginTop: '50px',
  },
  blogTextHead: {
    fontSize: '24px',
    fontWeight: 600,
    color: '#101828'
  },
  blogTextDes: {
    fontSize: '16px',
    fontWeight: 400,
    color: '#344054'
  },
  blogdetailsImage: {
    height: 'auto',
    width: '100%',
    objectFit: "cover",
    borderRadius: '0px',
    marginTop: '16px',
  },
  blogTextImg: {
    width: '895px',
    height: '400px'
  },
  blogTestimonial: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center"
  },
  blogTestimonialHeading: {
    fontSize: '30px',
    fontWeight: 600,
    color: '#0F172A'
  },
  blogTestimonialBox: {
    display: "flex",
    gap: "24px",
  },
  blogTestimonialImgBox: {
    borderRadius: "8px",
    height: "44px",
    width: "44px",
    background: "#F1F5F9",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  blogTestimonialImg: {
    height: "18.76px",
    width: "10.74"
  },
  blogTestimonialImgBox2: {
    borderRadius: "8px",
    height: "44px",
    width: "44px",
    background: "#1E3A8A",
    display: "flex",
    justifyContent: "center",
    alignItems: "center"
  },
  blogTestMainBox: {
    display: 'flex',
    justifyContent: 'flex-start',
    gap: '40px',
  },
  blogTestSubBox: {
    position: 'relative',
    height: '438px',
    width: '387px',
    border: 'none',
  },
  blogTestImg: {
    height: "280px",
    width: '100%',
    objectFit:"cover",
    borderRadius: '20px',
  },
  blogTestImgText: {
    position: 'absolute',
    top: 8,
    left: 8,
    backgroundColor: '#DBEAFE',
    padding: '6px 12px 6px 12px',
    color: '#1D4ED8',
    fontSize: '14px',
    fontWeight: 400,
    borderRadius: '26px'
  },
  blogTestTextBox: {
    display: 'flex',
    flexDirection: 'column',
    gap: '6px',
    marginTop: '12px'
  },
  blogTestTextDate: {
    display: 'flex',
    gap: '8px',
    fontSize: '14px',
    fontWeight: 400,
    color: '#64748B'
  },
  blogTestHeading: {
    fontSize: '18px',
    fontWeight: 600,
    color: '#101828'

  },
  blogAvatarDeatilBox: {
    display: 'flex',
    alignItems: 'center',
    gap: '6px'

  },
  blogAvatarImg: {
    height: '48px',
    width: '48px',
    border: '1px solid black',
    borderRadius: '50px'
  },
  blogAvatarName: {
    fontSize: '14px',
    fontWeight: 600,
    color: '#334155'

  },
  blogAvatarPosition: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#64748B'

  }

}
export default withStyles(styles)(ContentManagement);
// Customizable Area End
