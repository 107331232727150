import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";
// Customizable Area Start
import { getStorageData, setStorageData} from "framework/src/Utilities";
const utills = require('../../utilities/src/utills');
interface VerificationAttributes {
    uuid: string | null;
    full_name: string | null;
    status: 'pending' | 'approved' | 'rejected' | string; 
    registration_number: string | null;
    verification_type: string | null;
    request_date: string | null;
    details: string | null;
    Company: string | null;
    Debtor: string | null;
    premuim: string | null;
    supporting_documents: (string | null)[];
  }
  
  interface VerificationData {
    id: number;
    type: string;
    attributes: VerificationAttributes;
  }

interface ValidResponse {
    data: VerificationData[];
    message: string;
    meta: Meta;
};
  
interface Meta { 
    total_pages: number;
    current_page: number;
    total_count: number;
    counter: { 
        approved: number;
        pending: number;
        rejected: number; 
    } 
};

// Customizable Area End
export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    classes: any;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    tabs: { name: "All" | "Archive" | "Blocked", key: string }[],
    selectedTab: "All" | "Archive" | "Blocked"
    rowsPerPage: number,
    page: number,
    dot: HTMLElement | null,
    showFilter: boolean;
    listingType: string;
    startDate: string;
    endDate: string;
    status: string,
    currentLanguage: "ar" | "en",
    showDetail: boolean,
    breadscrumValues: string[],
    userType: string;
    currentPage: number;
    verifications: VerificationData[];
    searchTerm: string;
    itemsPerPage: number,
    currentpage: number,
    selectedRequest: {
        id: number,
        status: string
    };
    total_pages: number,
    current_page: number,
    total_count:number;
    statusCounts: {
        approved: number,
        pending: number,
        rejected: number,
    }
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start

    // Customizable Area End
}

export default class VerificationRequestManagementController extends BlockComponent<
    Props,
    S,
    SS
> {

    // Customizable Area Start
    getVarificationCallId : string = "";
    changeStatusCallId : string = "";
    regularStatusCallId: string= "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);
        this.send = this.send.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            getName(MessageEnum.NavigationPayLoadMessage),
            getName(MessageEnum.NavigationMessage),
            getName(MessageEnum.NavigationTargetMessage),
            getName(MessageEnum.NavigationPropsMessage),
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            tabs: [{ name: "All", key: "company_users" }, { name: "Archive", key: "archived_users" }],
            selectedTab: "All" ,
            rowsPerPage: 5,
            page: 0,
            dot: null,
            showFilter: false,
            listingType: " ",
            startDate: "",
            endDate: "",
            status: "",
            currentLanguage: "en",
            showDetail: false,
            breadscrumValues: [],
            userType: "",
            currentPage: 1,
            verifications: [
                {
                  id: 0,
                  type: "",
                  attributes: {
                    uuid: null,
                    full_name: null,
                    status: "",
                    registration_number: null,
                    verification_type: null,
                    request_date: null,
                    details: null,
                    Company: null,
                    Debtor: null,
                    premuim: null,
                    supporting_documents: [null],
                  }
                }
              ],
              searchTerm: "",
              itemsPerPage: 10,
              currentpage:1,
              selectedRequest: {
                id: 0, 
                status: ""
              },
              total_pages: 0,
              current_page: 1,
              total_count:0,
              statusCounts: {
                approved: 0,
                pending: 0,
                rejected: 0,
            }
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End
    }

    async receive(from: string, message: Message) {


        runEngine.debugLog("Message Recived", message);

        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(getName(MessageEnum.RestAPIResponceDataMessage));
            const responseJson = message.getData(getName(MessageEnum.RestAPIResponceSuccessMessage));
            if(apiRequestCallId && responseJson) {
                if(responseJson.data || responseJson.message) {
                    this.apiSuccessCall(apiRequestCallId, responseJson);
                }
            }
          }
        // Customizable Area End
    }

    // Customizable Area Start
    async componentDidMount() {
        // Customizable Area Start
        const language = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: language,
        });
        this.verificationRequestGet();
        // Customizable Area End
    }

    apiSuccessCall = (apiRequestCallId: string, response: ValidResponse) => {
        if (apiRequestCallId === this.getVarificationCallId) {
            const { data, meta } = response;
            this.verificationSuccess(data, meta);
        }
        if (apiRequestCallId === this.changeStatusCallId) {
            this.setState({ dot: null });
            this.verificationRequestGet(this.state.current_page);
        }
    };

    apiUpdateStatus =  async (status: string) => {
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.changeStatusCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.varificationRequestShowEndPoint}${this.state.selectedRequest.id}/change_status_admin`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.exampleAPiMethod
        );
        const formData = new FormData()
        formData.append("status", status);
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };
    
    handleDrawerNavigation2 = (routing: string) => {
        if(routing==="logOut"){
            this.logoutHandle2()
        } else {
                this.handleNavigatingRoute2(routing);
        }
    };
    logoutHandle2 = async() => {
        this.handleNavigatingRoute2("Home");
    };

    handleNavigatingRoute2 = (selectedRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectedRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    handleStatusColor = (value: string) => {
        let changeBackground = "#D1FAE5";
        let changeColor = "#059669";
        if (value === "pending" || value === "DUE") {
            changeBackground = "#FEF3C7";
            changeColor = "#D97706";
        }
        if (value === "rejected" || value === "PAST DUE") {
            changeBackground = "#FEE2E2";
            changeColor = "#DC2626";
        }
        return {
            background: changeBackground,
            color: changeColor,
        };
    };
    handleClose = () => {
        this.setState({ dot: null });
    };

    handleMoreClick = (event: React.MouseEvent<HTMLElement>, selectedRequiest: {id: number, status: string}) => {
        this.setState({ 
            dot: event.currentTarget,
            selectedRequest: selectedRequiest
        });
    };


    handleCloseFilter = () => {
        this.setState({ showFilter: false });
    };

    handleFilterDateChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
        this.setState({
          [name]: event.target.value
        }as unknown as Pick<S, keyof S>);
    };

    handleFilterDateReset = () => {
        this.setState({
          startDate: "",
          endDate: ""
        }, () => this.verificationRequestGet(this.state.current_page));
    };

    handleSelect = (event: React.ChangeEvent<{ value: unknown }>, name: string) => {
        let { value } = event.target;
        if (typeof value === "string") {
            this.setState({
                [name]: value
            } as unknown as Pick<S, keyof S>);
        }
    };
    handleToggleFilter = () => {
        this.setState({ showFilter: !this.state.showFilter });
    };
    handleSelectReset = (stateName: string) => {
        this.setState({
            [stateName]: ""
        } as unknown as Pick<S, keyof S>, () => this.verificationRequestGet(this.state.current_page));
    };

    handleFilterReset = () => {
        this.setState({
            startDate: "",
            endDate: "",
            status: ""
        }, () => this.verificationRequestGet(this.state.current_page));
    };

    handleApplyNow = () => {
        this.verificationRequestGet(this.state.current_page);
    };    
    
    verificationRequestGet = (page: number = 1) => {
        const { searchTerm, status, startDate, endDate } = this.state;
        const params = `per_page=10&page=${page}&search=${searchTerm}&query[status]=${status}&query[from]=${startDate}&query[to]=${endDate}`;
        const header = {
          "Content-Type": configJSON.validationApiContentType,
        };
      
        const requestMessage = new Message(
          getName(MessageEnum.RestAPIRequestMessage)
        );
      
        this.getVarificationCallId = requestMessage.messageId;
      
        const endPoint = `${configJSON.varificationRequestEndPoint}?${params}`;
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIResponceEndPointMessage),
          endPoint
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestHeaderMessage),
          JSON.stringify(header)
        );
      
        requestMessage.addData(
          getName(MessageEnum.RestAPIRequestMethodMessage),
          configJSON.validationApiMethodType
        );
      
        runEngine.sendMessage(requestMessage.id, requestMessage);
      };

    verificationSuccess = (data: VerificationData[], meta: Meta) => {
        if (data) {
            if(meta.total_pages>=meta.current_page) {
                this.setState({
                    verifications: data,
                    total_pages: meta.total_pages,
                    current_page: meta.current_page,
                    total_count: meta.total_count,
                    statusCounts: {
                        approved: meta.counter.approved,
                        pending: meta.counter.pending,
                        rejected: meta.counter.rejected
                    }
                });
            } else {
                this.verificationRequestGet(meta.total_pages)
            }
        } else {
            this.setState({
                verifications: [],
                total_pages: 1,
                current_page: 1,
                total_count: 0,
                statusCounts: {
                    approved: 0,
                    pending: 0,
                    rejected: 0
                }
            });
        }
    };
    

    handleSearch = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const searchTerm = event.target.value;
        this.setState({ searchTerm},
            () => this.verificationRequestGet(this.state.currentPage)
        );
    };

    handlePageChange = (_event: React.ChangeEvent<unknown>, page: number) => {
        if(page >= 1 && page <= this.state.total_pages) {
            this.verificationRequestGet(page);
        }
    };

    async  handleNavigation(user: any) {
        await setStorageData("selectedRequest", this.state.selectedRequest.id);
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), "dashboardRequests");
        const raiseMessage: Message = new Message(getName(MessageEnum.NavigationPayLoadMessage));
        raiseMessage.addData(getName(MessageEnum.SessionResponseData), { user });
        message.addData(getName(MessageEnum.NavigationRaiseMessage), raiseMessage);
        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    };

    formatDate(isoString: string) {
        const date = new Date(isoString);
        const day = date.getUTCDate();
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
        const month = monthNames[date.getUTCMonth()];
        const year = date.getUTCFullYear();
        return `${day} ${month} ${year}`;
    };
    
    // Customizable Area End
}
