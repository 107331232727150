import React, { Component } from "react";

import {
    Box,
    Typography,
    styled,
    Grid,
    Divider,
    Avatar,
    IconButton
} from "@material-ui/core";

import MonetizationOnOutlinedIcon from '@material-ui/icons/MonetizationOnOutlined';
import PersonOutlinedIcon from '@material-ui/icons/PersonOutlined';
import NotesIcon from "@material-ui/icons/Notes";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import ExpandMoreRoundedIcon from '@material-ui/icons/ExpandMoreRounded';
import ExpandLessRoundedIcon from '@material-ui/icons/ExpandLessRounded';
import { avgIcon } from "../../assets";
const utills = require('../../../../blocks/utilities/src/utills');
const languageTranslationData = require('../../../../blocks/languagesupport/src/LanguageData/Listing.json');

type FileArray = (File | null | IDocumentInfo)[];

interface IDocumentInfo {
    file_name: string | null
    url: string | null;
    size: string | null;
};
interface PropsObjectItem {
    time: string;
    profilePicture?: string;
    portfolioSize: string;
    listingTitle: string;
    averageBalance: string;
    accountCount: string;
    totalNumber: string | number;
    totalAmount: string | number;
    totalJudgement: string | number;
    totalMonth: string | number;
    pastPay: string;
    monthAvg: string | number;
    lastCollDay: string;
    lastCollMonth: string;
    lastCollYear: string;
    lastCredDay: string;
    lastCredMonth: string;
    lastCredYear: string;
    typeOfDebt:string;
    ageOfDebt:string;
    recoveryStatus:string;
    supportDocArray: FileArray,
    otherDocArray: FileArray,
    name: string;
    email: string;
    phoneNumber: string;
    country: string;
    state: string;
    city: string;
    zip: string;
    admin?: boolean;
};

interface DebtBuyerInfoType {
    image: string;
    name: string;
    email: string;
    phone: string;
    country: string;
    state: string;
    city: string;
    zipcode: string;
};

interface Props {
    previewValues: PropsObjectItem;
    toggleHide: boolean;
    debtBuyerInfo: DebtBuyerInfoType| null;
    showFullList: boolean;
    handleFullListShow: () => void;
}

interface S {
    open: boolean;
    currentLanguage: 'ar' | 'en';
    array: (File|null)[];
    updateTimeString: string;
}

interface SS {
    id: any;
}

export default class UserProfileDrawer extends Component<Props, S, SS> {
    private intervalId: NodeJS.Timeout | null = null;
    constructor(props: Props) {
        super(props);
        this.state = {
            open: false,
            currentLanguage: 'en',
            array: Array(2).fill(null),
            updateTimeString: ""
        };
    }

    static defaultProps = {
        toggleHide: false,
        debtBuyerInfo: null,
        showFullList: true,
        handleFullListShow: () => null
    };

    async componentDidMount() {
        const selectedLanguage = await utills.getLanguageOrSetCurrentLanguage();
        this.setState({
            currentLanguage: selectedLanguage
        });
        if(this.props.previewValues.time !== "none") {
            this.updateTimeAgo(this.props.previewValues.time);
            this.intervalId = setInterval(() => {
              this.updateTimeAgo(this.props.previewValues.time);
            }, 30000);
        }   
    };

    updateTimeAgo(dateString: string) {
        const timeAgoString = this.timeAgo(dateString);
        this.setState({ updateTimeString: timeAgoString });
      }
    
    timeAgo(dateString: string): string {
        const givenDate = new Date(dateString);
        const now = new Date();
        const diffInMs = now.getTime() - givenDate.getTime();
        if (diffInMs < 0) {
          return "Not posted yet";
        }
        const diffInMinutes = Math.floor(diffInMs / (1000 * 60));
        const diffInHours = Math.floor(diffInMinutes / 60);
        const diffInDays = Math.floor(diffInHours / 24);
        if (diffInMinutes < 60) {
          return `Posted ${diffInMinutes} minute${diffInMinutes !== 1 ? 's' : ''} ago`;
        } else if (diffInHours < 24) {
          return `Posted ${diffInHours} hour${diffInHours !== 1 ? 's' : ''} ago`;
        } else if (diffInDays < 3) {
          return `Posted ${diffInDays} day${diffInDays !== 1 ? 's' : ''} ago`;
        } else {
          return `Posted on ${givenDate.toLocaleDateString("en-GB")}`;
        }
    };

    isFile = (file: File | IDocumentInfo): file is File => {
        return (file as File).name !== undefined;
    };

    convertToNumber = (size: string) => {
        const sizeWithoutUnit = size.replace("KB", "").trim();
        const sizeInNumber = parseFloat(sizeWithoutUnit);
        return sizeInNumber.toFixed(3);
    };

    renderSecondBox = () => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        const basic = languageData.basicInfofields;
        const {portfolioSize, accountCount, averageBalance} = this.props.previewValues;
        return (
            <Box style={webStyle.secBox as React.CSSProperties}>
                {[
                    {icon:<MonetizationOnOutlinedIcon/>,value:portfolioSize, label:languageData.PortfolioSize}, 
                    {icon:<PersonOutlinedIcon/>,value:accountCount, label:basic.AccountCount},
                    {icon:<img src={avgIcon} alt="avg_icon"/>,value:averageBalance, label:basic.AverageBalance}
                ].map((item, index) => 
                <Box key={index} style={{display: "flex", gap: "12px"}}>
                    <Box>{item.icon}</Box>
                    <Box>
                        <Typography style={webStyle.secValueText}>{index===1? item.value: `$${item.value}`}</Typography>
                        <Typography style={{color: "#334155"}}>{item.label}</Typography>
                    </Box>
                </Box>)}
            </Box>
        );
    };

    renderDetailInfoBox = () => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        const { listingSummary } = languageData;
        const {
            totalNumber, 
            totalAmount, 
            pastPay, 
            monthAvg, 
            totalJudgement, 
            lastCollDay, 
            lastCollMonth, 
            lastCollYear,
            lastCredDay,
            lastCredMonth,
            lastCredYear,
            totalMonth
        } = this.props.previewValues;
        return (
            <Box>
                <Typography style={webStyle.boxHeadingText}>{languageData.DetailedInformation}</Typography>
                <DetailBodyBox container spacing={2}>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <Typography style={webStyle.detailInfLabel}>{listingSummary.TotalNumber}:</Typography>
                            <Typography>{totalNumber}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <Typography style={webStyle.detailInfLabel}>{listingSummary.PastPaymentMade}:</Typography>
                            <Typography>{pastPay}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <Typography style={webStyle.detailInfLabel}>{listingSummary.TotalAmount}:</Typography>
                            <Typography>${totalAmount}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <Typography style={webStyle.detailInfLabel}>{listingSummary.MonthAveragePayTime}:</Typography>
                            <Typography>{monthAvg}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <Typography style={webStyle.detailInfLabel}>{listingSummary.TotalJudgements}:</Typography>
                            <Typography>{totalJudgement}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <Typography style={webStyle.detailInfLabel}>{listingSummary.LastCollectorContact}:</Typography>
                            <Typography>{`${lastCollDay}.${lastCollMonth}.${lastCollYear}`}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <Typography style={webStyle.detailInfLabel}>{listingSummary.TotalMonthAsCustomer}:</Typography>
                            <Typography>{totalMonth}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6}>
                        <Box>
                            <Typography style={webStyle.detailInfLabel}>{listingSummary.LastCreditorContact}:</Typography>
                            <Typography>{`${lastCredDay}.${lastCredMonth}.${lastCredYear}`}</Typography>
                        </Box>
                    </Grid>
                </DetailBodyBox>
            </Box>
        );
    };

    renderPrevInputUpp = (
        phone: string,
        country: string,
        state: string,
        city: string,
        zipcode: string
    ) => {
        const {debtOwner} = languageTranslationData[this.state.currentLanguage];
        return (
            <PrevInBox style={webStyle.prevInWrapper}>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{`(${phone.substring(0,3)}) ${phone.substring(3,6)}-${phone.substring(6)}`}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{debtOwner.PhoneNumber}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{country}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{debtOwner.Country}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{state}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{debtOwner.State}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{city}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{debtOwner.City}</Typography>
                </Box>
                <Box >
                    <Typography style={webStyle.prevInUpValText}>{zipcode}</Typography>
                    <Typography style={{color:"#334155", fontSize: "16px"}}>{debtOwner.Zip}</Typography>
                </Box>
            </PrevInBox>
        )
    };

    renderOwnerInfoBox = (
        heading: string,
        image:string | undefined, 
        name: string, 
        email: string, 
        phone: string,
        country: string,
        state: string,
        city: string,
        zipcode: string
     ) => {
        return (
            <Box>
                <Typography style={webStyle.boxHeadingText}>{heading}</Typography>
                <Box style={webStyle.avatarTextWrapper}>
                    <Avatar
                        src={image? image: "prfile_picture"}
                        alt="prfile_picture"
                        style={webStyle.avatarStyle}
                    />
                    <Box style={webStyle.profileTextWrapper}>
                        <Typography style={webStyle.profileTextStyle}>
                            {
                               this.props.previewValues.admin? `Admin`: name 
                            }
                        </Typography>
                        {!this.props.previewValues.admin &&
                            <Typography style={webStyle.sizeTextStyle}>
                                {email}
                            </Typography>
                        }
                    </Box>
                </Box>
                { !this.props.previewValues.admin &&
                    this.renderPrevInputUpp(phone, country, state, city, zipcode)
                }
            </Box>
        );
    };

    renderPortfolioFile = (file:File | null | IDocumentInfo, index:number) => {
        const languageData = languageTranslationData[this.state.currentLanguage];
        return (
            <CustomGrid key={index} item xs={12} sm={12} md={6}>
                <Typography>
                    {languageData.Portfolio}
                </Typography>
                <Box style={webStyle.fileInputWrapper}>
                    <Box style={webStyle.noteIconBox}>
                        <NotesIcon style={{color: "#2563EB"}}/>
                    </Box>
                    {file !== null && 
                        <Box style={{flexGrow:1, width:"50%"}}>
                            {this.isFile(file) ? (
                                <>
                                    <Typography style={webStyle.fileNameStyle}>{file.name}</Typography>
                                    <Typography style={webStyle.fileSizeStyle}>{((file.size) / 1024).toFixed(2)} KB</Typography>
                                </>
                            ) :
                            (
                                <>
                                    <Typography style={webStyle.fileNameStyle}>{file.file_name}</Typography>
                                    <Typography style={webStyle.fileSizeStyle}>{(file.size && this.convertToNumber(file.size))} KB</Typography>
                                </>
                            )}
                        </Box>
                    }
                    <IconButton 
                        data-test-id={`fileMoreTestID${index}`}
                        aria-label="more1"
                        aria-haspopup="true"
                        id = {`fileArray${index}`}
                    >
                        <MoreVertIcon style={webStyle.fileMoreIcon} />
                    </IconButton>
                </Box>
                
            </CustomGrid>
        );
    };

    render() {
        const languageData = languageTranslationData[this.state.currentLanguage];
        const { 
            profilePicture,
            listingTitle, 
            recoveryStatus, 
            time, 
            typeOfDebt, 
            ageOfDebt, 
            supportDocArray, 
            otherDocArray,
            name,
            email,
            phoneNumber,
            country, 
            state, 
            city, 
            zip
        } = this.props.previewValues;
        const debtBuyer = this.props.debtBuyerInfo;
        return (
            <Box style={webStyle.mainBox}>
                <Box>
                    <Box style={{
                            display: "flex", 
                            justifyContent: "space-between",
                            alignItems: "center",
                            marginBottom: "10px"
                        }}
                    >
                        <Typography style={webStyle.headingText}>
                            {listingTitle}
                        </Typography>
                        {this.props.toggleHide &&
                            <IconButton
                                onClick={this.props.handleFullListShow}
                            >
                                {!this.props.showFullList?
                                    <ExpandMoreRoundedIcon/>:
                                    <ExpandLessRoundedIcon/>
                                }
                            </IconButton>
                        }
                    </Box>
                    <Box style={webStyle.statusTimeWrapper}>
                        <Typography 
                            style={{
                                ...webStyle.activeText, 
                                background: recoveryStatus === "active"? "#D1FAE5": "#FEE2E2",
                                color: recoveryStatus === "active"? "#059669": "#DC2626"
                            }}
                        >
                            {recoveryStatus === "active"? "Active Collection": "Inactive Collection"}</Typography>
                        <Typography style={webStyle.timeText}>
                            {
                                time === "none"?
                                "Not Yet Posted": 
                                `${this.state.updateTimeString}`
                            }
                            
                        </Typography>
                    </Box>
                </Box>
                <CustomDivider/>
                {this.renderSecondBox()}
                {(this.props.showFullList || !this.props.toggleHide) && 
                    <>
                        <CustomDivider/>
                        <Box style={{...webStyle.secBox, maxWidth:"270px"}}>
                            <Box>
                                <Typography style={{...webStyle.secValueText, textTransform: "capitalize"}}>{typeOfDebt}</Typography>
                                <Typography style={{color: "#334155"}}>{languageData.TypeofDebt}</Typography>
                            </Box>
                            <Box>
                                <Typography style={webStyle.secValueText}>{ageOfDebt} {languageData.years}</Typography>
                                <Typography style={{color: "#334155"}}>{languageData.DebtCharacFields.age}</Typography>
                            </Box>
                        </Box>
                        <CustomDivider/>
                        {this.renderDetailInfoBox()}
                        <CustomDivider/>
                        <Box>
                            <Typography style={webStyle.boxHeadingText}>{languageData.SupportingDocumentation}</Typography>
                            <Grid container spacing={4} xs={12} sm={12} md={12}>
                                    {supportDocArray && supportDocArray.map((item, index) =>
                                        item===null
                                        ? null
                                        : this.renderPortfolioFile(item, index)
                                    )}
                            </Grid>
                        </Box>
                        <CustomDivider/>
                        <Box>
                            <Typography style={webStyle.boxHeadingText}>{languageData.Otherdocuments}</Typography>
                            <Grid container spacing={4} xs={12} sm={12} md={12}>
                                    {otherDocArray && otherDocArray.map((item, index) =>
                                        item===null
                                        ? null
                                        : this.renderPortfolioFile(item, index)
                                    )}
                            </Grid>
                        </Box>
                        <CustomDivider/>
                        {this.renderOwnerInfoBox( languageData.debtOwner.heading, profilePicture, name, email, phoneNumber, country, state, city, zip)}
                        {
                            debtBuyer &&
                            <>
                                <CustomDivider/>
                                {this.renderOwnerInfoBox(
                                    languageData.DebtBuyerInformation,
                                    debtBuyer.image, 
                                    debtBuyer.name, 
                                    debtBuyer.email, 
                                    debtBuyer.phone, 
                                    debtBuyer.country, 
                                    debtBuyer.state, 
                                    debtBuyer.city, 
                                    debtBuyer.zipcode
                                )}
                            </>
                        }
                    </>
                }
            </Box>
        );
    }
}

const CustomDivider = styled(Divider)({
    margin:"25px 0 45px",
    background: "#E2E8F0",
    display: "block !important"
});

const PrevInBox = styled(Box)({
    justifyContent: "space-between",
    "@media(max-width: 1200px)":{
        justifyContent: "flex-start"
    }
});

const CustomGrid = styled(Grid)({
    "& .MuiTypography-root": {
      color: "#334155",
      fontSize: "14px",
      fontWeight: 700,
      marginBottom: "8px"
    },
    "& .MuiTextField-root": {
      "& .MuiOutlinedInput-root": {
        "& fieldset": {
          borderRadius: "8px",
          border: "1px solid #CBD5E1"
        }
      },
      "& .MuiInputBase-input": {
        height: "30px",
        color: "#0F172A",
        fontSize: "14px",
        fontWeight: "400",
        "&.Mui-disabled": {
          WebkitTextFillColor: "#0F172A"
        }
      },
      "& .MuiInputBase-input::placeholder": {
        fontSize: "16px",
        color: "#94A3B8",
        opacity: 1
      }
    }
});

const DetailBodyBox = styled(Grid)({
    "& .MuiGrid-root": {
        maxWidth: "750px",
        "@media(max-width: 800px)":{
            flexBasis: "100%"
        },
        "& .MuiBox-root": {
            display: "flex",
            gap: "5px",
            "& .MuiTypography-root": {
                color: "#334155",
                fontSize: "16px",
                whiteSpace: "nowrap"
            }
        }
    }
});

const webStyle = {
    statusTimeWrapper: {
        display: "flex", 
        gap: "15px", 
        alignItems: 
        "center", 
        flexWrap: "wrap"
    } as React.CSSProperties,
    avatarTextWrapper: {
        display: "flex",
        gap: "10px",
        alignItems: "center",
        marginBottom: "25px"
    },
    secBox: {
        display:"flex",
        flexWrap: "wrap",
        justifyContent: "space-between",
        maxWidth: "700px",
        gap: "25px"
    } as React.CSSProperties,
    detailInfLabel:{
        fontWeight: 600,
        color: "#334155",
        fontSize: "16px"
    },
    boxHeadingText: {
        fontWeight: 600,
        color: "#0F172A",
        fontSize: "20px",
        marginBottom: "15px"
    },
    secValueText:{
        fontWeight: 600,
        color: "#0F172A",
        fontSize: "18px",
        marginBottom: "10px"
    },
    timeText: {
        color: "#64748B",
        fontSize: "14px",
        fontWeight: 500
    },
    headingText: {
        fontWeight: 700,
        fontSize: "24px",
        color: "#0F172A"
    },
    activeText: {
        borderRadius: "40px",
        fontWeight: 700,
        fontSize: "12px",
        padding: "3px 10px",
        width: "fit-content",
        textTransform: "uppercase"
    } as React.CSSProperties,
    avatarStyle: {
        width: "80px",
        height: "80px"
    },
    mainBox: {
        padding: "40px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "24px",
        background: "white"
    },
    prevInUpValText: {
        color: "#0F172A",
        fontWeight: 600,
        fontSize: "18px",
        marginBottom: "10px"
    },
    prevInWrapper:{
        display:"flex",
        gap:"30px",
        flexWrap: "wrap"
    } as React.CSSProperties,
    sizeTextStyle: {
        color: "#334155",
        fontSize: "16px",
        fontSeight: 400
    },
    profileTextStyle: {
        color: "#0F172A",
        fontSize: "20px",
        fontWeight: 600
    },
    profileTextWrapper: {
        display: "flex",
        flexDirection: "column",
        gap: "5px"
    } as React.CSSProperties,
    fileMoreIcon: {
        color: "#475569",
        cursor: "pointer"
    },
    fileSizeStyle: {
        color: "#64748B",
        fontWeight: 400,
        fontSize: "16px",
        marginBottom: "0",
    },
    fileNameStyle: {
        color: "#0F172A",
        fontSize: "16px",
        fontWeight: 400,
        marginBottom: "0",
        width:"100%",
        overflow: "hidden",
        textOverflow: "ellipsis"
    },
    fileInputWrapper: {
        display: "flex",
        alignItems: "center",
        padding: "8px",
        boxShadow: "0px 0px 8px 4px #00000008",
        borderRadius: "8px",
        gap: "14px"
    },
    noteIconBox: {
        backgroundColor: "#DBEAFE",
        borderRadius: "8px",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "56px",
        minWidth: "56px",
        height: "56px",
        cursor: "pointer"
    }
}