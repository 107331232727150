import React from "react";

// Customizable Area Start
import { Box, IconButton, Button, Divider, Menu, Avatar, Typography } from "@material-ui/core";
import { createTheme, styled } from "@material-ui/core/styles";
import Image from "../../../components/src/design-system/Image/Image";
import NavigationMenuControllerWeb, { Props as NavigationProps } from "./NavigationMenuController.web";
import TranslateIcon from '@material-ui/icons/Translate';

// Customizable Area End

import NavigationMenuController, {
  Props,
  configJSON,
} from "./NavigationMenuController";

// Customizable Area Start
import { avatarIcon, btnPath, companyLogo, languageToggleIcon, notifactionIcon } from "./assets";
const languageTranslation = require('../../languagesupport/src/LanguageData/topNav.json')

export default class NavigationMenu extends NavigationMenuControllerWeb {
  constructor(props: NavigationProps) {
    super(props);
    // Customizable Area Start
    // Customizable Area End
  }

  // Customizable Area Start

  renderAvatar = () => {
    return (
      this.state.profile ?
        <IconButton onClick={() => this.props.handleChange('e', 9)} id="avatarclick-test">
          <Avatar src={avatarIcon} style={{ width: '48px', height: '48px', border: "3px solid #DBEAFE", boxSizing: "border-box" }} />
        </IconButton>
        : null
    )
  }

  renderToggler = () => {
    return (
      this.props.smallScreen ?
        <IconButton data-test-id="cta-showMenu" onClick={this.toggleMenu}>
          {
            this.state.showMenu ?
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px"><path d="M 9.15625 6.3125 L 6.3125 9.15625 L 22.15625 25 L 6.21875 40.96875 L 9.03125 43.78125 L 25 27.84375 L 40.9375 43.78125 L 43.78125 40.9375 L 27.84375 25 L 43.6875 9.15625 L 40.84375 6.3125 L 25 22.15625 Z" /></svg>
              :
              <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 50 50" width="20px" height="20px"><path d="M 0 7.5 L 0 12.5 L 50 12.5 L 50 7.5 L 0 7.5 z M 0 22.5 L 0 27.5 L 50 27.5 L 50 22.5 L 0 22.5 z M 0 37.5 L 0 42.5 L 50 42.5 L 50 37.5 L 0 37.5 z" /></svg>
          }
        </IconButton>
        : null
    )
  }
  // Customizable Area End

  render() {
    // Customizable Area Start
    const languageData = languageTranslation[this.state.currentLanguage];
    const MenuButton = styled(Box)({
      cursor: "pointer",
      background: 'transparent',
      display: 'flex',
      width: "100%",
      boxSizing: "border-box",
      textTransform: 'capitalize',
      padding: '12px 16px',
      alignItems: 'center',
      transition: 'background-color 0.3s',
      color: "#0F172A",
      fontSize: "16px",
      fontWeight: 400,
      lineHeight: "24px",
      '&:hover': {
        background: '#CBD5E1'
      }
    });

    const StyledMenu = styled(Menu)({
      "& .MuiList-root": {
        padding: "0px"
      }
    })

    const renderButton = () => {
      return (
        (!this.state.profile) &&
        <Button
          data-test-id="cta-login"
          variant="contained"
          style={classes.btn}
          color="primary"
          disableElevation
          onClick={() => this.handleNavigationLinkClick('e', this.state.isAdmin ? 10 : 8)}
        >
          <img src={btnPath} style={classes.btnImage} alt="path" /> <Typography style={classes.btnText}>{languageData.loginBtnText}</Typography>
        </Button>
      )
    }
    const divider = this.props.smallScreen && <Divider style={{ width: "70%" }} />;
    return (
      <Box sx={classes.tabContainerAuto}>
        <Box
          style={classes.tab}
        >
          <Image src={companyLogo} alt="Jeddah" data-test-id="cta-Image" style={classes.logoImage} onClick={() => { this.handleNavigationLinkClick(configJSON.Home, 0); }} />
          {!this.state.isAdmin && <ButtonBox sx={{ right: `${this.state.showMenu ? "0px" : "-100%"}` }} style={this.props.smallScreen ? classes.smallTabWrapper : classes.tabWrapper}>
            <Button data-test-id="cta-Creditors" variant="text" onClick={() => { this.handleNavigationLinkClick(configJSON.Creditors, 1); }} style={{ ...classes.tabs}}>{languageData.navLinks.Creditors}</Button>
            {divider}
            <Button data-test-id="cta-DebtBuyer" variant="text" onClick={() => { this.handleNavigationLinkClick(configJSON.DebtBuyer, 2); }} style={{ ...classes.tabs}}>
              { this.state.userType === "debt_owner"?
                languageData.navLinks.DebtOwners:
                languageData.navLinks.DebtBuyer
              }
            </Button>
            {divider}
            <Button data-test-id="cta-Industries" variant="text" onClick={() => { this.handleNavigationLinkClick(configJSON.Industries, 3); }} style={{ ...classes.tabs}}>{languageData.navLinks.Industries}</Button>
            {divider}
            <Button data-test-id="cta-Services" variant="text" onClick={() => { this.handleNavigationLinkClick(configJSON.Services, 5) }} style={{ ...classes.tabs}}>{languageData.navLinks.Services}</Button>
            {divider}
            <Button data-test-id="cta-Contact" variant="text" onClick={() => { this.handleNavigationLinkClick(configJSON.Contact, 6) }} style={{ ...classes.tabs}}>{languageData.navLinks.Contact}</Button>
            {divider}
            {this.state.profile ?
              <Button data-test-id="cta-About" variant="text" onClick={() => { this.handleNavigationLinkClick(configJSON.About, 4); }} style={{ ...classes.tabs}}>{languageData.navLinks.About}</Button>
              : null}
            {this.props.smallScreen && renderButton()}
          </ButtonBox>}
          <IconButton style={{ margin: 'auto' }} data-test-id='toggle-language' onClick={this.handleChangeLanguage} >
            <TranslateIcon style={{color: "#0F172A"}}/>
          </IconButton>
          {this.state.profile ?

            <IconButton data-test-id='toggle-language'>
              <img src={notifactionIcon} alt="change_laguage_icon" />
            </IconButton>
            : null}
          {
            this.renderAvatar()
          }
          {
            this.renderToggler()
          }
          {
            !this.props.smallScreen &&
            renderButton()
          }
        </Box>
      </Box>
    )
    // Customizable Area End
  }
}

// Customizable Area End

// Customizable Area Start
const theme = createTheme({
  palette: {
    primary: {
      main: "#0F172A",
      contrastText: "#fff",
    },
  },

  typography: {
    h6: {
      fontWeight: 500,
    },
    subtitle1: {
      margin: "20px 0px",
    },
  },
});

const ButtonBox = styled(Box)({
  "@media (max-width: 1280px)": {
    gap: "8px !important",
    padding: "0 10px !important"
  }
});

const classes = {
  logoImage: {
    cursor: "pointer",
    width: "120px",
    height: "40px",
    objectFit: "cover"

  },
  btnImage: {
    height: "16px",
    width: "16px",
    color: "#0F172A",
    marginRight:"6px",

  },
  btnText:{
    marginTop:"4px",
    fontSize:"16px",
    fontWeight:"700",
    color:"#0F172A"

  },
  btn: {
    widht: "96px",
    gap: "4px",
    display: "flex",
    alignItems: "center",
    fontWeight: 700,
    color: "#0F172A",
    textTransform: "capitalize",
    fontSize: "16px",
    backgroundColor: "#FFFFFF"
  } as React.CSSProperties,
  tabWrapper: {
    flex: 1,
    justifyContent: "center",
    display: "flex",
    gap: "15px",
    boxSizing: "border-box",
    padding: "0px 20px"
  } as React.CSSProperties,
  smallTabWrapper: {
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
    boxSizing: "border-box",
    position: "absolute",
    flexDirection: "column",
    backgroundColor: "white",
    width: " 100%",
    top: "80px",
    maxWidth: "400px",
    gap: "40px",
    transition: "all 0.5s ease"
  } as React.CSSProperties,
  tabs: {
    minWidth: "auto",
    padding: "8px 16px",
    textTransform: "capitalize",
    fontSize: "16px",
    fontWeight: 700,
    color: "#334155",
    cursor: "pointer",
    whiteSpace:"nowrap"
  } as React.CSSProperties,
  tab: {
    display: "flex",
    width: "100%",
    alignItems: "center",
    gap: "1px",
    justifyContent: "space-between"
  },
  tabContainer: {
    flexGrow: 1,
    backgroundColor: '#fff',
    color: "#0F172A",
    alignItems: 'center',
    justifyContent: 'center',
    display: 'grid',
    minHeight: '80px',
    "& .MuiTabs-flexContainer": {
      width: "100%",
      minWidth: "1160px",
      justifyContent: "space-between",
      maxWidth: '1200px'
    },
  },

  tabContainerAuto: {
    maxWidth: '1200px',
    margin: '0 auto',
    width: "100%",
    height: "80px",
    boxSizing: "border-box",
    background: "#FFFFFF",
    display: "flex",
    alignItems: "center",
    padding: "18px 5px",
    "@media (max-width: 1280px)": {
      maxWidth: '1000px'
    },
    "@media (max-width: 990px)": {
      padding: "18px 20px"
    },
    "@media (max-width: 380px)": {
      padding: "18px 10px",
      "& .MuiIconButton-root": {
        padding: "5px !important"
      }
    }
  },


};
// Customizable Area End
