import { IBlock } from "../../../framework/src/IBlock";
import { Message } from "../../../framework/src/Message";
import { BlockComponent } from "../../../framework/src/BlockComponent";
import MessageEnum, {
    getName
} from "../../../framework/src/Messages/MessageEnum";
import { runEngine } from "../../../framework/src/RunEngine";

// Customizable Area Start
import { getStorageData, removeStorageData } from "framework/src/Utilities";
const utills = require('../../utilities/src/utills');
const languageTranslationData = require('../../languagesupport/src/LanguageData/BusinessProfile.json');
import { createRef } from 'react';

type FileArray = (File | DocResponse | null)[];

type LogoType = File | DocResponse | null;

export interface ValidResponseType {
    data: BusinessProfileItem[];
    success: string;
};

interface BusinessProfileItem {
    id: string;
    type: string;
    attributes: {
        name: string;
        website: string;
        industry: string;
        registration: string;
        country: string;
        state: string;
        city: string;
        zip: string;
        services: string[];
        bio: string;
        image: DocResponse;
        documents: DocResponse[];
    };
};

interface DocResponse {
    file_name: string;
    url: string;
    size: string;
};

// Customizable Area End

export const configJSON = require("./config");

export interface Props {
    navigation: any;
    id: string;
    // Customizable Area Start
    // Customizable Area End
}

interface S {
    // Customizable Area Start
    selectedLanguage: "en" | "ar";
    breadscrumArray:string[];
    isDataAvailable: boolean;
    editModalOpen: boolean;
    pageHeading: string;
    saveCheck: boolean;
    errors:{
        clientNameErr: string;
        websiteErr: string;
        sectorErr: string;
        regNumberErr: string;
        countryErr: string;
        stateErr: string;
        cityErr: string;
        zipErr: string;
        serviceErr: string;
        bioErr: string;
        portfolioErr: string;
    },
    businessProfileId: string;
    logo: LogoType;
    sector:string;
    clientName: string;
    website: string;
    regNumber: string;
    country: string;
    state: string;
    city: string;
    zipCode: string;
    bio: string;
    portfolioArray:FileArray;
    serviceArray: string[];
    serviceInput: string;
    anchorEl: null | HTMLElement;
    showPreview: boolean,
    showModal: boolean;
    // Customizable Area End
}

interface SS {
    id: any;
    // Customizable Area Start
    // Customizable Area End
}

export default class BusinessProfileController extends BlockComponent<Props, S, SS> {
    // Customizable Area Start
    protected fileInputRef = createRef<HTMLInputElement>();
    getBusinessProfileCallId: string = "";
    createBusinessProfileCallId: string = "";
    updateBusinessProfileCallId: string = "";
    deleteBusinessProfileCallId: string = "";
    // Customizable Area End

    constructor(props: Props) {
        super(props);
        this.receive = this.receive.bind(this);

        this.subScribedMessages = [
            getName(MessageEnum.AccoutLoginSuccess),
            getName(MessageEnum.RestAPIResponceMessage),
            getName(MessageEnum.RestAPIRequestMessage),
            // Customizable Area Start
            // Customizable Area End
        ];

        this.state = {
            // Customizable Area Start
            selectedLanguage: "en",
            breadscrumArray: [],
            isDataAvailable: false,
            editModalOpen: false,
            pageHeading: "",
            saveCheck: false,
            errors:{
                clientNameErr: "",
                websiteErr: "",
                sectorErr: "",
                regNumberErr:"",
                countryErr: "",
                stateErr: "",
                cityErr: "",
                zipErr: "",
                serviceErr: "",
                bioErr: "",
                portfolioErr: "",
            },
            businessProfileId: "",
            sector:"",
            clientName: "",
            website: "",
            regNumber: "",
            country: "",
            state: "",
            city: "",
            zipCode: "",
            bio: "",
            portfolioArray:Array(2).fill(null),
            serviceArray:[],
            serviceInput:"",
            anchorEl: null,
            logo:null,
            showPreview: false,
            showModal: false
            // Customizable Area End
        };
        runEngine.attachBuildingBlock(this as IBlock, this.subScribedMessages);

        // Customizable Area Start
        // Customizable Area End

    }

    async receive(from: string, message: Message) {
        runEngine.debugLog("Message Recived", message);
        // Customizable Area Start
        if (getName(MessageEnum.RestAPIResponceMessage) === message.id) {
            const apiRequestCallId = message.getData(
                getName(MessageEnum.RestAPIResponceDataMessage)
            );
            let responseJson = message.getData(
                getName(MessageEnum.RestAPIResponceSuccessMessage)
            );
            if (apiRequestCallId && responseJson) {
                if(!responseJson.errors){
                    this.apiSuccessCallBacks(apiRequestCallId, responseJson);
                }
            }
        }
        // Customizable Area End
    }
    // Customizable Area Start

    async componentDidMount() {
        const selectedLanguage = await utills.getLanguageOrSetCurrentLanguage();
        let accessToken = await getStorageData("accessToken");
        let user = await getStorageData("userType");
        const heading = languageTranslationData[selectedLanguage].BusinessProfile;
        if (!accessToken || !user ) {
            this.navigationRoute("Home");
        }
        this.setState({
            selectedLanguage: selectedLanguage,
            pageHeading: heading
        });
        this.getBusinessProfile();
    };

    apiSuccessCallBacks = (
        apiRequestCallId: string,
        responseJson: ValidResponseType
        ) => {
        if (apiRequestCallId === this.getBusinessProfileCallId) {
            this.getBusinessProfileSuccessResponse(responseJson.data);
        }
        if (apiRequestCallId === this.createBusinessProfileCallId) {
            this.getBusinessProfile();
        }
        if (apiRequestCallId === this.updateBusinessProfileCallId) {
            this.getBusinessProfile();
            this.createUpdateBusinessProfileSuccess();
        }
        if (apiRequestCallId === this.deleteBusinessProfileCallId) {
            this.getBusinessProfile();
            this.setState({
                showModal: false
            });
        }
    };

    getBusinessProfile = async() => {
        const userId = await getStorageData("userId")
        const params = `?account_id=${userId}`
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.getBusinessProfileCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.BusinessEndPoints}${params}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.validationApiMethodType
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    createBusinessProfile = async() => {
        const { 
            logo, 
            clientName, 
            website, 
            sector, 
            regNumber, 
            country,
            state,
            city,
            zipCode,
            bio,
            serviceArray,
            portfolioArray 
        } = this.state;
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateBusinessProfileCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            configJSON.BusinessEndPoints
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.loginAPiMethod
        );
        const formData = new FormData();
        formData.append("[business_profile][name]", clientName);
        formData.append("[business_profile][website]", website);
        formData.append("[business_profile][industry]", sector);
        formData.append("[business_profile][registration]", regNumber);
        formData.append("[business_profile][country]", country);
        formData.append("[business_profile][state]", state);
        formData.append("[business_profile][city]", city);
        formData.append("[business_profile][zip]", zipCode);
        formData.append("[business_profile][bio]", bio);
        if (logo !== null && !this.isDocResponse(logo)) {
            formData.append("[business_profile][image]", logo);
        }
        serviceArray
        .forEach(service => {
            return formData.append("business_profile[services][]", service);
        });
        portfolioArray
        .forEach(file => {
            if(file !== null && !this.isDocResponse(file)) {
                return formData.append("business_profile[documents][]", file);
            }
        });
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    updateBusinessProfile = async() => {
        const { 
            businessProfileId,
            logo, 
            clientName, 
            website, 
            sector, 
            regNumber, 
            country,
            state,
            city,
            zipCode,
            bio,
            serviceArray,
            portfolioArray 
        } = this.state;
        let updateLogo = null;
        if(logo !== null) {
            if(logo instanceof File) {
                updateLogo = logo;
            } else {
                updateLogo = await this.fetchFileFromUrl(logo);
            }
        }
        const updatePortfolioArray = await this.convertFile(portfolioArray);
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.updateBusinessProfileCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.BusinessEndPoints}/${businessProfileId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.patchApiMethod
        );
        const formData = new FormData();
        formData.append("[business_profile][name]", clientName);
        formData.append("[business_profile][website]", website);
        formData.append("[business_profile][industry]", sector);
        formData.append("[business_profile][registration]", regNumber);
        formData.append("[business_profile][country]", country);
        formData.append("[business_profile][state]", state);
        formData.append("[business_profile][city]", city);
        formData.append("[business_profile][zip]", zipCode);
        formData.append("[business_profile][bio]", bio);
        if (updateLogo instanceof File) {
            formData.append("[business_profile][image]", updateLogo);
        }
        serviceArray
        .forEach(service => {
            return formData.append("business_profile[services][]", service);
        });
        updatePortfolioArray
        .forEach(file => {
            if(file instanceof File) {
                return formData.append("business_profile[documents][]", file);
            }
        });
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestBodyMessage),
            formData
        );
        const headers = {
            "token": await getStorageData("accessToken")
        }
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    deletBusinessProfile = async() => {
        const businessProfileId = this.state.businessProfileId;
        const headers = {
            "Content-Type": configJSON.loginApiContentType,
            "token": await getStorageData("accessToken")
        }
        const requestMessage = new Message(
            getName(MessageEnum.RestAPIRequestMessage)
        );
        this.deleteBusinessProfileCallId = requestMessage.messageId;
        requestMessage.addData(
            getName(MessageEnum.RestAPIResponceEndPointMessage),
            `${configJSON.BusinessEndPoints}/${businessProfileId}`
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestHeaderMessage),
            JSON.stringify(headers)
        );
        requestMessage.addData(
            getName(MessageEnum.RestAPIRequestMethodMessage),
            configJSON.methodDelete
        );
        runEngine.sendMessage(requestMessage.id, requestMessage);
    };

    convertFile = async(documentArray: (File | DocResponse | null)[]) => {
        const updateDocuments = await Promise.all(documentArray.map((document) => {
            if (document) {
                if(document instanceof File) {
                    return document;
                } else {
                    return this.fetchFileFromUrl(document);
                }
            }
            return null;
        }));
        return updateDocuments;
    };

    fetchFileFromUrl = async (document: { file_name: string; url: string; size: string }) => {
        try {
            const response = await fetch(document.url);
            const blob = await response.blob();
            return new File([blob], document.file_name, { type: blob.type });
        } catch (error) {
            return null;
        }
    };

    getBusinessProfileSuccessResponse = (data: BusinessProfileItem[]) => {
        if(data.length === 0) {
            this.setState({
                isDataAvailable: false,
                businessProfileId: "",
                logo: null,
                clientName : "",
                website: "",
                sector: "",
                regNumber: "",
                country: "",
                state: "",
                city: "",
                zipCode: "",
                serviceArray: [],
                bio: "",
                portfolioArray: Array(2).fill(null)
            });
        } else {
            let singleBusinessProfile = data[0].attributes
            this.setState({
                isDataAvailable: true,
                showPreview: false,
                editModalOpen: false,
                showModal: false,
                saveCheck: false,
                businessProfileId: data[0].id,
                logo: singleBusinessProfile.image,
                clientName : singleBusinessProfile.name,
                website: singleBusinessProfile.website,
                sector: singleBusinessProfile.industry,
                regNumber: singleBusinessProfile.registration,
                country: singleBusinessProfile.country,
                state: singleBusinessProfile.state,
                city: singleBusinessProfile.city,
                zipCode: singleBusinessProfile.zip,
                serviceArray: singleBusinessProfile.services,
                bio: singleBusinessProfile.bio,
                portfolioArray: singleBusinessProfile.documents
            });
        }
    };

    createUpdateBusinessProfileSuccess = () => {
        const languageData = languageTranslationData[this.state.selectedLanguage];
        this.setState({
            pageHeading: languageData.BusinessProfile,
            breadscrumArray: [],
            showPreview: false,
            isDataAvailable: true
        });
    };

    navigationRoute = (selectRoute: string) => {
        const message: Message = new Message(
            getName(MessageEnum.NavigationMessage)
        );
        message.addData(getName(MessageEnum.NavigationTargetMessage), selectRoute);

        message.addData(
            getName(MessageEnum.NavigationPropsMessage),
            this.props
        );
        this.send(message);
    }

    handleDrawerBreadscrumClick = (route:string)=>{
        if(route==="logOut"){
            this.handleLogout();
        } else {
            if(route.includes("breadScrum")) {
                this.handleBreadScrum(route)
            } else {
                this.navigationRoute(route);
            }
        }
    };

    handleLogout = async () => {
        await removeStorageData("profilePic");
        await removeStorageData("userType");
        await removeStorageData("accessToken");
        await removeStorageData("userId");
        await removeStorageData("otpToken");
        await removeStorageData("signupStatus");
        await removeStorageData("accessSignupToken");
        this.navigationRoute("Home");
    };

    handleBreadScrum = (value:string) => {
        if(value==="breadScrum0"){
            this.handleInputCancel();
        }
    };

    handleEditModalOpen = (isOpen:boolean) => {
        this.setState({
            editModalOpen:isOpen
        });
    };

    handleEditModalClose = () => {
        if(!this.state.showPreview) {
            this.getBusinessProfile();
        }
        this.setState({
            editModalOpen: false
        });
    };

    handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { name, value } = event.target;
        if(name==='zipCode'){
            value = value.replace(configJSON.replaceAlphabetRegex, "");
            if (value.length > 6) {
                value = value.slice(0, 6);
            }
        }
        this.setState({
            [name]: value
        } as unknown as Pick<S, keyof S>, () => this.handleErrorChecking());
    };

    handleSectorChange = (event: React.ChangeEvent<{ value:unknown}>) => {
        let {value} = event.target;
        if(typeof value==="string"){
            this.setState({sector:value}, () => this.handleErrorChecking());
        } 
    };

    handleServiceArray = (event: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = event.target;
        let { serviceArray } = this.state;
        if (value.includes(",")) {
          serviceArray.push(value.slice(0, -1));
          this.setState({ serviceArray: serviceArray, serviceInput: ""}, () => this.handleErrorChecking());
        } else {
          this.setState({ serviceInput: value }, () => this.handleErrorChecking());
        }
    };

    handleServiceBlur = () => {
        let {serviceInput, serviceArray} = this.state;
        if(serviceInput){
            serviceArray.push(serviceInput);
            this.setState({ serviceArray: serviceArray, serviceInput: ""}, () => this.handleErrorChecking());
        }
    };

    deleteServiceItem = (index: number) => {
        let array = this.state.serviceArray;
        if (index >= 0 && index < array.length) {
          array.splice(index, 1);
          this.setState({ serviceArray: array }, () => this.handleErrorChecking());
        }
    };

    handleMoreClick = (event: React.MouseEvent<HTMLElement>) => {
        this.setState({
            anchorEl: event.currentTarget
        });
    };

    handleCloseMenu = () => {
        this.setState({anchorEl:null});
    };

    handleDivClick = () => {
        if (this.fileInputRef.current) {
          this.fileInputRef.current.click();
        }
    };

    handleLogoUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
        const file: File | null = event.target.files ? event.target.files[0] : null;
        if (file && file.type.startsWith("image/")) {
            this.setState({
                logo: file
            });
        }
    };

    handleAddPortfolio = (event: React.ChangeEvent<HTMLInputElement>, index: number | null) => {
        const file: File | null = event.target.files ? event.target.files[0] : null;
        let fileArray = this.state.portfolioArray;
        let newIndex = index;
        if(index === null) {
            for (let i = 0; i < fileArray.length; i++) {
                if (fileArray[i] === null) {
                  newIndex = i;
                  break;
                }
              }
        }
        if (file) {
        this.setState(prevState => {
            const updatedArray = [...prevState.portfolioArray];
            if (newIndex !== null && newIndex >= 0 && newIndex < updatedArray.length) {
                updatedArray[newIndex] = file;
            } else {
                updatedArray.push(file);
            }
            return { portfolioArray: updatedArray, anchorEl : null };
        }, () => this.handleErrorChecking());
        }
    };

    handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const anchor = this.state.anchorEl;
        const file: File | null = event.target.files ? event.target.files[0] : null;
        if (anchor && anchor.id) {
            if (anchor.id === "componyLogo") {
                if (file && file.type.startsWith("image/")) {
                    this.setState({
                        logo: file,
                        anchorEl: null
                    });
                }
            } else {
                    const lastChar = anchor.id.slice(-1);
                    const indexNumber = Number(lastChar);
                    this.handleAddPortfolio(event, indexNumber);
            }
        }
    };

    handleFileRemove = () => {
        const anchor = this.state.anchorEl
        if (anchor && anchor.id) {
            if (anchor.id === "componyLogo") {
                this.setState({
                    logo: null,
                    anchorEl: null
                });
            } else {
                const lastChar = anchor.id.slice(-1);
                const indexNumber = Number(lastChar);
                this.handlePortfolioRemove(indexNumber);    
            }
        }
    };

    handlePortfolioRemove = (index:number) => {
        let array = this.state.portfolioArray;
        if (index >= 0 && index < array.length) {
            if(array.length>2) {
                array.splice(index, 1);
            } else {
                if(index===0 && array[1]!==null){
                    let value = array[1];
                    array[0] = value;
                    array[1] = null;
                } else {
                    array[index] = null;
                }
            }
            this.setState({ portfolioArray: array, anchorEl: null }, () => this.handleErrorChecking());
        }
        
    };

    handleErrorChecking = () => {
        let {clientName, website, sector, regNumber, country, state, city, zipCode, bio, serviceArray, selectedLanguage} = this.state;
        const errors = languageTranslationData[selectedLanguage].errors;
        let clientNameErr = "", websiteErr = "", sectorErr = "", regNumberErr = "", countryErr = "", stateErr = "", cityErr = "", zipErr = "", bioErr="";
        let serviceErr = "", portfolioErr="";
        if(!clientName){
            clientNameErr = errors.clientNameErr;
        }
        if(!website){
            websiteErr = errors.websiteErr;
        }
        if(!sector){
            sectorErr = errors.sectorErr;
        }
        if(!regNumber){
            regNumberErr = errors.regNumErr;
        }
        if(!country){
            countryErr = errors.countryErr;
        }
        if(!state){
            stateErr = errors.stateErr;
        }
        if(!city){
            cityErr = errors.cityErr;
        }
        if(!zipCode){
            zipErr = errors.zipErr;
        }
        if(!bio){
            bioErr = errors.bioErr;
        }
        if(serviceArray.length===0){
            serviceErr = errors.servceErr;
        }
        portfolioErr = this.handlePortfolioError();
        this.setState({
            errors:{
                clientNameErr: clientNameErr,
                websiteErr: websiteErr,
                sectorErr: sectorErr,
                regNumberErr: regNumberErr,
                countryErr: countryErr,
                stateErr: stateErr,
                cityErr: cityErr,
                zipErr: zipErr,
                serviceErr: serviceErr,
                bioErr: bioErr,
                portfolioErr: portfolioErr
            }
        });
        return {
                clientNameErr,
                websiteErr,
                sectorErr,
                regNumberErr,
                countryErr,
                stateErr,
                cityErr,
                zipErr,
                serviceErr,
                bioErr,
                portfolioErr
        }
    };

    handlePortfolioError = () => {
        let {portfolioArray, selectedLanguage} = this.state;
        const errors = languageTranslationData[selectedLanguage].errors;
        let isError = portfolioArray.some(item=>item===null);
        if(isError){
            return errors.portfolioErr
        } else {
            return ""
        }
    };

    handleInputCancel = () => {
        const languageData = languageTranslationData[this.state.selectedLanguage];
        this.getBusinessProfile();
        this.setState({
            anchorEl: null,
            editModalOpen: false,
            saveCheck: false,
            showPreview: false,
            breadscrumArray: [],
            pageHeading: languageData.BusinessProfile
        });
    };

    handleInputSave = () => {
        this.setState({saveCheck: true});
        let getErrors = this.handleErrorChecking();
        let checkErrors = Object.values(getErrors).every((value, index) =>
           value === undefined || value.length === 0
        );
        if(checkErrors){
            const languageData = languageTranslationData[this.state.selectedLanguage];
            const breadScrum = languageData.breadscrum;
            this.setState({
                saveCheck:false, 
                showPreview:true, 
                editModalOpen: false,
                breadscrumArray: Object.values(breadScrum),
                pageHeading: languageData.previewBusinessProfile
            });
        }
    };

    handlePrevConfirmSave = () => {
        if(this.state.isDataAvailable) {
            this.updateBusinessProfile();
        } else {
            this.createBusinessProfile();
        }
    };

    handleDeleteModalOpen = () => {
        this.setState({
            showModal: true
        });
    };

    handleDeleteModalClose = () => {
        this.setState({
            showModal: false
        });
    };

    handleLogoUrl = () => {
        let url = "";
        const logo = this.state.logo;
        if(logo !== null) {
            if (logo instanceof File) {
                url = URL.createObjectURL(logo);
            } else {
                url = logo.url
            }
        }
        return url;
    };

    handleFileOrDoc(value: File | DocResponse | null) {
        let name = "", size = "";
        if (value instanceof File) {
            name = value.name;
            size = `${((value.size)/1024).toFixed(2).toString()} KB`;
        } else if (value && this.isDocResponse(value)) {
            name = value.file_name;
            size = this.formatFileSize(value.size);
        }
        return {name, size};
    };
      
    isDocResponse(value: DocResponse | File): value is DocResponse {
        return typeof value === 'object' && 
            'file_name' in value && 
            'url' in value && 
            'size' in value;
    };

    formatFileSize(sizeInKB: string | number): string {
        const size = typeof sizeInKB === 'string' ? parseFloat(sizeInKB) : sizeInKB;
        const fixedSize = size.toFixed(3);
        return `${fixedSize} KB`;
    };
   

    // Customizable Area End
}
